import React ,{useState }from "react";
import styles from "./ZXYYMain.module.css";
import classNames from "classnames"
import { useSelector, useDispatch } from 'react-redux';
import logoImg from "../../../img/logo.png"
import { CourseConfig } from "../CourseConfig/CourseConfig";
import { QueryAndGetQR } from "../QueryAndGetQR/QueryAndGetQR";
import {
    selectBigMenuIndex,
    selectMenuIndex,
    selectSBPSMenuIndex,
    selectXMLDMenuIndex,
    selectXSSMMenuIndex, selectZSSMHMenuIndex, selectZSYBHMenuIndex,
    selectZXMenuIndex,
    setBigMenuIndex,
    setMenuIndex, setXMLDMenuIndex,
    setZXMenuIndex
} from "./ZXYYMainSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPowerOff,faChartColumn,faListAlt,faAngleUp,faAngleDown} from '@fortawesome/free-solid-svg-icons';
import { closeZXYYMain, openLogin } from "../../../store/modalsSlice";
import PaymentQuery from "../PaymentQuery/PaymentQuery";
import { loginOutRequest } from "../../Login/loginApi";
import {ZXSchool} from "../ZXSchool/ZXSchool";
import {ZXSchool1} from "../ZXSchool/ZXSchool1";
import {ZXSchool2} from "../ZXSchool/ZXSchool2";
import {ZXSchool3} from "../ZXSchool/ZXSchool3";
import {
    selectDingSchoolList,
    selectEndStatus,
    selectLieSchoolList,
    selectPingSchoolList
} from "../ZXSchool/ZXSchoolSlice";
import {ZXschool4} from "../ZXSchool/ZXSchool4";
import {API_URL} from "../../../util/constants";
import XSSMHJSlice from "../XSSMHJ/XSSMHJSlice";
import {XSSMHJ} from "../XSSMHJ/XSSMHJ";
import {SBPSHJ} from "../SBPSHJ/SBPSHJ";
import {XSSMHJDetail} from "../XSSMHJ/XSSMHJDetail";
import {SBPSHJDetail} from "../SBPSHJ/SBPSHJDetail";
import {ZSYBH} from "../ZSYBH/ZSYBH";
import {ZSSMH} from "../ZSSMH/ZSSMH";
import {ZSYBHDetail} from "../ZSYBH/ZSYBHDetail";

export function ZXYYMain() {
    const [showXMLDMenuList, setShowXMLDMenuList] = useState(true);
    const [showMenuList, setShowMenuList] = useState(true);
    const bigMenuIndex = useSelector(selectBigMenuIndex);
    const XMLDMenuIndex = useSelector(selectXMLDMenuIndex);//控制项目落地下面子菜单界面的切换
    const ZXMenuIndex = useSelector(selectZXMenuIndex);//控制列评定界面的切换
    const menuIndex = useSelector(selectMenuIndex);
    const XSSMMenuIndex = useSelector(selectXSSMMenuIndex);
    const SBPSMenuIndex = useSelector(selectSBPSMenuIndex)
    const ZSYBHMenuIndex = useSelector(selectZSYBHMenuIndex);
    const ZSSMHMenuIndex = useSelector(selectZSSMHMenuIndex);
    const lieSchoolList = useSelector(selectLieSchoolList)
    const pingSchoolList = useSelector(selectPingSchoolList)
    const dingSchoolList = useSelector(selectDingSchoolList)
    const endStatus = useSelector(selectEndStatus);
    const dispatch = useDispatch();
    function leftMenuMethod(typeMenu,index){
        if(typeMenu == 0){
            dispatch(setZXMenuIndex(0))
            dispatch(setXMLDMenuIndex(index));
        }
        else {
            dispatch(setMenuIndex(index))
        }
        dispatch(setBigMenuIndex(typeMenu));
    }
    function signOutMethod() {
        loginOutRequest((data)=>{
        })
        sessionStorage.clear()
        dispatch(openLogin())
        dispatch(closeZXYYMain())
    }
    return (
        <div className={styles.background}>
            <div className={styles.topContainer}>
                <div className={styles.topLeftMenus}>
                    <img 
                        className={styles.logIcon}
                        src={logoImg}
                    ></img>
                    <span className={styles.logoTitle}>全球胜任力研究所</span>
                </div>
                <div className={styles.topRightMenus}>
                    <div
                        className={styles.yyxxzx}
                        onClick={(e)=>{
                        // window.open(API_URL+"https://localhost:3001/g2")
                        window.open(API_URL + "/g2/index.html?utoken="+sessionStorage.getItem("utoken")+"&username="+sessionStorage.getItem("username"))
                    }}>运营学习中心&nbsp;&nbsp;&nbsp;</div>
                    <div className={styles.userName}>{sessionStorage.getItem("user_type_name")+"\u00A0\u00A0"+sessionStorage.getItem("name")}</div>
                    <div className={styles.signOutButton}
                        onClick={signOutMethod}
                    >
                        <FontAwesomeIcon icon={faPowerOff}/>
                    </div>
                </div>
                
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.leftContent}>
                    <div
                        className={styles.leftMenuDes}
                    >
                        {/* <FontAwesomeIcon icon={faBarChart}/> */}
                        <FontAwesomeIcon className={styles.leftMenuDesIcon} icon={faChartColumn}/>
                        学术运营看板
                    </div>
                    {/* <div
                        className={classNames(styles.leftMenu,menuIndex==0?styles.leftSelectedMenu:null)}
                        onClick={leftMenuMethod.bind(null,0)}
                    >提交开课申请</div> */}
                    {/*<div*/}
                    {/*    className={classNames(styles.leftMenuYYGL,bigMenuIndex==0?styles.leftSelectedMenu:null)}*/}
                    {/*    onClick={(e) => {*/}
                    {/*        setShowMenuList(false)*/}
                    {/*        dispatch(setBigMenuIndex(0))*/}
                    {/*        dispatch(setZXMenuIndex(0))*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    /!* <FontAwesomeIcon icon={faBarChart}/> *!/*/}
                    {/*    <FontAwesomeIcon className={styles.leftMenuYYGLIcon} icon={faSchool}/>*/}
                    {/*    甄选学校*/}
                    {/*</div>*/}

                    <div
                        className={styles.leftMenuYYGL}
                        onClick={(e) => {
                            setShowXMLDMenuList(!showXMLDMenuList)
                            dispatch(setBigMenuIndex(0))
                        }}
                    >
                        {/* <FontAwesomeIcon icon={faBarChart}/> */}
                        <FontAwesomeIcon className={styles.leftMenuYYGLIcon} icon={faListAlt}/>
                        项目落地
                        <FontAwesomeIcon className={styles.leftMenuYYGLIcon1}
                                         icon={showXMLDMenuList ? faAngleUp : faAngleDown}/>
                    </div>
                    {showXMLDMenuList ? <div className={styles.YYGLMenus}>
                        <div
                            className={classNames(styles.leftMenu, bigMenuIndex==0&&XMLDMenuIndex == 0 ? styles.leftSelectedMenu : null)}
                            onClick={leftMenuMethod.bind(null,0, 0)}
                        >甄选触达环节
                        </div>
                        <div
                            className={classNames(styles.leftMenu, bigMenuIndex==0&&XMLDMenuIndex == 1 ? styles.leftSelectedMenu : null)}
                            onClick={leftMenuMethod.bind(null,0, 1)}
                        >学术说明环节
                        </div>
                        <div
                            className={classNames(styles.leftMenu, bigMenuIndex==0&&XMLDMenuIndex == 2 ? styles.leftSelectedMenu : null)}
                            onClick={leftMenuMethod.bind(null,0, 2)}
                        >申报评审环节
                        </div>
                    </div> : null}
                    <div
                        className={styles.leftMenuYYGL}
                        onClick={(e) => {
                            setShowMenuList(!showMenuList)
                            dispatch(setBigMenuIndex(1))
                        }}
                    >
                        {/* <FontAwesomeIcon icon={faBarChart}/> */}
                        <FontAwesomeIcon className={styles.leftMenuYYGLIcon} icon={faListAlt}/>
                        运营管理
                        <FontAwesomeIcon className={styles.leftMenuYYGLIcon1}
                                         icon={showMenuList ? faAngleUp : faAngleDown}/>
                    </div>
                    {showMenuList ? <div className={styles.YYGLMenus}>
                        {/*<div*/}
                        {/*    className={classNames(styles.leftMenu, bigMenuIndex == 1 && menuIndex == 0 ? styles.leftSelectedMenu : null)}*/}
                        {/*    onClick={leftMenuMethod.bind(null, 1, 0)}*/}
                        {/*>招生预备会*/}
                        {/*</div>*/}
                        {/*<div*/}
                        {/*    className={classNames(styles.leftMenu, bigMenuIndex == 1 && menuIndex == 1 ? styles.leftSelectedMenu : null)}*/}
                        {/*    onClick={leftMenuMethod.bind(null, 1, 1)}*/}
                        {/*>招生说明会*/}
                        {/*</div>*/}
                        <div
                            className={classNames(styles.leftMenu, bigMenuIndex == 1 && menuIndex == 2 ? styles.leftSelectedMenu : null)}
                            onClick={leftMenuMethod.bind(null, 1, 2)}
                        >课程报名配置
                        </div>
                        <div
                            className={classNames(styles.leftMenu, bigMenuIndex == 1 && menuIndex == 3 ? styles.leftSelectedMenu : null)}
                            onClick={leftMenuMethod.bind(null, 1, 3)}
                        >收费二维码查询下载
                        </div>
                        <div
                            className={classNames(styles.leftMenu, bigMenuIndex == 1 && menuIndex == 4 ? styles.leftSelectedMenu : null)}
                            onClick={leftMenuMethod.bind(null, 1, 4)}
                        >开课到款
                        </div>
                    </div> : null}
                </div>
                <div className={styles.rightContent}>


                    {/*显示甄选触达关节中列评定的接个界面*/}
                    {bigMenuIndex == 0 && XMLDMenuIndex == 0 && ZXMenuIndex == 0 ? <ZXSchool/> : null}
                    {bigMenuIndex == 0 && XMLDMenuIndex==0&&ZXMenuIndex==1?<ZXSchool1/>:null}
                    {bigMenuIndex==0&&XMLDMenuIndex==0&&ZXMenuIndex==2?<ZXSchool2/>:null}
                    {bigMenuIndex==0&&XMLDMenuIndex==0&&ZXMenuIndex==3&&endStatus==false?<ZXSchool3/>:null}
                    {bigMenuIndex==0&&XMLDMenuIndex==0&&ZXMenuIndex==3&&endStatus==true?<ZXschool4/>:null}


                    {/*显示学术说明环节的界面*/}
                    {bigMenuIndex==0&&XMLDMenuIndex==1&&XSSMMenuIndex == 0?<XSSMHJ/>:null}
                    {bigMenuIndex==0&&XMLDMenuIndex==1&&XSSMMenuIndex == 1?<XSSMHJDetail/>:null}

                    {/*显示申报评审环节的界面*/}
                    {bigMenuIndex==0&&XMLDMenuIndex==2&&SBPSMenuIndex == 0?<SBPSHJ/>:null}
                    {bigMenuIndex==0&&XMLDMenuIndex==2&&SBPSMenuIndex == 1?<SBPSHJDetail/>:null}

                    {/*展示运营管理界面*/}
                    {/*招生启动会界面*/}
                    {bigMenuIndex==1&&menuIndex == 0&&ZSYBHMenuIndex == 0 ? <ZSYBH/> : null}
                    {/*{bigMenuIndex==1&&menuIndex == 0&&ZSYBHMenuIndex == 1 ? <ZSYBHDetail/> : null}*/}

                    {bigMenuIndex==1&&menuIndex == 1&&ZSSMHMenuIndex == 0 ? <ZSSMH/> : null}

                    {/*课程报名配置界面*/}
                    {bigMenuIndex==1&&menuIndex == 2 ? <CourseConfig/> : null}
                    {/*收费二维码查询界面*/}
                    {bigMenuIndex==1&&menuIndex == 3 ? <QueryAndGetQR/> : null}
                    {/*开课到款界面*/}
                    {bigMenuIndex==1&&menuIndex == 4 ? <PaymentQuery/> : null}

                    {/*{menuIndex == 0&&bigMenuIndex!=0 ? <CourseConfig/> : null}*/}
                    {/*{menuIndex == 1&&bigMenuIndex!=0 ? <QueryAndGetQR/> : null}*/}
                    {/*{menuIndex == 2&&bigMenuIndex!=0 ? <PaymentQuery/> : null}*/}



                    {/*{bigMenuIndex==0&&ZXMenuIndex==0?<ZXSchool/>:null}*/}
                    {/*{bigMenuIndex==0&&ZXMenuIndex==1?<ZXSchool1/>:null}*/}
                    {/*{bigMenuIndex==0&&ZXMenuIndex==2?<ZXSchool2/>:null}*/}
                    {/*{bigMenuIndex==0&&ZXMenuIndex==3&&endStatus==false?<ZXSchool3/>:null}*/}
                    {/*{bigMenuIndex==0&&ZXMenuIndex==3&&endStatus==true?<ZXschool4/>:null}*/}
                    {/*/!*{menuIndex == 0&&bigMenuIndex!=0 ? <SubmitCourse/> : null}*!/*/}
                    {/*{menuIndex == 0&&bigMenuIndex!=0 ? <CourseConfig/> : null}*/}
                    {/*{menuIndex == 1&&bigMenuIndex!=0 ? <QueryAndGetQR/> : null}*/}
                    {/*{menuIndex == 2&&bigMenuIndex!=0 ? <PaymentQuery/> : null}*/}
                </div>
            </div>
        </div>
    );
};
