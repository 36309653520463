
import React from "react";
import classNames from "classnames"
import { useSelector, useDispatch } from 'react-redux';
import styles from "./InfoList.module.css";
// import closeIcon from "./close1.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload} from '@fortawesome/free-solid-svg-icons';
import InfoItem from "./InfoItem";
import ExcelUtil from "../../../util/excelUtilNew";
import { selectInfoList } from "./QueryAndGetQRSlice";
import { setAlertInfo } from "../../../store/CustomViewSlice";
// import BottomControl from "./BottomControl"
// const InfoList = props =>(
export default function InfoList(props) {
    const infoList = useSelector(selectInfoList);
    const dispatch = useDispatch();
    function exportExcelMethod(){
        if (infoList.length==0) {
            dispatch(setAlertInfo({
                showStatus:true,
                title:"",
                title1:"暂无内容导出",
                content:""
            }))
            return;
        }
        const initColumn = [{
            title: '学校名称',
            dataIndex: 'schoolName',
            key: 'schoolName',
        }, {
            title: '缴费申请名称',
            dataIndex: 'jfsqName',
            key: 'jfsqName',
        }, {
            title: '申请时间',
            dataIndex: 'time',
            key: 'time',
        }, {
            title: '二维码状态',
            dataIndex: 'status',
            key: 'status',
            // className: 'text-monospace',
        }, {
            title: '联系人',
            dataIndex: 'contacts',
            key: 'contacts',
        }, {
            title: '联系方式',
            dataIndex: 'telephone',
            key: 'telephone',
        }];

        let attendanceInfoList = [];
        infoList.map((item,index)=>{
            attendanceInfoList.push({
                '学校名称':item.school_name,
                '缴费申请名称':item.pici_name,
                '申请时间':item.ctime,
                '审核状态':item.status==0?"待审核":item.status==1?"已通过":"已拒绝",
                '联系人':item.lianxiren,
                '联系方式':item.lianxi_phone
            })
        })
        ExcelUtil.exportExcel(attendanceInfoList,`二维码申请批次列表.xlsx`)
    }
    return <div className={styles.background}>
        <div className={styles.titleContainer}>
            <span className={styles.leftTitle}>数据列表</span>
            <div className={styles.rightButtonContainer}>
                <div className={styles.rightButton1} onClick={exportExcelMethod}>
                    <FontAwesomeIcon icon={faUpload} className={styles.rightButtonIcon}/>
                    导出
                </div>
                {/* <div className={styles.rightButton1}>
                    <FontAwesomeIcon icon={faDownload} className={styles.rightButtonIcon}/>
                    导入
                </div>
                <div className={styles.rightButton2}>
                    <FontAwesomeIcon icon={faAdd} className={styles.rightButtonIcon}/>
                    添加
                </div> */}
            </div>
        </div>
        <div className={styles.listContainer}>
            <table className={styles.infoTable}>
                <tr
                    className={styles.tr1}
                >
                    <td
                        className={styles.td1}
                    >学校名称
                    </td>
                    <td
                        className={styles.td1}
                    >缴费申请名称
                    </td>
                    <td
                        className={styles.td1}
                    >申请时间
                    </td>
                    <td
                        className={styles.td1}
                    >二维码状态
                    </td>
                    <td
                        className={styles.td1}
                    >联系人
                    </td>
                    <td
                        className={styles.td1}
                    >联系方式
                    </td>
                    <td
                        className={styles.td1}
                    >操作
                    </td>
                </tr>
                {infoList.map((item, index) => (
                    <InfoItem
                        item={item}
                        key={index}

                    />
                ))}
            </table>
        </div>


    </div>
}

