import React, {useRef, useState} from "react";
import styles from "./RefundRecord.module.css";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose, faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
import {setAlertInfo} from "../../../store/CustomViewSlice";
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {
    selectRefundRecordList,
    setShowRefundRecord
} from "./PaymentQuerySlice";
import {Checkbox} from "antd";

export default function RefundRecord(props) {
    const [confirmInfo, setConfirmInfo] = useState({})
    const dispatch = useDispatch();
    const infoList = useSelector(selectRefundRecordList)

    function isValidPhoneNumber(phoneNumber) {
        return /^1[3-9]\d{9}$/.test(phoneNumber);
    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {

        } else if (confirmInfo.eventId == 2) {

        } else if (confirmInfo.eventId == 3) {

        }
    }

    function onChange(checkIndex) {
    }

    return <div className={styles.background}>
        <CustomConfirmAlert
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        <div className={styles.contentContainer}>
            <div className={styles.topBar}>
                <div className={styles.topBarLeft}>退费记录</div>
                <FontAwesomeIcon
                    className={styles.topBarRight}
                    icon={faClose}
                    onClick={(e) => {
                        dispatch(setShowRefundRecord(false))
                    }}
                />
            </div>

            <div className={styles.middleContent}>
                <table
                    align="top"
                    border="0"
                    bordercolor="#f00"
                    cellSpacing="0"
                    cellspadding="0"
                    width="650"
                >
                    <tr valign="middle"
                        align="center">
                        <td
                            width="200" height="26"
                            className={styles.td1}
                        >退费时间
                        </td>
                        <td
                            width="100" height="26"
                            className={styles.td1}
                        >课程退费
                        </td>
                        <td
                            width="100" height="26"
                            className={styles.td1}
                        >器材退费
                        </td>
                        <td
                            width="100" height="26"
                            className={styles.td1}
                        >退费渠道
                        </td>
                        <td
                            width="150" height="26"
                            className={styles.td1}
                        >退费备注
                        </td>
                    </tr>
                    {infoList.map((item, index, arr) => {
                        return <tr valign="middle"
                                   align="center">
                            <td
                                width="200" height="26"
                                className={styles.td2}
                            >{item.ctime}
                            </td>
                            <td
                                width="100" height="26"
                                className={styles.td2}
                            >{item.pay_type==1?item.kecheng_jine/100:item.kecheng_jine}
                            </td>
                            <td
                                width="100" height="26"
                                className={styles.td2}
                            >{item.pay_type==1?item.jiaoju_jine/100:item.jiaoju_jine}
                            </td>
                            <td
                                width="100" height="26"
                                className={styles.td2}
                            >{item.pay_type==1?"微信":item.pay_type==2?"支付宝":"其他"}
                            </td>
                            <td
                                width="150" height="26"
                                className={styles.td2}
                            >{item.reason}
                            </td>
                        </tr>
                    })}
                </table>
            </div>

            <div className={styles.bottomContainer}>
                <div className={classNames(styles.confirmButton, styles.button)}
                     onClick={(e) => {
                         dispatch(setShowRefundRecord(false))
                     }}
                >确定
                </div>
            </div>
        </div>
    </div>
}

