// 提交开课申请,暂未对接接口
import React, {useRef, useState, useEffect} from "react";
import styles from "./ZXSchool3.module.css";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import Footer from "../../Footer/Footer";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight, faAngleUp, faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {selectAlertInfo, setAlertInfo} from "../../../store/CustomViewSlice";
import {message} from 'antd'
import {setZXMenuIndex} from "../ZXYYMain/ZXYYMainSlice";
import {
    selectDingSchoolList,
    selectLieSchoolList, selectLPDSchoolList,
    selectPingSchoolList, selectXuezhiIndex, selectXuezhiList,
    setDingSchoolList, setDYFXList,
    setEndStatus, setLieSchoolList, setLPDSchoolList, setPingSchoolList
} from "./ZXSchoolSlice";
import {ZXSchoolSetDYFX} from "./ZXSchoolSetDYFX";
import {ZXSchoolSetHX} from "./ZXSchoolSetHX";
import {addSchoolDYFX, addSchoolHX, getSchoolDYFX, getSchoolHX, saveDingSchoolRequest} from "./ZXSchoolApi";

export function ZXSchool3() {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [confirmInfo, setConfirmInfo] = useState({})
    const xuezhiIndex = useSelector(selectXuezhiIndex)
    const xuezhiList = useSelector(selectXuezhiList)
    const LPDSchoolList = useSelector(selectLPDSchoolList)
    const lieSchoolList = useSelector(selectLieSchoolList)
    const pingSchoolList = useSelector(selectPingSchoolList)
    const dingSchoolList = useSelector(selectDingSchoolList)

    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        let tempList = LPDSchoolList.map((item, index) => {return item});
        tempList.sort((a, b) => {
            return a.order_zonghe - b.order_zonghe;
        })
        var haveOrderZongHe = false;
        tempList.map((item, index) => {
            if(item.order_zonghe != 0){
                haveOrderZongHe = true;
            }
            return item
        });
        if(!haveOrderZongHe){
            tempList = tempList.map((item, index) => {
                let tempDic = JSON.parse(JSON.stringify(item))
                tempDic.order_zonghe = index;
                return tempDic;
            })
        }
        dispatch(setDingSchoolList(tempList))

        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次

    const [yiyuanList, setYiyuanList] = useState([{
        yiyuanName: "意愿未知",
    }, {
        yiyuanName: "意愿很强",
    }, {
        yiyuanName: "意愿较强",
    }, {
        yiyuanName: "意愿一般",
    }, {
        yiyuanName: "意愿不强",
    }]);
    const [addingStudentNumber, setAddingStudentNumber] = useState("");
    const [HXSchoolInfo, setHXSchoolInfo] = useState({});
    const [HXInfoList, setHXInfoList] = useState([]);
    const [showZXSchoolHX, setShowZXSchoolHX] = useState(false);
    const [DYFXSchoolInfo, setDYFXSchoolInfo] = useState({});
    const [showZXSchoolDYFX, setShowZXSchoolDYFX] = useState(false);

    function refreshMethod() {

    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
        } else if (confirmInfo.eventId == 2) {

        }

    }

    return (
        <div className={styles.background}>
            {contextHolder}
            <CustomConfirmAlert
                info={confirmInfo}
                cancelMethod={cancelMethod}
                confirmMethod={confirmMethod}
            />
            {
                showZXSchoolHX ? <ZXSchoolSetHX
                    info={HXSchoolInfo}
                    infoList={HXInfoList}
                    showUploadButton={false}
                    closeMethod={(e) => {
                        setShowZXSchoolHX(false)
                    }}
                    tijiaoMethod={(HSList) => {
                        //     该界面无法提交画像，所以不用处理
                    }}
                /> : null
            }
            {
                showZXSchoolDYFX ? <ZXSchoolSetDYFX
                    info={DYFXSchoolInfo}
                    showUploadButton={true}
                    closeMethod={(e) => {
                        setShowZXSchoolDYFX(false)
                    }}
                    tijiaoMethod={(DYFXList) => {

                        let DYFXJson = DYFXList.map((item, index) => {
                            let number = item.number === 0 ? "" : item.number;
                            let feiyong = item.feiyong === 0 ? "" : item.feiyong;
                            let PJKeshi = item.PJKeshi === 0 ? "" : item.PJKeshi;
                            let dxqffgm = item.dxqffgm === 0 ? "" : item.dxqffgm;
                            let DYFXItem = {
                                name: item.name,
                                code: index + 1,
                                content: number + "," + feiyong + "," + PJKeshi + "," + dxqffgm
                            }
                            return DYFXItem;
                        })

                        addSchoolDYFX(DYFXSchoolInfo.xuexiao_code, JSON.stringify(DYFXJson), (data) => {
                            if (data.ret == 1) {
                                let tempArray2 = dingSchoolList.map((item, index) => {
                                    if (DYFXSchoolInfo.xuexiao_code === item.xuexiao_code) {
                                        return Object.assign(JSON.parse(JSON.stringify(item)), {"diaoyan_status": "1"})
                                    } else {
                                        return item;
                                    }
                                })
                                dispatch(setDingSchoolList(tempArray2))


                                let tempArray3 = LPDSchoolList.map((item, index) => {
                                    if (DYFXSchoolInfo.xuexiao_code === item.xuexiao_code) {
                                        return Object.assign(JSON.parse(JSON.stringify(item)), {"diaoyan_status": "1"})
                                    } else {
                                        return item;
                                    }
                                })
                                dispatch(setLPDSchoolList(tempArray3))


                                setShowZXSchoolDYFX(false)
                            }else if(data.ret==99){

                                messageApi.open({
                                    type: 'warning',
                                    content:data.msg
                                });
                                setTimeout((e)=>{
                                    sessionStorage.clear()
                                    window.location.reload()
                                },1000)
                            } else {
                                 messageApi.open({
                                     type: 'warning',
                                     content: data.msg
                                 })
                             }
                        })
                    }}
                /> : null
            }

            <div className={styles.content}>
                <div className={styles.leftContent}>
                    <span className={styles.leftLieTitle}
                          onClick={(e) => {
                              dispatch(setZXMenuIndex(1))
                          }}
                    >第1步:【列】</span>
                    <span className={styles.leftPingTitle}
                          onClick={(e) => {
                              dispatch(setZXMenuIndex(2))
                          }}
                    >第2步:【评】</span>
                    <span className={styles.leftDingTitle}>第3步:【定】</span>
                </div>
                <div className={styles.middleContent}>
                    <div className={styles.middleTopContent}>
                        <div className={styles.middleTitle}>
                            第3步：【定】进一步调研分析各校学生自费课的参与情况，估测现有自费课的收益规模，并依此形成学校综合水平排名
                        </div>
                        {/*<div className={styles.szqxTitle}>所在区县：临汾市尧都区</div>*/}
                        <div className={styles.BXSchoolTitle}>备选学校：
                            <span className={styles.BXSchoolDes}>
                            {dingSchoolList.length}所
                        </span>
                        </div>
                        <div className={styles.BXSchoolList}>
                            {dingSchoolList.map((item, index) => {
                                return <div className={styles.BXSchoolItem}>
                                    <span className={styles.jcspTitle}>综合水平{index + 1}</span>
                                    <span className={styles.schoolName}>{item.xuexiao_name}</span>
                                    <span className={styles.studentNumber}>{item.xuesheng_num}人</span>
                                    <div className={styles.schoolDYFXButton}
                                         onClick={(e) => {
                                             getSchoolHX(item.xuexiao_code, (data) => {
                                                 if (data.ret == 1) {
                                                     setHXSchoolInfo(item)
                                                     setHXInfoList(data.data.list)
                                                     setShowZXSchoolHX(true)
                                                 }else if(data.ret==99){

                                                    messageApi.open({
                                                        type: 'warning',
                                                        content:data.msg
                                                    });
                                                    setTimeout((e)=>{
                                                        sessionStorage.clear()
                                                        window.location.reload()
                                                    },1000)
                                                } else {
                                                     setHXSchoolInfo(item)
                                                     setHXInfoList([])
                                                     setShowZXSchoolHX(true)
                                                 }
                                             })
                                         }}
                                    >学校画像>
                                    </div>
                                    <div className={styles.yiyuanText}>
                                        {yiyuanList[item.yiyuan_code].yiyuanName}
                                    </div>
                                    <div className={styles.diaoyanButton}
                                         onClick={(e) => {
                                             setDYFXSchoolInfo(item)
                                             setShowZXSchoolDYFX(true)


                                             getSchoolDYFX(item.xuexiao_code, (data) => {
                                                 if (data.ret == 1) {
                                                     setDYFXSchoolInfo(item)
                                                     let tempList = data.data.list.map((item, index) => {
                                                         let contentList = item.content.split(",")
                                                         let tempDic = {
                                                             name: item.name,
                                                             number: contentList.length === 0 || contentList[0] == "" ? 0 : contentList[0],
                                                             feiyong: contentList.length < 1 || contentList[1] == "" ? 0 : contentList[1],
                                                             PJKeshi: contentList.length < 2 || contentList[2] == "" ? 0 : contentList[2],
                                                             dxqffgm: contentList.length < 3 || contentList[3] == "" ? 0 : contentList[3]
                                                         }
                                                         return tempDic
                                                     })
                                                     dispatch(setDYFXList(tempList))
                                                     setShowZXSchoolDYFX(true)
                                                 }else if(data.ret==99){

                                                    messageApi.open({
                                                        type: 'warning',
                                                        content:data.msg
                                                    });
                                                    setTimeout((e)=>{
                                                        sessionStorage.clear()
                                                        window.location.reload()
                                                    },1000)
                                                } else {
                                                     setDYFXSchoolInfo(item)
                                                     dispatch(setDYFXList([{
                                                         "name": "自费-全部课后服务课程",
                                                         "number": 0,
                                                         "feiyong": 0,
                                                         "PJKeshi": 0,
                                                         "dxqffgm": 0

                                                     },
                                                         {
                                                             "name": "自费-科技类课后服务课程",
                                                             "number": 0,
                                                             "feiyong": 0,
                                                             "PJKeshi": 0,
                                                             "dxqffgm": 0

                                                         },
                                                         {
                                                             "name": "自费-全部校本课程",
                                                             "number": 0,
                                                             "feiyong": 0,
                                                             "PJKeshi": 0,
                                                             "dxqffgm": 0

                                                         },
                                                         {
                                                             "name": "自费-科技类校本课程",
                                                             "number": 0,
                                                             "feiyong": 0,
                                                             "PJKeshi": 0,
                                                             "dxqffgm": 0

                                                         }]))
                                                     setShowZXSchoolDYFX(true)
                                                 }
                                             })
                                         }}
                                    >调研分析
                                    </div>
                                    <span
                                        className={styles.writeStatus}>{!("diaoyan_status" in item&&item.diaoyan_status==1) || item.diaoyan_status == null ? "未填写" : "已填写"}</span>
                                    <div className={styles.angleContainer}>
                                        {index !== 0 && dingSchoolList.length > 1 ?
                                            <FontAwesomeIcon icon={faAngleUp}
                                                             className={classNames(styles.angleIcon, styles.angleUp)}
                                                             onClick={(e) => {

                                                                 var tempArray = dingSchoolList.map((item1, index1) => {
                                                                     console.log(item1)
                                                                     console.log('分割线-------------')
                                                                     console.log(item)

                                                                     let tempDic = JSON.parse(JSON.stringify(item1))
                                                                     if (item1.order_zonghe == item.order_zonghe) {
                                                                         tempDic.order_zonghe = item1.order_zonghe - 1;
                                                                     }
                                                                     if (item1.order_zonghe == item.order_zonghe-1) {
                                                                         tempDic.order_zonghe = item1.order_zonghe + 1;
                                                                     }
                                                                     return tempDic;
                                                                 })
                                                                 tempArray.sort((a, b) => {
                                                                     return a.order_zonghe - b.order_zonghe;
                                                                 })
                                                                 dispatch(setDingSchoolList(tempArray))




                                                                 var tempArray1 = LPDSchoolList.map((item1, index1) => {

                                                                     let tempDic = JSON.parse(JSON.stringify(item1))
                                                                     if (item1.order_zonghe == item.order_zonghe) {
                                                                         tempDic.order_zonghe = item1.order_zonghe - 1;
                                                                     }
                                                                     if (item1.order_zonghe == item.order_zonghe-1) {
                                                                         tempDic.order_zonghe = item1.order_zonghe + 1;
                                                                     }
                                                                     return tempDic;
                                                                 })
                                                                 tempArray1.sort((a, b) => {
                                                                     return a.order_zonghe - b.order_zonghe;
                                                                 })
                                                                 dispatch(setLPDSchoolList(tempArray1))
                                                             }}
                                            /> : null}
                                        {index !== dingSchoolList.length - 1 && dingSchoolList.length > 1 ?
                                            <FontAwesomeIcon icon={faAngleDown}
                                                             className={classNames(styles.angleIcon, styles.angleDown)}
                                                             onClick={(e) => {

                                                                 var tempArray = dingSchoolList.map((item1, index1) => {

                                                                     let tempDic = JSON.parse(JSON.stringify(item1))
                                                                     if (item1.order_zonghe == item.order_zonghe) {
                                                                         tempDic.order_zonghe = item1.order_zonghe + 1;
                                                                     }
                                                                     if (item1.order_zonghe == item.order_zonghe+1) {
                                                                         tempDic.order_zonghe = item1.order_zonghe - 1;
                                                                     }
                                                                     return tempDic;
                                                                 })
                                                                 tempArray.sort((a, b) => {
                                                                     return a.order_zonghe - b.order_zonghe;
                                                                 })
                                                                 dispatch(setDingSchoolList(tempArray))



                                                                 var tempArray1 = LPDSchoolList.map((item1, index1) => {
                                                                     let tempDic = JSON.parse(JSON.stringify(item1))
                                                                     if (item1.order_zonghe == item.order_zonghe) {
                                                                         tempDic.order_zonghe = item1.order_zonghe + 1;
                                                                     }
                                                                     if (item1.order_zonghe == item.order_zonghe+1) {
                                                                         tempDic.order_zonghe = item1.order_zonghe - 1;
                                                                     }
                                                                     return tempDic;
                                                                 })
                                                                 tempArray1.sort((a, b) => {
                                                                     return a.order_zonghe - b.order_zonghe;
                                                                 })
                                                                 dispatch(setLPDSchoolList(tempArray1))
                                                             }}
                                            /> : null}
                                    </div>
                                </div>
                            })}
                        </div>

                    </div>
                    <div className={styles.middleBottomContent}>
                        <div className={styles.lastStepButton}
                             onClick={(e) => {
                                 dispatch(setZXMenuIndex(2))
                             }}
                        >上一步
                        </div>
                        <div className={styles.nextButton}
                             onClick={(e) => {
                                 let haveDYFXInfo = false;
                                 dingSchoolList.map((item, index) => {
                                     for (const itemKey in item) {
                                         console.log(itemKey)
                                         if (itemKey == "diaoyan_status"&&item[itemKey]==1) {
                                             haveDYFXInfo = true;
                                         }
                                     }
                                 })
                                 if (!haveDYFXInfo) {
                                     messageApi.open({
                                         type: "warning",
                                         content: " 请填写学校调研分析"
                                     })
                                 } else {
                                     saveDingSchoolRequest(xuezhiList[xuezhiIndex].xuezhi_code, JSON.stringify(dingSchoolList), (data) => {
                                         console.log("ffdispatch(setEndStatus(true))dispatch(setEndStatus(true))")
                                        //  if(data.ret == 1){
                                        if(data.ret==99){

                                            messageApi.open({
                                                type: 'warning',
                                                content:data.msg
                                            });
                                            setTimeout((e)=>{
                                                sessionStorage.clear()
                                                window.location.reload()
                                            },1000)
                                        } else {
                                            dispatch(setEndStatus(true))
                                            //  messageApi.open({
                                            //      type: 'warning',
                                            //      content: data.msg
                                            //  })
                                         }
                                     })
                                 }
                             }}
                             >
                                 完成并保存
                                 </div>
                             </div>
                             </div>
                                 <div className={styles.rightContent}>
                                     <div className={styles.xtsContainer}>
                                         <div className={styles.xtsTitle}>小贴士</div>
                                         <div
                                             className={styles.xtsDes}>
                                             1. 进一步调研分析各备选学校现有学生自费课程的参与情况（例如自费课后服务等），预估家长/学生对诺奖计划的接受程度及付费能力，并做记录。
                                             <br/>
                                             2. 根据对各备选学校所有已知信息的综合评估，给备选学校进行“综合水平”排序。与综合水平靠前学校进一步沟通，从中选定最适合落地诺奖计划的学校。
                                         </div>
                                     </div>
                                 </div>
                             </div>
                                 <Footer/>
                             </div>
                             )
                                 ;
                             };
