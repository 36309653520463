import React, {useRef, useState, useEffect} from "react";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import styles from "./InfoList.module.css";
// import closeIcon from "./close1.png"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUpload} from '@fortawesome/free-solid-svg-icons';
import InfoItem from "./InfoItem";
import ExcelUtil from "../../../util/excelUtilNew";
import {
    selectCurrentPage,
    selectInfoCheckedAll,
    selectInfoList,
    selectPageNumber,
    selectReviewStatus,
    selectSchoolIndex,
    selectSchoolList,
    setCurrentPage,
    setInfoCheckedAll,
    setInfoList,
    setReviewStatus,
    setTotalPageNum
} from "./PaymentQuerySlice";
import {Checkbox} from 'antd';
import {getAllInfoListRequest, getInfoListRequest, shenhePayment} from "./PaymentQueryApi";
import {setAlertInfo} from "../../../store/CustomViewSlice";
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {message} from 'antd'
import {getMoneyDate} from "../../../util/currencyUnit";

export default function InfoList(props) {
    const dispatch = useDispatch();
    const infoList = useSelector(selectInfoList);
    const infoCheckedAll = useSelector(selectInfoCheckedAll);
    const schoolIndex = useSelector(selectSchoolIndex);
    const schoolList = useSelector(selectSchoolList);
    const pageNumber = useSelector(selectPageNumber);
    const currentPage = useSelector(selectCurrentPage)
    const reviewStatus = useSelector(selectReviewStatus);
    const [confirmInfo, setConfirmInfo] = useState({})
    const [messageApi, contextHolder] = message.useMessage();

    function exportAllInfo() {
        getAllInfoListRequest((data) => {
            if (data.ret == 1) {
                var tempList = [];
                data.data.map((item, index) => {
                    item.checked = false
                    tempList.push(item)
                })
                exportExcelMethod(tempList)
            } else if (data.ret == 99) {
                messageApi.open({
                    type: 'warning',
                    content: data.msg
                });
                setTimeout((e) => {
                    sessionStorage.clear()
                    window.location.reload()
                }, 1000)
            } else {
                dispatch(setAlertInfo({
                    showStatus: true,
                    title: "",
                    title1: "暂无内容导出",
                    content: ""
                }))
            }
        })
    }

    function exportThisSchoolInfo() {
        if (schoolIndex == 0) {
            dispatch(setAlertInfo({
                showStatus: true,
                title: "",
                title1: "请选择相应学校",
                content: ""
            }))
            return;
        }
        getInfoListRequest(schoolList[schoolIndex].school_code, reviewStatus, 0, 10000000000, (data) => {
            if (data.ret == 1) {
                var tempList = [];
                data.data.pay_list.map((item, index) => {
                    item.checked = false
                    tempList.push(item)
                })
                exportExcelMethod(tempList)
            } else if (data.ret == 99) {
                messageApi.open({
                    type: 'warning',
                    content: data.msg
                });
                setTimeout((e) => {
                    sessionStorage.clear()
                    window.location.reload()
                }, 1000)
            } else {
                dispatch(setAlertInfo({
                    showStatus: true,
                    title: "",
                    title1: "暂无内容导出",
                    content: ""
                }))
            }
        })
    }

    function exportExcelMethod(infoList) {
        if (infoList.length == 0) {
            dispatch(setAlertInfo({
                showStatus: true,
                title: "",
                title1: "暂无内容导出",
                content: ""
            }))
            return;
        }
        const initColumn = [{
            title: '缴费时间',
            dataIndex: 'paymentTime',
            key: 'paymentTime',
        }, {
            title: '学年度',
            dataIndex: 'xnd',
            key: 'xnd',
        }, {
            title: '学期',
            dataIndex: 'xq',
            key: 'xq',
        },
            //     {
            //     title: '省级区域',
            //     dataIndex: 'sjqy',
            //     key: 'sjqy',
            //     // className: 'text-monospace',
            // }, {
            //     title: '区县区域',
            //     dataIndex: 'qxqy',
            //     key: 'qxqy',
            // },
            {
                title: '学校名称',
                dataIndex: 'schoolName',
                key: 'schoolName',
            }, {
                title: '年级',
                dataIndex: 'grade',
                key: 'grade',
            }, {
                title: '班级',
                dataIndex: 'className',
                key: 'className',
            }, {
                title: '学生姓名',
                dataIndex: 'studentName',
                key: 'studentName',
            }, {
                title: '性别',
                dataIndex: 'sexTag',
                key: 'sexTag',
            }, {
                title: '生日',
                dataIndex: 'borthday',
                key: 'borthday',
            }, {
                title: '缴款课程',
                dataIndex: 'courseName',
                key: 'courseName',
            }, {
                title: '课程费用',
                dataIndex: 'coursePrice',
                key: 'coursePrice',
            }, {
                title: '教具名称',
                dataIndex: 'jiaojuName',
                key: 'jiaojuName',
            }, {
                title: '教具费用',
                dataIndex: 'jiaojuPrice',
                key: 'jiaojuPrice',
            }, {
                title: '支付渠道',
                dataIndex: 'payType',
                key: 'payType',
            }, {
                title: '家长姓名',
                dataIndex: 'parentName',
                key: 'parentName',
            },
            {
                title: '家长电话',
                dataIndex: 'parentPhone',
                key: 'parentPhone',
            },
            {
                title: '退费',
                dataIndex: 'refundStatus',
                key: 'refundStatus',
            }]
        let attendanceInfoList = [];
        infoList.map((item, index) => {
            console.log(typeof (item.jiaoju_name))
            console.log(item.jiaoju_name)
            attendanceInfoList.push({
                '缴费时间': item.jiaofei_time,
                '学年度': item.shoufei_zhouqi_code,
                '学期': item.shoufei_zhouqi_name,
                // sjqy:item.school_shengji_name,
                // qxqy:item.school_quxian_name,
                '学校名称': item.school_name,
                '年级': item.grade_name,
                '班级': item.class_name,
                '学生姓名': item.name,
                '性别': item.xingbie == 1 ? '男' : '女',
                '生日': item.shengri == null ? "" : item.shengri,
                '缴款课程': item.kecheng_name == null ? "" : item.kecheng_name,
                '课程费用':getMoneyDate("yuan",item.kecheng_jiage),
                '教具名称': item.jiaoju_name == null ? "" : item.jiaoju_name,
                '教具费用':getMoneyDate("yuan",item.jiaoju_jiage),
                '支付渠道': item.pay_type == 1 ? "微信" : item.pay_type == 2 ? "支付宝" : "第三方",
                '家长姓名': item.jiazhang_name,
                '家长电话': item.jiazhang_phone,
                '退费': item.tuifei_status == 1 ? "有退费记录" : ""

            })
        })
        ExcelUtil.exportExcel( attendanceInfoList, `学校开课到款.xlsx`,
            [
                {wpx: 150},
                {wpx: 90},
                {wpx: 80},
                {wpx: 116},
                {wpx: 116},
                {wpx: 110},
                {wpx: 70},
                {wpx: 60},
                {wpx: 100},
                {wpx: 66},
                {wpx: 200},
                {wpx: 90},
                {wpx: 200},
                {wpx: 90},
                {wpx: 88},
                {wpx: 110},
                {wpx: 110},
                {wpx: 110}
            ]
        )
    }

    function onChange(e) {
        console.log(e.target.checked)
        if (e.target.checked) {
            let tempInfoList = []
            let checkedAll = true;
            infoList.forEach(element => {
                var newElement = {}
                for (let key in element) {
                    if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                        newElement[key] = element[key]
                    }
                }
                newElement.checked = true
                tempInfoList.push(newElement)
                if (newElement.checked == false) {
                    checkedAll = false
                }
            });
            dispatch(setInfoList(tempInfoList))
            dispatch(setInfoCheckedAll(checkedAll))

        } else {
            let tempInfoList = []
            infoList.forEach(element => {
                var newElement = {}
                for (let key in element) {
                    if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                        newElement[key] = element[key]
                    }
                }
                newElement.checked = false
                tempInfoList.push(newElement)
            });
            dispatch(setInfoList(tempInfoList))
            dispatch(setInfoCheckedAll(false))
        }
    }

    function getInfoList() {
        getInfoListRequest(schoolList[schoolIndex].school_code, reviewStatus, 0, pageNumber, (data) => {
            if (data.ret == 1) {
                var tempList = [];
                data.data.pay_list.map((item, index) => {
                    item.checked = false
                    tempList.push(item)
                })
                dispatch(setInfoList(tempList))
                dispatch(setInfoCheckedAll(false))
                var pageNumber1 = Math.ceil(data.data.total_num / pageNumber)
                dispatch(setTotalPageNum(pageNumber1))
                dispatch(setCurrentPage(1))
            } else if (data.ret == 99) {
                messageApi.open({
                    type: 'warning',
                    content: data.msg
                });
                setTimeout((e) => {
                    sessionStorage.clear()
                    window.location.reload()
                }, 1000)
            } else {
                dispatch(setInfoList([]))
                dispatch(setInfoCheckedAll(false))
                dispatch(setTotalPageNum(1))
                dispatch(setCurrentPage(1))
            }
        })
    }

    function acceptMethod() {
        setConfirmInfo({
            showStatus: true,
            title: "",
            title1: `是否批量审核选中的数据？`,
            content: "",
            eventId: 1
        })
    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
            var baomingList = []
            infoList.forEach(element => {
                if (element.checked) {
                    baomingList.push({
                        baoming_id: element.Id,
                    })
                }
            })
            console.log("baomingListbaomingListbaomingList")
            console.log(baomingList)
            shenhePayment(JSON.stringify(baomingList), "1", (data) => {
                // if (data.ret==1) {
                getInfoList()
                // }
                if (data.ret == 99) {
                    messageApi.open({
                        type: 'warning',
                        content: data.msg
                    });
                    setTimeout((e) => {
                        sessionStorage.clear()
                        window.location.reload()
                    }, 1000)
                }
            })
        } else if (confirmInfo.eventId == 2) {


        }
    }

    return <div className={styles.background}>
        {contextHolder}
        <CustomConfirmAlert
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        <div className={styles.titleContainer}>
            <span className={styles.leftTitle}>数据列表</span>
            <div className={styles.rightButtonContainer}>
                {/*<div className={styles.rightButton2}*/}
                {/*    onClick={acceptMethod}*/}
                {/*>*/}
                {/*    /!* <FontAwesomeIcon icon={faAdd} className={styles.rightButtonIcon}/> *!/*/}
                {/*    确认审核*/}
                {/*</div>*/}
                <div className={styles.rightButton1} onClick={exportThisSchoolInfo}>
                    <FontAwesomeIcon icon={faUpload} className={styles.rightButtonIcon}/>
                    导出选中条件数据
                </div>
                <div className={styles.rightButton1} onClick={exportAllInfo}>
                    <FontAwesomeIcon icon={faUpload} className={styles.rightButtonIcon}/>
                    导出全部学校数据
                </div>
                {/*<div className={styles.rightButton1} onClick={exportExcelMethod.bind(null,infoList)}>*/}
                {/*    <FontAwesomeIcon icon={faUpload} className={styles.rightButtonIcon}/>*/}
                {/*    导出*/}
                {/*</div>*/}
                {/* <div className={styles.rightButton1}>
                    <FontAwesomeIcon icon={faDownload} className={styles.rightButtonIcon}/>
                    导入
                </div>
                <div className={styles.rightButton2}>
                    <FontAwesomeIcon icon={faAdd} className={styles.rightButtonIcon}/>
                    添加
                </div> */}
            </div>
        </div>
        <div className={styles.listContainer}>
            <table className={styles.infoTable}>
                <tr
                    className={styles.tr1}
                >
                    <td
                        className={styles.xuhaoTd}
                    >序号
                    </td>
                    <td
                        className={styles.td1}
                    >缴费时间
                    </td>
                    <td
                        className={styles.td1}
                    >学年度
                    </td>
                    <td
                        className={styles.td1}
                    >学期
                    </td>
                    <td
                        className={styles.td1}
                    >学校名称
                    </td>
                    <td
                        className={styles.td1}
                    >年级
                    </td>
                    <td
                        className={styles.td1}
                    >班级
                    </td>
                    <td
                        className={styles.td1}
                    >学生姓名
                    </td>
                    <td
                        className={styles.td1}
                    >性别
                    </td>
                    <td
                        className={styles.td1}
                    >生日
                    </td>
                    <td
                        className={styles.td1}
                    >缴款课程
                    </td>
                    <td
                        className={styles.td1}
                    >课程费用
                    </td>
                    <td
                        className={styles.td1}
                    >教具名称
                    </td>
                    <td
                        className={styles.td1}
                    >教具费用
                    </td>
                    <td
                        className={styles.td1}
                    >支付渠道
                    </td>
                    <td
                        className={styles.td1}
                    >家长姓名
                    </td>
                    <td
                        className={styles.td1}
                    >家长电话
                    </td>
                    <td
                        className={styles.td1}
                    >退费
                    </td>
                </tr>
                {infoList.map((item, index) => (
                    <InfoItem
                        item={item}
                        key={(currentPage - 1) * pageNumber + index + 1}
                        index={(currentPage - 1) * pageNumber + index + 1}

                    />
                ))}
            </table>
        </div>


    </div>
}

