import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
    rangeTime: ["", ""],
    payStatus: false,
    refundStatus: false,
    payTypeIndex: 0,
    payTypeList: [{
        "name": "全部",
        "code": ""
    }, {
        "name": "微信",
        "code": "1"
    }, {
        "name": "支付宝",
        "code": "2"
    }, {
        "name": "第三方",
        "code": "3"
    }],
    provinceIndex: 0,
    provinceList: [{
        quyu_name: "全部",
        quyu_code: ""
    }],
    municipalityIndex: 0,
    municipalityList: [{
        quyu_name: "全部",
        quyu_code: ""
    }],
    countyIndex: 0,
    countyList: [{
        quyu_name: "全部",
        quyu_code: ""
    }],
    schoolIndex: 0,
    schoolList: [{
        "school_name": "全部",
        "school_code": ""
    }],
    xueNianIndex: 0,
    xueNianList: [{
        "name": "2024-2025",
        "code": "2024-2025"
    }, {
        "name": "2025-2026",
        "code": "2025-2026"
    }, {
        "name": "2026-2027",
        "code": "2026-2027"
    }, {
        "name": "2027-2028",
        "code": "2027-2028"
    },],
    xueQiIndex: 0,
    xueQiList: [{
        "name": "全部",
        "code": 0
    }, {
        "name": "9-3秋季学期",
        "code": 1
    }, {
        "name": "3-9春季学期",
        "code": 2
    }],
    gradeIndex: 0,
    gradeList: [
        {"name": "全部年级", "code": ""},
        {"name": "一年级", "code": "1"},
        {"name": "二年级", "code": "2"},
        {"name": "三年级", "code": "3"},
        {"name": "四年级", "code": "4"},
        {"name": "五年级", "code": "5"},
        {"name": "六年级", "code": "6"},
        {"name": "七年级", "code": "7"},
        {"name": "八年级", "code": "8"},
        {"name": "九年级", "code": "9"}],
    parentName: "",
    studentName: "",
    sexIndex: 0,
    sexList: [{
        "name": "全部",
        "code": ""
    }, {
        "name": "男",
        "code": "1"
    }, {
        "name": "女",
        "code": "0"
    }],
    courseIndex: 0,
    courseList: [{
        "name": "全部",
        "code": ""
    }],
    kejiIndex: 0,
    kejiList: [{
        "name": "全部",
        "code": ""
    }],
    jiaojuIndex: 0,
    jiaojuList: [{
        "name": "全部",
        "code": ""
    }],
    merchantOrderNo: "",
    platformOrderNo: "",
    schoolOrCourseNameKey:"",
    infoNumber: 0,
    infoAccount:0,
    infoList: [],
    //分页所需参数
    groupCount: 5,
    startPage: 1,
    pageNumber: 10,
    currentPage: 1,
    totalPageNum: 1,
    pages: [],
};

export const QJSJYYQAEOrderExportSlice = createSlice({
    name: 'QJSJYYQAEOrderExport',
    initialState,
    reducers: {
        setRangeTime: (state, action) => {
            state.rangeTime = action.payload;
        },
        setPayStatus: (state, action) => {
            state.payStatus = action.payload;
        },
        setRefundStatus: (state, action) => {
            state.refundStatus = action.payload;
        },
        setPayTypeIndex: (state, action) => {
            state.payTypeIndex = action.payload;
        },
        setPayTypeList: (state, action) => {
            state.payTypeList = action.payload;
        },
        setProvinceIndex: (state, action) => {
            state.provinceIndex = action.payload;
        },
        setProvinceList: (state, action) => {
            state.provinceList = action.payload;
        },
        setMunicipalityIndex: (state, action) => {
            state.municipalityIndex = action.payload;
        },
        setMunicipalityList: (state, action) => {
            state.municipalityList = action.payload;
        },
        setCountyIndex: (state, action) => {
            state.countyIndex = action.payload;
        },
        setCountyList: (state, action) => {
            state.countyList = action.payload;
        },
        setSchoolIndex: (state, action) => {
            state.schoolIndex = action.payload;
        },
        setSchoolList: (state, action) => {
            state.schoolList = action.payload;
        },
        setXueNianIndex: (state, action) => {
            state.xueNianIndex = action.payload;
        },
        setXueNianList: (state, action) => {
            state.xueNianList = action.payload;
        },
        setXueQiIndex: (state, action) => {
            state.xueQiIndex = action.payload;
        },
        setXueQiList: (state, action) => {
            state.xueQiList = action.payload;
        },
        setGradeIndex: (state, action) => {
            state.gradeIndex = action.payload;
        },
        setGradeList: (state, action) => {
            state.gradeList = action.payload;
        },
        setParentName: (state, action) => {
            state.parentName = action.payload;
        },
        setStudentName: (state, action) => {
            state.studentName = action.payload;
        },
        setSexIndex: (state, action) => {
            state.sexIndex = action.payload;
        },
        setSexList: (state, action) => {
            state.sexList = action.payload;
        },
        setCourseIndex: (state, action) => {
            state.courseIndex = action.payload;
        },
        setCourseList: (state, action) => {
            state.courseList = action.payload;
        },
        setKejiIndex: (state, action) => {
            state.kejiIndex = action.payload;
        },
        setKejiList: (state, action) => {
            state.kejiList = action.payload;
        },
        setJiaojuIndex: (state, action) => {
            state.jiaojuIndex = action.payload;
        },
        setJiaojuList: (state, action) => {
            state.jiaojuList = action.payload;
        },
        setMerchantOrderNo: (state, action) => {
            state.merchantOrderNo = action.payload;
        },
        setPlatformOrderNo: (state, action) => {
            state.platformOrderNo = action.payload;
        },
        setSchoolOrCourseNameKey:(state, action) => {
            state.schoolOrCourseNameKey = action.payload;
        },
        setInfoNumber: (state, action) => {
            state.infoNumber = action.payload;
        },
        setInfoAccount: (state, action) => {
            state.infoAccount = action.payload;
        },

        setInfoList: (state, action) => {
            state.infoList = action.payload;
        },


        setGroupCount: (state, action) => {
            state.groupCount = action.payload;
        },
        setStartPage: (state, action) => {
            state.startPage = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setTotalPageNum: (state, action) => {
            state.totalPageNum = action.payload;
        },


    },
});
export const selectRangeTime = (state) => state.QJSJYYQAEOrderExport.rangeTime;
export const selectPayStatus = (state) => state.QJSJYYQAEOrderExport.payStatus;
export const selectRefundStatus = (state) => state.QJSJYYQAEOrderExport.refundStatus;
export const selectPayTypeIndex = (state) => state.QJSJYYQAEOrderExport.payTypeIndex;
export const selectPayTypeList = (state) => state.QJSJYYQAEOrderExport.payTypeList;
export const selectProvinceIndex = (state) => state.QJSJYYQAEOrderExport.provinceIndex;
export const selectProvinceList = (state) => state.QJSJYYQAEOrderExport.provinceList;
export const selectMunicipalityIndex = (state) => state.QJSJYYQAEOrderExport.municipalityIndex;
export const selectMunicipalityList = (state) => state.QJSJYYQAEOrderExport.municipalityList;
export const selectCountyIndex = (state) => state.QJSJYYQAEOrderExport.countyIndex;
export const selectCountyList = (state) => state.QJSJYYQAEOrderExport.countyList;
export const selectSchoolIndex = (state) => state.QJSJYYQAEOrderExport.schoolIndex;
export const selectSchoolList = (state) => state.QJSJYYQAEOrderExport.schoolList;
export const selectXueNianIndex = (state) => state.QJSJYYQAEOrderExport.xueNianIndex;
export const selectXueNianList = (state) => state.QJSJYYQAEOrderExport.xueNianList;
export const selectXueQiIndex = (state) => state.QJSJYYQAEOrderExport.xueQiIndex;
export const selectXueQiList = (state) => state.QJSJYYQAEOrderExport.xueQiList;
export const selectGradeIndex = (state) => state.QJSJYYQAEOrderExport.gradeIndex;
export const selectGradeList = (state) => state.QJSJYYQAEOrderExport.gradeList;
export const selectParentName = (state) => state.QJSJYYQAEOrderExport.parentName;
export const selectStudentName = (state) => state.QJSJYYQAEOrderExport.studentName;
export const selectSexIndex = (state) => state.QJSJYYQAEOrderExport.sexIndex;
export const selectSexList = (state) => state.QJSJYYQAEOrderExport.sexList;
export const selectCourseIndex = (state) => state.QJSJYYQAEOrderExport.courseIndex;
export const selectCourseList = (state) => state.QJSJYYQAEOrderExport.courseList;
export const selectKejiIndex = (state) => state.QJSJYYQAEOrderExport.kejiIndex;
export const selectKejiList = (state) => state.QJSJYYQAEOrderExport.kejiList;
export const selectJiaojuIndex = (state) => state.QJSJYYQAEOrderExport.jiaojuIndex;
export const selectJiaojuList = (state) => state.QJSJYYQAEOrderExport.jiaojuList;
export const selectSchoolOrCourseNameKey= (state) => state.QJSJYYQAEOrderExport.schoolOrCourseNameKey;
export const selectInfoNumber = (state) => state.QJSJYYQAEOrderExport.infoNumber;
export const selectInfoAccount = (state) => state.QJSJYYQAEOrderExport.infoAccount;
export const selectMerchantOrderNo = (state) => state.QJSJYYQAEOrderExport.merchantOrderNo;
export const selectPlatformOrderNo = (state) => state.QJSJYYQAEOrderExport.platformOrderNo;
export const selectInfoList = (state) => state.QJSJYYQAEOrderExport.infoList;

//分页所需数据
export const selectGroupCount = (state) => state.QJSJYYQAEOrderExport.groupCount;
export const selectStartPage = (state) => state.QJSJYYQAEOrderExport.startPage;
export const selectPageNumber = (state) => state.QJSJYYQAEOrderExport.pageNumber;
export const selectCurrentPage = (state) => state.QJSJYYQAEOrderExport.currentPage;
export const selectTotalPageNum = (state) => state.QJSJYYQAEOrderExport.totalPageNum;


export const {
    setRangeTime,
    setPayStatus,
    setRefundStatus,
    setPayTypeIndex,
    setPayTypeList,
    setProvinceIndex,
    setProvinceList,
    setMunicipalityIndex,
    setMunicipalityList,
    setCountyIndex,
    setCountyList,
    setSchoolIndex,
    setSchoolList,
    setXueNianIndex,
    setXueNianList,
    setXueQiIndex,
    setXueQiList,
    setGradeIndex,
    setGradeList,
    setParentName,
    setStudentName,
    setSexIndex,
    setSexList,
    setCourseIndex,
    setCourseList,
    setKejiIndex,
    setKejiList,
    setJiaojuIndex,
    setJiaojuList,
    setSchoolOrCourseNameKey,
    setMerchantOrderNo,
    setPlatformOrderNo,
    setInfoNumber,
    setInfoAccount,
    setInfoList,
    setGroupCount,
    setStartPage,
    setPageNumber,
    setCurrentPage,
    setTotalPageNum,
} = QJSJYYQAEOrderExportSlice.actions;

export default QJSJYYQAEOrderExportSlice.reducer;