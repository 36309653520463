// import XLSX from 'xlsx';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

// function exportExcel(data, sheetName, fileName) {
function exportExcel( data, fileName = '数据表.xlsx',colsWidth=[{ wpx: 145 }, { wpx: 100 }, { wpx: 200 }, { wpx: 80 }, { wpx: 150 }, { wpx: 100 }, { wpx: 300 }, { wpx: 300 }]) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "sheet");
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(dataBlob, fileName);
}
export default {exportExcel};