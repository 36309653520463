import request from "../../../util/request";


export function getRegionListRequest(regionLevel,parentCode,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "quyulist");
    filedata.append('quyu_jibie', regionLevel);
    filedata.append('quyu_fuji_code', parentCode);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body:filedata,
    }, callback);
}

export function getZhuxiaoListRequest(provinceCode,municipalityCode,countyCode,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_quxian_zhuxiao_list");
    filedata.append('shengji_quyu_code', provinceCode);
    filedata.append('shiji_quyu_code', municipalityCode);
    filedata.append('quxian_quyu_code', countyCode);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body:filedata,
    }, callback);
}

export function getInfoListRequest(regionName,schoolName,contactName,offset,limit,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_all_school_list");
    filedata.append('school_quyu_namekey', regionName);
    filedata.append('school_namekey', schoolName);
    filedata.append('school_lianxiren_namekey', contactName);
    filedata.append('offset', offset);
    filedata.append('limit', limit);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body:filedata,
    }, callback);
}
export function addSchoolInfo(provinceName,provinceCode,
    municipalityName,municipalityCode,
    countyName,countyCode,
    zhuxiaoName,zhuxiaoCode,
    zhuxiaoUserName,
    schoolName,contactName,
    contactPhone,telephone1,
    telephone2,telephone3,
    schoolAddress,accountStatus,notes,
    callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "add_school");
    filedata.append('shengji_quyu_name', provinceName);
    filedata.append('shengji_quyu_code', provinceCode);
    filedata.append('shiji_quyu_code', municipalityCode);
    filedata.append('shiji_quyu_name', municipalityName);
    filedata.append('quxian_quyu_code', countyCode);
    filedata.append('quxian_quyu_name', countyName);
    filedata.append('quxian_zhuxiao_name', zhuxiaoName);
    filedata.append('quxian_zhuxiao_code', zhuxiaoCode);
    filedata.append('quxian_zhuxiao_username', zhuxiaoUserName);
    filedata.append('school_name', schoolName);
    filedata.append('lianxiren', contactName);
    filedata.append('lianxi_dianhua', contactPhone);
    filedata.append('zuoji_quhao', telephone1);
    filedata.append('zuoji_dianhuahao', telephone2);
    filedata.append('zuoji_fenjihao', telephone3);
    filedata.append('school_dizhi', schoolAddress);
    filedata.append('status', accountStatus);
    filedata.append('beizhu', notes);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function updateCountyCompany(
    provinceName,provinceCode,
    municipalityName,municipalityCode,
    countyName,countyCode,
    zhuxiaoName,zhuxiaoCode,
    zhuxiaoUserName,
    schoolName,contactName,
    contactPhone,telephone1,
    telephone2,telephone3,
    schoolAddress,accountStatus,
    notes,schoolCode,
    callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "update_school");
    filedata.append('shengji_quyu_name', provinceName);
    filedata.append('shengji_quyu_code', provinceCode);
    filedata.append('shiji_quyu_code', municipalityCode);
    filedata.append('shiji_quyu_name', municipalityName);
    filedata.append('quxian_quyu_code', countyCode);
    filedata.append('quxian_quyu_name', countyName);
    filedata.append('quxian_zhuxiao_name', zhuxiaoName);
    filedata.append('quxian_zhuxiao_code', zhuxiaoCode);
    filedata.append('quxian_zhuxiao_username', zhuxiaoUserName);
    filedata.append('school_name', schoolName);
    filedata.append('lianxiren', contactName);
    filedata.append('lianxi_dianhua', contactPhone);
    filedata.append('zuoji_quhao', telephone1);
    filedata.append('zuoji_dianhuahao', telephone2);
    filedata.append('zuoji_fenjihao', telephone3);
    filedata.append('school_dizhi', schoolAddress);
    filedata.append('status', accountStatus);
    filedata.append('beizhu', notes);
    filedata.append('school_code', schoolCode);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function openAccount(schoolCode,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "kaitong_school");
    filedata.append('school_code', schoolCode);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function closeAccount(schoolCode,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "jinyong_school");
    filedata.append('school_code', schoolCode);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function deleteAccount(schoolCode,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "del_school");
    filedata.append('school_code', schoolCode);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}