import { createSlice} from '@reduxjs/toolkit';
const initialState = {
    bigMenuIndex: 0,//外部大菜单选项，0是甄选学校，1是运营管理
    ZXMenuIndex: 0,//甄选学校下的页面索引
    XMLDMenuIndex:0,//项目落地下的页面索引
    menuIndex: 0, //运营管理下的页面索引
    XSSMMenuIndex:0,
    SBPSMenuIndex:0,
    ZSYBHMenuIndex:0,
    ZSSMHMenuIndex:0,
    schoolList: []
};

export const ZXYYMainSlice = createSlice({
    name: 'ZXYYMain',
    initialState,
    reducers: {
        setBigMenuIndex: (state, action) => {
            state.bigMenuIndex = action.payload;
        },
        setZXMenuIndex: (state, action) => {
            state.ZXMenuIndex = action.payload;
        },
        setXMLDMenuIndex: (state, action) => {
            state.XMLDMenuIndex = action.payload;
        },
        setMenuIndex: (state, action) => {
            state.menuIndex = action.payload;
        },
        setSchoolList: (state, action) => {
            state.schoolList = action.payload;
        },
        setXSSMMenuIndex: (state, action) => {
            state.XSSMMenuIndex = action.payload;
        },
        setSBPSMenuIndex: (state, action) => {
            state.SBPSMenuIndex = action.payload;
        },
        setZSYBHMenuIndex: (state, action) => {
            state.ZSYBHMenuIndex = action.payload;
        },
        setZSSMHMenuIndex: (state, action) => {
            state.ZSSMHMenuIndex = action.payload;
        }
    },
});

export const selectBigMenuIndex = (state) => state.ZXYYMain.bigMenuIndex;
export const selectZXMenuIndex = (state) => state.ZXYYMain.ZXMenuIndex;
export const selectXMLDMenuIndex = (state) => state.ZXYYMain.XMLDMenuIndex;
export const selectMenuIndex = (state) => state.ZXYYMain.menuIndex;
export const selectXSSMMenuIndex = (state) => state.ZXYYMain.XSSMMenuIndex;
export const selectSBPSMenuIndex = (state) => state.ZXYYMain.SBPSMenuIndex;
export const selectZSYBHMenuIndex = (state) => state.ZXYYMain.ZSYBHMenuIndex;
export const selectZSSMHMenuIndex = (state) => state.ZXYYMain.ZSSMHMenuIndex;
export const {
    setBigMenuIndex,
    setZXMenuIndex,
    setXMLDMenuIndex,
    setMenuIndex,
    setSchoolList,
    setXSSMMenuIndex,
    setSBPSMenuIndex,
    setZSYBHMenuIndex,
    setZSSMHMenuIndex,
} = ZXYYMainSlice.actions;

export default ZXYYMainSlice.reducer;
