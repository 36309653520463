
import React, {useRef, useState} from "react";
import styles from "./AddInfo.module.css";
import { useDispatch,useSelector } from "react-redux";
import classNames from "classnames"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClose,faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
import { selectAllCourseList, selectShowAddInfoCoursePage, selectShowAddInfoTimePage, setAllCourseList, setShowAddInfoCoursePage, setShowAddInfoPage, setShowAddInfoTimePage } from "./TeacherManageSlice";
import { addProvinceCompany, getRegionListRequest } from "./TeacherManageApi";
import { setAlertInfo } from "../../../store/CustomViewSlice";
import { CustomConfirmAlert } from "../../CustomView/CustomConfirmAlert";
import AddInfoCourse from "./AddInfoCourse";
import AddInfoTime from "./AddInfoTime";
export default function AddInfo(props) {
    const [confirmInfo,setConfirmInfo]=useState({})
    const [teacherName, setTeacherName] = useState("");
    const [showSexList, setShowSexList] = useState(false);
    const [teacherSex, setTeacherSex] = useState(1);//0为女，1为男
    const [teacherSexList, setTeacherSexList] = useState([{
        "name":"男",
        "code":1
    },{
        "name":"女",
        "code":0
    }]);//0为女，1为男
    const [teacherSource, setTeacherSource] = useState("");
    const [teacherPhone, setTeacherPhone] = useState("");
    const [courseList, setCourseList] = useState([{
        "name":"人工智能L1级别课程（固定数据）",
        "code":"NPL00001"
    },{
        "name":"脑科学学习力提升",
        "code":"NPL00002"
    }]);//0为女，1为男
    
    const showAddInfoCoursePage = useSelector(selectShowAddInfoCoursePage)
    const showAddInfoTimePage = useSelector(selectShowAddInfoTimePage)

    



    const [courseTimeList, setCourseTimeList] = useState([{
        "name":"周一",
        "time":"8:00-12:00 ; 13:00-15:00"
    },{
        "name":"周二",
        "time":"8:00-12:00 ; 13:00-15:00"
    },{
        "name":"周三",
        "time":"8:00-12:00 ; 13:00-15:00"
    },{
        "name":"周四",
        "time":"8:00-12:00 ; 13:00-15:00"
    },{
        "name":"周五",
        "time":"8:00-12:00 ; 13:00-15:00"
    },{
        "name":"周六",
        "time":"8:00-12:00 ; 13:00-15:00"
    },{
        "name":"周日",
        "time":"8:00-12:00 ; 13:00-15:00"
    }]);
    const dispatch = useDispatch();
    function isValidPhoneNumber(phoneNumber) {
        return /^1[3-9]\d{9}$/.test(phoneNumber);
    }
    function cancelMethod(params) {
        setConfirmInfo({
            showStatus:false,
            title:"",
            title1:"",
            content:""
        })
    }
    function confirmMethod(params) {
        setConfirmInfo({
            showStatus:false,
            title:"",
            title1:"",
            content:""
        })
        if (confirmInfo.eventId==1) {
            
        } else if(confirmInfo.eventId==2) {
            // closeAccount(
            //     props.item.code,
            //     props.item.login_username,
            //     (data)=>{
            //         if (data.ret==1) {
            //             refreshMethod()
            //         } else {
            //             dispatch(setAlertInfo({
            //                 showStatus:true,
            //                 title:"",
            //                 title1:'账户禁用失败，请重试',
            //                 content:""
            //             }))
            //         }
            //     }
            // )
        }
        else if(confirmInfo.eventId==3){
            
        }
    }
    return <div className={styles.background}>
        <CustomConfirmAlert
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        {showSexList?<div className={styles.hideListCover}
            onClick={(e)=>{
                setShowSexList(false)
            }}
        ></div>:null}
        {showAddInfoCoursePage?<AddInfoCourse />:null}
        {showAddInfoTimePage?<AddInfoTime/>:null}
        <div className={styles.contentContainer}>
            <div className={styles.topBar}>
                <div className={styles.topBarLeft}>教师信息添加</div>
                <FontAwesomeIcon 
                    className={styles.topBarRight}
                    icon={faClose}
                    onClick={(e)=>{
                        dispatch(setShowAddInfoPage(false))
                    }}
                    />
            </div>
            <div className={styles.middleContent}>
                <div className={styles.middleHeader}>教师基础信息</div>
                <div className={styles.teacherInfoRow1}>
                    <div className={styles.teacherName}>教师姓名：
                        <input 
                            className={styles.teacherNameInput}
                            placeholder="请输入姓名"
                            value={teacherName}
                            onChange={(e)=>{
                                console.log(e.target.value)
                                setTeacherName(e.target.value)
                            }}
                        />
                    </div>
                    <div className={styles.teacherSex}>教师性别：
                        <div className={styles.teacherSexChecked}
                            onClick={(e)=>{
                                setShowSexList(!showSexList)
                            }}
                        >
                            {teacherSex==1?"男":"女"}
                            <FontAwesomeIcon 
                                className={styles.sexIcon}
                                icon={faAngleDown}
                            />
                            {showSexList?<div className={classNames(styles.tankangContainer,styles.sexListPosition)}>
                                {teacherSexList.map((item,index,arr)=>{
                                    return <div className={styles.tankuangItem}
                                        key={index}
                                        onClick={(e)=>{
                                            setTeacherSex(item.code)
                                        }}
                                    >{item.name}</div>
                                })}
                            </div>
                            :
                            null}
                        </div>
                    </div>
                    <div className={styles.teacherSource}>教师来源：
                        <input 
                            className={styles.teacherSourceInput}
                            placeholder="请输入教师来源"
                            value={teacherSource}
                            onChange={(e)=>{
                                setTeacherSource(e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className={styles.teacherInfoRow1}>联系手机：
                    <input className={styles.teacherPhoneInput}
                            placeholder="填写联系人电话"
                            value={teacherPhone}
                            maxLength={11}
                            onChange={((e)=>{
                                setTeacherPhone(e.target.value)
                            })}
                        />
                </div>
                <div className={styles.middleHeader1}>
                    <p>教师教授课程</p>
                    <div className={styles.addButton}
                        onClick={(e=>{
                            dispatch(setAllCourseList([{
                                "name":"人工智能L1级别课程（固定数据）",
                                "code":"NPL00001",
                                "checked":false
                            },{
                                "name":"脑科学学习力提升",
                                "code":"NPL00002",
                                "checked":false
                            },{
                                "name":"脑科学学习力提升",
                                "code":"NPL00003",
                                "checked":false
                            },{
                                "name":"脑科学学习力提升",
                                "code":"NPL00004",
                                "checked":false
                            }]))
                            dispatch(setShowAddInfoCoursePage(true))
                        })}
                    >添加课程</div>
                </div>
                <table 
                    align="top"
                    border="0"
                    bordercolor="#f00"
                    // bgcolor="7fffaa"
                    cellspacing="0"
                    cellspadding="0"
                    width="580"
                >
                    <tr valign="middle"
                         align="center"
                        className={styles.tr1}
                    >
                        <td 
                            width="120" height="5"
                            className={styles.td1}
                        >课程编号</td>
                        <td 
                            width="340" height="5"
                            className={styles.td1}
                        >课程名称</td>
                        <td 
                            width="100" height="5"
                            className={styles.td1}
                        >管理操作</td>
                    </tr>
                    {courseList.map((item,index,arr)=>{
                        return <tr valign="middle"
                        align="center">
                            <td 
                                width="120" height="5"
                                className={styles.td2}
                            >{item.code}</td>
                            <td 
                                width="340" height="5"
                                className={styles.td2}
                            >{item.name}</td>
                            <td 
                                width="100" height="5"
                                className={styles.td3}
                                onClick={(e)=>{
                                    setConfirmInfo({
                                        showStatus:true,
                                        title:"",
                                        title1:'是否确认删除该课程？',
                                        content:"",
                                        eventId:1
                                    })
                                }}
                            >删除</td>
                        </tr>
                    })}
                    
                </table>
                <div className={styles.middleHeader1}>
                    <p>教师备授课时间</p>
                    <div className={styles.addButton}
                        onClick={(e=>{
                            dispatch(setShowAddInfoTimePage(true))
                        })}
                    >添加时段</div>
                </div>
                <table 
                    align="top"
                    border="0"
                    bordercolor="#f00"
                    // bgcolor="7fffaa"
                    cellspacing="0"
                    cellspadding="0"
                    width="580"
                >
                    <tr valign="middle"
                         align="center"
                        className={styles.tr1}
                    >
                        <td 
                            width="120" height="5"
                            className={styles.td1}
                        >周日期</td>
                        <td 
                            width="340" height="5"
                            className={styles.td1}
                        >可授课时间段</td>
                        <td 
                            width="100" height="5"
                            className={styles.td1}
                        >管理操作</td>
                    </tr>
                    {courseTimeList.map((item,index,arr)=>{
                        return <tr valign="middle"
                        align="center">
                            <td 
                                width="120" height="5"
                                className={styles.td2}
                            >{item.name}</td>
                            <td 
                                width="340" height="5"
                                className={styles.td2}
                            >{item.time}</td>
                            <td 
                                width="100" height="5"
                                className={styles.td3}
                                onClick={(e)=>{
                                    setConfirmInfo({
                                        showStatus:true,
                                        title:"",
                                        title1:'是否确认删除该时间？',
                                        content:"",
                                        eventId:2
                                    })
                                }}
                            >删除</td>
                        </tr>
                    })}
                    
                </table>
            </div>
            <div className={styles.bottomContainer}>
                <div className={classNames(styles.cancelButton,styles.button)}
                    onClick={(e)=>{
                        dispatch(setShowAddInfoPage(false))
                    }}
                >取消</div>
                <div className={classNames(styles.confirmButton,styles.button)}
                    onClick={(e)=>{
                        if (teacherName=="") {
                            dispatch(setAlertInfo({
                                showStatus:true,
                                title:"",
                                title1:"姓名不能为空",
                                content:""
                            }))
                        }
                        else if (teacherSource=="") {
                            dispatch(setAlertInfo({
                                showStatus:true,
                                title:"",
                                title1:"请检查教师来源是否填写",
                                content:""
                            }))
                        }
                        else if (!isValidPhoneNumber(teacherPhone)) {
                            dispatch(setAlertInfo({
                                showStatus:true,
                                title:"",
                                title1:"请检查手机号是否正确",
                                content:""
                            }))
                        }
                        
                        else{
                            setConfirmInfo({
                                showStatus:true,
                                title:"",
                                title1:'确认提交？',
                                content:"",
                                eventId:3
                            })
                            // addProvinceCompany(
                            //     companyName,
                            //     contactsName,
                            //     contactsPhone,
                            //     telePhone1,
                            //     telePhone2,
                            //     telePhone3,
                            //     account,
                            //     password,
                            //     accountStatus,
                            //     notes,
                            //     (data)=>{
                            //         console.log('-添加请求的结果---')
                            //         console.log(data)
                            //         if (data.ret==1) {
                            //             dispatch(setShowAddInfoPage(false))
                            //             props.refresh()
                            //         }else if (data.ret==2) {
                            //             dispatch(setAlertInfo({
                            //                 showStatus:true,
                            //                 title:"",
                            //                 title1:"登录账号已存在，请填写其他账号",
                            //                 content:""
                            //             }))
                            //         } else {
                            //             dispatch(setAlertInfo({
                            //                 showStatus:true,
                            //                 title:"",
                            //                 title1:"添加失败，请检查内容重试",
                            //                 content:""
                            //             }))
                            //         }
                            //     }
                            // )
                        }
                    }}
                >确定</div>  
            </div>
        </div>
    </div>
}

