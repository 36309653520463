import React, { useState,useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import styles from './CustomAlert.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClose,faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import { setAlertInfo } from '../../store/CustomViewSlice';
export function CustomAlert(props) {
  const dispatch = useDispatch();

  useEffect(()=>{
  })
  function confirmMethod(params) {
    dispatch(setAlertInfo({
        showStatus:false,
        title:"",
        title1:"",
        content:""
    }))
  }


  return (
    props.info.showStatus?
        <div className={styles.background}>
          <div className={styles.container}>
            <div className={styles.topBar}>
                <div className={classNames(styles.topTitle,styles.scrollable)}>{props.info.title==""?"温馨提示":props.info.title}</div>
                <div className={styles.topCancelButton}>
                    <FontAwesomeIcon 
                        icon={faClose}
                        onClick={(e)=>{
                            // dispatch(setShowAddInfoPage(false))
                            confirmMethod()
                        }}
                    />
                </div>
                
            </div>
            <div className={styles.middleContainer}>
                <FontAwesomeIcon 
                    className={styles.questionIcon}
                    icon={faQuestionCircle}
                    onClick={(e)=>{
                        // dispatch(setShowAddInfoPage(false))
                    }}
                />
                <div className={styles.middleRightContainer}>
                    <div className={classNames(styles.middleTitle,styles.scrollable)}>
                        {props.info.title1}
                    </div>
                    <div className={classNames(styles.middleContent,styles.scrollable)}>
                        {props.info.content}
                    </div>
                </div>
            </div>
            <div className={styles.bottomContainer}>
                {/* <div className={styles.cancelButton}
                    onClick={(e)=>{
                        props.cancelMethod()
                    }}
                >取消</div> */}
                <div className={styles.confirmButton}
                    onClick={(e)=>{
                        // dispatch(setShowAddInfoPage(false))
                        confirmMethod()
                    }}
                >确定</div>
            </div>
          </div>
        </div>:null
        )
}
