import {
    createAsyncThunk,
    createSlice
  } from '@reduxjs/toolkit';
  // import { fetchCount } from './counterAPI';
  
  const initialState = {
    infoList:[],
    QRContentStr:"",
    downloadStatus:true,

    

    //分页所需参数
    groupCount:5,
    startPage:1,
    pageNumber:15,
    currentPage:1,
    totalPageNum:1,


    //添加驻校信息界面
    showAddInfoPage:false,
    showEditInfoPage:false,
    
    editInfo:{},

    // 数据筛选所需参数
    regionName:'',
    companyName:'',
    contactName:'',

    provinceList:[{
    }]
  };
  
  export const ZBZGProvinceInfoManageSlice = createSlice({
    name: 'ZBZGProvinceInfoManage',
    initialState,
    reducers: {
      setInfoList: (state, action) => {
        state.infoList = action.payload;
      },


      setGroupCount:(state, action) => {
        state.groupCount = action.payload;
      },
      setStartPage:(state, action) => {
        state.startPage = action.payload;
      },
      setPageNumber:(state, action) => {
        state.pageNumber = action.payload;
      },
      setCurrentPage:(state, action) => {
        state.currentPage = action.payload;
      },
      setTotalPageNum:(state, action) => {
        state.totalPageNum = action.payload;
      },

      setShowAddInfoPage:(state, action) => {
        state.showAddInfoPage = action.payload;
      },
      setShowEditInfoPage:(state, action) => {
        state.showEditInfoPage = action.payload;
      },
      setEditInfo:(state, action) => {
        state.editInfo = action.payload;
      },
      setRegionName:(state, action) => {
        state.regionName = action.payload;
      },
      setCompanyName:(state, action) => {
        state.companyName = action.payload;
      },
      setContactName:(state, action) => {
        state.contactName = action.payload;
      },
      setProvinceList:(state, action) => {
        state.provinceList = action.payload;
      },
    },
  });
  export const selectInfoList = (state) => state.ZBZGProvinceInfoManage.infoList;
  export const selectQRContentStr = (state) => state.ZBZGProvinceInfoManage.QRContentStr;
  export const selectDownloadStatus = (state) => state.ZBZGProvinceInfoManage.downloadStatus;
  //分页所需数据
  export const selectGroupCount = (state) => state.ZBZGProvinceInfoManage.groupCount;
  export const selectStartPage = (state) => state.ZBZGProvinceInfoManage.startPage;
  export const selectPageNumber = (state) => state.ZBZGProvinceInfoManage.pageNumber;
  export const selectCurrentPage = (state) => state.ZBZGProvinceInfoManage.currentPage;
  export const selectTotalPageNum = (state) => state.ZBZGProvinceInfoManage.totalPageNum;
  export const selectShowAddInfoPage = (state) => state.ZBZGProvinceInfoManage.showAddInfoPage;
  export const selectShowEditInfoPage = (state) => state.ZBZGProvinceInfoManage.showEditInfoPage;
  export const selectEditInfo = (state) => state.ZBZGProvinceInfoManage.editInfo;
  export const selectRegionName = (state) => state.ZBZGProvinceInfoManage.regionName;
  export const selectCompanyName = (state) => state.ZBZGProvinceInfoManage.companyName;
  export const selectContactName = (state) => state.ZBZGProvinceInfoManage.contactName;
  export const selectProvinceList = (state) => state.ZBZGProvinceInfoManage.provinceList;
  
  
  export const {
    setInfoList,
    setGroupCount,
    setStartPage,
    setPageNumber,
    setCurrentPage,
    setTotalPageNum,
    setShowAddInfoPage,
    setShowEditInfoPage,
    setEditInfo,
    setRegionName,
    setCompanyName,
    setContactName,
    setProvinceList
  } = ZBZGProvinceInfoManageSlice.actions;
  
  export default ZBZGProvinceInfoManageSlice.reducer;