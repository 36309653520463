import React, {useRef, useState, useEffect} from "react";
import styles from "./InfoItem.module.css";
import classNames from "classnames"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import {
    selectContactName,
    selectInfoList,
    selectPageNumber,
    selectRegionName,
    setCurrentPage,
    setEditInfo,
    setInfoList,
    setQRContentStr,
    setQRDownloadStatus,
    setShowEditInfoPage,
    setTotalPageNum
} from "./ZXInfoManageSlice";
import {useDispatch, useSelector} from "react-redux";
import {API_URL} from "../../../util/constants";
import {closeAccount, deleteAccount, getInfoListRequest, openAccount} from "./ZXInfoManageApi";
import {setAlertInfo} from "../../../store/CustomViewSlice";
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {message} from 'antd'
import {closeZBZGMain, openLogin} from "../../../store/modalsSlice";
// const PaymentInfoList = props =>(
export default function InfoItem(props) {
    const dispatch = useDispatch();
    const pageNumber = useSelector(selectPageNumber);
    const regionName = useSelector(selectRegionName);
    const contactName = useSelector(selectContactName);
    const [confirmInfo, setConfirmInfo] = useState({})
    const [messageApi, contextHolder] = message.useMessage();

    function refreshMethod() {
        dispatch(setCurrentPage(1))
        getInfoList(1, pageNumber)
    }

    function getInfoList(tempCurrentPage, tempPageNumber) {
        getInfoListRequest(regionName, contactName, (tempCurrentPage - 1) * tempPageNumber, tempPageNumber, (data) => {
            if (data.ret == 1) {
                dispatch(setInfoList(data.data.zhuxiao_list))
                var pageNumber1 = Math.ceil(data.data.total_num / tempPageNumber)
                dispatch(setTotalPageNum(pageNumber1))
            }
            else if(data.ret==99){

                messageApi.open({
                    type: 'warning',
                    content:data.msg
                });
                setTimeout((e)=>{
                    sessionStorage.clear()
                    window.location.reload()
                },1000)
            }
            else {
                dispatch(setInfoList([]))
                dispatch(setTotalPageNum(1))
            }

        })
    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
            openAccount(
                props.item.code,
                props.item.login_username,
                (data) => {
                    if (data.ret == 1) {
                        refreshMethod()
                    }else if(data.ret==99){

                        messageApi.open({
                            type: 'warning',
                            content:data.msg
                        });
                        setTimeout((e)=>{
                            sessionStorage.clear()
                            window.location.reload()
                        },1000)
                    } else {
                        dispatch(setAlertInfo({
                            showStatus: true,
                            title: "",
                            title1: '开通失败，请重试',
                            content: ""
                        }))
                    }
                }
            )
        } else if (confirmInfo.eventId == 2) {
            closeAccount(
                props.item.code,
                props.item.login_username,
                (data) => {
                    if (data.ret == 1) {
                        refreshMethod()
                    }else if(data.ret==99){

                        messageApi.open({
                            type: 'warning',
                            content:data.msg
                        });
                        setTimeout((e)=>{
                            sessionStorage.clear()
                            window.location.reload()
                        },1000)
                    } else {
                        dispatch(setAlertInfo({
                            showStatus: true,
                            title: "",
                            title1: '禁用失败，请重试',
                            content: ""
                        }))
                    }
                }
            )
        } else if (confirmInfo.eventId == 3) {
            deleteAccount(
                props.item.code,
                props.item.login_username,
                (data) => {
                    if (data.ret == 1) {
                        refreshMethod()
                    }else if(data.ret==99){

                        messageApi.open({
                            type: 'warning',
                            content:data.msg
                        });
                        setTimeout((e)=>{
                            sessionStorage.clear()
                            window.location.reload()
                        },1000)
                    } else {
                        dispatch(setAlertInfo({
                            showStatus: true,
                            title: "",
                            title1: '删除失败，请重试',
                            content: ""
                        }))
                    }
                }
            )
        }
    }

    return <tr
        className={styles.tr1}
    >
        <td
            className={styles.td1}
        >
            {props.item.xingming}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.quxian_gongsi_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.shengji_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.shiji_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.quxian_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.lianxi_dianhua}
        </td>
        <td
            className={styles.td1}
        >{props.item.status == 1 ? "开启" : props.item.status == 2 ? "禁用" : props.item.status == 3 ? "删除" : ""}
        </td>
        <td
            className={styles.td2}
        >
            <span className={classNames(styles.listContentSpan, styles.manageDes)}
                  onClick={(e) => {
                      dispatch(setEditInfo(props.item))
                      dispatch(setShowEditInfoPage(true))
                  }}
            >编辑</span>
            <span className={classNames(styles.listContentSpan, styles.manageDes)}
                  onClick={(e) => {
                      if (props.item.status == 1) {
                          dispatch(setAlertInfo({
                              showStatus: true,
                              title: "",
                              title1: '该账号已处于开通状态',
                              content: ""
                          }))
                      } else {
                          setConfirmInfo({
                              showStatus: true,
                              title: "",
                              title1: '是否开通该账号？',
                              content: "",
                              eventId: 1
                          })
                      }
                  }}
            >开通</span>
            <span className={classNames(styles.listContentSpan, styles.manageDes)}
                  onClick={(e) => {
                      if (props.item.status == 2) {
                          dispatch(setAlertInfo({
                              showStatus: true,
                              title: "",
                              title1: '该账号已处于禁用状态',
                              content: ""
                          }))
                      } else {
                          setConfirmInfo({
                              showStatus: true,
                              title: "",
                              title1: '是否禁用该账号？',
                              content: "",
                              eventId: 2
                          })
                      }
                  }}
            >禁用</span>
            <span className={classNames(styles.listContentSpan, styles.manageDes)}
                  onClick={(e) => {
                      setConfirmInfo({
                          showStatus: true,
                          title: "",
                          title1: '是否确定删除该账号？',
                          content: "",
                          eventId: 3
                      })
                  }}
            >删除</span>
        </td>
        <CustomConfirmAlert
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        {contextHolder}
    </tr>

}
// export default PaymentInfoList;

