
import React,{useState,useEffect} from "react";
import {useSelector,useDispatch} from "react-redux"
import styles from "./BottomControl.module.css";
import classNames from "classnames"
import { connect } from 'react-redux'
// import closeIcon from "./close1.png"
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft,faChevronRight,faAngleDown} from '@fortawesome/free-solid-svg-icons';
import { selectContactName, selectCurrentPage, selectGroupCount, selectPageNumber, selectRegionName, selectSchoolIndex, selectSchoolList, selectStartPage, selectTotalPageNum, setCurrentPage, setInfoList, setPageNumber, setStartPage, setTotalPageNum } from "./ZXInfoManageSlice";
import { getInfoListRequest } from "./ZXInfoManageApi";
import {message} from 'antd'
// class BottomControl extends React.Component {
export default function BottomControl(props) {
    // const [groupCount, setGroupCount] = useState(5);
    const schoolList = useSelector(selectSchoolList);
    const schoolIndex = useSelector(selectSchoolIndex);
    const groupCount = useSelector(selectGroupCount);
    const startPage = useSelector(selectStartPage);
    const pageNumber = useSelector(selectPageNumber);
    const currentPage = useSelector(selectCurrentPage);
    const totalPageNum = useSelector(selectTotalPageNum);
    const regionName = useSelector(selectRegionName);
    // const schoolName = useSelector(selectSchoolName);
    const contactName = useSelector(selectContactName);

    const [showPageNumerStatus, setShowPageNumerStatus] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();



    const dispatch = useDispatch();

    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    
    
    // 点击页码
    function pageClick(index) {
        index = parseInt(index)
        // const { groupCount } = this.state;
        // const getCurrentPage = this.props.pageCallbackFn;

        //当 当前页码 大于 分组的页码 时，使 当前页 前面 显示 两个页码
        let startPage = index >= groupCount ? index - 2: 1
        dispatch(setStartPage(startPage))
        dispatch(setCurrentPage(index))
        // dispatch(changeCurrentPage(index))
        //将当前页码返回父组件
        //this.props.getCurrentPage(currentPage)
        //add
        //调用上级请求列表
        // this.props.pageClick(currentPage)
        getInfoList(index,pageNumber)
    }
    function getInfoList(tempCurrentPage,tempPageNumber) {
        console.log('asdfffff')
        getInfoListRequest(regionName,contactName, (tempCurrentPage-1)*tempPageNumber,tempPageNumber,(data)=>{
            if (data.ret==1) {
                dispatch(setInfoList(data.data.zhuxiao_list))
                var pageNumber1 = Math.ceil(data.data.total_num/tempPageNumber)
                dispatch(setTotalPageNum(pageNumber1))
            }else if(data.ret==99){

                messageApi.open({
                    type: 'warning',
                    content:data.msg
                });
                setTimeout((e)=>{
                    sessionStorage.clear()
                    window.location.reload()
                },1000)
            } else {
                dispatch(setInfoList([]))
                dispatch(setTotalPageNum(1))
            }
        })
    }
    //上一页事件
    function prePageHandeler() {
        // let { currentPage } = this.state
        if(currentPage>1){
            // pageClick(--currentPage)
            var index = currentPage - 1;
            pageClick(index)
        }
        
    }
    //下一页事件
    function nextPageHandeler() {
        // let { currentPage,totalPage } = this.state
        if(currentPage<totalPageNum){
            var index = currentPage + 1;
            pageClick(index)
        }
    }
    console.log('------==============>>>>>>>>')
    
    let pages = []
    // 如果当前面不是第一页 则添加上一页
    if(currentPage!==1){
        pages.push('last')
    }
    else{
        pages.push('noLast')
    }
    /*总页码小于等于10时，全部显示出来*/
    if (totalPageNum <= 10) {
        for (let i = 1; i <= totalPageNum; i++) {
            pages.push(`${i}`)
        }
    } else {/*总页码大于10时，部分显示*/
        //第一页
        pages.push("1")

        //前面省略号(当当前页码比分组的页码大时显示省略号)
        if (currentPage >= groupCount) {
            pages.push("...")
        }
        //非第一页和最后一页显示
        
        for (let i = currentPage-2; i < currentPage+3; i++) {
            if (i <= totalPageNum - 1 && i > 1) {
                pages.push(`${i}`)
            }
        }
        //后面省略号
        if (totalPageNum - startPage >= groupCount + 1) {
            pages.push('...')
        }
        //最后一页
        pages.push(`${totalPageNum}`)
    }
    //如果当前面不是最后一页 则添加下一页
    if(currentPage!==totalPageNum){
        pages.push("next")
    }
    else{
        pages.push('noNext')
    }

    return (
        <div className={styles.background}>
            {contextHolder}
            {pages.map((item,index)=>(
                (index==0?
                <div className={pages[0]!="last"?styles.smallButton1:styles.smallButton}
                    onClick={prePageHandeler}
                    key={index}
                >
                    <FontAwesomeIcon
                     className={styles.topBarRight}
                     icon={faChevronLeft}/>
            </div>
            :index<pages.length-1?
                <div className={classNames(item=="..."||item==currentPage?styles.smallButton1:styles.smallButton,item==currentPage?styles.smallButtonBlue:null) }
                    onClick={item=="..."?null:pageClick.bind(null,item)}
                    key={index}
                >
                    <span>{item}</span>
                </div>
            :
            <div className={pages[pages.length-1]!="next"?styles.smallButton1:styles.smallButton}
                onClick={nextPageHandeler}
                key={index}
            >
                <FontAwesomeIcon icon={faChevronRight}/>
            </div>
            )  
            ))}
            
            <div className={styles.bigButton} 
                onClick={() => {
                    setShowPageNumerStatus(!showPageNumerStatus)
                }}
            >
                <span>{ pageNumber}条/页</span>
                <FontAwesomeIcon icon={faAngleDown} className={styles.pageButtonIcon}/>
                { showPageNumerStatus?
                <div className={styles.pageNumberContainer}>
                    { props.pageNumberList.map((item,index)=>(
                        <div className={styles.pageNumberItem} 
                        onClick={() =>{
                            dispatch(setPageNumber(item))
                            dispatch(setCurrentPage(1))
                            getInfoList(1,item)
                            // dispatch(changePageNumber(item))
                        }}
                        key={"pageNumberItem"+index}
                        >
                            {item}条/页
                        </div>
                    ))}
                </div>
                :null}
            </div>
        </div>
    )
}

