import React, {useRef, useState, useEffect} from "react";
import styles from "./InfoItem.module.css";
import classNames from "classnames"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import {
    selectInfoList,
    setInfoList,
    setQRContentStr,
    setQRDownloadStatus,
    setReviewInfo,
    setShowReviewPage
} from "./QRApprovalSlice";
import {useDispatch, useSelector} from "react-redux";
import {API_URL} from "../../../util/constants";
import {getSchoolShoufeixiangBypici, shenheCourseConfig} from "./QRApprovalApi";
import {setAlertInfo} from "../../../store/CustomViewSlice";
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {setQRSchoolName} from "../../Zhuxiaoyunying/QueryAndGetQR/QueryAndGetQRSlice";
import {message} from 'antd'
// const PaymentInfoList = props =>(
export default function InfoItem(props) {
    const dispatch = useDispatch();
    const infoList = useSelector(selectInfoList);
    const [confirmInfo, setConfirmInfo] = useState({})
    const [messageApi, contextHolder] = message.useMessage();

    function acceptMethod() {
        setConfirmInfo({
            showStatus: true,
            title: "",
            title1: `是否通过${props.item.pici_name}的申请？`,
            content: "",
            eventId: 1
        })
    }

    function passMethod() {
        setConfirmInfo({
            showStatus: true,
            title: "",
            title1: `是否拒绝${props.item.pici_name}的申请？`,
            content: "",
            eventId: 2,
            shenheStatus:2
        })
    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
            shenheCourseConfig(props.item.school_code, props.item.pici_code, "1", (data) => {
                if (data.ret == 1) {
                    let tempInfoList = []
                    infoList.forEach(element => {
                        var newElement = {}
                        for (let key in element) {
                            if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                                newElement[key] = element[key]
                            }
                        }
                        if (newElement.pici_code == props.item.pici_code) {
                            newElement.status = "1"
                        }
                        tempInfoList.push(newElement)
                    });
                    console.log(tempInfoList)
                    dispatch(setInfoList(tempInfoList))
                }else if(data.ret==99){
                    messageApi.open({
                        type: 'warning',
                        // content: data.msg,
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }
            })
        } else if (confirmInfo.eventId == 2) {
            shenheCourseConfig(props.item.school_code, props.item.pici_code, confirmInfo.shenheStatus, (data) => {
                if (data.ret == 1) {
                    // let tempInfoList = infoList.filter(element => element.pici_code != props.item.pici_code);
                    let tempInfoList = []
                    infoList.forEach(element => {
                        var newElement = {}
                        for (let key in element) {
                            if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                                newElement[key] = element[key]
                            }
                        }
                        if (newElement.pici_code == props.item.pici_code) {
                            newElement.status = confirmInfo.shenheStatus
                        }
                        tempInfoList.push(newElement)
                    });
                    console.log(tempInfoList)
                    dispatch(setInfoList(tempInfoList))
                }else if(data.ret==99){
                    messageApi.open({
                        type: 'warning',
                        // content: data.msg,
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }
            })
        }
    }
    function manageMethod(qrStatus) {
        if(qrStatus==3){
            setConfirmInfo({
                showStatus:true,
                title:"",
                title1:"是否停用该二维码？",
                content:"停用二维码后，二维码将停止收费功能",
                eventId:2,
                shenheStatus:4
            })
        }
        else  if(qrStatus==4){
            setConfirmInfo({
                showStatus:true,
                title:"",
                title1:"是否启用该二维码？",
                content:"启用二维码后，二维码将正常使用",
                eventId:2,
                shenheStatus:1
            })
        }
        else  if(qrStatus==5){
            setConfirmInfo({
                showStatus:true,
                title:"",
                title1:"是否废除该二维码？",
                content:"废除二维码后，二维码将永久失效",
                eventId:2,
                shenheStatus:5
            })
        }
    }
    var qrStatus = 5;
    return <tr
        className={styles.tr1}
    >
        <td
            className={styles.td1}
        >{props.item.school_name}
        </td>
        <td
            className={styles.td1}
        >{props.item.pici_name}
        </td>
        <td
            className={styles.td1}
        >{props.item.ctime}
        </td>
        <td
            className={styles.td1}
        >{props.item.status == 0 ? "待审核" : props.item.status == 1 ? "正常": props.item.status == 2 ? "已拒绝": props.item.status == 3 ? "已撤销" : props.item.status == 4 ? "已停用" : props.item.status == 5 ? "已废除" : ""}
        </td>
        <td
            className={styles.td1}
        >{props.item.lianxiren}
        </td>
        <td
            className={styles.td1}
        >{props.item.lianxi_phone}
        </td>
        <td
            className={styles.td2}
        >
            <span
                className={classNames(styles.listContentSpan, styles.manageDes)}
                onClick={(e) => {
                    getSchoolShoufeixiangBypici(props.item.school_code, props.item.pici_code, (data) => {
                        if (data.ret == 1) {
                            dispatch(setShowReviewPage(true))
                            dispatch(setReviewInfo({
                                tableName: props.item.school_name+"_"+props.item.pici_name,
                                list: data.data
                            }))
                        }else if(data.ret==99){
                            messageApi.open({
                                type: 'warning',
                                // content: data.msg,
                                content:data.msg
                            });
                            setTimeout((e)=>{
                                sessionStorage.clear()
                                window.location.reload()
                            },1000)
                        } else {
                            dispatch(setAlertInfo({
                                showStatus: true,
                                title: "",
                                title1: data.msg,
                                content: ""
                            }))
                        }

                    })
                }}
            >查看</span>
            {props.item.status != 2&&props.item.status != 3&&props.item.status != 4&&props.item.status != 5 ?<span
                className={classNames(styles.listContentSpan, styles.manageDes)}
                onClick={(e) => {
                    if (props.item.status == 0) {
                        dispatch(setQRDownloadStatus(false))
                    } else {
                        dispatch(setQRDownloadStatus(true))
                    }
                    dispatch(setQRContentStr(API_URL + "/shoufei/skip.html?pici_code=" + props.item.pici_code))
                    dispatch(setQRSchoolName(props.item.school_name))

                }}
            > 预览</span>:null}
            {props.item.status == 0 ?<span
                className={classNames(styles.listContentSpan, styles.manageDes)}
                onClick={acceptMethod}
            >通过</span>:null}
            {props.item.status == 0 ?<span
                className={classNames(styles.listContentSpan, styles.manageDes)}
                onClick={passMethod}
            >拒绝</span>:null}
            {props.item.status == 1 ?<span
                className={classNames(styles.listContentSpan, styles.manageDes)}
                onClick={manageMethod.bind(null,3)}
            >停用</span>:null}
            {props.item.status == 4 ?<span
                className={classNames(styles.listContentSpan, styles.manageDes)}
                onClick={manageMethod.bind(null,4)}
            >启用</span>:null}
            {props.item.status == 1 || props.item.status == 4 ?<span
                className={classNames(styles.listContentSpan, styles.manageDes)}
                onClick={manageMethod.bind(null,5)}
            >废除</span>:null}
        </td>
        <CustomConfirmAlert
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        {contextHolder}
    </tr>
}
// export default PaymentInfoList;

