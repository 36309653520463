import request from "../../../util/request";

export function getRegionListRequest(regionLevel, parentCode, callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "quyulist");
    filedata.append('quyu_jibie', regionLevel);
    filedata.append('quyu_fuji_code', parentCode);

    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata,
    }, callback);
}

export function getSchools(provinceCode, municipalityCode, countyCode, callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_school_list_byquyucode");
    filedata.append('shengji_quyu_code', provinceCode);
    filedata.append('shiji_quyu_code', municipalityCode);
    filedata.append('quxian_quyu_code', countyCode);
    return request("/Caiwuchaxun/admin", {
        method: "POST",
        body: filedata
    }, callback);
}

export function getCourseAndCycle(callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "caiwu_get_kecheng_jiaoju_list");
    return request("/Caiwuchaxun/admin", {
        method: "POST",
        body: filedata
    }, callback);
}

export function getInfoListRequest(startDate,
                                   endDate,
                                   payStatus,
                                   refundStatus,
                                   payType,
                                   provinceCode,
                                   municipalityCode,
                                   countyCode,
                                   schoolCode,
                                   xuanianCode,
                                   xueqiCode,
                                   studentName,
                                   sexCode,
                                   parentName,
                                   courseCode,
                                   jiaojuCode,
                                   nameKey,
                                   offset, limit, callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "caiwu_zonghe_chaxun_list");
    filedata.append('start_date', startDate);
    filedata.append('end_date', endDate);
    filedata.append('jiaofei', payStatus);
    filedata.append('tuifei', refundStatus);
    filedata.append('pay_type', payType);
    filedata.append('shengji_quyu_code', provinceCode);
    filedata.append('shiji_quyu_code', municipalityCode);
    filedata.append('quxian_quyu_code', countyCode);
    filedata.append('school_code', schoolCode);
    filedata.append('xuanian_code', xuanianCode);
    filedata.append('xueqi_code', xueqiCode);
    filedata.append('xuesheng_name', studentName);
    filedata.append('xingbie', sexCode);
    filedata.append('jiazhang_name', parentName);
    filedata.append('kecheng_code', courseCode);
    filedata.append('jiaoju_code', jiaojuCode);
    filedata.append('school_kecheng_namekey', nameKey);
    filedata.append('offset', offset);
    filedata.append('limit', limit);

    return request("/Caiwuchaxun/admin", {
        method: "POST",
        body: filedata,
    }, callback);
}

export function getOrderInfoListRequest(startDate,
                                        endDate,
                                        payStatus,
                                        refundStatus,
                                        payType,
                                        provinceCode,
                                        municipalityCode,
                                        countyCode,
                                        schoolCode,
                                        xuanianCode,
                                        xueqiCode,
                                        studentName,
                                        sexCode,
                                        parentName,
                                        merchantOrderNo,
                                        platformOrderNo,
                                        gradeCode,
                                        nameKey,
                                        offset, limit, callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "caiwu_dingdan_chaxun_list");
    filedata.append('start_date', startDate);
    filedata.append('end_date', endDate);
    filedata.append('jiaofei', payStatus);
    filedata.append('tuifei', refundStatus);
    filedata.append('pay_type', payType);
    filedata.append('shengji_quyu_code', provinceCode);
    filedata.append('shiji_quyu_code', municipalityCode);
    filedata.append('quxian_quyu_code', countyCode);
    filedata.append('school_code', schoolCode);
    filedata.append('xuanian_code', xuanianCode);
    filedata.append('xueqi_code', xueqiCode);
    filedata.append('xuesheng_name', studentName);
    filedata.append('xingbie', sexCode);
    filedata.append('jiazhang_name', parentName);
    filedata.append('shanghu_dingdan_code', merchantOrderNo);
    filedata.append('pingtai_dingdan_code', platformOrderNo);
    filedata.append('grade_code', gradeCode);
    filedata.append('school_kecheng_namekey', nameKey);
    filedata.append('offset', offset);
    filedata.append('limit', limit);

    return request("/Caiwuchaxun/admin", {
        method: "POST",
        body: filedata,
    }, callback);
}
