
import React ,{ useRef,useState,useEffect } from "react";
import classNames from "classnames"
import { useSelector, useDispatch } from 'react-redux';
import styles from "./OrderInfoList.module.css";
import OrderInfoItem from "./OrderInfoItem";
import ExcelUtil from "../../../util/excelUtil";
import {
    selectInfoList,
    selectPageNumber,
    selectReviewStatus,
    selectSchoolIndex,
    selectSchoolList,
    selectCurrentPage,
    selectInfoNumber,
    selectInfoAccount
} from "./QAEOrderExportSlice";
import { setAlertInfo } from "../../../store/CustomViewSlice";
import { CustomConfirmAlert } from "../../CustomView/CustomConfirmAlert";
import {message} from 'antd'
import {getMoneyDate} from "../../../util/currencyUnit";
export default function OrderInfoList(props) {
    const dispatch = useDispatch();
    const infoNumber = useSelector(selectInfoNumber)
    const infoAccount = useSelector(selectInfoAccount)
    const infoList = useSelector(selectInfoList);
    const schoolIndex = useSelector(selectSchoolIndex);
    const schoolList = useSelector(selectSchoolList);
    const pageNumber = useSelector(selectPageNumber);
    const currentPage = useSelector(selectCurrentPage)
    const [confirmInfo,setConfirmInfo]=useState({})
    const [messageApi, contextHolder] = message.useMessage();
    function cancelMethod(params) {
        setConfirmInfo({
            showStatus:false,
            title:"",
            title1:"",
            content:""
        })
    }
    function confirmMethod(params) {
        setConfirmInfo({
            showStatus:false,
            title:"",
            title1:"",
            content:""
        })
        if (confirmInfo.eventId==1) {
        } else if(confirmInfo.eventId==2) {
        }
    }
    return <div className={styles.background}>
        {contextHolder}
        <CustomConfirmAlert
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        <div className={styles.titleContainer}>
            <span className={styles.leftTitle}>记录条数：{infoNumber}&nbsp;&nbsp;总金额：{getMoneyDate("fen",infoAccount)} 元</span>
        </div>
        <div className={styles.listContainer}>
            <table className={styles.infoTable}>
                <tr
                    className={styles.tr1}
                >
                    <td
                        className={styles.xuhaoTd}
                    >序号
                    </td>
                    <td
                        className={styles.td1}
                    >交易时间
                    </td>
                    <td
                        className={styles.td1}
                    >交易类型
                    </td>
                    <td
                        className={styles.td1}
                    >支付渠道
                    </td>
                    <td
                        className={styles.td1}
                    >平台订单号
                    </td>
                    {/*<td*/}
                    {/*    className={styles.td1}*/}
                    {/*>商户订单号*/}
                    {/*</td>*/}
                    <td
                        className={styles.td1}
                    >退款订单号
                    </td>

                    <td
                        className={styles.td1}
                    >省级区域
                    </td>
                    <td
                        className={styles.td1}
                    >市级区域
                    </td>
                    <td
                        className={styles.td1}
                    >区县区域
                    </td>
                    <td
                        className={styles.td1}
                    >学校名称
                    </td>
                    {/*<td*/}
                    {/*    className={styles.td1}*/}
                    {/*>学年*/}
                    {/*</td>*/}
                    {/*<td*/}
                    {/*    className={styles.td1}*/}
                    {/*>学期*/}
                    {/*</td>*/}
                    <td
                        className={styles.td1}
                    >年级
                    </td>
                    {/*<td*/}
                    {/*    className={styles.td1}*/}
                    {/*>班级*/}
                    {/*</td>*/}
                    <td
                        className={styles.td1}
                    >学生姓名
                    </td>
                    <td
                        className={styles.td1}
                    >性别
                    </td>
                    <td
                        className={styles.td1}
                    >出生年月
                    </td>
                    <td
                        className={styles.td1}
                    >家长姓名
                    </td>
                    <td
                        className={styles.td1}
                    >交易金额
                    </td>
                </tr>
                {infoList.map((item, index) => (
                    <OrderInfoItem
                        item={item}
                        key={(currentPage - 1) * pageNumber + index + 1}
                        index={(currentPage - 1) * pageNumber + index + 1}

                    />
                ))}
            </table>

        </div>


    </div>
}

