// 提交开课申请,暂未对接接口
import React, {useRef, useState, useEffect} from "react";
import styles from "./XSSMHJDetail.module.css";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import Footer from "../../Footer/Footer";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faReply} from '@fortawesome/free-solid-svg-icons';
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {selectAlertInfo, setAlertInfo} from "../../../store/CustomViewSlice";
import {message, DatePicker, Image, Upload, Modal} from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import dayjs from "dayjs";
import 'dayjs/locale/zh-cn'
import {
    selectXSSMDetailInfo,
} from "./XSSMHJSlice";
import {API_URL} from "../../../util/constants";
import {selectDingSchoolList} from "../ZXSchool/ZXSchoolSlice";
import OSS from 'ali-oss'
import {
    addXSSMSchoolInfoRequest,
    getAliOssTokenRequest,
    getXSSMSchoolInfoRequest,
    getXSSMSchoolListRequest,
    getXueqiListRequest
} from "./XSSMHJApi";
import {getSixMathRandom} from "../../../util/handMath";
import {setXMLDMenuIndex, setXSSMMenuIndex} from "../ZXYYMain/ZXYYMainSlice";
import uploadImg from "../../../img/upload_picture.svg";

dayjs.locale('zh-cn')
const {RangePicker} = DatePicker;


let client;


export function XSSMHJDetail() {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [confirmInfo, setConfirmInfo] = useState({})
    const schoolInfo = useSelector(selectXSSMDetailInfo);
    const [huiyiImages, setHuiyiImages] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        getAliOssToken()
        setImagesMethod()
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次

    function setImagesMethod(){
        let tempList = schoolInfo.huiyi_image.split(',')
        let tempNameList = schoolInfo.huiyi_image_name.split(',')
        tempList = tempList.map((item, index) => {
            let tempDic = {
                "url":item,
                "uid":index,
                "name": tempNameList[index]
            }
            return tempDic
        })
        setHuiyiImages(tempList)
    }
    function getAliOssToken() {
        if (!client) {
            getAliOssTokenRequest((data) => {
                if ("Credentials" in data) {
                    client = new OSS({
                        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
                        region: 'oss-cn-qingdao',
                        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                        accessKeyId: data.Credentials.AccessKeyId,
                        accessKeySecret: data.Credentials.AccessKeySecret,
                        // 从STS服务获取的安全令牌（SecurityToken）。
                        stsToken: data.Credentials.SecurityToken,
                        refreshSTSToken: async () => {
                            // 向您搭建的STS服务获取临时访问凭证。
                            getAliOssTokenRequest((data) => {
                                return {
                                    accessKeyId: data.Credentials.AccessKeyId,
                                    accessKeySecret: data.Credentials.AccessKeySecret,
                                    stsToken: data.Credentials.SecurityToken,
                                }
                            })

                        },
                        // 刷新临时访问凭证的时间间隔，单位为毫秒。
                        refreshSTSTokenInterval: 3000000,
                        // 填写Bucket名称。
                        bucket: 'tgnp'
                    })
                }
            })
        }
    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
        } else if (confirmInfo.eventId == 2) {

        }

    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    function handleCancel() {
        setPreviewVisible(false);
    }

    async function handlePreview(file) {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true);
    }



    return (
        <div className={styles.background}>
            {contextHolder}
            <CustomConfirmAlert
                info={confirmInfo}
                cancelMethod={cancelMethod}
                confirmMethod={confirmMethod}
            />
            <div className={styles.topBar}>
                <div className={styles.backContainer}
                     onClick={(e)=>{
                         dispatch(setXSSMMenuIndex(0))
                     }}
                >
                    <span className={styles.backIcon}>&lt;</span>
                    <span>&nbsp;返回</span>
                </div>
            </div>
            <div className={styles.content}>
                <span className={styles.schoolName}>{schoolInfo.school_name}</span>
                <div className={styles.contentLine}>
                    <span className={styles.leftTitle}>会议日期：</span>
                    <span className={styles.rightContent1}>
                        {schoolInfo.start_time}&nbsp;~&nbsp;{schoolInfo.end_time}
                    </span>
                </div>
                <div className={styles.contentLine}>
                    <span className={styles.leftTitle}>学校参会人：</span>
                    <span className={styles.rightContent1}>
                        {schoolInfo.school_canhui}
                    </span>
                </div>
                <div className={styles.contentLine}>
                    <span className={styles.leftTitle}>研究所参会人：</span>
                    <span className={styles.rightContent1}>
                        {schoolInfo.yanjiusuo_canhui}
                    </span>
                </div>
                <div className={styles.contentLine}>
                    <span className={styles.leftTitle}>会议截图：</span>
                    <span className={styles.rightContent1}>
                        <Upload
                            listType="picture"
                            fileList={huiyiImages}
                            onPreview={handlePreview}
                            accept=".jpg,.png,.jpeg,.svg,.gif"
                            disabled={true}
                        >
                        </Upload>
                        <Modal visible={previewVisible} footer={null}
                               onCancel={handleCancel}
                               width={1200}
                        >
                                <img alt="example" style={{width: '100%'}} src={previewImage}/>
                            </Modal>
                        {/*{schoolInfo.huiyi_image.split(",").map((item, index) => {*/}
                        {/*    return <div className={styles.imgCard}>*/}
                        {/*        <Image src={item} alt="图片获取失败"></Image>*/}
                        {/*    </div>*/}
                        {/*})}*/}
                    </span>
                </div>
                <div className={styles.contentLine}>
                    <span className={styles.leftTitle}>研究所参会人：</span>
                    <span className={styles.rightContent1}>
                        {schoolInfo.jielun_name}
                    </span>
                </div>

            </div>
            <Footer/>
        </div>
    );
};
