
import React, {useRef, useState} from "react";
import styles from "./AddInfoTime.module.css";
import { useDispatch,useSelector } from "react-redux";
import classNames from "classnames"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClose,faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
import { setAlertInfo } from "../../../store/CustomViewSlice";
import { CustomConfirmAlert } from "../../CustomView/CustomConfirmAlert";
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { selectAllCourseChecked, selectAllCourseList, setAllCourseChecked, setAllCourseList, setShowAddInfoTimePage } from "./TeacherManageSlice";
export default function AddInfoTime(props) {
    const [confirmInfo,setConfirmInfo]=useState({})
    const [showWeekList,setShowWeekList]=useState(false)
    const [weekList,setWeekList]=useState([
        {name:"周一",code:"1"},
        {name:"周二",code:"2"},
        {name:"周三",code:"3"},
        {name:"周四",code:"4"},
        {name:"周五",code:"5"},
        {name:"周六",code:"6"},
        {name:"周日",code:"7"},
    ])
    const [weekIndex,setWeekIndex]=useState(0)
    
    const allCourseList = useSelector(selectAllCourseList)
    const allCourseChecked = useSelector(selectAllCourseChecked)
    const format = 'HH:mm';
    const dispatch = useDispatch();
    function isValidPhoneNumber(phoneNumber) {
        return /^1[3-9]\d{9}$/.test(phoneNumber);
    }
    function cancelMethod(params) {
        setConfirmInfo({
            showStatus:false,
            title:"",
            title1:"",
            content:""
        })
    }
    function confirmMethod(params) {
        setConfirmInfo({
            showStatus:false,
            title:"",
            title1:"",
            content:""
        })
        if (confirmInfo.eventId==1) {
            
        } else if(confirmInfo.eventId==2) {
            // closeAccount(
            //     props.item.code,
            //     props.item.login_username,
            //     (data)=>{
            //         if (data.ret==1) {
            //             refreshMethod()
            //         } else {
            //             dispatch(setAlertInfo({
            //                 showStatus:true,
            //                 title:"",
            //                 title1:'账户禁用失败，请重试',
            //                 content:""
            //             }))
            //         }
            //     }
            // )
        }
        else if(confirmInfo.eventId==3){
            
        }
    }
    function onChange(checkIndex) {
        let tempAllCouseList = []
        let checkedAll = true;
        allCourseList.map((element,index)=>{
            var newElement = {}
            for (let key in element) {
                if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                    newElement[key] = element[key]
                }
            }
            if (index==checkIndex) {
                if (element.checked) {
                    newElement.checked=false
                } else {
                    newElement.checked=true
                }
            }
            tempAllCouseList.push(newElement)
            if (newElement.checked==false) {
                checkedAll = false
            }
        })
        // infoList.forEach(element => {
        //     var newElement = {}
        //     for (let key in element) {
        //         if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
        //             newElement[key] = element[key]
        //         }
        //     }
        //     if (newElement.Id==props.item.Id) {
        //         if (e.target.checked) {
        //             newElement.checked=true
        //         } else {
        //             newElement.checked=false
        //         }
        //     }
        //     tempAllCouseList.push(newElement)
        //     if (newElement.checked==false) {
        //         checkedAll = false
        //     }
        // });
        // console.log(tempInfoList)
        dispatch(setAllCourseList(tempAllCouseList))
        dispatch(setAllCourseChecked(checkedAll))
    }
    return <div className={styles.background}>
        <CustomConfirmAlert
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        <div className={styles.contentContainer}>
            <div className={styles.whiteContainer}>
                <table 
                    align="top"
                    border="0"
                    bordercolor="#f00"
                    cellspacing="0"
                    cellspadding="0"
                    width="200"
                >
                    <tr valign="middle"
                            align="center"
                        className={styles.tr1}
                    >
                        <td 
                            width="30" height="5"
                            className={styles.td1}
                        >
                            周日期
                        </td>
                        <td 
                            width="40" height="5"
                            className={styles.td1}
                            onClick={(e)=>{
                                setShowWeekList(!showWeekList)
                            }}
                        >
                            {weekList[weekIndex].name}
                            <FontAwesomeIcon 
                                className={styles.sexIcon}
                                icon={faAngleDown}
                            />
                            {showWeekList?<div className={classNames(styles.tankangContainer,styles.weekListPosition)}>
                                {weekList.map((item,index,arr)=>{
                                    return <div className={styles.tankuangItem}
                                        key={index}
                                        onClick={(e)=>{
                                            setShowWeekList(false)
                                            setWeekIndex(index)
                                        }}
                                    >{item.name}</div>
                                })}
                            </div>
                            :
                            null}
                        </td>
                    </tr>
                    <tr valign="middle"
                            align="center"
                        className={styles.tr1}
                    >
                        <td 
                            width="30" height="5"
                            className={styles.td1}
                        >
                            开始时间
                        </td>
                        <td 
                            width="40" height="5"
                            className={styles.td1}
                        >
                            {/* <TimePicker defaultValue={dayjs('00:00', format)} format={format} /> */}
                            <TimePicker defaultValue={dayjs('12:08', format)} format={format} />
                        </td>
                    </tr>
                    <tr valign="middle"
                            align="center"
                        className={styles.tr1}
                    >
                        <td 
                            width="30" height="5"
                            className={styles.td1}
                        >
                            结束时间
                        </td>
                        <td 
                            width="40" height="5"
                            className={styles.td1}
                        >
                            <TimePicker defaultValue={dayjs('12:08', format)} format={format} />
                        </td>
                    </tr>
                </table>
            </div>
            <div className={styles.bottomContainer}>
                <div className={classNames(styles.cancelButton,styles.button)}
                    onClick={(e)=>{
                        dispatch(setShowAddInfoTimePage(false))
                    }}
                >取消</div>
                <div className={classNames(styles.confirmButton,styles.button)}
                    onClick={(e)=>{
                        setConfirmInfo({
                            showStatus:true,
                            title:"",
                            title1:'确认提交？',
                            content:"",
                            eventId:3
                        })
                    }}
                >确定</div>  
            </div>
        </div>
    </div>
}

