// 提交开课申请,暂未对接接口
import React, {useRef, useState, useEffect} from "react";
import styles from "./ZXSchoolSetDYFX.module.css";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp, faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {message} from 'antd'
import {selectDYFXList,setDYFXList} from "./ZXSchoolSlice";

export function ZXSchoolSetDYFX(props) {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const DYFXList = useSelector(selectDYFXList)
    const [beizhu, setBeizhu] = useState('')

    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次


    return (
        <div className={styles.background}>
            {contextHolder}
            <div className={styles.content}>
                <div className={styles.topBar}>
                    <div className={styles.topBarLeft}>
                        <span className={styles.topBarTitle}>自费课情况调研分析</span>
                        <div className={styles.topBarInfo}>
                            <span className={styles.schoolName}>{props.info.xuexiao_name}</span>
                            <span className={styles.studentNumber}>总学生数{props.info.xuesheng_num}人</span>
                        </div>
                    </div>
                    <div className={styles.topBarRight}>
                    <span className={styles.closeButton}
                          onClick={props.closeMethod}
                    >关闭</span>
                        {props.showUploadButton ? <span className={styles.uploadButton}
                                                        onClick={()=>{
                                                            let editStatus=false;
                                                            DYFXList.map((item,index)=>{
                                                                for (const itemKey in item) {
                                                                    if(itemKey!="name"&&item[itemKey]!=0){
                                                                        editStatus = true;
                                                                    }
                                                                }
                                                            })
                                                            if(!editStatus){
                                                                messageApi.open({
                                                                    type: 'warning',
                                                                    content: "内容为空，无法提交"
                                                                });
                                                            }
                                                            else {
                                                                props.tijiaoMethod(DYFXList)
                                                            }
                                                        }}
                        >提交</span> : null}
                    </div>
                </div>
                <table
                    className={styles.DYFXTable}
                >
                    <tr
                        valign="middle"
                        align="center"
                        className={styles.tr1}
                    >
                        <td
                            className={classNames(styles.tdUtil, styles.td0)}
                        >
                            <div className={classNames(styles.lineTagView)}></div>
                        </td>
                        <td
                            className={classNames(styles.tdUtil, styles.td1)}
                        >
                            学生自费项目（校内课程）
                        </td>
                        <td
                            className={classNames(styles.tdUtil, styles.td2)}
                        >
                            当前已购买学生总数
                        </td>
                        <td
                            className={classNames(styles.tdUtil, styles.td3)}
                        >
                            人均费用/学期
                        </td>
                        <td
                            className={classNames(styles.tdUtil, styles.td4)}
                        >
                            平均课时数/学期
                        </td>
                        <td
                            className={classNames(styles.tdUtil, styles.td5)}
                        >
                            单学期付费规模（购买学生数×人均费用）
                        </td>
                    </tr>
                    {DYFXList.map((item, index) => {
                        return <tr
                            valign="middle"
                            align="center"
                            className={styles.tr2}
                        >
                            <td
                                className={classNames(styles.tdUtil, styles.td0)}
                            >
                                {index + 1}
                            </td>
                            <td
                                className={classNames(styles.tdUtil, styles.td1)}
                            >
                                {item.name}
                            </td>
                            <td
                                className={classNames(styles.tdUtil, styles.td2)}
                            >
                                <input
                                    className={classNames(styles.inputStyle, styles.studentNumInput)}
                                    type={"number"}
                                    onChange={(e) => {
                                        if (e.target.value > parseInt(props.info.xuesheng_num)) {
                                            messageApi.open({
                                                type: 'warning',
                                                content: "当前购买学生总数不能超过该校总人数"
                                            });
                                            return
                                        }
                                        var tempList = DYFXList.map((item1, index1) => {
                                            if (index === index1) {
                                                let tempDic = Object.assign(JSON.parse(JSON.stringify(item1)), {"number": e.target.value ? e.target.value : 0})
                                                return tempDic
                                            }
                                            else {
                                                return item1
                                            }

                                        })
                                        dispatch(setDYFXList(tempList))
                                    }}
                                    value={item.number === 0 ? "" : item.number}
                                />&nbsp;&nbsp;人&nbsp;&nbsp;{(parseFloat(item.number) / props.info.xuesheng_num * 100).toFixed(2)}%

                            </td>
                            <td
                                className={classNames(styles.tdUtil, styles.td3)}
                            >
                                <input
                                    className={classNames(styles.inputStyle, styles.feiyongInput)}
                                    type={"number"}
                                    onChange={(e) => {
                                        var tempList = DYFXList.map((item1, index1) => {
                                            if (index === index1) {
                                                let tempDic = Object.assign(JSON.parse(JSON.stringify(item1)), {"feiyong": e.target.value ? e.target.value : 0})
                                                return tempDic
                                            }
                                            else {
                                                return item1
                                            }

                                        })
                                        dispatch(setDYFXList(tempList))
                                    }}
                                    value={item.feiyong === 0 ? "" : item.feiyong}
                                />&nbsp;&nbsp;元
                            </td>
                            <td
                                className={classNames(styles.tdUtil, styles.td4)}
                            >
                                <input
                                    className={classNames(styles.inputStyle, styles.PJKeshiInput)}
                                    type={"number"}
                                    onChange={(e) => {
                                        var tempList = DYFXList.map((item1, index1) => {
                                            if (index === index1) {
                                                let tempDic = Object.assign(JSON.parse(JSON.stringify(item1)), {"PJKeshi": e.target.value ? e.target.value : 0})
                                                return tempDic
                                            }
                                            else {
                                                return item1
                                            }

                                        })
                                        dispatch(setDYFXList(tempList))
                                    }}
                                    value={item.PJKeshi === 0 ? "" : item.PJKeshi}
                                />&nbsp;&nbsp;课时
                            </td>
                            <td
                                className={classNames(styles.tdUtil, styles.td5)}
                            >
                                <input
                                    className={classNames(styles.inputStyle, styles.dxqffgmInput)}
                                    type={"number"}
                                    onChange={(e) => {
                                        var tempList = DYFXList.map((item1, index1) => {
                                            if (index === index1) {
                                                let tempDic = Object.assign(JSON.parse(JSON.stringify(item1)), {"dxqffgm": e.target.value ? e.target.value : 0})
                                                return tempDic
                                            }
                                            else {
                                                return item1
                                            }

                                        })
                                        dispatch(setDYFXList(tempList))
                                    }}
                                    value={item.dxqffgm === 0 ? "" : item.dxqffgm}
                                />&nbsp;&nbsp;元
                            </td>
                        </tr>
                    })}
                    <tr
                        valign="middle"
                        align="center"
                        className={styles.tr1}
                    >
                        <td
                            className={classNames(styles.tdUtil, styles.td0)}
                        >
                            <div className={classNames(styles.lineTagView)}></div>
                        </td>
                        <td
                            className={classNames(styles.tdUtil, styles.td1)}
                        >
                            调研到的其他参考信息
                        </td>

                    </tr>
                    <tr
                        valign="middle"
                        align="center"
                        className={styles.tr3}
                    >
                        <td className={classNames(styles.tdUtil, styles.td0)}></td>
                        <td
                            className={styles.textareaTd}
                            rowSpan={5}
                            colSpan={5}
                        >
                            <textarea
                                className={styles.textarea}
                                value={beizhu}
                                onChange={(e) => {
                                    setBeizhu(e.target.value)
                                }}
                                placeholder='请描述~'
                            ></textarea>
                        </td>
                    </tr>

                </table>

            </div>
        </div>
    );
};
