import request from "../../util/request";

// export function loginRequest(username,pwd, callback) {

//     return request("/Caiwuadmin/admin", {
//         method: "POST",
//         body: {
//             appkey: sessionStorage.getItem("utoken"),
//             username,
//             pwd,
//             web_code:1
//         }
//     }, callback);
// }
//老的登录接口
// export function loginRequest(username,pwd, callback) {
//     let filedata = new FormData();
//     filedata.append('appkey', sessionStorage.getItem("utoken"));
//     filedata.append('caozuo', "caiwu_login");
//     filedata.append('username', username);
//     filedata.append('pwd', pwd);
//     filedata.append('web_code', "1");
//     return request("/Caiwuadmin/admin", {
//         method: "POST",
//         body: filedata
//     }, callback);
// }

export function loginRequest(username,pwd,token, callback) {
    let filedata = new FormData();
    filedata.append('appkey', token);
    filedata.append('caozuo', "caiwu_login");
    filedata.append('username', username);
    filedata.append('pwd', pwd);
    // filedata.append('token', token);
    filedata.append('web_code', "1");
    return request("/index.php/Login/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function loginOutRequest( callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('username_login', sessionStorage.getItem("username_login"));
    // filedata.append('token', token);
    filedata.append('web_code', "1");
    return request("/index.php/Loginout/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function autoLoginRequest(token, callback) {
    let filedata = new FormData();
    filedata.append('appkey', token);
    filedata.append('caozuo', "getuserbytoken");
    // filedata.append('token', token);
    return request("/index.php/Yunyingd/i", {
        method: "POST",
        body: filedata
    }, callback);
}