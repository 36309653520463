import React ,{ useRef,useState,useEffect }from "react";
import { useSelector, useDispatch } from 'react-redux';
import styles from "./ConditionalQuery.module.css";
import classNames from "classnames"
// import closeIcon from "./close1.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faReply,faSearch,faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {getInfoListRequest} from './TeacherManageApi'
import { selectCompanyName, selectContactName, selectPageNumber, selectTeacherName, selectSchoolList, setCompanyName, setContactName, setCurrentPage, setInfoList, setTeacherName, setTotalPageNum, selectTeacherCategoryIndex, selectTeacherCategoryList, selectCourseTypeIndex, selectCourseTypeList, setTeacherCategoryIndex } from "./TeacherManageSlice";
export default function ConditionalQuery(props) {
    const dispatch = useDispatch();
    const pageNumber = useSelector(selectPageNumber);
    const teacherName = useSelector(selectTeacherName);
    const teacherCategoryIndex = useSelector(selectTeacherCategoryIndex);
    const teacherCategoryList = useSelector(selectTeacherCategoryList);
    const [showTeacherCategoryList, setShowTeacherCategoryList] = useState(false);
    const courseTypeIndex = useSelector(selectCourseTypeIndex);
    const courseTypeList = useSelector(selectCourseTypeList);
    const [showCourseTypeList, setShowCourseTypeList] = useState(false);
    




    const companyName = useSelector(selectCompanyName);
    const contactName = useSelector(selectContactName);
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        dispatch(setInfoList([]))
        getInfoList("","","")
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    function getInfoList(region,company,contanct) {
        getInfoListRequest(region,company,contanct,0,pageNumber,(data)=>{
            if (data.ret==1) {
                dispatch(setInfoList(data.data.shengji_quyu_list))
                var pageNumber1 = Math.ceil(data.data.total_num/pageNumber)
                dispatch(setTotalPageNum(pageNumber1))
                dispatch(setCurrentPage(1))
            } else {
                dispatch(setInfoList([]))
                dispatch(setTotalPageNum(1))
                dispatch(setCurrentPage(1))
            }
        })
    }
    function tapTeacherCategoryListItem(index) {
        if (index!=0) {
            dispatch(setTeacherCategoryIndex(index))
            // getInfoList(index,reviewIndex)
        } else {
            dispatch(setTeacherCategoryIndex(index))
            dispatch(setInfoList([]))
        }
    }
    function tapCourseListItem(index) {
        if (index!=0) {
            dispatch(setTeacherCategoryIndex(index))
            // getInfoList(index,reviewIndex)
        } else {
            dispatch(setTeacherCategoryIndex(index))
            dispatch(setInfoList([]))
        }
    }
    function searchMethod() {
        getInfoList(teacherName,companyName,contactName)
    }
    function resetMethod() {
        dispatch(setTeacherName(""))
        dispatch(setCompanyName(""))
        dispatch(setContactName(""))
        getInfoList("","","")
    }
    return <div className={styles.background}>
        {showTeacherCategoryList||showCourseTypeList?<div className={styles.hideListCover}
            onClick={(e)=>{
                setShowTeacherCategoryList(false)
                setShowCourseTypeList(false)
            }}
        ></div>:null}
        <div className={styles.titleContainer}>
            <span className={styles.leftTitle}>数据筛选</span>
            {/* <span className={styles.rightTitle}>高级搜索</span> */}
        </div>
        <div className={styles.conditionalContainer}>
            <span className={styles.conditionalTitle}>教师姓名：</span>
            <input
                placeholder=""
                className={styles.conditionInput}
                value={teacherName}
                onChange={(e)=>{
                    dispatch(setTeacherName(e.target.value))
                }}
            />
            <span className={styles.conditionalTitle1}>教师类别：</span>
            <div className={styles.topBarSchoolsContainer} onClick={()=>setShowTeacherCategoryList(!showTeacherCategoryList)}>
                <div className={styles.topBarSchoolsName}>{teacherCategoryList[teacherCategoryIndex].name}</div>
                <FontAwesomeIcon icon={faAngleDown} className={styles.topBarSchoolsArrow}/>
                {showTeacherCategoryList?<div className={classNames(styles.tankangContainer,styles.schoolListPosition)}>
                    {teacherCategoryList.map((item,index,arr)=>{
                        return <div className={styles.tankuangItem}
                            key={index}
                            onClick={tapTeacherCategoryListItem.bind(null,index)}
                        >{item.name}</div>
                    })}
                </div>:null}
                
            </div>
            <span className={styles.conditionalTitle2}>教授课程：</span>
            <div className={styles.topBarSchoolsContainer} onClick={()=>setShowCourseTypeList(!showCourseTypeList)}>
                <div className={styles.topBarSchoolsName}>{courseTypeList[courseTypeIndex].name}</div>
                <FontAwesomeIcon icon={faAngleDown} className={styles.topBarSchoolsArrow}/>
                {showCourseTypeList?<div className={classNames(styles.tankangContainer,styles.schoolListPosition)}>
                    {courseTypeList.map((item,index,arr)=>{
                        return <div className={styles.tankuangItem}
                            key={index}
                            onClick={tapCourseListItem.bind(null,index)}
                        >{item.name}</div>
                    })}
                </div>:null}
                
            </div>
            
            <div className={styles.selectButton}
                onClick={searchMethod}
            >
                <FontAwesomeIcon icon={faSearch} className={styles.selectButtonIcon}/>
                查询
            </div>
            <div className={styles.resetButton}
                onClick={resetMethod}
            >
                <FontAwesomeIcon icon={faReply} className={styles.selectButtonIcon}/>
                重置
            </div>
        </div>
       
    </div>
}