// import { faL } from '@fortawesome/free-solid-svg-icons';
import {faL} from '@fortawesome/free-solid-svg-icons';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
    customAlert: false,
    login:sessionStorage.getItem('username')==null?true:false,
    // login: false,
    ZXYYMain: sessionStorage.getItem('username') == null ? false : sessionStorage.getItem('user_type') == 4 ? true : false,//住校运营主页面
    CWZGMain: sessionStorage.getItem('username') == null ? false : sessionStorage.getItem('user_type') == 3 ? true : false,//财务主管界面
    ZBZGMain: sessionStorage.getItem('username') == null ? false : sessionStorage.getItem('user_type') == 99 ? true : false,//总部主管界面
    YYZJMain: false,//运营总监
    CPZJLMain: false,//产品总经理
    QGZMain: false,//区管总
    QJSJYYMain:sessionStorage.getItem('username') == null ? false : sessionStorage.getItem('user_type') == 7? true : false,//全局数据运营
};

export const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        openLogin: (state) => {
            state.login = true
        },
        closeLogin: (state) => {
            state.login = false
        },
        openZXYYMain: (state) => {
            state.ZXYYMain = true
        },
        closeZXYYMain: (state) => {
            state.ZXYYMain = false
        },
        openCWZGMain: (state) => {
            state.CWZGMain = true
        },
        closeCWZGMain: (state) => {
            state.CWZGMain = false
        },
        openZBZGMain: (state) => {
            state.ZBZGMain = true
        },
        closeZBZGMain: (state) => {
            state.ZBZGMain = false
        },
        openYYZJMain: (state) => {
            state.YYZJMain = true
        },
        closeYYZJMain: (state) => {
            state.YYZJMain = false
        },
        openCPZJLMain: (state) => {
            state.CPZJLMain = true
        },
        closeCPZJLMain: (state) => {
            state.CPZJLMain = false
        },
        openQGZMain: (state) => {
            state.QGZMain = true
        },
        closeQGZMain: (state) => {
            state.QGZMain = false
        },
        openQJSJYYMain: (state) => {
            state.QJSJYYMain = true
        },
        closeQJSJYYMain:(state) => {
            state.QJSJYYMain = false
        },

    },
});

export const selectLogin = (state) => state.modals.login;
export const selectZXYYMain = (state) => state.modals.ZXYYMain;
export const selectCWZGMain = (state) => state.modals.CWZGMain;
export const selectZBZGMain = (state) => state.modals.ZBZGMain;
export const selectYYZJMain = (state) => state.modals.YYZJMain;
export const selectCPZJLMain = (state) => state.modals.CPZJLMain;
export const selectQGZMain = (state) => state.modals.QGZMain;
export const selectQJSJYYMain = (state) => state.modals.QJSJYYMain;

export const {
    openLogin,
    closeLogin,
    openZXYYMain,
    closeZXYYMain,
    openCWZGMain,
    closeCWZGMain,
    openZBZGMain,
    closeZBZGMain,
    openYYZJMain,
    closeYYZJMain,
    openCPZJLMain,
    closeCPZJLMain,
    openQGZMain,
    closeQGZMain,
    openQJSJYYMain,
    closeQJSJYYMain
} = modalsSlice.actions;

export default modalsSlice.reducer;
