import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
  menuIndex:0,
  schoolList:[]
};

export const ZBZGMainSlice = createSlice({
  name: 'ZBZGMain',
  initialState,
  reducers: {
    setMenuIndex: (state,action) => {
        state.menuIndex = action.payload;
    },
    setSchoolList: (state,action) => {
      state.menuIndex = action.payload;
    }
  },
});

export const selectMenuIndex = (state) => state.ZBZGMain.menuIndex;


export const { 
  setMenuIndex,
  setSchoolList
} = ZBZGMainSlice.actions;

export default ZBZGMainSlice.reducer;
