import { configureStore } from '@reduxjs/toolkit';
import modalsReducer from './modalsSlice'
import ZXYYMainReducer from '../features/Zhuxiaoyunying/ZXYYMain/ZXYYMainSlice';//住校运营界面
import QueryAndGetQRReducer from '../features/Zhuxiaoyunying/QueryAndGetQR/QueryAndGetQRSlice';//住校运营收费二维码查询下载
import ZXYYSubmitCourseReducer  from '../features/Zhuxiaoyunying/SubmitCourse/SubmitCourseSlice';
import ZXYYPaymentQueryReducer from "../features/Zhuxiaoyunying/PaymentQuery/PaymentQuerySlice";
import ZXYYZXSchoolReducer from "../features/Zhuxiaoyunying/ZXSchool/ZXSchoolSlice";
import ZXYYXSSMHJReducer from "../features/Zhuxiaoyunying/XSSMHJ/XSSMHJSlice";
import ZXYYSBPSHJReducer from "../features/Zhuxiaoyunying/SBPSHJ/SBPSHJSlice"
import ZXYYZSYBHReducer from "../features/Zhuxiaoyunying/ZSYBH/ZSYBHSlice";
import ZXYYZSSMHReducer from "../features/Zhuxiaoyunying/ZSSMH/ZSSMHSlice";

import CWZGMainReducer from '../features/caiwuzhuguan/CWZGMain/CWZGMainSlice'//财务主管主页面
import CWZGQRApprovalReducer from '../features/caiwuzhuguan/QRApproval/QRApprovalSlice'
import CWZGPaymentQueryReducer from '../features/caiwuzhuguan/PaymentQuery/PaymentQuerySlice'
import CWZGQAECourseExportReducer from '../features/caiwuzhuguan/QueryAndExport/QAECourseExportSlice'
import CWZGQAEOrderExportReducer from '../features/caiwuzhuguan/QueryAndExport/QAEOrderExportSlice'

import ZBZGMainReducer from '../features/ZBZhuGuan/ZBZGMain/ZBZGMainSlice';
import ZBZGProvinceInfoManageReducer from '../features/ZBZhuGuan/ProvinceInfoManage/ProvinceInfoManageSlice';
import ZBZGCountyInfoManageReducer from '../features/ZBZhuGuan/CountyInfoManage/CountyInfoManageSlice';
import ZBZGZXInfoManageReducer  from '../features/ZBZhuGuan/ZXInfoManage/ZXInfoManageSlice';
import ZBZGXXInfoManageReducer from '../features/ZBZhuGuan/XXInfoManage/XXInfoManageSlice';

// 运营总监
import YYZJMainReducer from '../features/YunYingZongJian/YYZJMain/YYZJMainSlice';
import YYZJTeacherManageReducer  from '../features/YunYingZongJian/TeacherManage/TeacherManageSlice';
import YYZJCourseApprovalReducer from "../features/YunYingZongJian/CourseApproval/CourseApprovalSlice";
import YYZJSKJHMangeReducer from "../features/YunYingZongJian/SKJHMange/SKJHMangeSlice";

// 产品总监界面
import CPZJLMainReducer from '../features/CPZJL/CPZJLMain/CPZJLMainSlice';
import CPZJLCourseApprovalReducer from "../features/CPZJL/CourseApproval/CourseApprovalSlice";


// 区管总相关界面
import QGZMainReducer from '../features/QGZ/QGZMain/QGZMainSlice';
import QGZCourseApprovalReducer from "../features/QGZ/CourseApproval/CourseApprovalSlice";

//全局数据运营
import QJSJYYMainReducer from "../features/QJSJYunYing/QJSJYYMain/QJSJYYMainSlice";
import QJSJYYQAECourseExportReducer from "../features/QJSJYunYing/QueryAndExport/QAECourseExportSlice";
import QJSJYYQAEOrderExportReducer from "../features/QJSJYunYing/QueryAndExport/QAEOrderExportSlice";



import CustomViewReducer from './CustomViewSlice';

import loginReducer from "../features/Login/LoginSlice";
export const store = configureStore({
  reducer: {
    modals:modalsReducer,
    login:loginReducer,
    // coursePaymentQuery:coursePaymentQueryReducer,
    // 住校运营
    ZXYYMain:ZXYYMainReducer,
    ZXYYQueryAndGetQR:QueryAndGetQRReducer,
    ZXYYSubmitCourse:ZXYYSubmitCourseReducer,
    ZXYYPaymentQuery:ZXYYPaymentQueryReducer,
    ZXYYZXSchool:ZXYYZXSchoolReducer,
    ZXYYXSSMHJ:ZXYYXSSMHJReducer,
    ZXYYSBPSHJ:ZXYYSBPSHJReducer,
    ZXYYZSYBH:ZXYYZSYBHReducer,
    ZXYYZSSMH:ZXYYZSSMHReducer,

    //财务主管
    CWZGMain:CWZGMainReducer,
    CWZGQRApproval:CWZGQRApprovalReducer,
    CWZGPaymentQuery:CWZGPaymentQueryReducer,
    CWZGQAECourseExport:CWZGQAECourseExportReducer,
    CWZGQAEOrderExport:CWZGQAEOrderExportReducer,

    //总部主管
    ZBZGMain :ZBZGMainReducer,
    ZBZGZXInfoManage:ZBZGZXInfoManageReducer,
    ZBZGXXInfoManage:ZBZGXXInfoManageReducer,
    ZBZGProvinceInfoManage:ZBZGProvinceInfoManageReducer,
    ZBZGCountyInfoManage:ZBZGCountyInfoManageReducer,

    //运营总监
    YYZJMain:YYZJMainReducer,
    YYZJTeacherManage:YYZJTeacherManageReducer,
    YYZJCourseApproval:YYZJCourseApprovalReducer,
    YYZJSKJHMange:YYZJSKJHMangeReducer,

    //产品总经理
    CPZJLMain:CPZJLMainReducer,
    CPZJLCourseApproval:CPZJLCourseApprovalReducer,

    //区管总
    QGZMain:QGZMainReducer,
    QGZCourseApproval:QGZCourseApprovalReducer,

    //全局数据运营
    QJSJYYMain:QJSJYYMainReducer,
    QJSJYYQAEOrderExport:QJSJYYQAEOrderExportReducer,
    QJSJYYQAECourseExport:QJSJYYQAECourseExportReducer,

    CustomView:CustomViewReducer

  },
});
