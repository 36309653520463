import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
    rangeTime:["",""],
    payStatus:false,
    refundStatus:false,
    payTypeIndex:0,
    payTypeList:[{
        "name":"全部",
        "code":""
    },{
        "name":"微信",
        "code":"1"
    },{
        "name":"支付宝",
        "code":"2"
    },{
        "name":"第三方",
        "code":"3"
    }],
    provinceIndex:0,
    provinceList: [{
        quyu_name:"全部",
        quyu_code:""
    }],
    municipalityIndex:0,
    municipalityList:[{
        quyu_name:"全部",
        quyu_code:""
    }],
    countyIndex:0,
    countyList:[{
        quyu_name:"全部",
        quyu_code:""
    }],
    schoolIndex: 0,
    schoolList: [{
        "school_name":"全部",
        "school_code":""
    }],
    xueNianIndex:0,
    xueNianList:[{
        "name":"2024-2025",
        "code":"2024-2025"
    },{
        "name":"2025-2026",
        "code":"2025-2026"
    },{
        "name":"2026-2027",
        "code":"2026-2027"
    },{
        "name":"2027-2028",
        "code":"2027-2028"
    },],
    xueQiIndex:0,
    xueQiList:[{
        "name":"全部",
        "code":3    //接口需要3，所以由0改为3
    },{
        "name":"9-3秋季学期",
        "code":1
    },{
        "name":"3-9春季学期",
        "code":2
    }],
    gradeIndex:0,
    gradeList:[{
        "name":"全部年级",
        "code":""
    }],
    parentName:"",
    studentName:"",
    sexIndex:0,
    sexList:[{
        "name":"全部",
        "code":""
    },{
        "name":"男",
        "code":"1"
    },{
        "name":"女",
        "code":"0"
    }],
    courseIndex:0,
    courseList:[{
        "name":"全部",
        "code":""
    }],
    kejiIndex:0,
    kejiList:[{
        "name":"全部",
        "code":""
    }],
    jiaojuIndex:0,
    jiaojuList:[{
        "name":"全部",
        "code":""
    }],
    schoolOrCourseNameKey:"",
    infoNumber:0,
    infoAccount:0,
    infoList: [],
    //分页所需参数
    groupCount: 5,
    startPage: 1,
    pageNumber: 10,
    currentPage: 1,
    totalPageNum: 1,
    pages: [],
};

export const CWZGQAECourseExportSlice = createSlice({
    name: 'CWZGQAECourseExport',
    initialState,
    reducers: {
        setRangeTime:(state, action) => {
            state.rangeTime = action.payload;
        },
        setPayStatus:(state, action) => {
            state.payStatus = action.payload;
        },
        setRefundStatus:(state, action) => {
            state.refundStatus = action.payload;
        },
        setPayTypeIndex:(state, action) => {
            state.payTypeIndex = action.payload;
        },
        setPayTypeList: (state, action) => {
            state.payTypeList = action.payload;
        },
        setProvinceIndex:(state, action) => {
            state.provinceIndex = action.payload;
        },
        setProvinceList: (state, action) => {
            state.provinceList = action.payload;
        },
        setMunicipalityIndex:(state, action) => {
            state.municipalityIndex = action.payload;
        },
        setMunicipalityList: (state, action) => {
            state.municipalityList = action.payload;
        },
        setCountyIndex:(state, action) => {
            state.countyIndex = action.payload;
        },
        setCountyList:(state, action) => {
            state.countyList = action.payload;
        },
        setSchoolIndex: (state, action) => {
            state.schoolIndex = action.payload;
        },
        setSchoolList: (state, action) => {
            state.schoolList = action.payload;
        },
        setXueNianIndex:(state, action) => {
            state.xueNianIndex = action.payload;
        },
        setXueNianList:(state, action) => {
            state.xueNianList = action.payload;
        },
        setXueQiIndex:(state, action) => {
            state.xueQiIndex = action.payload;
        },
        setXueQiList:(state, action) => {
            state.xueQiList = action.payload;
        },
        setGradeIndex:(state, action) => {
            state.gradeIndex = action.payload;
        },
        setGradeList:(state, action) => {
            state.gradeList = action.payload;
        },
        setParentName:(state, action) => {
            state.parentName = action.payload;
        },
        setStudentName:(state, action) => {
            state.studentName = action.payload;
        },
        setSexIndex:(state, action) => {
            state.sexIndex = action.payload;
        },
        setSexList:(state, action) => {
            state.sexList = action.payload;
        },
        setCourseIndex:(state, action) => {
            state.courseIndex = action.payload;
        },
        setCourseList:(state, action) => {
            state.courseList = action.payload;
        },
        setKejiIndex:(state, action) => {
            state.kejiIndex = action.payload;
        },
        setKejiList:(state, action) => {
            state.kejiList = action.payload;
        },
        setJiaojuIndex:(state, action) => {
            state.jiaojuIndex = action.payload;
        },
        setJiaojuList:(state, action) => {
            state.jiaojuList = action.payload;
        },
        setSchoolOrCourseNameKey:(state, action) => {
            state.schoolOrCourseNameKey = action.payload;
        },
        setInfoNumber: (state, action) => {
            state.infoNumber = action.payload;
        },
        setInfoAccount: (state, action) => {
            state.infoAccount = action.payload;
        },
        setInfoList: (state, action) => {
            state.infoList = action.payload;
        },


        setGroupCount: (state, action) => {
            state.groupCount = action.payload;
        },
        setStartPage: (state, action) => {
            state.startPage = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setTotalPageNum: (state, action) => {
            state.totalPageNum = action.payload;
        },



    },
});
export const selectRangeTime = (state) => state.CWZGQAECourseExport.rangeTime;
export const selectPayStatus = (state) => state.CWZGQAECourseExport.payStatus;
export const selectRefundStatus = (state) => state.CWZGQAECourseExport.refundStatus;
export const selectPayTypeIndex = (state) => state.CWZGQAECourseExport.payTypeIndex;
export const selectPayTypeList = (state) => state.CWZGQAECourseExport.payTypeList;
export const selectProvinceIndex = (state) => state.CWZGQAECourseExport.provinceIndex;
export const selectProvinceList = (state) => state.CWZGQAECourseExport.provinceList;
export const selectMunicipalityIndex = (state) => state.CWZGQAECourseExport.municipalityIndex;
export const selectMunicipalityList = (state) => state.CWZGQAECourseExport.municipalityList;
export const selectCountyIndex = (state) => state.CWZGQAECourseExport.countyIndex;
export const selectCountyList = (state) => state.CWZGQAECourseExport.countyList;
export const selectSchoolIndex = (state) => state.CWZGQAECourseExport.schoolIndex;
export const selectSchoolList = (state) => state.CWZGQAECourseExport.schoolList;
export const selectXueNianIndex = (state) => state.CWZGQAECourseExport.xueNianIndex;
export const selectXueNianList = (state) => state.CWZGQAECourseExport.xueNianList;
export const selectXueQiIndex = (state) => state.CWZGQAECourseExport.xueQiIndex;
export const selectXueQiList = (state) => state.CWZGQAECourseExport.xueQiList;
export const selectGradeIndex = (state) => state.CWZGQAECourseExport.gradeIndex;
export const selectGradeList = (state) => state.CWZGQAECourseExport.gradeList;
export const selectParentName = (state) => state.CWZGQAECourseExport.parentName;
export const selectStudentName = (state) => state.CWZGQAECourseExport.studentName;
export const selectSexIndex = (state) => state.CWZGQAECourseExport.sexIndex;
export const selectSexList = (state) => state.CWZGQAECourseExport.sexList;
export const selectCourseIndex = (state) => state.CWZGQAECourseExport.courseIndex;
export const selectCourseList = (state) => state.CWZGQAECourseExport.courseList;
export const selectKejiIndex = (state) => state.CWZGQAECourseExport.kejiIndex;
export const selectKejiList = (state) => state.CWZGQAECourseExport.kejiList;
export const selectJiaojuIndex = (state) => state.CWZGQAECourseExport.jiaojuIndex;
export const selectJiaojuList = (state) => state.CWZGQAECourseExport.jiaojuList;
export const selectSchoolOrCourseNameKey= (state) => state.CWZGQAECourseExport.schoolOrCourseNameKey;
export const selectInfoNumber = (state) => state.CWZGQAECourseExport.infoNumber;
export const selectInfoAccount = (state) => state.CWZGQAECourseExport.infoAccount;
export const selectInfoList = (state) => state.CWZGQAECourseExport.infoList;

//分页所需数据
export const selectGroupCount = (state) => state.CWZGQAECourseExport.groupCount;
export const selectStartPage = (state) => state.CWZGQAECourseExport.startPage;
export const selectPageNumber = (state) => state.CWZGQAECourseExport.pageNumber;
export const selectCurrentPage = (state) => state.CWZGQAECourseExport.currentPage;
export const selectTotalPageNum = (state) => state.CWZGQAECourseExport.totalPageNum;





export const {
    setRangeTime,
    setPayStatus,
    setRefundStatus,
    setPayTypeIndex,
    setPayTypeList,
    setProvinceIndex,
    setProvinceList,
    setMunicipalityIndex,
    setMunicipalityList,
    setCountyIndex,
    setCountyList,
    setSchoolIndex,
    setSchoolList,
    setXueNianIndex,
    setXueNianList,
    setXueQiIndex,
    setXueQiList,
    setGradeIndex,
    setGradeList,
    setParentName,
    setStudentName,
    setSexIndex,
    setSexList,
    setCourseIndex,
    setCourseList,
    setKejiIndex,
    setKejiList,
    setJiaojuIndex,
    setJiaojuList,
    setSchoolOrCourseNameKey,
    setInfoNumber,
    setInfoAccount,
    setInfoList,
    setGroupCount,
    setStartPage,
    setPageNumber,
    setCurrentPage,
    setTotalPageNum,
} = CWZGQAECourseExportSlice.actions;

export default CWZGQAECourseExportSlice.reducer;