import React from 'react';
import { Document, Page } from '@react-pdf/renderer';
// import 'react-to-pdf/dist/index.css';
import styles from "./PdfExport.module.css"
import classNames from "classnames"
 
// const MyComponent = (props) => (
const MyComponent = React.forwardRef((props, ref) => (
  <div className={styles.pageView}ref={ref}>
    <div className={styles.contentView}>
        <h3 className={styles.headerTitle}>{props.tableName}</h3>
        <div className={styles.sfx_title}>
            <div className={classNames(styles.sfx_title1,styles.sfx_H_B)}>收费项名称</div>
            <div className={classNames(styles.sfx_title2,styles.sfx_H_B)}>课程名称</div>
            <div className={classNames(styles.sfx_title3,styles.sfx_H_B)}>收费周期</div>
            <div className={classNames(styles.sfx_title4,styles.sfx_H_B)}>课程价格(元)</div>
            <div className={classNames(styles.sfx_title5,styles.sfx_H_B)}>课程教具</div>
            <div className={classNames(styles.sfx_title6,styles.sfx_H_B)}>教具价格(元)</div>
            <div className={classNames(styles.sfx_title7,styles.sfx_H_B)}>收费项总额(元)</div>
        </div>
        {props.infoList.map((item,index)=>{
            return <div className={classNames(styles.sfx_title,index==props.infoList.length-1?styles.sfx_last_item:null)}>
                <div className={classNames(styles.sfx_title1,styles.sfx_H_B)}>{item.sfxName}</div>
            <div className={classNames(styles.sfx_title2,styles.sfx_H_B)}>{item.courseName}</div>
            <div className={classNames(styles.sfx_title3,styles.sfx_H_B)}>{item.cycleName}</div>
            <div className={classNames(styles.sfx_title4,styles.sfx_H_B)}>{item.coursePrice}</div>
            <div className={classNames(styles.sfx_title5,styles.sfx_H_B)}>{item.jiaojuName}</div>
            <div className={classNames(styles.sfx_title6,styles.sfx_H_B)}>{item.jiaojuPrice}</div>
            <div className={classNames(styles.sfx_title7,styles.sfx_H_B)}>{item.totalPrice}</div>
            </div>
        })}
    </div>
  </div>
));
 
// const PdfExport = (props) => (
const PdfExport = React.forwardRef((props, ref) => (
  <Document ref={ref}>
    <Page size="A4" className={styles.pdfPage}>
      <MyComponent 
        tableName = {props.tableName}
        infoList = {props.infoList}
      />
    </Page>
  </Document>
));
 
export default MyComponent;