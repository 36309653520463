import React,{useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import styles from "./ProvinceInfoManage.module.css";
import classNames from "classnames"
import TopControl from "../../TopControl/TopControl"
import Footer from "../../Footer/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
// import {getSchools} from './QueryAndGetQRApi'
import ConditionalQuery from "./ConditionalQuery";
import { selectCompanyName, selectContactName, selectPageNumber, selectRegionName, selectShowAddInfoPage,selectShowEditInfoPage, setCurrentPage, setInfoList, setProvinceList, setTotalPageNum } from "./ProvinceInfoManageSlice";
import InfoList from "./InfoList";
import BottomControl from "./BottomControl";
import AddInfo from "./AddInfo";
import EditInfo from "./EditInfo";
import { getInfoListRequest, getRegionListRequest } from "./ProvinceInfoManageApi";
import {message} from 'antd'
// import {getPaymentInfoList} from './QueryAndGetQRApi'
export function ProvinceInfoManage(props) {
    
    const showAddInfoPage = useSelector(selectShowAddInfoPage);
    const showEditInfoPage = useSelector(selectShowEditInfoPage);
    const pageNumber = useSelector(selectPageNumber);
    const regionName = useSelector(selectRegionName);
    const companyName = useSelector(selectCompanyName);
    const contactName = useSelector(selectContactName);
    const [messageApi, contextHolder] = message.useMessage();
    // const currentPage = useSelector(selectCurrentPage)


    const dispatch = useDispatch();
    
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // 这里可以返回一个清理函数，它会在组件卸载前执行

        getProvinceList()
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    function getProvinceList(){
        getRegionListRequest("1","",(data)=>{
            var newProvinceList = [{
                quyu_name:"未选择",
                quyu_code:""
            }];
            if (data.ret==1) {
                data.data.forEach(element => {
                    newProvinceList.push(element)
                });
                dispatch(setProvinceList(newProvinceList))
            }else if(data.ret==99){

                messageApi.open({
                    type: 'warning',
                    content:data.msg
                });
                setTimeout((e)=>{
                    sessionStorage.clear()
                    window.location.reload()
                },1000)
            }
        })
    }
    function refreshMethod() {
        dispatch(setCurrentPage(1))
        getInfoList(1,pageNumber)
    }
    function getInfoList(tempCurrentPage,tempPageNumber) {
        getInfoListRequest(regionName,companyName,contactName,(tempCurrentPage-1)*tempPageNumber,tempPageNumber,(data)=>{
            if (data.ret==1) {
                dispatch(setInfoList(data.data.shengji_quyu_list))
                var pageNumber1 = Math.ceil(data.data.total_num/tempPageNumber)
                dispatch(setTotalPageNum(pageNumber1))
            }else if(data.ret==99){

                messageApi.open({
                    type: 'warning',
                    content:data.msg
                });
                setTimeout((e)=>{
                    sessionStorage.clear()
                    window.location.reload()
                },1000)
            } else {
                dispatch(setInfoList([]))
                dispatch(setTotalPageNum(1))
            }
        })
    }
    return <div className={styles.background}>
        {contextHolder}
        <TopControl
            leftTopTitle = "省级区域管理"
            showRefreshButton = {true}
            // refreshMethod={refreshMethod}
        />
        <ConditionalQuery/>
        <InfoList/>
        <BottomControl
            pageNumberList = {[15,20,25,30]}
        />
        <Footer/>
        {showAddInfoPage?<AddInfo
            refresh = {refreshMethod}
        />:null}
        {showEditInfoPage?<EditInfo
            refresh = {refreshMethod}
        />:null}
        
    </div>
}

