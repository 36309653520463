
import React ,{ useRef,useState,useEffect } from "react";
import styles from "./InfoItem.module.css";
import classNames from "classnames"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheckCircle, faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import { selectInfoList, setInfoList, setQRContentStr,setQRSchoolName } from "./QueryAndGetQRSlice";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../../util/constants";
import { deleteCourseConfig, getSchoolShoufeixiangBypici } from "./QueryAndGetQRApi";
import { setReviewInfo, setShowReviewPage } from "../../caiwuzhuguan/QRApproval/QRApprovalSlice";
import { setAlertInfo } from "../../../store/CustomViewSlice";
import { CustomConfirmAlert } from "../../CustomView/CustomConfirmAlert";
import {shenheCourseConfig} from "../../caiwuzhuguan/QRApproval/QRApprovalApi";
import {message} from 'antd'
// const PaymentInfoList = props =>(
export default function InfoItem(props) {
    const dispatch = useDispatch();
    const infoList = useSelector(selectInfoList);
    const [confirmInfo,setConfirmInfo]=useState({})
    const [messageApi, contextHolder] = message.useMessage();
    // qrStatus
    // 1，撤销申请
    // 2，查看二维码
    // 3，停用
    // 4，启用
    // 5，废除
    function manageMethod(qrStatus) {
        if(qrStatus==-1){

        }
        else  if(qrStatus==1){
            setConfirmInfo({
                showStatus:true,
                title:"",
                title1:"是否确认撤销？",
                content:"",
                eventId:1
            })
        }
        else  if(qrStatus==2){
            dispatch(setQRContentStr(API_URL+"/shoufei/skip.html?pici_code="+props.item.pici_code))
            dispatch(setQRSchoolName(props.item.school_name))
        }
        else  if(qrStatus==3){
            setConfirmInfo({
                showStatus:true,
                title:"",
                title1:"是否停用该二维码？",
                content:"停用二维码后，二维码将停止收费功能",
                eventId:2,
                shenheStatus:4
            })
        }
        else  if(qrStatus==4){
            setConfirmInfo({
                showStatus:true,
                title:"",
                title1:"是否启用该二维码？",
                content:"启用二维码后，二维码将正常使用",
                eventId:2,
                shenheStatus:1
            })
        }
        else  if(qrStatus==5){
            setConfirmInfo({
                showStatus:true,
                title:"",
                title1:"是否废除该二维码？",
                content:"废除二维码后，二维码将永久失效",
                eventId:2,
                shenheStatus:5
            })
        }
    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus:false,
            title:"",
            title1:"",
            content:""
        })
    }
    function confirmMethod(params) {
        setConfirmInfo({
            showStatus:false,
            title:"",
            title1:"",
            content:""
        })
        if (confirmInfo.eventId==1) {
            deleteCourseConfig(props.item.school_code,props.item.pici_code,(data)=>{
                if (data.ret==1) {
                    let tempInfoList = infoList.filter(element => element.pici_code != props.item.pici_code);
                    dispatch(setInfoList(tempInfoList))
                }else if(data.ret==99){
                    messageApi.open({
                        type: 'warning',
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }
            })
        } else if(confirmInfo.eventId==2) {
            shenheCourseConfig(props.item.school_code, props.item.pici_code, confirmInfo.shenheStatus, (data) => {
                if (data.ret == 1) {
                    let tempInfoList = []
                    infoList.forEach(element => {
                        var newElement = {}
                        for (let key in element) {
                            if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                                newElement[key] = element[key]
                            }
                        }
                        if (newElement.pici_code == props.item.pici_code) {
                            newElement.status = confirmInfo.shenheStatus
                        }
                        tempInfoList.push(newElement)
                    });
                    dispatch(setInfoList(tempInfoList))
                }else if(data.ret==99){
                    messageApi.open({
                        type: 'warning',
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }
            })
        }
    }
    return <tr
        className={styles.tr1}
    >

        <td
            className={styles.td1}
        >{props.item.school_name}
        </td>
        <td
            className={styles.td1}
        >{props.item.pici_name}
        </td>
        <td
            className={styles.td1}
        >{props.item.ctime}
        </td>
        <td
            className={styles.td1}
        >{props.item.status == 0 ? "待审核" : props.item.status == 1 ? "正常": props.item.status == 2 ? "已拒绝": props.item.status == 3 ? "已撤销" : props.item.status == 4 ? "已停用" : props.item.status == 5 ? "已废除" : ""}
        </td>
        <td
            className={styles.td1}
        >{props.item.lianxiren}
        </td>
        <td
            className={styles.td1}
        >{props.item.lianxi_phone}
        </td>
        <td
            className={styles.td2}
        >
            <span
                className={classNames(styles.listContentSpan, styles.manageDes)}
                onClick={(e) => {
                    getSchoolShoufeixiangBypici(props.item.school_code, props.item.pici_code, (data) => {
                        console.log(data)
                        if (data.ret == 1) {
                            dispatch(setShowReviewPage(true))
                            dispatch(setReviewInfo({
                                tableName: props.item.school_name+"_"+props.item.pici_name,
                                list: data.data
                            }))
                        }else if(data.ret==99){
                            messageApi.open({
                                type: 'warning',
                                content:data.msg
                            });
                            setTimeout((e)=>{
                                sessionStorage.clear()
                                window.location.reload()
                            },1000)
                        } else {
                            dispatch(setAlertInfo({
                                showStatus: true,
                                title: "",
                                title1: data.msg,
                                content: ""
                            }))
                        }

                    })
                }}
            >查看</span>
            {/*{props.item.status == 0||props.item.status == 1?<FontAwesomeIcon icon={props.item.status == 0 ? faMinusCircle : props.item.status == 1 ? faCheckCircle : ""}*/}
            {/*                 className={styles.courseStatusIcon}/>:null}*/}
            {/*{props.item.status == 0 || props.item.status == 1 ?*/}
            {/*    <FontAwesomeIcon icon={props.item.status == 0 ? faMinusCircle : faCheckCircle}*/}
            {/*                     className={styles.courseStatusIcon}/> : null}*/}
            {/*<span*/}
            {/*    className={classNames(styles.listContentSpan, styles.manageDes)}*/}
            {/*    onClick={manageMethod}*/}
            {/*>{props.item.status == 0 ? "撤销申请" : props.item.status == 1 ? "查看二维码" : ""}</span>*/}

            {props.item.status == 0 ?<span
                className={classNames(styles.listContentSpan, styles.manageDes)}
                onClick={manageMethod.bind(null,1)}
            >撤销申请</span>:null}
            {props.item.status == 1 ?<span
                className={classNames(styles.listContentSpan, styles.manageDes)}
                onClick={manageMethod.bind(null,2)}
            >查看二维码</span>:null}
            {props.item.status == 1 ?<span
                className={classNames(styles.listContentSpan, styles.manageDes)}
                onClick={manageMethod.bind(null,3)}
            >停用</span>:null}
            {props.item.status == 4 ?<span
                className={classNames(styles.listContentSpan, styles.manageDes)}
                onClick={manageMethod.bind(null,4)}
            >启用</span>:null}
            {props.item.status == 1 || props.item.status == 4 ?<span
                className={classNames(styles.listContentSpan, styles.manageDes)}
                onClick={manageMethod.bind(null,5)}
            >废除</span>:null}
        </td>
        <CustomConfirmAlert
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        {contextHolder}
    </tr>
    // return <div className={styles.background}>
    //     <CustomConfirmAlert
    //         info={confirmInfo}
    //         cancelMethod={cancelMethod}
    //         confirmMethod={confirmMethod}
    //     />
    //     <div className={classNames(styles.listContent, styles.schoolNameWidth)}>
    //         <span className={styles.listContentSpan}>{props.item.school_name}</span>
    //     </div>
    //     <div className={classNames(styles.listContent, styles.courseNameWidth)}>
    //         <span className={styles.listContentSpan}>{props.item.pici_name}</span>
    //     </div>
    //     <div className={classNames(styles.listContent, styles.paymentTimeWidth)}>
    //         <span className={styles.listContentSpan}>{props.item.ctime}</span>
    //     </div>
    //     <div className={classNames(styles.listContent, styles.progressStatusWidth)}>
    //         <span
    //             className={styles.listContentSpan}>{props.item.status == 0 ? "待审核" : props.item.status == 1 ? "已通过" : "已拒绝"}</span>
    //     </div>
    //     <div className={classNames(styles.listContent, styles.contactsWidth)}>
    //         <span className={styles.listContentSpan}>{props.item.lianxiren}</span>
    //     </div>
    //     <div className={classNames(styles.listContent, styles.telephoneWidth)}>
    //         <span className={styles.listContentSpan}>{props.item.lianxi_phone}</span>
    //     </div>
    //     <div className={classNames(styles.listContent,styles.manageWidth)}>
    //         <span
    //             className={classNames(styles.listContentSpan,styles.manageDes)}
    //             onClick={(e)=>{
    //                 getSchoolShoufeixiangBypici(props.item.school_code,props.item.pici_code,(data)=>{
    //                     console.log(data)
    //                     if (data.ret==1) {
    //                         dispatch(setShowReviewPage(true))
    //                         dispatch(setReviewInfo({
    //                             tableName:props.item.school_name,
    //                             list:data.data
    //                         }))
    //                     } else {
    //                         dispatch(setAlertInfo({
    //                             showStatus:true,
    //                             title:"",
    //                             title1:data.msg,
    //                             content:""
    //                         }))
    //                     }
    //
    //                 })
    //             }}
    //         >查看</span>
    //         <FontAwesomeIcon icon={props.item.status==0?faMinusCircle:props.item.status==1?faCheckCircle:""} className={styles.courseStatusIcon}/>
    //         <span
    //             className={classNames(styles.listContentSpan,styles.manageDes)}
    //             onClick={manageMethod}
    //         >{props.item.status==0?"撤销申请":props.item.status==1?"查看二维码":""}</span>
    //     </div>
    // </div>
}
// export default PaymentInfoList;

