import React, {useRef, useState} from "react";
import styles from "./RefundAlert.module.css";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose, faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
import {setAlertInfo} from "../../../store/CustomViewSlice";
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {
    selectInfoList,
    selectPaymentInfo, setInfoList,
    setShowRefundAlert
} from "./PaymentQuerySlice";
import {Checkbox} from "antd";
import {selectPageNumber} from "../../ZBZhuGuan/XXInfoManage/XXInfoManageSlice";
import {wxRefundRequest, zfbRefundRequest} from "./PaymentQueryApi";

export default function RefundAlert(props) {
    const [confirmInfo, setConfirmInfo] = useState({})
    const [coursePrice, setCoursePrice] = useState("")
    const [equipmentPrice, setEquipmentPrice] = useState("")
    const [notesStr, setNotesStr] = useState("")
    const paymentInfo = useSelector(selectPaymentInfo)
    const dispatch = useDispatch();
    const infoList = useSelector(selectInfoList);

    function isValidPhoneNumber(phoneNumber) {
        return /^1[3-9]\d{9}$/.test(phoneNumber);
    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
            console.log("paymentInfopaymentInfopaymentInfo")
            console.log(paymentInfo)
            if (paymentInfo.pay_type == 1) {
                wxRefundRequest(paymentInfo.dingdan_code, paymentInfo.dingdan_amount,
                    Math.round(coursePrice * 100) + Math.round(equipmentPrice * 100),
                    notesStr, paymentInfo.Id, Math.round(coursePrice * 100),
                    Math.round(equipmentPrice * 100), (data) => {
                        if (data == 1) {
                            dispatch(setShowRefundAlert(false))
                            let tempInfoList = []
                            infoList.forEach(element => {
                                var newElement = {}
                                for (let key in element) {
                                    if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                                        newElement[key] = element[key]
                                    }
                                }
                                if (newElement.Id == paymentInfo.Id) {
                                    newElement.tuifei_status = "1"
                                }
                                tempInfoList.push(newElement)
                            });
                            dispatch(setInfoList(tempInfoList))
                        } else {
                            dispatch(setAlertInfo({
                                showStatus: true,
                                title: "",
                                title1: "退款失败",
                                content: ""
                            }))
                        }
                    })
            } else if (paymentInfo.pay_type == 2) {
                zfbRefundRequest(paymentInfo.dingdan_code,
                    (Math.round(coursePrice*100) + Math.round(equipmentPrice*100))/100,
                    notesStr, paymentInfo.Id, coursePrice,
                    equipmentPrice, (data) => {
                        console.log(data == 1)
                        if (data == 1) {
                            dispatch(setShowRefundAlert(false))
                            let tempInfoList = []
                            infoList.forEach(element => {
                                var newElement = {}
                                for (let key in element) {
                                    if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                                        newElement[key] = element[key]
                                    }
                                }
                                if (newElement.Id == paymentInfo.Id) {
                                    newElement.tuifei_status = "1"
                                }
                                tempInfoList.push(newElement)
                            });
                            dispatch(setInfoList(tempInfoList))
                        } else {
                            dispatch(setAlertInfo({
                                showStatus: true,
                                title: "",
                                title1: "退款失败",
                                content: ""
                            }))
                        }
                    })
            }
            else {
                dispatch(setAlertInfo({
                    showStatus: true,
                    title: "",
                    title1: "目前仅支持微信和支付宝，其他退款暂未开放",
                    content: ""
                }))
            }
        }
    }

    function isValidAmount(amount) {
        // 匹配金额的正则表达式，小数点后最多两位数字
        const regex = /^\d+(\.\d{1,2})?$/;
        return regex.test(amount);
    }

    return <div className={styles.background}>
        <CustomConfirmAlert
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        <div className={styles.contentContainer}>
            <div className={styles.topBar}>
                <div className={styles.topBarLeft}>退费操作</div>
                <FontAwesomeIcon
                    className={styles.topBarRight}
                    icon={faClose}
                    onClick={(e) => {
                        dispatch(setShowRefundAlert(false))
                    }}
                />
            </div>

            <div className={styles.middleContent}>
                <div className={styles.classNameContainer}>
                    <div className={styles.redStar}>*</div>
                    <div className={styles.className}>课程退费</div>
                    <input className={styles.classNameInput}
                           placeholder="请输入课程退费金额"
                           onChange={(e) => {
                               setCoursePrice(e.target.value)
                           }}
                    />
                </div>
                <div className={styles.classNameContainer}>
                    <div className={styles.redStar}>*</div>
                    <div className={styles.className}>器材退费</div>
                    <input className={styles.classNameInput}
                           placeholder="请输入器材退费金额"
                           onChange={(e) => {
                               setEquipmentPrice(e.target.value)
                           }}
                    />
                </div>
                <div className={styles.fbsmTitle}>退费备注：</div>
                <textarea className={styles.fbsmTextarea}
                          placeholder="如有补充说明请在此填写"
                          onChange={(e) => {
                              setNotesStr(e.target.value)
                          }}
                />
            </div>

            <div className={styles.bottomContainer}>
                <div className={classNames(styles.cancelButton, styles.button)}
                     onClick={(e) => {
                         dispatch(setShowRefundAlert(false))
                     }}
                >取消
                </div>
                <div className={classNames(styles.confirmButton, styles.button)}
                     onClick={(e) => {
                         console.log(isValidAmount(coursePrice))
                         if (!isValidAmount(coursePrice)) {
                             dispatch(setAlertInfo({
                                 showStatus: true,
                                 title: "",
                                 title1: "请检查课程金额格式是否正确",
                                 content: ""
                             }))
                         } else if (!isValidAmount(equipmentPrice)) {
                             dispatch(setAlertInfo({
                                 showStatus: true,
                                 title: "",
                                 title1: "请检查器材金额格式是否正确",
                                 content: ""
                             }))
                         } else if (coursePrice+equipmentPrice==0) {
                             dispatch(setAlertInfo({
                                 showStatus: true,
                                 title: "",
                                 title1: "退款金额为0",
                                 content: ""
                             }))
                         } else {
                             setConfirmInfo({
                                 showStatus: true,
                                 title: "",
                                 title1: '确认退款？',
                                 content: "",
                                 eventId: 1
                             })
                         }
                     }}
                >确定
                </div>
            </div>
        </div>
    </div>
}

