import React from 'react';
import {Document, Page} from '@react-pdf/renderer';
// import 'react-to-pdf/dist/index.css';
import styles from "./ReviewPage.module.css"
import classNames from "classnames"
import {getMoneyDate} from "../../../util/currencyUnit";

const ReviewPage = React.forwardRef((props, ref) => (
    <div className={styles.pageView} ref={ref}>
        <h3 className={styles.headerTitle}>{props.reviewInfo.tableName}</h3>
        <div className={styles.contentView}>
            <table className={styles.infoTable}>
                <tr
                    className={styles.tr1}
                >
                    <td
                        className={styles.td1}
                    >收费项名称
                    </td>
                    <td
                        className={styles.td1}
                    >课程名称
                    </td>
                    <td
                        className={styles.td1}
                    >收费周期
                    </td>
                    <td
                        className={styles.td1}
                    >课程价格
                    </td>
                    <td
                        className={styles.td1}
                    >教具名称
                    </td>
                    <td
                        className={styles.td1}
                    >教具价格
                    </td>
                    <td
                        className={styles.td1}
                    >总计价格
                    </td>
                </tr>
                {props.reviewInfo.list.map((item, index) => (
                    <tr className={styles.tr2}>
                        <td
                            className={styles.td1}
                        >
                            {item.shoufeixiang_name}
                        </td>
                        <td
                            className={styles.td1}
                        >
                            {item.kecheng_name}
                        </td>
                        <td
                            className={styles.td1}
                        >
                            {item.shoufei_zhouqi_name}
                        </td>
                        <td
                            className={styles.td1}
                        >
                            {getMoneyDate("yuan",item.kecheng_jiage)}
                        </td>
                        <td
                            className={styles.td1}
                        >
                            {item.jiaoju_name}
                        </td>
                        <td
                            className={styles.td1}
                        >
                            {getMoneyDate("yuan",item.jiaoju_jiage)}
                        </td>
                        <td
                            className={styles.td1}
                        >
                            {getMoneyDate("yuan",item.total_jiage)}
                        </td>
                    </tr>
                ))}
            </table>
        </div>
    </div>
));

export default ReviewPage;