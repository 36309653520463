import request from "../../../util/request";


export function getSchools(callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_schools_byzhuxiao");
    filedata.append('username', sessionStorage.getItem('username'));
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function getSchoolCourseAndPrice(callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_kecheng_jiaoju_list");
    // filedata.append('school_code', school_code);
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function setQRRequest(school_code,school_name,batchName,shoufeixiang_json,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "set_school_shoufeixiangs");
    filedata.append('school_code', school_code);
    filedata.append('school_name', school_name);
    filedata.append('lianxiren', sessionStorage.getItem("name"));
    filedata.append('lianxi_phone', sessionStorage.getItem("phone"));
    filedata.append('jiaofei_shenqing_name',batchName);
    filedata.append('shoufeixiang_json',shoufeixiang_json);
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}