// 申报评审环节界面
import React, {useEffect, useState} from "react";
import styles from "./ZSYBH.module.css";
import classNames from "classnames"
import {useDispatch, useSelector} from 'react-redux';
import Footer from "../../Footer/Footer";
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {DatePicker, message, Modal, Tooltip, Upload} from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import dayjs from "dayjs";
import 'dayjs/locale/zh-cn'
import questionIcon from "../../../img/questionIcon.png"
import {
    selectFileList1,
    selectFileList2,
    selectHygcjj,
    selectpsjlIndex,
    selectpsjlList,
    selectRangeTime,
    selectSBPSSchoolIndex,
    selectSBPSSchoolList,
    selectXxchr,
    selectYjschr,
    selectZSYBHSchoolList,
    selectZSYBHXueqiIndex,
    selectZSYBHXueqiList,
    setFileList1,
    setFileList2,
    setHygcjj,
    setpsjlIndex,
    setRangeTime,
    setSBPSSchoolIndex,
    setSBPSSchoolList,
    setXxchr,
    setYjschr,
    setZSYBHDetailInfo,
    setZSYBHSchoolList,
    setZSYBHXueqiIndex,
    setZSYBHXueqiList,
} from "./ZSYBHSlice";
import OSS from 'ali-oss'
import {
    addZSYBHSchoolInfoRequest,
    getAliOssTokenRequest,
    getSBPSSchoolListRequest,
    getXueqiListRequest,
    getZSYBHSchoolInfoRequest,
    getZSYBHSchoolListRequest
} from "./ZSYBHApi";
import {getSixMathRandom} from "../../../util/handMath";
import {setBigMenuIndex, setXMLDMenuIndex, setZSYBHMenuIndex} from "../ZXYYMain/ZXYYMainSlice";
import {ALI_OSS_PATH} from "../../../util/constants";
import uploadLogImg from "../../../img/upload_logs.svg";
import uploadImg from "../../../img/upload_picture.svg";

dayjs.locale('zh-cn')
const {RangePicker} = DatePicker;


let client;
let clientExpTime;


export function ZSYBH() {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [confirmInfo, setConfirmInfo] = useState({})
    const [tjStatus, setTjStatus] = useState(0)

    const ZSYBHXueqiList = useSelector(selectZSYBHXueqiList)
    const ZSYBHXueqiIndex = useSelector(selectZSYBHXueqiIndex)
    const SBPSSchoolIndex = useSelector(selectSBPSSchoolIndex)
    const SSMSchoolList = useSelector(selectSBPSSchoolList)
    const ZSYBHSchoolList = useSelector(selectZSYBHSchoolList)
    const fileList1 = useSelector(selectFileList1)
    const fileList2 = useSelector(selectFileList2)
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const rangeTime = useSelector(selectRangeTime)
    const xxchr = useSelector(selectXxchr)
    const yjschr = useSelector(selectYjschr)
    const hygcjj = useSelector(selectHygcjj)

    const psjlIndex = useSelector(selectpsjlIndex)
    const psjlList = useSelector(selectpsjlList)
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        getAliOssToken()
        getXueqiList()
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    function getXueqiList(index) {
        getXueqiListRequest((data) => {
            if (data.ret == 1) {
                if (data.data) {
                    dispatch(setZSYBHXueqiList(data.data));
                    data.data.map((item, index) => {
                        if (item.dangqian_xueqi == 1) {
                            dispatch(setZSYBHXueqiIndex(index))
                            getZSYBHSchoolList(item.xueqi_code, 0)
                            getSBPSSchoolList(item.xueqi_code, 0)
                        }
                    })
                }
            }else if(data.ret==99){

                messageApi.open({
                    type: 'warning',
                    content:data.msg
                });
                setTimeout((e)=>{
                    sessionStorage.clear()
                    window.location.reload()
                },1000)
            } else {
                messageApi.open({
                    type: 'error',
                    content: "学期请求失败，请重新进入该界面"
                })
            }
        })
    }

    /*已完成招生启动会的学校=====*/
    function getZSYBHSchoolList(xueqiCode, index) {
        getZSYBHSchoolListRequest(xueqiCode, (data) => {
            if (data.ret == 1) {
                // dispatch(setZSYBHSchoolList(data.data));
                dispatch(setZSYBHSchoolList([]))
            } else {
            }
        })
    }


    function getSBPSSchoolList(xueqiCode, index) {
        getSBPSSchoolListRequest(xueqiCode, (data) => {
            if (data.ret == 1) {
                // dispatch(setSBPSSchoolList(data.data));
                // dispatch(setSBPSSchoolIndex(index))
                // if(data.data&&data.data.length>0){
                //     getZSYBHSchoolInfo(xueqiCode, data.data[0].school_code)
                // }
                dispatch(setSBPSSchoolList([]));
                dispatch(setSBPSSchoolIndex(index))
                // if(data.data&&data.data.length>0){
                //     getZSYBHSchoolInfo(xueqiCode, data.data[0].school_code)
                // }
            } else {

            }
        })
    }

    function getZSYBHSchoolInfo(xueqi_code, school_code) {
        getZSYBHSchoolInfoRequest(xueqi_code, school_code, (data) => {
            dispatch(setRangeTime(["", ""]))
            dispatch(setXxchr(""))
            dispatch(setYjschr(""))
            dispatch(setFileList1([]))
            dispatch(setFileList2([]))
            dispatch(setpsjlIndex(0))
            setTjStatus(0)
            // if(data.ret == 1){
            //     // set
            //     let result = data.data;
            //     dispatch(setRangeTime([result.start_time,result.end_time]))
            //     dispatch(setXxchr(result.school_canhui))
            //     dispatch(setYjschr(result.yanjiusuo_canhui))
            //     let tempList1 = result.huiyi_image.split(',')
            //     tempList1 = tempList1.map((item, index) => {
            //         let tempDic = {
            //             "url":item,
            //             "uid":index
            //         }
            //         return tempDic
            //     })
            //     dispatch(setFileList1(tempList1))
            //
            //     let tempList2 = result.shenqingbiao_image.split(',')
            //     tempList2 = tempList2.map((item, index) => {
            //         let tempDic = {
            //             "url":item,
            //             "uid":index
            //         }
            //         return tempDic
            //     })
            //     dispatch(setFileList2(tempList2))
            //
            //     let tempList3 = result.cailiao__url.split(',')
            //     tempList3 = tempList3.map((item, index) => {
            //         let tempDic = {
            //             "url":item,
            //             "uid":index
            //         }
            //         return tempDic
            //     })
            //     dispatch(setFileList3(tempList3))
            //
            //     let tempList4 = result.xieyi_image.split(',')
            //     tempList4 = tempList4.map((item, index) => {
            //         let tempDic = {
            //             "url":item,
            //             "uid":index
            //         }
            //         return tempDic
            //     })
            //     dispatch(setFileList4(tempList4))
            //
            //
            //     dispatch(setpsjlIndex(result.jielun_code - 1))
            //     setTjStatus(1)
            // }
            // else {
            //     dispatch(setRangeTime(["",""]))
            //     dispatch(setXxchr(""))
            //     dispatch(setYjschr(""))
            //     dispatch(setFileList1([]))
            //     dispatch(setFileList2([]))
            //     dispatch(setFileList3([]))
            //     dispatch(setFileList4([]))
            //     dispatch(setpsjlIndex(0))
            //     setTjStatus(0)
            // }
        })
    }

    function getAliOssToken() {
        let nowDate = new Date().getTime()
        if (!client || nowDate - clientExpTime > 1200000) {
            clientExpTime = new Date().getTime();
            getAliOssTokenRequest((data) => {
                if ("Credentials" in data) {
                    client = new OSS({
                        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
                        region: 'oss-cn-qingdao',
                        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                        accessKeyId: data.Credentials.AccessKeyId,
                        accessKeySecret: data.Credentials.AccessKeySecret,
                        // 从STS服务获取的安全令牌（SecurityToken）。
                        stsToken: data.Credentials.SecurityToken,
                        refreshSTSToken: async () => {
                            // 向您搭建的STS服务获取临时访问凭证。
                            getAliOssTokenRequest((data) => {
                                return {
                                    accessKeyId: data.Credentials.AccessKeyId,
                                    accessKeySecret: data.Credentials.AccessKeySecret,
                                    stsToken: data.Credentials.SecurityToken,
                                }
                            })

                        },
                        // 刷新临时访问凭证的时间间隔，单位为毫秒。
                        refreshSTSTokenInterval: 3000000,
                        // 填写Bucket名称。
                        bucket: 'tgnp'
                    })
                }
            })
        }
    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
        } else if (confirmInfo.eventId == 2) {

        }

    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    function handleCancel() {
        setPreviewVisible(false);
    }

    function isImage(filename) {
        const pattern = /\.(jpg|jpeg|png|gif|svg|bmp)$/i;
        return pattern.test(filename);
    }

    async function handlePreview(file) {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        if (isImage(file.url)) {
            setPreviewImage(file.url || file.preview)
            setPreviewVisible(true);
        } else {
            if (!file.url) {
                message.open({
                    type: "warning",
                    content: "链接无效"
                })
            }
            window.location.href = file.url
        }
    }

    async function handlePreviewWork(file) {
        if (!file.url) {
            message.open({
                type: "warning",
                content: "链接无效"
            })
        }
        window.location.href = file.url
    }

    function handleChange1(info) {
        getAliOssToken()
        let tempList = info.fileList.map((item, index) => {
            let tempDic = {
                "url": item.url,
                "uid": item.uid,
                "status": item.status,
                "name": item.name
            }
            return tempDic
        })
        dispatch(setFileList1(tempList));
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    function handleChange2(info) {
        getAliOssToken()
        let tempList = info.fileList.map((item, index) => {
            console.log(item)
            let tempDic = {
                "url": item.url,
                "uid": item.uid,
                "status": item.status,
                "name": item.name
            }
            return tempDic
        })
        dispatch(setFileList2(tempList));
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }


    const customRequest = async options => {
        const {onSuccess, onError, file} = options;

        try {
            // 使用阿里云OSS SDK上传文件
            let timestamp = new Date().getTime()
            let random = getSixMathRandom()
            const result = await client.put(ALI_OSS_PATH + "zhaoshengqidonghui/" + timestamp + random + file.name, file);
            file.url = result.url;
            onSuccess(file);
        } catch (e) {
            onError(e);
        }
    };

    const dateFormat = 'YYYY-MM-DD HH:mm';
    var currentDate = new Date();
    // var maxDateString = currentDate.toJSON().slice(0, 10);
    var maxDateString = (parseInt(currentDate.toJSON().slice(0, 4)) + 5) + "-12-31 00:00";
    const uploadButton = (
        <div>
            {/*<Icon type="plus" />*/}
            <div className="ant-upload-text">上传111</div>
        </div>
    );
    return (
        <div className={styles.background}>
            {contextHolder}
            <CustomConfirmAlert
                info={confirmInfo}
                cancelMethod={cancelMethod}
                confirmMethod={confirmMethod}
            />
            <div className={styles.topBar}>
                <div className={styles.topBarLine1}>
                    <span className={styles.xueqiLabel}>学期：</span>
                    <select className={styles.xueqiData}
                            onChange={(e) => {
                                dispatch(setZSYBHXueqiIndex(e.target.value))
                                getZSYBHSchoolList(ZSYBHXueqiList[e.target.value].xueqi_code, 0)
                                getSBPSSchoolList(ZSYBHXueqiList[e.target.value].xueqi_code, 0)
                            }}
                            value={ZSYBHXueqiIndex}
                    >
                        {ZSYBHXueqiList.map((item, index) => {
                            return <option value={index}>{item.xueqi_nmae}</option>
                        })}
                    </select>
                </div>
                <div className={styles.topBarLine2}>
                    <span className={styles.xueqiLabel}>已完成“招生启动会”的学校：</span>
                    <div className={styles.YWCSchoolContainer}>
                        {ZSYBHSchoolList.length > 0 ? ZSYBHSchoolList.map((item, index) => {
                            // return <span className={styles.schooolName}>{item.school_name}</span>
                            return <span>
                                <span className={styles.schooolName}>{item.school_name}</span>
                                <span className={styles.lookDetail}
                                      onClick={(e) => {
                                          getZSYBHSchoolInfoRequest(
                                              ZSYBHXueqiList[ZSYBHXueqiIndex].xueqi_code,
                                              item.school_code,
                                              (data) => {
                                                  if (data.ret == 1) {
                                                      dispatch(setZSYBHDetailInfo(data.data))
                                                      dispatch(setZSYBHMenuIndex(1))
                                                  }
                                              }
                                          )
                                          // dispatch(setSBPSMenuIndex(1))
                                      }}
                                >查看></span>
                            </span>
                        }) : <span className={styles.schooolName}>暂无</span>}
                    </div>
                </div>
            </div>

            <div className={styles.content}>
                <div className={styles.contentLine1}>
                    <span
                        className={styles.contentTitle}>组织实施招生启动会，并记录：</span>
                </div>
                <div className={styles.contentBottom}>
                    <div className={styles.bottomLine1}>
                        <span className={styles.bottomLeftTitle}>
                            学校：
                        </span>
                        {SSMSchoolList.length > 0 ? <select className={styles.schoolList}
                                                            onChange={(e) => {
                                                                dispatch(setSBPSSchoolIndex(e.target.value))
                                                                getZSYBHSchoolInfo(ZSYBHXueqiList[ZSYBHXueqiIndex].xueqi_code, SSMSchoolList[e.target.value].school_code)
                                                            }}
                                                            value={SBPSSchoolIndex}
                        >
                            {SSMSchoolList.map((item, index) => {
                                return <option value={index}>{item.school_name}</option>
                            })}
                        </select> : <div className={styles.zanwuContainer}>
                            <span className={styles.zanwu}>暂无</span>
                            <span className={styles.goZXCD}
                                  onClick={(e) => {
                                      dispatch(setBigMenuIndex(0))
                                      dispatch(setXMLDMenuIndex(2))
                                  }}
                            >需先完成“申报评审”环节></span>
                            <Tooltip placement={"top"} title={"只有申报评“通过”的学校，才能出进入本环节"}>
                                <img src={questionIcon}/>
                            </Tooltip>
                        </div>}
                    </div>
                    <div className={styles.bottomLine1}>
                        <span className={styles.bottomLeftTitle}>
                            会议日期：
                        </span>
                        {tjStatus == 0 ? <RangePicker
                                locale={locale}
                                // needConfirm = {true}
                                // minDate={dayjs('2024-01-01', dateFormat)}
                                minDate={dayjs('2024-01-01 00:00', dateFormat)}
                                maxDate={dayjs(maxDateString, dateFormat)}
                                showTime={{format: 'HH:mm'}}
                                value={rangeTime[0].length > 0 ? [dayjs(rangeTime[0], dateFormat), dayjs(rangeTime[1], dateFormat)] : null}
                                onChange={(date, dateString) => {
                                    dispatch(setRangeTime(dateString))
                                }}
                            />
                            : <span className={styles.rightContent1}>{rangeTime[0]}&nbsp;~&nbsp;{rangeTime[1]}</span>
                        }
                    </div>
                    <div className={styles.bottomLine2}>
                         <span className={styles.bottomLeftTitle}>
                            学校参会人：
                        </span>
                        {tjStatus == 0 ? <textarea
                                className={styles.textarea}
                                // value={beizhu}
                                onChange={(e) => {
                                    dispatch(setXxchr(e.target.value))
                                }}
                                placeholder='请输入~'
                                value={xxchr}
                            ></textarea> :
                            <div className={styles.textarea}>{xxchr}</div>}
                    </div>
                    <div className={styles.bottomLine2}>
                         <span className={styles.bottomLeftTitle}>
                            研究所参会人：
                        </span>
                        {tjStatus == 0 ? <textarea
                                className={styles.textarea}
                                // value={beizhu}
                                onChange={(e) => {
                                    dispatch(setYjschr(e.target.value))
                                }}
                                placeholder='请输入~'
                                value={yjschr}
                            ></textarea> :
                            <div className={styles.textarea}>{yjschr}</div>}
                    </div>
                    <div className={styles.bottomLine2}>
                         <span className={styles.bottomLeftTitle}>
                            会议过程简介：
                        </span>
                        {tjStatus == 0 ? <textarea
                                className={styles.textarea}
                                // value={beizhu}
                                onChange={(e) => {
                                    dispatch(setHygcjj(e.target.value))
                                }}
                                placeholder='请输入~'
                                value={hygcjj}
                            ></textarea> :
                            <div className={styles.textarea}>{hygcjj}</div>}
                    </div>
                    <div className={styles.bottomLine2}>
                         <span className={styles.bottomLeftTitle}>
                            上传会议结论：
                        </span>
                        <div className={styles.uploadWidth}>
                            <Upload
                                listType="picture"
                                fileList={fileList1}
                                onPreview={handlePreview}
                                onChange={handleChange1}
                                customRequest={customRequest}
                                accept=".pdf,.doc,.docx,.ppt,.pptx"
                                disabled={tjStatus == 1}
                            >
                                {fileList1.length >= 8 || tjStatus == 1 ? null :
                                    // <Button type="primary" icon={<UploadOutlined/>}>
                                    //     上传文件
                                    // </Button>
                                    <div className={styles.uploadButton}>
                                        <img  className={styles.uploadImgIcon}  src={uploadLogImg}/>上传文件
                                    </div>

                                }
                            </Upload>
                        </div>

                        {tjStatus == 0 && fileList1.length < 8 ?
                            <div className={styles.uploadImgDes}>上传会议结论文件（pdf/word/ppt格式）</div> : null}
                        <Modal visible={previewVisible} footer={null}
                               onCancel={handleCancel}
                               width={1200}
                        >
                            <img alt="example" style={{width: '100%'}} src={previewImage}/>
                        </Modal>
                    </div>
                    <div className={styles.bottomLine2}>
                        <span className={styles.bottomLeftTitle}>
                            上传会议照片：
                        </span>
                        <div className={styles.uploadWidth}>
                            <Upload
                                // listType="picture-card"
                                listType={'picture'}
                                fileList={fileList2}
                                onPreview={handlePreview}
                                onChange={handleChange2}
                                customRequest={customRequest}
                                disabled={tjStatus == 1}
                                accept=".jpg,.png,.jpeg,.svg,.gif"
                            >
                                {fileList2.length >= 5 || tjStatus == 1 ? null :
                                    // <Button type="primary" icon={<UploadOutlined/>}>
                                    //     上传图片
                                    // </Button>
                                    <div className={styles.uploadButton}>
                                        <img className={styles.uploadImgIcon}  src={uploadImg}/>上传图片
                                    </div>
                                }
                            </Upload>
                        </div>
                        {tjStatus == 0 && fileList2.length < 5 ?
                            <div className={styles.uploadImgDes}>上传会议过程照片</div> : null}
                    </div>

                    <div className={styles.bottomLine3}>
                        <div
                            className={classNames(styles.tijiaoButton, tjStatus == 1 ? styles.tijiaoButton1 : null)}
                            onClick={(e) => {
                                if (tjStatus == 1) {
                                    setTjStatus(0);
                                    return;
                                }
                                let haveError1 = false;
                                let fileName1 = '';
                                fileList1.map((item, index) => {
                                    if (item.status == "error") {
                                        haveError1 = true;
                                    }
                                    if (fileName1 == "") {
                                        fileName1 = item.url
                                    } else {
                                        fileName1 = fileName1 + "," + item.url
                                    }
                                })
                                let haveError2 = false;
                                let fileName2 = '';
                                fileList2.map((item, index) => {
                                    if (item.status == "error") {
                                        haveError2 = true;
                                    }
                                    if (fileName2 == "") {
                                        fileName2 = item.url
                                    } else {
                                        fileName2 = fileName2 + "," + item.url
                                    }
                                })
                                if (SSMSchoolList.length == 0) {
                                    message.open({
                                        type: "warning",
                                        content: "需先完成甄选触达环节"
                                    })
                                    return;
                                } else if (rangeTime[1] == "") {
                                    message.open({
                                        type: "warning",
                                        content: "请选择会议时间"
                                    })
                                    return;
                                } else if (xxchr == "") {
                                    message.open({
                                        type: "warning",
                                        content: "请填写学校参会人"
                                    })
                                    return;
                                } else if (yjschr == "") {
                                    message.open({
                                        type: "warning",
                                        content: "请填写研究所参会人"
                                    })
                                    return;
                                } else if (haveError1 || haveError2) {
                                    message.open({
                                        type: "warning",
                                        content: "部分图片或文档上传失败，请将红色边框图片删除后重新上传"
                                    })
                                    return;
                                } else if (fileList1.length == 0) {
                                    message.open({
                                        type: "warning",
                                        content: "请先上传会议结论"
                                    })
                                    return;
                                } else if (fileList2.length === 0) {
                                    message.open({
                                        type: "warning",
                                        content: "请先上传申请表图片"
                                    })
                                    return;
                                }
                                return;
                                addZSYBHSchoolInfoRequest(
                                    ZSYBHXueqiList[ZSYBHXueqiIndex].xueqi_code,
                                    ZSYBHXueqiList[ZSYBHXueqiIndex].xueqi_nmae,
                                    SSMSchoolList[SBPSSchoolIndex].school_code,
                                    SSMSchoolList[SBPSSchoolIndex].school_name,
                                    rangeTime[0],
                                    rangeTime[1],
                                    xxchr,
                                    yjschr,
                                    fileName1,
                                    psjlList[psjlIndex].code,
                                    psjlList[psjlIndex].name,
                                    fileName2,
                                    (data) => {
                                        if (data.ret == 1) {
                                            setTjStatus(1)
                                            messageApi.open({
                                                type: 'success',
                                                content: "恭喜您，提交完成"
                                            })
                                            dispatch(setXMLDMenuIndex(2))
                                        }
                                    })
                            }}
                        >
                            {tjStatus == 1 ? "修改" : "提交"}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};
