import React, {useRef, useState, useEffect} from "react";
import styles from "./CourseConfig.module.css";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import TopControl from "../../TopControl/TopControl"
import Footer from "../../Footer/Footer";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faL, faUpload} from '@fortawesome/free-solid-svg-icons';
import {
    getSchoolCourseAndPrice,
    getSchools,
    setQRRequest
} from './CourseConfigApi'
import checkBox from "../../../img/checkBox.svg"
import checkBoxSelected from "../../../img/checkBoxSelected.svg"
import {selectMenuIndex, setMenuIndex} from "../ZXYYMain/ZXYYMainSlice";
import PdfExport from "./PdfExport";
import {exportComponentAsPNG} from "react-component-export-image";
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {Checkbox} from "antd";
import {selectAlertInfo, setAlertInfo} from "../../../store/CustomViewSlice";
import {message} from 'antd'

export function CourseConfig() {
    const componentRef = useRef();
    const dispatch = useDispatch();
    const [schoolList, setSchoolList] = useState([{
        school_name: "未选择",
        school_code: ""
    }]);
    const [schoolIndex, setSchoolIndex] = useState(0);
    const [showSchoolList, setShowSchoolList] = useState(false);//是否弹出学校列表
    const [batchName, setBatchName] = useState("");
    const [sfxCheckList, setSfxCheckList] = useState([false, false, false, false, false, false, false]);//选中课程的状态
    const [showCourseIndex, setShowCourseIndex] = useState(-1);//是否弹出学校列表
    const [courseStatusList, setCourseStatusList] = useState([0, 0, 0, 0, 0, 0, 0]);//课程所选的索引列表，默认每一条都是第一项
    const [sfxNameList, setSfxNameList] = useState(["", "", "", "", "", "", ""]);//收费项名称列表
    const [courseList, setCourseList] = useState([]);
    const [showCycleIndex, setShowCycleIndex] = useState(-1);//是否弹出周期列表
    const [cycleStatusList, setCycleStatusList] = useState([0, 0, 0, 0, 0, 0, 0]);//周期所选的索引列表，默认每一条都是第一项
    const [cycleList, setCycleList] = useState([]);
    const [showJiaojuIndex, setShowJiaojuIndex] = useState(-1);//是否弹出教具列表
    const [jiaojuStatusList, setJiaojuStatusList] = useState([0, 0, 0, 0, 0, 0, 0]);//教具所选的索引列表，默认每一条都是第一项
    const [jiaojuList, setJiaojuList] = useState([]);
    const [jiaojuPriceList, setJiaojuPriceList] = useState([0, 0, 0, 0, 0, 0, 0]);//教具价格列表
    const [courseCyclePriceList, setCourseCyclePriceList] = useState([]);
    const [coursePriceList, setCoursePriceList] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [totalPriceList, setTotalPriceList] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [pdfInfoList, setPdfInfoList] = useState(["", "", "", "", "", "", ""]);//收费项名称列表
    const [pdfViewStatus, setPdfViewStatus] = useState(false)
    const [confirmInfo, setConfirmInfo] = useState({})
    const [sfxList, setSfxList] = useState([])
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑

        getSchoolsList()
        getCourseList()
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    function getSchoolsList() {
        getSchools((data) => {
            var newSchoolList = [{
                school_name: "未选择",
                school_code: ""
            }];
            if (data.ret == 1) {
                data.data.forEach(element => {
                    newSchoolList.push(element)
                });
            } else if (data.ret == 99) {
                messageApi.open({
                    type: 'warning',
                    content: data.msg
                });
                setTimeout((e) => {
                    sessionStorage.clear()
                    window.location.reload()
                }, 1000)
            }
            setSchoolIndex(0)
            setSchoolList(newSchoolList)
        })
    }

    function getCourseList(index) {
        getSchoolCourseAndPrice((data) => {
            if (data.ret == 1) {
                var newCourseList = [{
                    kecheng_name: "未选择",
                    kecheng_jiage: "0.00"
                }];
                data.data.kecheng_list.forEach(element => {
                    newCourseList.push(element)
                });
                setCourseList(newCourseList)
                setCourseCyclePriceList(data.data.kecheng_zhouqi_list)
                var newJiaojuList = [{
                    jiaoju_name: "无教具",
                    jiaoju_jiage: "0",
                    jiaoju_code: ""
                }];
                data.data.jiaoju_list.forEach(element => {
                    newJiaojuList.push(element)
                });
                setJiaojuList(newJiaojuList)
                var newCycleList = [{
                    zhouqi_name: "未选择"
                }];
                data.data.shoufei_zhouqi_list.forEach(element => {
                    newCycleList.push(element)
                });
                setCycleList(newCycleList)
            } else if (data.ret == 99) {
                messageApi.open({
                    type: 'warning',
                    content: data.msg
                });
                setTimeout((e) => {
                    sessionStorage.clear()
                    window.location.reload()
                }, 1000)
            } else {
                dispatch(setAlertInfo({
                    showStatus: true,
                    title: "",
                    title1: data.msg,
                    content: ""
                }))
            }

        })
    }

    function tapSchoolListItem(index) {
        setSchoolIndex(index)
        if (courseList.length == 0) {
            getCourseList(index)
        }
        // if (index!=0) {
        //     setSchoolIndex(index)
        //     getCourseList(index)
        // } else {
        //     setSchoolIndex(index)
        //     setCourseList([])
        //     setJiaojuList([])
        // }
    }

    function clearMethod() {
        setConfirmInfo({
            showStatus: true,
            title: "",
            title1: "是否确认清除?",
            content: "",
            eventId: 1
        })
    }

    function generateQRCode(params) {

        var shoufeixiangList = [];
        var stopGenerate = false;
        if (schoolIndex == 0) {
            if (!stopGenerate) {
                dispatch(setAlertInfo({
                    showStatus: true,
                    title: "",
                    title1: "未选择学校",
                    content: ``
                }))
            }
            return
        }
        sfxCheckList.map((item, index) => {
            if (item) {
                if (sfxNameList[index].length == 0) {
                    if (!stopGenerate) {
                        dispatch(setAlertInfo({
                            showStatus: true,
                            title: "",
                            title1: `第${index + 1}条收费项名称为空`,
                            content: ''
                        }))
                    }
                    stopGenerate = true;
                }
                if (courseStatusList[index] == 0) {
                    if (!stopGenerate) {
                        dispatch(setAlertInfo({
                            showStatus: true,
                            title: "",
                            title1: `第${index + 1}条课程未选择`,
                            content: ""
                        }))
                    }
                    stopGenerate = true;
                }
                if (cycleStatusList[index] == 0) {
                    if (!stopGenerate) {
                        dispatch(setAlertInfo({
                            showStatus: true,
                            title: "",
                            title1: `第${index + 1}条收费周期未选择`,
                            content: ""
                        }))
                    }
                    stopGenerate = true;
                }
                // if(jiaojuStatusList[index]==0){
                //     if (!stopGenerate) {
                //         dispatch(setAlertInfo({
                //             showStatus:true,
                //             title:"",
                //             title1:`第${index+1}条教具未选择`,
                //             content:""
                //         }))
                //     } 
                //     stopGenerate = true;
                // }
                if (jiaojuPriceList[index].length == 0) {
                    if (!stopGenerate) {
                        dispatch(setAlertInfo({
                            showStatus: true,
                            title: "",
                            title1: `第${index + 1}条教具价格未填写`,
                            content: ""
                        }))
                    }
                    stopGenerate = true;
                }
                if (!stopGenerate) {
                    shoufeixiangList.push({
                        "shoufeixiang_name": sfxNameList[index],
                        "kecheng_name": courseList[courseStatusList[index]].kecheng_name,
                        "kecheng_code": courseList[courseStatusList[index]].kecheng_code,
                        "kecheng_jiage": coursePriceList[index],
                        "shoufei_zhouqi_name": cycleList[cycleStatusList[index]].zhouqi_name,
                        "jiaoju_name": jiaojuList[jiaojuStatusList[index]].jiaoju_name,
                        "jiaoju_code": jiaojuList[jiaojuStatusList[index]].jiaoju_code,
                        "jiaoju_jiage": jiaojuPriceList[index],
                        "total_jiage": totalPriceList[index]
                    })
                } else {

                }

            }
        })
        if (!stopGenerate) {
            if (shoufeixiangList.length == 0) {
                dispatch(setAlertInfo({
                    showStatus: true,
                    title: "",
                    title1: "请检查是否选择了收费项",
                    content: ''
                }))
                return;
            }
            if (batchName == "") {
                dispatch(setAlertInfo({
                    showStatus: true,
                    title: "",
                    title1: "请检查是否填写缴费申请名称",
                    content: ''
                }))
                return;
            }
            setConfirmInfo({
                showStatus: true,
                title: "",
                title1: "是否确认申请二维码?",
                content: "申请二维码之后，可在收费二维码查询下载页面，通过查看功能确认收费项",
                eventId: 2
            })
            setSfxList(shoufeixiangList)
        }

    }

    function checkMethod(index) {
        var temPdfList = []
        var tempCheckList = sfxCheckList.map((item, index1) => {
            if (index == index1) {
                return !item
            } else {
                return item
            }
        })
        setSfxCheckList(tempCheckList)
    }

    function tapCourseItem(index, index1) {//index是收费项索引，index1是课程弹框点击项的索引，下面的index2是courseStatusList元素的索引
        var tempCourseStatusList = courseStatusList.map((item, index2) => {
            if (index == index2) {
                return index1
            } else {
                return item
            }
        })
        setCourseStatusList(tempCourseStatusList)
        var tempCoursePriceList = coursePriceList;
        var tempTotlePriceList = totalPriceList;
        courseCyclePriceList.map((item, index3) => {
            if (courseList[index1].kecheng_name == "未选择"
                || cycleList[cycleStatusList[index]].zhouqi_name == "未选择") {
                tempCoursePriceList[index] = "0"
                var jiaojuPrice = jiaojuPriceList[index] == "" ? 0 : parseFloat(jiaojuPriceList[index])
                tempTotlePriceList[index] = jiaojuPrice;
            } else if (item.kecheng_code == courseList[index1].kecheng_code
                && item.zhouqi_name == cycleList[cycleStatusList[index]].zhouqi_name
            ) {
                tempCoursePriceList[index] = item.jiage;
                var jiaojuPrice = jiaojuPriceList[index] == "" ? 0 : parseFloat(jiaojuPriceList[index])
                tempTotlePriceList[index] = jiaojuPrice + parseFloat(item.jiage);
            }
        })
        setCoursePriceList(tempCoursePriceList)
        setTotalPriceList(tempTotlePriceList)
        if (index1 != 0 && courseList[index1].jiaoju_code != 0) {
            for (let i = 0; i < jiaojuList.length; i++) {
                if (courseList[index1].jiaoju_code == jiaojuList[i].jiaoju_code) {
                    tapJiaojuItem(index, i);
                    break
                }
            }
        } else {
            tapJiaojuItem(index, 0);
        }
        tapCycleItem(index, 0)

    }

    function tapCycleItem(index, index1) {//index是收费项索引，index1是周期弹框点击项的索引，下面的index2是cycleStatusList元素的索引
        var tempCycleStatusList = cycleStatusList.map((item, index2) => {
            if (index == index2) {
                return index1
            } else {
                return item
            }
        })
        setCycleStatusList(tempCycleStatusList)

        var tempCoursePriceList = coursePriceList;
        var tempTotlePriceList = totalPriceList;
        courseCyclePriceList.map((item, index3) => {
            if (courseList[courseStatusList[index]].kecheng_name == "未选择"
                || cycleList[index1].zhouqi_name == "未选择") {
                tempCoursePriceList[index] = "0"
                var jiaojuPrice = jiaojuPriceList[index] == "" ? 0 : parseFloat(jiaojuPriceList[index])
                tempTotlePriceList[index] = jiaojuPrice;
            } else if (item.kecheng_code == courseList[courseStatusList[index]].kecheng_code
                && item.zhouqi_name == cycleList[index1].zhouqi_name
            ) {
                tempCoursePriceList[index] = item.jiage;
                var jiaojuPrice = jiaojuPriceList[index] == "" ? 0 : parseFloat(jiaojuPriceList[index])
                tempTotlePriceList[index] = jiaojuPrice + parseFloat(item.jiage);
            }
        })
        setCoursePriceList(tempCoursePriceList)
        setTotalPriceList(tempTotlePriceList)
    }

    function tapJiaojuItem(index, index1) {//index是收费项索引，index1是教具弹框点击项的索引，下面的index2是jiaojuStatusList元素的索引
        console.log('-----jiaojutapJiaojuItem')
        var tempJiaojuStatusList = jiaojuStatusList.map((item, index2) => {
            if (index == index2) {
                return index1
            } else {
                return item
            }
        })
        setJiaojuStatusList(tempJiaojuStatusList)

        var newJiaojuPriceList = [];
        jiaojuPriceList.map((item, index2) => {
            if (index == index2) {
                var price = jiaojuList[index1].jiaoju_jiage;
                newJiaojuPriceList.push(price)
            } else {
                newJiaojuPriceList.push(item)
            }
        })
        setJiaojuPriceList(newJiaojuPriceList)

        var tempTotlePriceList = totalPriceList.map((item, index2) => {
            if (index == index2) {
                var price = jiaojuList[index1].jiaoju_jiage;
                var totalPrice = parseFloat(price) + parseFloat(coursePriceList[index]);
                return totalPrice;
            } else {
                return item
            }
        })
        setTotalPriceList(tempTotlePriceList)
    }

    function showPdfView() {
        setSfxList([])
        var shoufeixiangList = [];
        var stopGenerate = false;
        sfxCheckList.map((item, index) => {
            if (item) {
                if (sfxNameList[index].length == 0) {
                    if (!stopGenerate) {
                        dispatch(setAlertInfo({
                            showStatus: true,
                            title: "",
                            title1: `第${index + 1}条收费项名称为空`,
                            content: ""
                        }))
                    }
                    stopGenerate = true;
                }
                if (courseStatusList[index] == 0) {
                    if (!stopGenerate) {
                        dispatch(setAlertInfo({
                            showStatus: true,
                            title: "",
                            title1: `第${index + 1}条课程未选择`,
                            content: ""
                        }))
                    }
                    stopGenerate = true;
                }
                if (cycleStatusList[index] == 0) {
                    if (!stopGenerate) {
                        dispatch(setAlertInfo({
                            showStatus: true,
                            title: "",
                            title1: `第${index + 1}条收费周期未选择`,
                            content: ""
                        }))
                    }
                    stopGenerate = true;
                }
                // if(jiaojuStatusList[index]==0){
                //     if (!stopGenerate) {
                //         dispatch(setAlertInfo({
                //             showStatus:true,
                //             title:"",
                //             title1:`第${index+1}条教具未选择`,
                //             content:""
                //         }))
                //     }
                //     stopGenerate = true;
                // }
                if (jiaojuPriceList[index].length == 0) {
                    if (!stopGenerate) {
                        dispatch(setAlertInfo({
                            showStatus: true,
                            title: "",
                            title1: `第${index + 1}条教具价格未填写`,
                            content: ""
                        }))
                    }
                    stopGenerate = true;
                }
                if (!stopGenerate) {
                    shoufeixiangList.push({
                        "shoufeixiang_name": sfxNameList[index],
                        "kecheng_name": courseList[courseStatusList[index]].kecheng_name,
                        "kecheng_code": courseList[courseStatusList[index]].kecheng_code,
                        "kecheng_jiage": coursePriceList[index],
                        "shoufei_zhouqi_name": cycleList[cycleStatusList[index]].zhouqi_name,
                        "jiaoju_name": jiaojuList[jiaojuStatusList[index]].jiaoju_name,
                        "jiaoju_code": jiaojuList[jiaojuStatusList[index]].jiaoju_code,
                        "jiaoju_jiage": jiaojuPriceList[index],
                        "total_jiage": totalPriceList[index]
                    })
                } else {

                }

            }
        })
        if (!stopGenerate) {
            if (shoufeixiangList.length == 0) {
                dispatch(setAlertInfo({
                    showStatus: true,
                    title: "",
                    title1: '请检查是否选择了收费项',
                    content: ""
                }))
                return;
            }
            if (batchName == "") {
                dispatch(setAlertInfo({
                    showStatus: true,
                    title: "",
                    title1: '请检查是否填写缴费申请名称',
                    content: ""
                }))
                return;
            }
            var temPdfList = []
            sfxCheckList.map((item, index) => {
                if (item == 1) {
                    temPdfList.push({
                        "sfxName": sfxNameList[index],
                        "courseName": courseList[courseStatusList[index]].kecheng_name,
                        "cycleName": cycleList[cycleStatusList[index]].zhouqi_name,
                        "coursePrice": coursePriceList[index],
                        "jiaojuName": jiaojuList[jiaojuStatusList[index]].jiaoju_name,
                        "jiaojuPrice": jiaojuPriceList[index],
                        "totalPrice": totalPriceList[index]
                    })
                }
            })
            setPdfInfoList(temPdfList)
            setPdfViewStatus(true)
        }
    }

    function exportInfoMethod(type) {
        if (type == 0) {
            setPdfViewStatus(false)
        } else {
            var scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
            var scrollBarHeight = window.innerHeight - document.documentElement.clientHeight;
            // // exportComponentAsPDF(componentRef,{"fileName":"收费二维码",pdfOptions:{x:0,y:0,w:900,unit:"px",orientation:"p",pdfFormat:"a3"}})
            exportComponentAsPNG(componentRef, {
                "fileName": "收费二维码",
                html2CanvasOptions: {scrollX: -scrollBarWidth / 2, scrollY: -scrollBarHeight / 2}
            })
            setPdfViewStatus(false)
        }
    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
            setBatchName("")
            setSfxCheckList([false, false, false, false, false, false, false])
            setCourseStatusList([0, 0, 0, 0, 0, 0, 0])
            setCoursePriceList([0, 0, 0, 0, 0, 0, 0])
            setJiaojuStatusList([0, 0, 0, 0, 0, 0, 0])
            setCycleStatusList([0, 0, 0, 0, 0, 0, 0])
            setSfxNameList(["", "", "", "", "", "", ""])
            setJiaojuPriceList([0, 0, 0, 0, 0, 0, 0])
            setTotalPriceList([0, 0, 0, 0, 0, 0, 0])
        } else if (confirmInfo.eventId == 2) {
            setQRRequest(schoolList[schoolIndex].school_code, schoolList[schoolIndex].school_name, batchName, JSON.stringify(sfxList), (data) => {
                if (data.ret == 1) {
                    dispatch(setMenuIndex(2))
                } else if (data.ret == 99) {
                    messageApi.open({
                        type: 'warning',
                        content: data.msg
                    });
                    setTimeout((e) => {
                        sessionStorage.clear()
                        window.location.reload()
                    }, 1000)
                } else {
                    dispatch(setAlertInfo({
                        showStatus: true,
                        title: "",
                        title1: "申请失败",
                        content: ""
                    }))
                }

            })
        }

    }

    function refreshMethod() {
        // alert("nihao")
        // dispatch(setCurrentPage(1))
        // getInfoList(1,pageNumber)
        setBatchName("")
        setSfxCheckList([false, false, false, false, false, false, false])
        setCourseStatusList([0, 0, 0, 0, 0, 0, 0])
        setJiaojuStatusList([0, 0, 0, 0, 0, 0, 0])
        setCycleStatusList([0, 0, 0, 0, 0, 0, 0])
        setSfxNameList(["", "", "", "", "", "", ""])
        setJiaojuPriceList([0, 0, 0, 0, 0, 0, 0])
        setTotalPriceList([0, 0, 0, 0, 0, 0, 0])
        getCourseList()
    }

    return (
        <div className={styles.background}>
            {contextHolder}
            <CustomConfirmAlert
                info={confirmInfo}
                cancelMethod={cancelMethod}
                confirmMethod={confirmMethod}
            />
            {showSchoolList || showCourseIndex != -1 || showCycleIndex != -1 || showJiaojuIndex != -1 ?
                <div className={styles.hideListCover}
                     onClick={(e) => {
                         setShowSchoolList(false)
                         setShowCourseIndex(-1)
                         setShowCycleIndex(-1)
                         setShowJiaojuIndex(-1)
                     }}
                ></div> : null}
            {pdfViewStatus ?
                <div className={styles.exportView}>
                    <PdfExport
                        ref={componentRef}
                        tableName={batchName}
                        infoList={pdfInfoList}
                    />
                    <div className={styles.exportButtons}>
                        <div
                            className={styles.exportButton}
                            onClick={exportInfoMethod.bind(null, 0)}
                        >关闭
                        </div>
                        <div
                            className={styles.exportButton}
                            onClick={exportInfoMethod.bind(null, 1)}
                        >导出
                        </div>
                    </div>

                </div>
                : null}
            <TopControl
                leftTopTitle={"课程报名配置"}
                showRefreshButton={true}
                refreshMethod={refreshMethod}
            />
            <div className={styles.content}>
                <div className={styles.contentTopBar}>
                    {/* <span className={styles.topBarLeftTitle}>配置课程收费信息并生成报名缴费二维码</span> */}
                    <div className={styles.topBarSchoolContainer}>
                        <span className={styles.topBarShoolTitle}>选择缴费学校</span>
                        <select className={styles.dropDown}
                                onChange={(e) => {
                                    tapSchoolListItem(e.target.value)
                                }}
                                value={schoolIndex}
                        >
                            {schoolList.map((item, index) => {
                                return <option value={index}>{item.school_name}</option>
                            })}
                        </select>
                        <span className={styles.topBarBatchTitle}>缴费申请名称</span>
                        <input
                            className={styles.topBarBatchInput}
                            placeholder="填写内部用于识别的名称"
                            value={batchName}
                            onChange={(e) => {
                                setBatchName(e.currentTarget.value)
                            }}
                        />
                    </div>
                    <p className={styles.topBarRightContainer}>
                        {/* <span className={styles.topBarRightIcon}>*</span>
                        <span>为必填项</span> */}
                        <div className={styles.rightButton1} onClick={showPdfView}>
                            <FontAwesomeIcon icon={faUpload} className={styles.rightButtonIcon}/>
                            导出
                        </div>
                    </p>
                </div>
                <div className={styles.sfxContent}>
                    <div className={styles.sfx_title}>
                        <div className={styles.sfx_title1}>收费项名称</div>
                        <div className={styles.sfx_title2}>课程名称</div>
                        <div className={styles.sfx_title3}>收费周期</div>
                        <div className={styles.sfx_title4}>课程价格(元)</div>
                        <div className={styles.sfx_title5}>课程教具</div>
                        <div className={styles.sfx_title6}>教具价格(元)</div>
                        <div className={styles.sfx_title7}>收费项总额(元)</div>
                    </div>
                    {courseList.length > 0 ?
                        sfxCheckList.map((item, index, arr) => {
                            return <div className={styles.sfxItem}
                                        key={index}
                            >
                                {/* <img 
                                    className={styles.sfxCheckImg}
                                    src={sfxCheckList[index]?checkBoxSelected:checkBox}
                                    onClick={checkMethod.bind(null,index)}
                                    ></img> */}
                                <Checkbox className={styles.sfxCheckImg} defaultChecked={false}
                                          checked={sfxCheckList[index]} onClick={checkMethod.bind(null, index)}/>
                                <input
                                    className={styles.sfxNameInput}
                                    value={sfxNameList[index]}
                                    onChange={(e) => {
                                        var newSfxNameList = [];
                                        sfxNameList.map((item, index1) => {
                                            if (index == index1) {
                                                newSfxNameList.push(e.target.value)
                                            } else {
                                                newSfxNameList.push(item)
                                            }
                                        })
                                        setSfxNameList(newSfxNameList)
                                    }}
                                />
                                <div
                                    className={classNames(styles.sfx_title2, styles.sfxCourseNameContainer)}
                                    onClick={() => setShowCourseIndex(showCourseIndex == index ? -1 : index)}
                                >
                                    <div
                                        className={styles.sfxCourseName}>{courseList[courseStatusList[index]].kecheng_name}</div>
                                    <FontAwesomeIcon icon={faAngleDown} className={styles.sfxCourseImg}/>
                                    {showCourseIndex == index ? <div
                                        className={classNames(styles.tankangContainer, styles.sfxCourseListPosition)}>
                                        {courseList.map((item, index1, arr) => {
                                            return <div className={styles.tankuangItem}
                                                        key={index1}
                                                        onClick={tapCourseItem.bind(null, index, index1)}
                                            >{item.kecheng_name}</div>
                                        })}
                                    </div> : null}
                                </div>
                                <div
                                    className={classNames(styles.sfx_title3, styles.sfxCycleContainer)}
                                    onClick={() => setShowCycleIndex(showCycleIndex == index ? -1 : index)}
                                >
                                    <div
                                        className={styles.sfxCycleName}>{cycleList[cycleStatusList[index]].zhouqi_name}</div>
                                    <FontAwesomeIcon icon={faAngleDown} className={styles.sfxCourseImg}/>
                                    {showCycleIndex == index ? <div
                                        className={classNames(styles.tankangContainer, styles.sfxCycleListPosition)}>
                                        {/*NPCSOTH01为教具收费专享课程，只有前两个周期选项*/}
                                        {courseList[courseStatusList[index]].kecheng_code == "NPCSOTH01" ? cycleList.map((item, index1, arr) => {
                                            if (index1 < 3) {
                                                return <div className={styles.tankuangItem}
                                                            key={index1}
                                                            onClick={tapCycleItem.bind(null, index, index1)}
                                                >{item.zhouqi_name}</div>
                                            } else {
                                                return null
                                            }

                                        }) : cycleList.map((item, index1, arr) => {
                                            return <div className={styles.tankuangItem}
                                                        key={index1}
                                                        onClick={tapCycleItem.bind(null, index, index1)}
                                            >{item.zhouqi_name}</div>
                                        })}
                                    </div> : null}
                                </div>
                                <div
                                    className={classNames(styles.sfx_title4, styles.sfxCoursePriceContainer)}
                                >
                                    <div className={styles.sfxCoursePrice}>{coursePriceList[index]}</div>
                                </div>
                                <div
                                    className={classNames(styles.sfx_title5, styles.sfxJiaoJuNameContainer)}
                                    onClick={() => {
                                        if (courseList[courseStatusList[index]].jiaoju_code == "0") {
                                            return
                                        } else {
                                            setShowJiaojuIndex(showJiaojuIndex == index ? -1 : index)
                                        }
                                    }}
                                >
                                    <div
                                        className={styles.sfxJiaoJuName}>{jiaojuList[jiaojuStatusList[index]].jiaoju_name}</div>
                                    <FontAwesomeIcon icon={faAngleDown} className={styles.sfxCourseImg}/>
                                    {showJiaojuIndex == index ?
                                        <div
                                            className={classNames(styles.tankangContainer, styles.sfxJiaojuListPosition)}>
                                            {jiaojuList.map((item, index1, arr) => {

                                                return <div className={styles.tankuangItem}
                                                            key={index1}
                                                            onClick={tapJiaojuItem.bind(null, index, index1)}
                                                >{item.jiaoju_name}</div>
                                            })}
                                        </div>
                                        : null}
                                </div>
                                <input
                                    className={classNames(styles.sfx_title6, styles.sfxJiaoJuPriceName)}
                                    value={jiaojuPriceList[index]}
                                    type="number"
                                    onChange={(e) => {
                                        var newJiaojuPriceList = [];
                                        jiaojuPriceList.map((item, index1) => {
                                            if (index == index1) {
                                                var price = e.target.value;
                                                var dotIndex = price.indexOf('.');
                                                if (dotIndex != -1) {
                                                    price = price.substring(0, dotIndex + 3); // Limit to two decimal places
                                                }
                                                newJiaojuPriceList.push(price)
                                            } else {
                                                newJiaojuPriceList.push(item)
                                            }
                                        })
                                        setJiaojuPriceList(newJiaojuPriceList)
                                        var tempTotlePriceList = totalPriceList.map((item, index1) => {
                                            if (index == index1) {
                                                var price = e.target.value;
                                                var dotIndex = price.indexOf('.');
                                                if (dotIndex != -1) {
                                                    price = price.substring(0, dotIndex + 3); // Limit to two decimal places
                                                }
                                                var jiaojuPrice = price == "" ? 0 : parseFloat(price);
                                                var totalPrice = jiaojuPrice + parseFloat(coursePriceList[index]);
                                                return totalPrice;
                                            } else {
                                                return item;
                                            }
                                        })
                                        setTotalPriceList(tempTotlePriceList)
                                    }}
                                >
                                </input>
                                <div
                                    className={classNames(styles.sfx_title7, styles.sfxTotalAmountContainer)}
                                >
                                    <div className={styles.sfxTotalAmount}>{totalPriceList[index]}</div>
                                </div>
                            </div>
                        })
                        : null
                    }
                </div>

                {/* <div className={styles.middleContent}>
                    <div className={styles.courseList}>
                    {courseList.map((item,index,arr)=>{
                       return <div className={styles.courseItem}
                        key={index}
                        >
                            <div className={styles.courseName}>
                                {item.kecheng_name}
                                <FontAwesomeIcon icon={faAngleDown} className={styles.rightButtonIcon}/>
                            </div>
                            <span className={styles.coursePriceTitle}>课程价格</span>
                            <div className={styles.coursePrice}>{item.jiage}</div>
                        </div>   
                    })}
                        
                        
                    </div>
                    <QRCodeImage
                        QrCodeValue = {QrCodeValue}
                        ref={componentRef}
                    />
                </div> */}
                <div className={styles.bottomWarning}>注意：家长手机端报名仅可见“收费项名称”及“收费项总额”</div>
                <div className={styles.bottomControl}>
                    <div
                        className={classNames(styles.clearButton, styles.bottomButton)}
                        onClick={clearMethod}
                    >清除
                    </div>
                    <div
                        className={classNames(styles.getQrCodeButton, styles.bottomButton)}
                        onClick={generateQRCode}
                    >申请支付二维码
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};
