import {
    createAsyncThunk,
    createSlice
  } from '@reduxjs/toolkit';
  // import { fetchCount } from './counterAPI';
  
  const initialState = {
    schoolIndex:0,
    schoolList: [{
      school_name:"未选择",
      school_code:""
    }],
    showXSFBPage:false,//显示选生分班界面
    allPaymentList:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
    allPaymentChecked:false,
  };
  
  export const ZXYYSubmitCourseSlice = createSlice({
    name: 'ZXYYSubmitCourse',
    initialState,
    reducers: {
      setSchoolIndex:(state, action) => {
        state.schoolIndex = action.payload;
      },
      setSchoolList: (state, action) => {
        state.schoolList = action.payload;
      },
      setShowXSFBPage:(state, action) => {
        console.log("actio")
        console.log(action.payload)
        state.showXSFBPage = action.payload;
      },
      setAllPaymentList:(state, action) => {
        state.allPaymentList = action.payload;
      },
      setAllPaymentChecked:(state, action) => {
        state.allPaymentChecked = action.payload;
      },
      
    },
  });
  export const selectSchoolIndex = (state) => state.ZXYYSubmitCourse.schoolIndex;
  export const selectSchoolList = (state) => state.ZXYYSubmitCourse.schoolList;
  export const selectShowXSFBPage = (state) => state.ZXYYSubmitCourse.showXSFBPage;
  export const selectAllPaymentList = (state) => state.ZXYYSubmitCourse.allPaymentList;
  export const selectAllPaymentChecked = (state) => state.ZXYYSubmitCourse.allPaymentChecked;
  
  
  
  
  export const {
    setSchoolIndex,
    setSchoolList,
    setShowXSFBPage,
    setAllPaymentList,
    setAllPaymentChecked,
  } = ZXYYSubmitCourseSlice.actions;
  
  export default ZXYYSubmitCourseSlice.reducer;