
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const initialState = {
    token: "",
    userName:'',
    userNameLogin:''
};

export const loginSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        setToken: (state,action) => {
            state.token = action.payload;
        },
        setUserName: (state,action) => {
            state.userName = action.payload;
        },
        setUserNameLogin: (state,action) => {
            state.userNameLogin = action.payload;
        }
    },
});

export const selectToken = (state) => state.login.token;
export const selectUserName = (state) => state.login.userName;
export const selectUserNameLogin = (state) => state.login.userNameLogin;
export const {
    setToken,
    setUserName,
    setUserNameLogin
} = loginSlice.actions;

export default loginSlice.reducer;
