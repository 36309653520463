import React, {useRef, useState, useEffect, useImperativeHandle, forwardRef} from "react";
import {useSelector, useDispatch} from 'react-redux';
import styles from "./ConditionalQuery.module.css";
import classNames from "classnames"
// import closeIcon from "./close1.png"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faSearch} from '@fortawesome/free-solid-svg-icons';
import {getInfoListRequest, getRegionListRequest, getSchools} from './PaymentQueryApi'
import {
    selectNameKey,
    selectPageNumber,
    selectProvinceList,
    selectReviewStatus,
    selectSchoolIndex,
    selectSchoolList,
    setCurrentPage,
    setInfoCheckedAll,
    setInfoList,
    setNameKey,
    setPages,
    setProvinceList,
    setReviewStatus,
    setSchoolIndex,
    setSchoolList,
    setTotalPageNum
} from "./PaymentQuerySlice";
import {message} from 'antd'
// export default function ConditionalQuery(props) {
const ConditionalQuery = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const [reviewIndex, setReviewIndex] = useState(0);
    const [showReviewList, setShowReviewList] = useState(false);
    const [reviewStatusList, setReviewStatusList] = useState([{
        name: '待审核',
        code: '0'
    }, {
        name: '已审核',
        code: "1"
    }, {
        name: '全部',
        code: ""
    }]);
    const reviewStatus = useSelector(selectReviewStatus);
    const provinceList = useSelector(selectProvinceList);
    const [provinceIndex, setProvinceIndex] = useState(0);
    const [localNameKey, setLocalNameKey] = useState("");
    const schoolIndex = useSelector(selectSchoolIndex);
    const schoolList = useSelector(selectSchoolList);
    const nameKey = useSelector(selectNameKey);
    const pageNumber = useSelector(selectPageNumber);
    const [messageApi, contextHolder] = message.useMessage();
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        getProvinceList()
        dispatch(setInfoList([]))
        dispatch(setInfoCheckedAll(false))
        dispatch(setSchoolIndex(0))
        dispatch(setSchoolList([{
            school_name: "未选择",
            school_code: ""
        }]))
        setReviewIndex(0)
        getInfoList(0, nameKey)
        // getSchoolsList()
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    useImperativeHandle(ref, () => ({
        refresh() {
            // if (schoolIndex!=0){
            getInfoList(schoolIndex,nameKey)
            // }
        }
    }))

    function getProvinceList() {
        getRegionListRequest("1", "", (data) => {
            var newProvinceList = [{
                quyu_name: "未选择",
                quyu_code: ""
            }];
            if (data.ret == 1) {
                data.data.forEach(element => {
                    newProvinceList.push(element)
                });
                dispatch(setProvinceList(newProvinceList))
            } else if (data.ret == 99) {
                messageApi.open({
                    type: 'warning',
                    // content: data.msg,
                    content: data.msg
                });
                setTimeout((e) => {
                    sessionStorage.clear()
                    window.location.reload()
                }, 1000)
            }
        })
    }

    function tapRegionListItem(index) {
        setProvinceIndex(index)
        getSchoolsList(provinceList[index].quyu_code)
        dispatch(setSchoolIndex(0))
    }

    function getSchoolsList(regionCode) {
        getSchools(regionCode, (data) => {
            var newSchoolList = [{
                school_name: "未选择",
                school_code: ""
            }];
            if (data.ret == 1) {
                data.data.forEach(element => {
                    newSchoolList.push(element)
                });
            } else if (data.ret == 99) {
                messageApi.open({
                    type: 'warning',
                    // content: data.msg,
                    content: data.msg
                });
                setTimeout((e) => {
                    sessionStorage.clear()
                    window.location.reload()
                }, 1000)
            }
            dispatch(setSchoolList(newSchoolList))
        })
    }

    function tapSchoolListItem(index) {
        dispatch(setSchoolIndex(index))
    }

    function getInfoList(schoolIndex,namekey) {
        getInfoListRequest(schoolList[schoolIndex].school_code, namekey, 0, pageNumber, (data) => {
            if (data.ret == 1) {
                var tempList = [];
                data.data.pay_list.map((item) => {
                    item.checked = false
                    tempList.push(item)
                })
                dispatch(setInfoList(tempList))
                dispatch(setInfoCheckedAll(false))
                var pageNumber1 = Math.ceil(data.data.total_num / pageNumber)
                dispatch(setTotalPageNum(pageNumber1))
                dispatch(setCurrentPage(1))
            } else if (data.ret == 99) {
                messageApi.open({
                    type: 'warning',
                    // content: data.msg,
                    content: data.msg
                });
                setTimeout((e) => {
                    sessionStorage.clear()
                    window.location.reload()
                }, 1000)
            } else {
                dispatch(setInfoList([]))
                dispatch(setInfoCheckedAll(false))
                dispatch(setTotalPageNum(1))
                dispatch(setCurrentPage(1))
            }
        })
    }

    return <div className={styles.background}>
        {contextHolder}
        <div className={styles.titleContainer}>
            <span className={styles.leftTitle}>数据筛选</span>
            {/* <span className={styles.rightTitle}>高级搜索</span> */}
        </div>
        <div className={styles.conditionalContainer}>
            <span className={styles.conditionalTitle}>选择区域：</span>
            <select className={styles.dropDown}
                    onChange={(e) => {
                        tapRegionListItem(e.target.value)
                    }}
                    value={provinceIndex}
            >
                {provinceList.map((item, index) => {
                    return <option value={index}>{item.quyu_name}</option>
                })}
            </select>

            <span className={styles.conditionalTitle}>选择学校：</span>
            <select className={styles.dropDown}
                    onChange={(e) => {
                        tapSchoolListItem(e.target.value)
                    }}
                    value={schoolIndex}
            >
                {schoolList.map((item, index) => {
                    return <option value={index}>{item.school_name}</option>
                })}
            </select>
            <span className={styles.conditionalTitle}>关键字：</span>
            <input
                className={styles.topBarNameKey}
                placeholder="关键字：学校、学生、家长、手机号"
                onChange={(e) => {
                    setLocalNameKey(e.target.value)
                }}
            />

            <div className={styles.selectButton}
                // onClick={props.searchMethod}
                 onClick={(e) => {
                     dispatch(setNameKey(localNameKey))
                     getInfoList(schoolIndex, localNameKey)
                 }}
            >
                <FontAwesomeIcon icon={faSearch} className={styles.selectButtonIcon}/>
                查询
            </div>
            {/* <div className={styles.resetButton}>
                <FontAwesomeIcon icon={faReply} className={styles.selectButtonIcon}/>
                重置
            </div> */}
        </div>

    </div>
})
export default ConditionalQuery;