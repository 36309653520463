
import React ,{ useRef,useState,useEffect } from "react";
import classNames from "classnames"
import { useSelector, useDispatch } from 'react-redux';
import styles from "./InfoList.module.css";
// import closeIcon from "./close1.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload} from '@fortawesome/free-solid-svg-icons';
import InfoItem from "./InfoItem";
import ExcelUtil from "../../../util/excelUtilNew";
import {
    selectInfoCheckedAll,
    selectInfoList,
    selectPageNumber,
    selectReviewStatus,
    selectSchoolIndex,
    selectSchoolList,
    setCurrentPage,
    setInfoCheckedAll,
    setInfoList,
    setReviewStatus,
    setTotalPageNum,
    selectCurrentPage,
    selectNameKey
} from "./PaymentQuerySlice";
import { Checkbox } from 'antd';
import { getInfoListRequest, shenhePayment } from "./PaymentQueryApi";
import { setAlertInfo } from "../../../store/CustomViewSlice";
import { CustomConfirmAlert } from "../../CustomView/CustomConfirmAlert";
import {message} from 'antd'
import {getMoneyDate} from "../../../util/currencyUnit";
export default function InfoList(props) {
    const dispatch = useDispatch();
    const infoList = useSelector(selectInfoList);
    const infoCheckedAll = useSelector(selectInfoCheckedAll);
    const schoolIndex = useSelector(selectSchoolIndex);
    const schoolList = useSelector(selectSchoolList);
    const nameKey = useSelector(selectNameKey);
    const pageNumber = useSelector(selectPageNumber);
    const currentPage = useSelector(selectCurrentPage)
    const reviewStatus = useSelector(selectReviewStatus);
    const [confirmInfo,setConfirmInfo]=useState({})
    const [messageApi, contextHolder] = message.useMessage();
    function exportExcelMethod(){
        if (infoList.length==0) {
            dispatch(setAlertInfo({
                showStatus:true,
                title:"",
                title1:"暂无内容导出",
                content:""
            }))
            return;
        }
        let attendanceInfoList = [];
        infoList.map((item,index)=>{
            attendanceInfoList.push({
                '缴费时间':item.jiaofei_time,
                '学年度':item.shoufei_zhouqi_code,
                '学期':item.shoufei_zhouqi_name,
                '省级区域':item.school_shengji_name == null?"":item.school_shengji_name,
                // shijqy:item.school_shiji_name == null?"":item.school_shiji_name,
                '区县区域':item.school_quxian_name==null?"":item.school_quxian_name,
                '学校名称':item.school_name,
                '年级':item.grade_name,
                '班级':item.class_name,
                '学生姓名':item.name,
                '性别':item.xingbie==1?'男':'女',
                '生日':item.shengri==null?"":item.shengri,
                '缴款课程': item.kecheng_name == null ? "" : item.kecheng_name,
                '课程费用': getMoneyDate("yuan",item.kecheng_jiage),
                '教具名称': item.jiaoju_name == null ? "" : item.jiaoju_name,
                '教具费用':getMoneyDate("yuan",item.jiaoju_jiage),
                '支付渠道': item.pay_type == 1 ? "微信" : item.pay_type == 2 ? "支付宝" : "第三方",
                '家长姓名':item.jiazhang_name,
                '家长电话':item.jiazhang_phone,
                '退费':item.tuifei_status == 1?"有退费记录":""

            })
        })
        ExcelUtil.exportExcel(attendanceInfoList,`学校开课到款.xlsx`,
            [   
                { wpx: 150 },
                { wpx: 90 },
                { wpx: 80 }, 
                { wpx: 116 }, 
                { wpx: 116 }, 
                { wpx: 110 }, 
                { wpx: 70 }, 
                { wpx: 60 },
                { wpx: 100 },
                { wpx: 66 }, 
                { wpx: 200 }, 
                { wpx: 90 }, 
                { wpx: 200 }, 
                { wpx: 90 }, 
                { wpx: 88 },
                { wpx: 110 },
                { wpx: 110 }
            ]
        )
    }
    function onChange(e) {
        console.log(e.target.checked)
        if (e.target.checked) {
            let tempInfoList = []
            let checkedAll = true;
            infoList.forEach(element => {
                var newElement = {}
                for (let key in element) {
                    if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                        newElement[key] = element[key]
                    }
                }
                newElement.checked = true
                tempInfoList.push(newElement)
                if (newElement.checked==false) {
                    checkedAll = false
                }
            });
            dispatch(setInfoList(tempInfoList))
            dispatch(setInfoCheckedAll(checkedAll))
            
        } else {
            let tempInfoList = []
            infoList.forEach(element => {
                var newElement = {}
                for (let key in element) {
                    if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                        newElement[key] = element[key]
                    }
                }
                newElement.checked = false
                tempInfoList.push(newElement)
            });
            dispatch(setInfoList(tempInfoList))
            dispatch(setInfoCheckedAll(false))
        }
    }
    function getInfoList() {
        getInfoListRequest(schoolList[schoolIndex].school_code,nameKey,0,pageNumber,(data)=>{
            if (data.ret==1) {
                var tempList = [];
                data.data.pay_list.map((item,index)=>{
                    item.checked=false
                    tempList.push(item)
                })
                dispatch(setInfoList(tempList))
                dispatch(setInfoCheckedAll(false))
                var pageNumber1 = Math.ceil(data.data.total_num/pageNumber)
                dispatch(setTotalPageNum(pageNumber1))
                dispatch(setCurrentPage(1))
            }else if(data.ret==99){
                messageApi.open({
                    type: 'warning',
                    // content: data.msg,
                    content:data.msg
                });
                setTimeout((e)=>{
                    sessionStorage.clear()
                    window.location.reload()
                },1000)
            } else {
                dispatch(setInfoList([]))
                dispatch(setInfoCheckedAll(false))
                dispatch(setTotalPageNum(1))
                dispatch(setCurrentPage(1))
            }
        })
    }
    function acceptMethod() {
        setConfirmInfo({
            showStatus:true,
            title:"",
            title1:`是否批量审核选中的数据？`,
            content:"",
            eventId:1
        })
    }
    function cancelMethod(params) {
        setConfirmInfo({
            showStatus:false,
            title:"",
            title1:"",
            content:""
        })
    }
    function confirmMethod(params) {
        setConfirmInfo({
            showStatus:false,
            title:"",
            title1:"",
            content:""
        })
        if (confirmInfo.eventId==1) {
            var baomingList = []
            infoList.forEach(element => {
                if (element.checked) {
                    baomingList.push({
                        baoming_id:element.Id,
                    })
                }
            })
            console.log("baomingListbaomingListbaomingList")
            console.log(baomingList)
            shenhePayment(JSON.stringify(baomingList),"1",(data)=>{
                // if (data.ret==1) {
                    getInfoList()
                // }
                if(data.ret==2){
                    messageApi.open({
                        type: 'warning',
                        // content: data.msg,
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }
            })
        } else if(confirmInfo.eventId==2) {
           
            
        }
    }
    return <div className={styles.background}>
        {contextHolder}
        <CustomConfirmAlert
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        <div className={styles.titleContainer}>
            <span className={styles.leftTitle}>数据列表</span>
            <div className={styles.rightButtonContainer}>
                {/*<div className={styles.rightButton2} */}
                {/*    onClick={acceptMethod}*/}
                {/*>*/}
                {/*    /!* <FontAwesomeIcon icon={faAdd} className={styles.rightButtonIcon}/> *!/*/}
                {/*    确认审核*/}
                {/*</div>*/}
                <div className={styles.rightButton1} onClick={exportExcelMethod}>
                    <FontAwesomeIcon icon={faUpload} className={styles.rightButtonIcon}/>
                    导出
                </div>
                {/* <div className={styles.rightButton1}>
                    <FontAwesomeIcon icon={faDownload} className={styles.rightButtonIcon}/>
                    导入
                </div>
                <div className={styles.rightButton2}>
                    <FontAwesomeIcon icon={faAdd} className={styles.rightButtonIcon}/>
                    添加
                </div> */}
            </div>
        </div>
        <div className={styles.listContainer}>
            <table className={styles.infoTable}>
                <tr
                    className={styles.tr1}
                >
                    <td
                        className={styles.xuhaoTd}
                    >序号
                    </td>
                    <td
                        className={styles.td1}
                    >缴费时间
                    </td>
                    <td
                        className={styles.td1}
                    >学年度
                    </td>
                    <td
                        className={styles.td1}
                    >学期
                    </td>
                    <td
                        className={styles.td1}
                    >省级区域
                    </td>
                    {/*<td*/}
                    {/*    className={styles.td1}*/}
                    {/*>市级区域*/}
                    {/*</td>*/}
                    <td
                        className={styles.td1}
                    >区县区域
                    </td>
                    <td
                        className={styles.td1}
                    >学校名称
                    </td>
                    <td
                        className={styles.td1}
                    >年级
                    </td>
                    <td
                        className={styles.td1}
                    >班级
                    </td>
                    <td
                        className={styles.td1}
                    >学生姓名
                    </td>
                    <td
                        className={styles.td1}
                    >性别
                    </td>
                    <td
                        className={styles.td1}
                    >生日
                    </td>
                    <td
                        className={styles.td1}
                    >缴款课程
                    </td>
                    <td
                        className={styles.td1}
                    >课程费用
                    </td>
                    <td
                        className={styles.td1}
                    >教具名称
                    </td>
                    <td
                        className={styles.td1}
                    >教具费用
                    </td>
                    <td
                        className={styles.td1}
                    >支付渠道
                    </td>
                    <td
                        className={styles.td1}
                    >家长姓名
                    </td>
                    <td
                        className={styles.td1}
                    >家长电话
                    </td>
                    <td
                        className={styles.td1}
                    >退费
                    </td>
                    <td
                        className={styles.td1}
                    >操作
                    </td>
                </tr>
                {infoList.map((item, index) => (
                    <InfoItem
                        item={item}
                        key={(currentPage - 1) * pageNumber + index + 1}
                        index={(currentPage - 1) * pageNumber + index + 1}

                    />
                ))}
            </table>
            {/*<div className={styles.listTitleContainer}>*/}
            {/*    /!*<div className={classNames(styles.listTitle, styles.width1)}>*!/*/}
            {/*    /!*    <Checkbox defaultChecked={false} checked={infoCheckedAll} onChange={onChange}/>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    <div className={classNames(styles.listTitle, styles.paymentTimeWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>缴费时间</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.xndWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>学年度</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.xqWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>学期</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.sjqyWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>省级区域</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.qxqyWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>区县区域</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.schoolNameWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>学校名称</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.gradeWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>年级</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.classNameWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>班级</span>*/}
            {/*    </div>*/}

            {/*    <div className={classNames(styles.listTitle, styles.studentNameWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>学生姓名</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.sexTagWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>性别</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.courseNameWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>缴款课程</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.coursePriceWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>课程费用</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.jiaojuNameWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>教具名称</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.jiaojuPriceWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>教具费用</span>*/}
            {/*    </div>*/}

            {/*    <div className={classNames(styles.listTitle, styles.parentNameWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>家长姓名</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.parentPhoneWidth)}>*/}
            {/*        <span className={styles.listContentSpan}>家长电话</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.width18)}>*/}
            {/*        <span className={styles.listContentSpan}>退费</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.width19)}>*/}
            {/*        <span className={styles.listContentSpan}>操作</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*{infoList.map((item, index) => (*/}
            {/*    <InfoItem*/}
            {/*        item={item}*/}
            {/*        key={index}*/}

            {/*    />*/}
            {/*))}*/}
        </div>


    </div>
}

