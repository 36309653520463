// 提交开课申请,暂未对接接口
import React, {useRef, useState, useEffect} from "react";
import styles from "./ZXSchoolSetHX.module.css";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp, faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {message} from 'antd'

export function ZXSchoolSetHX(props) {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [HXInfoList,setHXInfoList] = useState([
        {
            name:"1 学校规模",
            code:"1",
            content:""
        },
        {
            name:"2 民办/公办",
            code:"2",
            content:""
        },
        {
            name:"3 学校历史",
            code:"3",
            content:""
        },
        {
            name:"4 办学特色",
            code:"4",
            content:""
        },
        {
            name:"5 课程实践",
            code:"5",
            content:""
        },
        {
            name:"6 目前痛点",
            code:"6",
            content:""
        },
        {
            name:"7 管理团队",
            code:"7",
            content:""
        },
        {
            name:"1 基本信息",
            code:"8",
            content:""
        },
        {
            name:"2 性格描述与个人喜好",
            code:"9",
            content:""
        },
        {
            name:"3 主要工作履历",
            code:"10",
            content:""
        },
        {
            name:"4 家庭背景与社会关系",
            code:"11",
            content:""
        },
        {
            name:"5 过往合作经历或预期接触方式",
            code:"12",
            content:""
        },
        {
            name:"6 核心诉求",
            code:"13",
            content:""
        },
        {
            name:"7 已经达成的共识",
            code:"14",
            content:""
        },
        {
            name:"8 目前存在的难点",
            code:"15",
            content:""
        },
        {
            name:"9 下次交流计划与思路",
            code:"16",
            content:""
        },
    ]);

    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        if(props.infoList.length>0){
            setHXInfoList(props.infoList);
        }

        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次


    return (
        <div className={styles.background}>
            {contextHolder}
            <div className={styles.content}>
                <div className={styles.topBar}>
                    <div className={styles.topBarLeft}>
                        <span className={styles.topBarTitle}>学校画像</span>
                        <div className={styles.topBarInfo}>
                            <span className={styles.schoolName}>{props.info.xuexiao_name}</span>
                            <span className={styles.studentNumber}>总学生数{props.info.xuesheng_num}人</span>
                        </div>
                    </div>
                    <div className={styles.topBarRight}>
                        <span className={styles.closeButton}
                            onClick={props.closeMethod}
                        >关闭</span>
                        {props.showUploadButton ? <span className={styles.uploadButton}
                                                        onClick={()=>{
                                                            let havaData = false
                                                            HXInfoList.map((item,index)=>{
                                                                if(item.content!==""){
                                                                    havaData = true;
                                                                }
                                                                return item;
                                                            })
                                                            if(!havaData){
                                                                messageApi.open({
                                                                    type: 'warning',
                                                                    content: "内容为空，无法提交"
                                                                });
                                                            }
                                                            else {
                                                                props.tijiaoMethod(HXInfoList)
                                                            }
                                                        }}
                        >提交</span>:null}

                    </div>
                </div>
                <div className={styles.bottomContent}>
                <div className={styles.line1Content}>
                        <span className={classNames(styles.lineTagView,styles.lineTagViewPositon1)}></span>
                        <span className={styles.line1Title}>学校基本信息</span>
                    </div>
                    <div className={styles.line2Content}>
                        <span
                            className={classNames(styles.lineTitle, styles.line2TitlePosition)}>1&nbsp;&nbsp;&nbsp;学校规模</span>
                        <input
                            className={styles.line2Input}
                            value={HXInfoList[0].content}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 0){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        />
                    </div>
                    <div className={styles.line2Content}>
                        <span
                            className={classNames(styles.lineTitle, styles.line3TitlePosition)}>2&nbsp;&nbsp;&nbsp;民办/公办</span>
                        <input
                            className={styles.line3Input}
                            value={HXInfoList[1].content}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 1){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        />
                    </div>
                    <div className={styles.textAreaContainer}>
                        <span
                            className={classNames(styles.lineTitle, styles.textAreaTitlePosition)}>3&nbsp;&nbsp;&nbsp;学校历史</span>
                        <textarea
                            className={classNames(styles.textarea)}
                            value={HXInfoList[2].content}
                            placeholder={'创立时间，历史沿革，当地口碑等'}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 2){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        ></textarea>
                    </div>
                    <div className={styles.textAreaContainer}>
                        <span
                            className={classNames(styles.lineTitle, styles.textAreaTitlePosition)}>4&nbsp;&nbsp;&nbsp;办学特色</span>
                        <textarea
                            className={classNames(styles.textarea)}
                            value={HXInfoList[3].content}
                            placeholder={'学校过去的特色办学方向，目前正在思考的特色办学方向'}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 3){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        ></textarea>
                    </div>
                    <div className={styles.textAreaContainer}>
                        <span
                            className={classNames(styles.lineTitle, styles.textAreaTitlePosition)}>5&nbsp;&nbsp;&nbsp;课程实践</span>
                        <textarea
                            className={classNames(styles.textarea)}
                            value={HXInfoList[4].content}
                            placeholder={'在科技、外教英语等诺奖计划相关课程领域，引入了哪些课程，口碑与反馈，和谁正在合作，是否有过向家长收费的实践'}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 4){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        ></textarea>
                    </div>
                    <div className={styles.textAreaContainer}>
                        <span
                            className={classNames(styles.lineTitle, styles.textAreaTitlePosition)}>6&nbsp;&nbsp;&nbsp;目前痛点</span>
                        <textarea
                            className={classNames(styles.textarea)}
                            value={HXInfoList[5].content}
                            placeholder={'学校目前的主要需求、挑战'}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 5){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        ></textarea>
                    </div>
                    <div className={styles.textAreaContainer}>
                        <span
                            className={classNames(styles.lineTitle, styles.textAreaTitlePosition)}>7&nbsp;&nbsp;&nbsp;管理团队</span>
                        <textarea
                            className={classNames(styles.textarea)}
                            value={HXInfoList[6].content}
                            placeholder={'学校管理团队成员信息介绍'}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 6){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        ></textarea>
                    </div>
                    <div className={styles.line3Content}>
                        <span className={classNames(styles.lineTagView,styles.lineTagViewPositon2)}></span>
                        <span className={styles.line1Title}>实控人相关个人信息</span>
                    </div>
                    <div className={styles.textAreaContainer}>
                        <span
                            className={classNames(styles.lineTitle, styles.textAreaTitlePosition)}>1&nbsp;&nbsp;&nbsp;基本信息</span>
                        <textarea
                            className={classNames(styles.textarea)}
                            value={HXInfoList[7].content}
                            placeholder={'姓名、职务、性别、年龄'}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 7){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        ></textarea>
                    </div>
                    <div className={styles.textAreaContainer}>
                        <span
                            className={classNames(styles.lineTitle, styles.textAreaTitlePosition)}>2&nbsp;&nbsp;&nbsp;性格描述与个人喜好</span>
                        <textarea
                            className={classNames(styles.textarea)}
                            value={HXInfoList[8].content}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 8){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        ></textarea>
                    </div>
                    <div className={styles.textAreaContainer}>
                        <span
                            className={classNames(styles.lineTitle, styles.textAreaTitlePosition)}>3&nbsp;&nbsp;&nbsp;主要工作履历</span>
                        <textarea
                            className={classNames(styles.textarea)}
                            value={HXInfoList[9].content}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 9){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        ></textarea>
                    </div>
                    <div className={styles.textAreaContainer}>
                        <span
                            className={classNames(styles.lineTitle, styles.textAreaTitlePosition)}>4&nbsp;&nbsp;&nbsp;家庭背景与社会关系</span>
                        <textarea
                            className={classNames(styles.textarea)}
                            value={HXInfoList[10].content}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 10){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        ></textarea>
                    </div>
                    <div className={styles.textAreaContainer}>
                        <span
                            className={classNames(styles.lineTitle, styles.textAreaTitlePosition)}>5&nbsp;&nbsp;&nbsp;过往合作经历或预期接触方式</span>
                        <textarea
                            className={classNames(styles.textarea)}
                            value={HXInfoList[11].content}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 11){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        ></textarea>
                    </div>
                    <div className={styles.textAreaContainer}>
                        <span
                            className={classNames(styles.lineTitle, styles.textAreaTitlePosition)}>6&nbsp;&nbsp;&nbsp;核心诉求</span>
                        <textarea
                            className={classNames(styles.textarea)}
                            value={HXInfoList[12].content}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 12){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        ></textarea>
                    </div>
                    <div className={styles.textAreaContainer}>
                        <span
                            className={classNames(styles.lineTitle, styles.textAreaTitlePosition)}>7&nbsp;&nbsp;&nbsp;已经达成的共识</span>
                        <textarea
                            className={classNames(styles.textarea)}
                            value={HXInfoList[13].content}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 13){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        ></textarea>
                    </div>
                    <div className={styles.textAreaContainer}>
                        <span
                            className={classNames(styles.lineTitle, styles.textAreaTitlePosition)}>8&nbsp;&nbsp;&nbsp;目前存在的难点</span>
                        <textarea
                            className={classNames(styles.textarea)}
                            value={HXInfoList[14].content}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 14){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        ></textarea>
                    </div>
                    <div className={styles.textAreaContainer}>
                        <span
                            className={classNames(styles.lineTitle, styles.textAreaTitlePosition)}>9&nbsp;&nbsp;&nbsp;下次交流计划与思路</span>
                        <textarea
                            className={classNames(styles.textarea)}
                            value={HXInfoList[15].content}
                            onChange={(e) => {
                                let tempList = HXInfoList.map((item,index)=>{
                                    if(index === 15){
                                        item.content = e.target.value;
                                    }
                                    return item;
                                })
                                setHXInfoList(tempList)
                            }}
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
    );
};
