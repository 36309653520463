import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
    schoolIndex: 0,
    schoolList: [{
        school_name: "未选择",
        school_code: ""
    }],
    infoList: [],
    QRContentStr: "",
    QRSchoolName: "",
    downloadStatus: true,
    showReviewPage: false,
    reviewInfo: {}
};

export const CWZGQRApprovalSlice = createSlice({
    name: 'CWZGQRApproval',
    initialState,
    reducers: {
        setSchoolIndex: (state, action) => {
            state.schoolIndex = action.payload;
        },
        setSchoolList: (state, action) => {
            state.schoolList = action.payload;
        },
        setInfoList: (state, action) => {
            state.infoList = action.payload;
        },
        setQRContentStr: (state, action) => {
            state.QRContentStr = action.payload;
        },
        setQRSchoolName: (state, action) => {
            state.QRSchoolName = action.payload;
        },
        setQRDownloadStatus: (state, action) => {
            state.downloadStatus = action.payload;
        },
        setShowReviewPage: (state, action) => {
            state.showReviewPage = action.payload;
        },
        setReviewInfo: (state, action) => {
            state.reviewInfo = action.payload;
        },

    },
});
export const selectSchoolIndex = (state) => state.CWZGQRApproval.schoolIndex;
export const selectSchoolList = (state) => state.CWZGQRApproval.schoolList;
export const selectInfoList = (state) => state.CWZGQRApproval.infoList;
export const selectQRContentStr = (state) => state.CWZGQRApproval.QRContentStr;
export const selectQRSchoolName = (state) => state.ZXYYQueryAndGetQR.QRSchoolName;

export const selectDownloadStatus = (state) => state.CWZGQRApproval.downloadStatus;
export const selectShowReviewPage = (state) => state.CWZGQRApproval.showReviewPage;
export const selectReviewInfo = (state) => state.CWZGQRApproval.reviewInfo;


export const {
    setSchoolIndex,
    setSchoolList,
    setInfoList,
    setQRContentStr,
    setQRDownloadStatus,
    setShowReviewPage,
    setReviewInfo,
    setQRSchoolName
} = CWZGQRApprovalSlice.actions;

export default CWZGQRApprovalSlice.reducer;