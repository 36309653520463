import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
  alertInfo:{
    showStatus:false,
    title:"",
    title1:"",
    content:""
  }
};

export const CustomViewSlice = createSlice({
  name: 'CustomView',
  initialState,
  reducers: {
    setAlertInfo: (state,action) => {
        state.alertInfo = action.payload;
    }
  },
});

export const selectAlertInfo = (state) => state.CustomView.alertInfo;


export const { 
    setAlertInfo,
} = CustomViewSlice.actions;

export default CustomViewSlice.reducer;
