import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
    xuezhiIndex:0,
    xuezhiList:[{
        xuezhi_name: "小学",
        xuezhi_code: "1"
    }, {
        xuezhi_name: "初中",
        xuezhi_code: "2"
    }, {
        xuezhi_name: "9年级",
        xuezhi_code: "3"
    }, {
        xuezhi_name: "12年制",
        xuezhi_code: "4"
    }, {
        xuezhi_name: "高中",
        xuezhi_code: "5"
    }],
    DYFXList:[
        {
            "name": "自费-全部课后服务课程",
            "number": 0,
            "feiyong": 0,
            "PJKeshi": 0,
            "dxqffgm": 0

        },
        {
            "name": "自费-科技类课后服务课程",
            "number": 0,
            "feiyong": 0,
            "PJKeshi": 0,
            "dxqffgm": 0

        },
        {
            "name": "自费-全部校本课程",
            "number": 0,
            "feiyong": 0,
            "PJKeshi": 0,
            "dxqffgm": 0

        },
        {
            "name": "自费-科技类校本课程",
            "number": 0,
            "feiyong": 0,
            "PJKeshi": 0,
            "dxqffgm": 0

        }
    ],
    LPDSchoolList:[],
    lieSchoolList: [],          //第一步列中的学校数组
    pingSchoolList: [],         //第二步评中的学校数组
    dingSchoolList: [],         //第三步定中的学校数组
    endStatus: false,            //第三步流程是否结束
};

export const ZXYYZXSchoolSlice = createSlice({
    name: 'ZXYYZXSchool',
    initialState,
    reducers: {
        setXuezhiIndex: (state, action) => {
            state.xuezhiIndex = action.payload;
        },
        setXuezhiList: (state, action) => {
            state.xuezhiList = action.payload;
        },
        setDYFXList:(state, action) => {
            state.DYFXList = action.payload;
        },
        setLPDSchoolList: (state, action) => {
            state.LPDSchoolList = action.payload;
        },
        setLieSchoolList: (state, action) => {
            state.lieSchoolList = action.payload;
        },
        setPingSchoolList: (state, action) => {
            state.pingSchoolList = action.payload;
        },
        setDingSchoolList: (state, action) => {
            state.dingSchoolList = action.payload;
        },
        setEndStatus: (state, action) => {
            state.endStatus = action.payload;
        },



    },
});
export const selectXuezhiIndex = (state) => state.ZXYYZXSchool.xuezhiIndex;
export const selectXuezhiList = (state) => state.ZXYYZXSchool.xuezhiList;
export const selectDYFXList = (state) => state.ZXYYZXSchool.DYFXList;
export const selectLPDSchoolList = (state) => state.ZXYYZXSchool.LPDSchoolList;
export const selectLieSchoolList = (state) => state.ZXYYZXSchool.lieSchoolList;
export const selectPingSchoolList = (state) => state.ZXYYZXSchool.pingSchoolList;
export const selectDingSchoolList = (state) => state.ZXYYZXSchool.dingSchoolList;
export const selectEndStatus = (state) => state.ZXYYZXSchool.endStatus;

export const {
    setXuezhiIndex,
    setDYFXList,
    setXuezhiList,
    setLPDSchoolList,
    setLieSchoolList,
    setPingSchoolList,
    setDingSchoolList,
    setEndStatus
} = ZXYYZXSchoolSlice.actions;

export default ZXYYZXSchoolSlice.reducer;