import React ,{ useRef,useState,useEffect }from "react";
import styles from "./QJSJYYMain.module.css";
import classNames from "classnames"
import { useSelector, useDispatch } from 'react-redux';
import logoImg from "../../../img/logo.png"
import { selectMenuIndex, setMenuIndex } from "./QJSJYYMainSlice";
import {QueryAndExport} from "../QueryAndExport/QueryAndExport";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPowerOff,faChartColumn} from '@fortawesome/free-solid-svg-icons';
import { openLogin,closeQJSJYYMain } from "../../../store/modalsSlice";
import { loginOutRequest } from "../../Login/loginApi";
export function QJSJYYMain() {
    const menuIndex = useSelector(selectMenuIndex);
    const dispatch = useDispatch();
    function leftMenuMethod(index){
        dispatch(setMenuIndex(index))
    }
    function signOutMethod() {
        loginOutRequest((data)=>{
        })
        sessionStorage.clear()
        dispatch(openLogin())
        dispatch(closeQJSJYYMain())
    }
    return (
        <div className={styles.background}>
            <div className={styles.topContainer}>
                <div className={styles.topLeftMenus}>
                    <img 
                        className={styles.logIcon}
                        src={logoImg}
                    ></img>
                    {/* <span className={styles.logoTitle}>全球胜任力研究所</span> */}
                    <span className={styles.logoTitle}>TBI教育集团</span>
                </div>
                <div className={styles.topRightMenus}>
                    <div className={styles.userName}>{sessionStorage.getItem("user_type_name")+"\u00A0\u00A0"+sessionStorage.getItem("name")}</div>
                    <div className={styles.signOutButton}
                        onClick={signOutMethod}
                    >
                        <FontAwesomeIcon icon={faPowerOff}/>
                    </div>
                </div>
                
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.leftContent}>
                    <div
                        className={styles.leftMenuDes}
                    >
                        {/* <FontAwesomeIcon icon={faBarChart}/> */}
                        <FontAwesomeIcon className={styles.leftMenuDesIcon} icon={faChartColumn}/>
                        全国运营情况总览
                    </div>
                    <div
                        className={classNames(styles.leftMenu, menuIndex == 0 ? styles.leftSelectedMenu : null)}
                        onClick={leftMenuMethod.bind(null, 0)}
                    >诺奖计划数据查询
                    </div>

                </div>
                <div className={styles.rightContent}>
                    {menuIndex == 0 ? <QueryAndExport/> : null}
                </div>
            </div>
        </div>
    );
};
