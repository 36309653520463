
import React from "react";
import classNames from "classnames"
import { useSelector, useDispatch } from 'react-redux';
import styles from "./InfoList.module.css";
import { Checkbox } from 'antd';
// import closeIcon from "./close1.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload,faDownload,faAdd} from '@fortawesome/free-solid-svg-icons';
import InfoItem from "./InfoItem";
import ExcelUtil from "../../../util/excelUtil";
import {selectInfoCheckedAll, selectInfoList,setInfoList,setInfoCheckedAll} from "./CourseApprovalSlice";
import { setAlertInfo } from "../../../store/CustomViewSlice";
export default function InfoList(props) {
    const dispatch = useDispatch();
    const infoList = useSelector(selectInfoList);
    const infoCheckedAll = useSelector(selectInfoCheckedAll);
    function exportExcelMethod(){
        alert('接口未对接')
        return;
        if (infoList.length==0) {
            dispatch(setAlertInfo({
                showStatus:true,
                title:"",
                title1:"暂无内容导出",
                content:""
            }))
            return;
        }
        const initColumn = [{
            title: '省级区域',
            dataIndex: 'provinceName',
            key: 'provinceName',
        },{
            title: '省级公司',
            dataIndex: 'companyName',
            key: 'companyName',
        },{
            title: '联系人',
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
        },{
            title: '联系电话',
            dataIndex: 'contactPhone',
            key: 'contactPhone',
        },{
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },{
            title: '状态',
            dataIndex: 'status',
            key: 'status',
        }]
        let attendanceInfoList = [];
        infoList.map((item,index)=>{
            attendanceInfoList.push({
                provinceName:item.quyu_name,
                companyName:item.gongsi_name,
                approvalStatus:item.lianxiren,
                contactPhone:item.lianxi_dianhua,
                createTime:item.ctime,
                status:item.status==1?"开启":item.status==2?"禁用":item.status==3?"删除":""
            })
        })
        ExcelUtil.exportExcel(initColumn,attendanceInfoList,`省级区域管理列表.xlsx`,
            [   
                { wpx: 150 },
                { wpx: 90 },
                { wpx: 80 }, 
                { wpx: 116 }, 
                { wpx: 116 }, 
                { wpx: 110 }, 
                { wpx: 70 }, 
                { wpx: 60 },
                { wpx: 100 },
                { wpx: 66 }, 
                { wpx: 200 }, 
                { wpx: 90 }, 
                { wpx: 200 }, 
                { wpx: 90 }, 
                { wpx: 88 }, 
                { wpx: 110 }
            ]
        )
    }
    function onChange(e) {
        console.log("=======")
        console.log(e.target.checked)
        if (e.target.checked) {
            let tempInfoList = []
            let checkedAll = true;
            infoList.forEach(element => {
                var newElement = {}
                for (let key in element) {
                    if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                        newElement[key] = element[key]
                    }
                }
                newElement.checked = true
                tempInfoList.push(newElement)
                if (newElement.checked==false) {
                    checkedAll = false
                }
            });
            dispatch(setInfoList(tempInfoList))
            dispatch(setInfoCheckedAll(checkedAll))

        } else {
            let tempInfoList = []
            infoList.forEach(element => {
                var newElement = {}
                for (let key in element) {
                    if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                        newElement[key] = element[key]
                    }
                }
                newElement.checked = false
                tempInfoList.push(newElement)
            });
            dispatch(setInfoList(tempInfoList))
            dispatch(setInfoCheckedAll(false))
        }
    }
    return <div className={styles.background}>
        <div className={styles.titleContainer}>
            <span className={styles.leftTitle}>数据列表</span>
            <div className={styles.rightButtonContainer}>
                <div className={styles.rightButton1} onClick={exportExcelMethod}>
                    <FontAwesomeIcon icon={faUpload} className={styles.rightButtonIcon}/>
                    导出
                </div>
                {/* <div className={styles.rightButton1}>
                    <FontAwesomeIcon icon={faDownload} className={styles.rightButtonIcon}/>
                    导入
                </div> */}
                {/* <div className={styles.rightButton2} onClick={addInfoMethod}>
                    <FontAwesomeIcon icon={faAdd} className={styles.rightButtonIcon}/>
                    添加
                </div> */}
            </div>
        </div>
        <div className={styles.listContainer}>
            <div className={styles.listTitleContainer}>
                <div className={classNames(styles.listTitle, styles.itemWidth1)}>
                    <Checkbox defaultChecked={false} checked={infoCheckedAll} onChange={onChange}/>
                </div>
                <div className={classNames(styles.listTitle, styles.itemWidth2)}>
                    <span className={styles.listContentSpan}>申请学校名称</span>
                </div>
                <div className={classNames(styles.listTitle, styles.itemWidth3)}>
                    <span className={styles.listContentSpan}>申请时间</span>
                </div>
                <div className={classNames(styles.listTitle, styles.itemWidth4)}>
                    <span className={styles.listContentSpan}>课程名称</span>
                </div>
                <div className={classNames(styles.listTitle, styles.itemWidth5)}>
                    <span className={styles.listContentSpan}>课程金额</span>
                </div>
                <div className={classNames(styles.listTitle, styles.itemWidth6)}>
                    <span className={styles.listContentSpan}>课程人数</span>
                </div>
                <div className={classNames(styles.listTitle, styles.itemWidth7)}>
                    <span className={styles.listContentSpan}>审核状态</span>
                </div>
                <div className={classNames(styles.listTitle, styles.itemWidth8)}>
                    <span className={styles.listContentSpan}>运营经理</span>
                </div>
            </div>
            {infoList.map((item, index) => (
                <InfoItem
                    item={item}
                    key={index}
                />
            ))}
        </div>
        

        
    </div>
}

