
import React, {useRef, useState} from "react";
import styles from "./AddInfo.module.css";
import { useDispatch,useSelector } from "react-redux";
import classNames from "classnames"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClose,faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
import { selectProvinceList, setProvinceList, setShowAddInfoPage } from "./CountyInfoManageSlice";
import { addCountyCompany, getRegionListRequest } from "./CountyInfoManageApi";
import { setAlertInfo } from "../../../store/CustomViewSlice";
import {message} from 'antd'
export default function AddInfo(props) {
    const provinceList = useSelector(selectProvinceList);
    const [provinceIndex, setProvinceIndex] = useState(0);
    const [showProvinceList, setShowProvinceList] = useState(false);

    const [municipalityList, setMunicipalityList] = useState([]);
    const [municipalityIndex, setMunicipalityIndex] = useState(0);
    const [showMunicipalityList, setShowMunicipalityList] = useState(false);

    const [showCountyList, setShowCountyList] = useState(false);
    const [countyIndex, setCountyIndex] = useState(0);
    const [countyList, setCountyList] = useState([]);
    const [companyName, setCompanyName] = useState("");
    const [contactsName, setContactsName] = useState("");
    const [contactsPhone, setContactsPhone] = useState("");
    const [telePhone1, setTelePhone1] = useState("");
    const [telePhone2, setTelePhone2] = useState("");
    const [telePhone3, setTelePhone3] = useState("");
    const [schoolAddress,setSchoolAddress] = useState("")
    const [notes,setNotes] = useState("")
    const [account, setAccount] = useState("");
    const [password, setPassword] = useState("");
    const [accountStatus,setAccountStatus] = useState(1)
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    function getProvinceList(){
        getRegionListRequest("1","",(data)=>{
            var newProvinceList = [{
                quyu_name:"未选择",
                quyu_code:""
            }];
            if (data.ret==1) {
                data.data.forEach(element => {
                    newProvinceList.push(element)
                });
                dispatch(setProvinceList(newProvinceList))
            }
            else if(data.ret==99){

                messageApi.open({
                    type: 'warning',
                    content:data.msg
                });
                setTimeout((e)=>{
                    sessionStorage.clear()
                    window.location.reload()
                },1000)
            }
        })
    }
    function getMunicipalityList(index){
        setCountyIndex(0)
        setCountyList([])
        if (index==0) {
            setMunicipalityIndex(0)
            setMunicipalityList([])
        }
        else{
            getRegionListRequest("2",provinceList[index].quyu_code,(data)=>{
                var tempList = [{
                    quyu_name:"未选择",
                    quyu_code:""
                }];
                if (data.ret==1) {
                    data.data.forEach(element => {
                        tempList.push(element)
                    });
                    setMunicipalityIndex(0)
                    setMunicipalityList(tempList)
                }else if(data.ret==99){

                    messageApi.open({
                        type: 'warning',
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }
                else{
                    setMunicipalityIndex(0)
                    setMunicipalityList([])
                }
            })
        }
    }
    function getCountyList(index){
        if (index==0) {
            setCountyIndex(0)
            setCountyList([])
        }
        else{
            getRegionListRequest("3",municipalityList[index].quyu_code,(data)=>{
                console.log("datadatadatadatadata")
                console.log(data)
                console.log(municipalityList[index].quyu_code)
                var tempList = [{
                    quyu_name:"未选择",
                    quyu_code:""
                }];
                if (data.ret==1) {
                    data.data.forEach(element => {
                        tempList.push(element)
                    });
                    setCountyIndex(0)
                    setCountyList(tempList)
                }else if(data.ret==99){

                    messageApi.open({
                        type: 'warning',
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }
                else{
                    setCountyIndex(0)
                    setCountyList([])
                }
            })
        }
        
    }
    function isValidPhoneNumber(phoneNumber) {
        return /^1[3-9]\d{9}$/.test(phoneNumber);
    }
    return <div className={styles.background}>
        {contextHolder}
        {showProvinceList||showMunicipalityList||showCountyList?<div className={styles.hideListCover}
            onClick={(e)=>{
                setShowProvinceList(false)
                setShowMunicipalityList(false)
                setShowCountyList(false)
            }}
        ></div>:null}
        <div className={styles.contentContainer}>
            <div className={styles.topBar}>
                <div className={styles.topBarLeft}>添加区县区域</div>
                <FontAwesomeIcon 
                    className={styles.topBarRight}
                    icon={faClose}
                    onClick={(e)=>{
                        dispatch(setShowAddInfoPage(false))
                    }}
                    />
            </div>
            <div className={styles.middleContent}>
                <div className={styles.middleLeftContainer}>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            所属省级区域：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            所属市：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            区县名称：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            区县公司名称：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            联系人：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            联系电话：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div>
                            联系电话(座机)：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            登录账号：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            登录密码：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight3)}>
                        <div>
                            账户状态：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight1)}>
                        {/* <div className={styles.redStar}>*</div> */}
                        <div>
                            登录备注：
                        </div>
                    </div>
                    
                </div>
                <div className={styles.middleRightContainer}>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            placeholder="请选择所属省级区域"
                            readOnly
                            value={provinceIndex==0?"":provinceList[provinceIndex].quyu_name}
                            onClick={(e)=>{
                                if (provinceList.length<=1) {
                                    getProvinceList()
                                }
                                setShowProvinceList(!showProvinceList)
                                setShowMunicipalityList(false)
                                setShowCountyList(false)
                            }}
                        />
                        <FontAwesomeIcon 
                            className={styles.inputIcon}
                            icon={faAngleDown}
                        />
                        {showProvinceList?<div className={classNames(styles.tankangContainer,styles.municipalityListPosition)}>
                            {provinceList.map((item,index,arr)=>{
                                return <div className={styles.tankuangItem}
                                    key={index}
                                    onClick={(e)=>{
                                        setProvinceIndex(index)
                                        setShowProvinceList(false)
                                        getMunicipalityList(index)
                                    }}
                                >{item.quyu_name}</div>
                            })}
                        </div>:null}
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            placeholder="请选择所属地市"
                            readOnly
                            value={municipalityIndex==0?"":municipalityList[municipalityIndex].quyu_name}
                            onClick={(e)=>{
                                setShowProvinceList(false)
                                setShowMunicipalityList(!showMunicipalityList)
                                setShowCountyList(false)
                            }}
                        />
                        <FontAwesomeIcon 
                            className={styles.inputIcon}
                            icon={faAngleDown}
                        />
                        {showMunicipalityList?<div className={classNames(styles.tankangContainer,styles.municipalityListPosition)}>
                            {municipalityList.map((item,index,arr)=>{
                                return <div className={styles.tankuangItem}
                                    key={index}
                                    onClick={(e)=>{
                                        setMunicipalityIndex(index)
                                        setShowMunicipalityList(false)
                                        getCountyList(index)
                                    }}
                                >{item.quyu_name}</div>
                            })}
                        </div>:null}
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            placeholder="请选择所属区县区域"
                            readOnly
                            value={countyIndex==0?"":countyList[countyIndex].quyu_name}
                            onClick={(e)=>{
                                setShowProvinceList(false)
                                setShowMunicipalityList(false)
                                setShowCountyList(!showCountyList)
                            }}
                        />
                        <FontAwesomeIcon 
                            className={styles.inputIcon}
                            icon={faAngleDown}
                        />
                        {showCountyList?<div className={classNames(styles.tankangContainer,styles.municipalityListPosition)}>
                            {countyList.map((item,index,arr)=>{
                                return <div className={styles.tankuangItem}
                                    key={index}
                                    onClick={(e)=>{
                                        setCountyIndex(index)
                                        setShowCountyList(false)
                                    }}
                                >{item.quyu_name}</div>
                            })}
                        </div>:null}
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            placeholder="请填写区县公司全名"
                            value={companyName}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setCompanyName(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            placeholder="请填写区县公司联系人全名"
                            value={contactsName}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setContactsName(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            placeholder="请填写区县公司联系人电话"
                            value={contactsPhone}
                            maxLength={11}
                            onChange={((e)=>{
                                setContactsPhone(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={classNames(styles.middleRightInput,styles.schoolTelephoneWidth)}
                            placeholder="电话区号"
                            value={telePhone1}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setTelePhone1(e.target.value)
                            })}
                        />
                        <input className={classNames(styles.middleRightInput,styles.schoolTelephoneWidth1)}
                            placeholder="电话号码"
                            value={telePhone2}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setTelePhone2(e.target.value)
                            })}
                        />
                        <input className={classNames(styles.middleRightInput,styles.schoolTelephoneWidth2)}
                            placeholder="分机号码"
                            value={telePhone3}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setTelePhone3(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            placeholder="区县公司登录系统所使用的账号"
                            value={account}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setAccount(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            placeholder="设定登录密码"
                            value={password}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setPassword(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight4)}>
                        <input
                            className={styles.manRadio}
                            type="radio"
                            checked={accountStatus==1}
                            onChange={(e)=>{
                                setAccountStatus(1)
                            }}
                        />
                        <div className={styles.sexName}>开通</div>
                        <input
                            className={styles.womanRadio}
                            type="radio"
                            checked={accountStatus==2}
                            onChange={(e)=>{
                                setAccountStatus(2)
                            }}
                        />
                        <div className={styles.sexName}>禁用</div>
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight1)}>
                        <textarea className={styles.middleRightTextarea}
                            placeholder="请输入备注信息"
                            value={notes}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setNotes(e.target.value)
                            })}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.bottomContainer}>
                <div className={classNames(styles.cancelButton,styles.button)}
                    onClick={(e)=>{
                        dispatch(setShowAddInfoPage(false))
                    }}
                >取消</div>
                <div className={classNames(styles.confirmButton,styles.button)}
                    onClick={(e)=>{
                        if (provinceIndex==0) {
                            dispatch(setAlertInfo({
                                showStatus:true,
                                title:"",
                                title1:"未选择省",
                                content:""
                            }))
                        }
                        else if(municipalityIndex==0){
                            dispatch(setAlertInfo({
                                showStatus:true,
                                title:"",
                                title1:'未选择市',
                                content:""
                            }))
                        }
                        else if(countyIndex==0){
                            dispatch(setAlertInfo({
                                showStatus:true,
                                title:"",
                                title1:'未选择区县',
                                content:""
                            }))
                        }
                        else if(companyName==""){
                            dispatch(setAlertInfo({
                                showStatus:true,
                                title:"",
                                title1:'请检查公司名称',
                                content:""
                            }))
                        }
                        else if(contactsName==""){
                            dispatch(setAlertInfo({
                                showStatus:true,
                                title:"",
                                title1:'请检查联系人',
                                content:""
                            }))
                        }
                        else if(!isValidPhoneNumber(contactsPhone)){
                            dispatch(setAlertInfo({
                                showStatus:true,
                                title:"",
                                title1:'请检查联系人电话',
                                content:""
                            }))
                        }
                        else if(account==""){
                            dispatch(setAlertInfo({
                                showStatus:true,
                                title:"",
                                title1:'请检查登录账号',
                                content:""
                            }))
                        }
                        else if(password.length<6){
                            dispatch(setAlertInfo({
                                showStatus:true,
                                title:"",
                                title1:'登录密码不能小于6位',
                                content:""
                            }))
                        }
                        else{
                            addCountyCompany(
                                provinceList[provinceIndex].quyu_name,
                                provinceList[provinceIndex].quyu_code,
                                municipalityList[municipalityIndex].quyu_name,
                                municipalityList[municipalityIndex].quyu_code,
                                countyList[countyIndex].quyu_name,
                                countyList[countyIndex].quyu_code,
                                companyName,
                                contactsName,
                                contactsPhone,
                                telePhone1,
                                telePhone2,
                                telePhone3,
                                account,
                                password,
                                accountStatus,
                                notes,
                                (data)=>{
                                    console.log('-添加请求的结果---')
                                    console.log(data)
                                    if (data.ret==1) {
                                        dispatch(setShowAddInfoPage(false))
                                        props.refresh()
                                    }else if (data.ret==2) {
                                        dispatch(setAlertInfo({
                                            showStatus:true,
                                            title:"",
                                            title1:'登录账号已存在，请填写其他账号',
                                            content:""
                                        }))
                                    }else if(data.ret==99){

                                        messageApi.open({
                                            type: 'warning',
                                            content:data.msg
                                        });
                                        setTimeout((e)=>{
                                            sessionStorage.clear()
                                            window.location.reload()
                                        },1000)
                                    } else {
                                        dispatch(setAlertInfo({
                                            showStatus:true,
                                            title:"",
                                            title1:'添加失败，请检查内容重试',
                                            content:""
                                        }))
                                    }
                                }
                            )
                        }
                    }}
                >确定</div>  
            </div>
        </div>
    </div>
}

