
import React, {useRef, useState} from "react";
import styles from "./AddInfoCourse.module.css";
import { useDispatch,useSelector } from "react-redux";
import classNames from "classnames"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClose,faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
import { setAlertInfo } from "../../../store/CustomViewSlice";
import { CustomConfirmAlert } from "../../CustomView/CustomConfirmAlert";
import { Checkbox } from "antd";
import { selectAllCourseChecked, selectAllCourseList, setAllCourseChecked, setAllCourseList, setShowAddInfoCoursePage } from "./TeacherManageSlice";
export default function AddInfoCourse(props) {
    const [confirmInfo,setConfirmInfo]=useState({})
    const allCourseList = useSelector(selectAllCourseList)
    const allCourseChecked = useSelector(selectAllCourseChecked)
    const dispatch = useDispatch();
    function isValidPhoneNumber(phoneNumber) {
        return /^1[3-9]\d{9}$/.test(phoneNumber);
    }
    function cancelMethod(params) {
        setConfirmInfo({
            showStatus:false,
            title:"",
            title1:"",
            content:""
        })
    }
    function confirmMethod(params) {
        setConfirmInfo({
            showStatus:false,
            title:"",
            title1:"",
            content:""
        })
        if (confirmInfo.eventId==1) {
            
        } else if(confirmInfo.eventId==2) {
            // closeAccount(
            //     props.item.code,
            //     props.item.login_username,
            //     (data)=>{
            //         if (data.ret==1) {
            //             refreshMethod()
            //         } else {
            //             dispatch(setAlertInfo({
            //                 showStatus:true,
            //                 title:"",
            //                 title1:'账户禁用失败，请重试',
            //                 content:""
            //             }))
            //         }
            //     }
            // )
        }
        else if(confirmInfo.eventId==3){
            
        }
    }
    function onChange(checkIndex) {
        let tempAllCouseList = []
        let checkedAll = true;
        allCourseList.map((element,index)=>{
            var newElement = {}
            for (let key in element) {
                if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                    newElement[key] = element[key]
                }
            }
            if (index==checkIndex) {
                if (element.checked) {
                    newElement.checked=false
                } else {
                    newElement.checked=true
                }
            }
            tempAllCouseList.push(newElement)
            if (newElement.checked==false) {
                checkedAll = false
            }
        })
        // infoList.forEach(element => {
        //     var newElement = {}
        //     for (let key in element) {
        //         if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
        //             newElement[key] = element[key]
        //         }
        //     }
        //     if (newElement.Id==props.item.Id) {
        //         if (e.target.checked) {
        //             newElement.checked=true
        //         } else {
        //             newElement.checked=false
        //         }
        //     }
        //     tempAllCouseList.push(newElement)
        //     if (newElement.checked==false) {
        //         checkedAll = false
        //     }
        // });
        // console.log(tempInfoList)
        dispatch(setAllCourseList(tempAllCouseList))
        dispatch(setAllCourseChecked(checkedAll))
    }
    return <div className={styles.background}>
        <CustomConfirmAlert
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        <div className={styles.contentContainer}>
            <div className={styles.whiteContainer}>
                <table 
                    align="top"
                    border="0"
                    bordercolor="#f00"
                    cellspacing="0"
                    cellspadding="0"
                    width="580"
                >
                    <tr valign="middle"
                            align="center"
                        className={styles.tr1}
                    >
                        <td 
                            width="30" height="5"
                            className={styles.td1}
                        >
                            <Checkbox defaultChecked={false} checked={allCourseChecked}  onChange={(e)=>{
                                if (allCourseChecked) {
                                    dispatch(setAllCourseChecked(false))
                                    var tempAllCouseList = [];
                                    allCourseList.map((element,index)=>{
                                        var newElement = {}
                                        for (let key in element) {
                                            if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                                            newElement[key] = element[key]
                                            }
                                        }
                                        newElement.checked=false
                                        tempAllCouseList.push(newElement)
                                    })
                                    dispatch(setAllCourseList(tempAllCouseList))
                                } else {
                                    dispatch(setAllCourseChecked(true))
                                    var tempAllCouseList = [];
                                    allCourseList.map((element,index)=>{
                                        var newElement = {}
                                        for (let key in element) {
                                            if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                                            newElement[key] = element[key]
                                            }
                                        }
                                        newElement.checked=true
                                        tempAllCouseList.push(newElement)
                                    })
                                    dispatch(setAllCourseList(tempAllCouseList))
                                }
                            }} />
                        </td>
                        <td 
                            width="120" height="5"
                            className={styles.td1}
                        >课程编号</td>
                        <td 
                            width="340" height="5"
                            className={styles.td1}
                        >课程名称</td>
                    </tr>
                    {allCourseList.map((item,index,arr)=>{
                        return <tr valign="middle"
                        align="center">
                            <td 
                                width="30" height="5"
                                className={styles.td2}
                            >
                                <Checkbox defaultChecked={false} checked={item.checked} onChange={onChange.bind(null,index)} />
                            </td>
                            <td 
                                width="120" height="5"
                                className={styles.td2}
                            >{item.code}</td>
                            <td 
                                width="340" height="5"
                                className={styles.td2}
                            >{item.name}</td>
                        </tr>
                    })}
                    
                </table>
            </div>
            <div className={styles.bottomContainer}>
                <div className={classNames(styles.cancelButton,styles.button)}
                    onClick={(e)=>{
                        dispatch(setShowAddInfoCoursePage(false))
                    }}
                >取消</div>
                <div className={classNames(styles.confirmButton,styles.button)}
                    onClick={(e)=>{
                        setConfirmInfo({
                            showStatus:true,
                            title:"",
                            title1:'确认提交？',
                            content:"",
                            eventId:3
                        })
                    }}
                >确定</div>  
            </div>
        </div>
    </div>
}

