
import React,{Component}  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './TopControl.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh} from '@fortawesome/free-solid-svg-icons';
import {selectLeftTopTitle,selectShowRefreshButton} from './TopControlSlice'

export default function TopControl(props) {
    const leftTopTitle = useSelector(selectLeftTopTitle);
    const showRefreshButton = useSelector(selectShowRefreshButton);
    return (
        <div className={styles.background}>
            <div className={styles.centerContainer}>
                <div className={styles.leftContainer}>
                    <div className={styles.leftBlueDiv}></div>
                    <p className={styles.leftTitle}>{props.leftTopTitle}</p>
                </div>
                <div className={styles.leftContainer}>
                    {props.showRefreshButton?
                    <div className={styles.refreshContainer}
                         onClick={props.refreshMethod?props.refreshMethod:()=>{}}
                    >
                        <FontAwesomeIcon icon={faRefresh} />
                        <span className={styles.rightTitle}>刷新</span>
                    </div>
                    :
                    null}
                    
                    {/* <div className={styles.backContainer}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                        <span className={styles.rightTitle}>返回</span>
                    </div> */}
                </div>
                
            </div>
        </div>
    )
}
