import request from "../../../util/request";


export function getRegionListRequest(regionLevel,parentCode,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "quyulist");
    filedata.append('quyu_jibie', regionLevel);
    filedata.append('quyu_fuji_code', parentCode);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body:filedata,
    }, callback);
}

export function getInfoListRequest(regionName,companyName,contactName,offset,limit,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_quxian_quyu_list");
    filedata.append('quxian_quyu_namekey', regionName);
    filedata.append('quxian_gongsi_namekey', companyName);
    filedata.append('quxian_quyu_lianxiren_namekey', contactName);
    filedata.append('offset', offset);
    filedata.append('limit', limit);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body:filedata,
    }, callback);
}
export function addCountyCompany(
    provinceName,provinceCode,
    municipalityName,municipalityCode,
    countyName,countyCode,
    countyCompanyName,
    contactName,contactPhone,
    telephone1,telephone2,telephone3,
    account,password,accountStatus,notes,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "add_quxian_quyu");
    filedata.append('shengji_quyu_name', provinceName);
    filedata.append('shengji_quyu_code', provinceCode);
    filedata.append('shiji_quyu_code', municipalityCode);
    filedata.append('shiji_quyu_name', municipalityName);
    filedata.append('quxian_quyu_code', countyCode);
    filedata.append('quxian_quyu_name', countyName);
    filedata.append('quxian_gongsi_name', countyCompanyName);
    filedata.append('lianxiren', contactName);
    filedata.append('lianxi_dianhua', contactPhone);
    filedata.append('zuoji_quhao', telephone1);
    filedata.append('zuoji_dianhuahao', telephone2);
    filedata.append('zuoji_fenjihao', telephone3);
    filedata.append('user_login', account);
    filedata.append('password', password);
    filedata.append('status', accountStatus);
    filedata.append('beizhu', notes);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function updateCountyCompany(
    provinceName,provinceCode,
    municipalityName,municipalityCode,
    countyName,countyCode,
    countyCompanyName,
    contactName,contactPhone,
    telephone1,telephone2,telephone3,
    account,password,accountStatus,notes,
    code,username,
    callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "update_quxian_quyu");
    filedata.append('shengji_quyu_name', provinceName);
    filedata.append('shengji_quyu_code', provinceCode);
    filedata.append('shiji_quyu_code', municipalityCode);
    filedata.append('shiji_quyu_name', municipalityName);
    filedata.append('quxian_quyu_code', countyCode);
    filedata.append('quxian_quyu_name', countyName);
    filedata.append('quxian_gongsi_name', countyCompanyName);
    filedata.append('lianxiren', contactName);
    filedata.append('lianxi_dianhua', contactPhone);
    filedata.append('zuoji_quhao', telephone1);
    filedata.append('zuoji_dianhuahao', telephone2);
    filedata.append('zuoji_fenjihao', telephone3);
    filedata.append('user_login', account);
    filedata.append('password', password);
    filedata.append('status', accountStatus);
    filedata.append('beizhu', notes);
    filedata.append('code', code);
    filedata.append('username', username);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function openAccount(code,username,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "kaitong_quxian_quyu_zhanghao");
    filedata.append('code', code);
    filedata.append('username', username);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function closeAccount(code,username,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "jinyong_quxian_quyu_zhanghao");
    filedata.append('code', code);
    filedata.append('username', username);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function deleteAccount(code,username,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "del_quxian_quyu");
    filedata.append('code', code);
    filedata.append('username', username);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}