// 产品总经理界面
import React ,{ useRef,useState,useEffect }from "react";
import styles from "./CPZJLMain.module.css";
import classNames from "classnames"
import { useSelector, useDispatch } from 'react-redux';
import logoImg from "../../../img/logo.png"
// import { CourseConfig } from "../CourseConfig/CourseConfig";
import { selectMenuIndex, setMenuIndex } from "./CPZJLMainSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPowerOff} from '@fortawesome/free-solid-svg-icons';
import { closeCPZJLMain, openLogin } from "../../../store/modalsSlice";
import {CourseApproval} from "../CourseApproval/CourseApproval";
import { loginOutRequest } from "../../Login/loginApi";

export function CPZJLMain() {
    const menuIndex = useSelector(selectMenuIndex);
    const dispatch = useDispatch();
    function leftMenuMethod(index){
        dispatch(setMenuIndex(index))
    }
    function signOutMethod() {
        loginOutRequest((data)=>{
        })
        sessionStorage.clear()
        dispatch(openLogin())
        dispatch(closeCPZJLMain())
    }
    return (
        <div className={styles.background}>
            <div className={styles.topContainer}>
                <div className={styles.topLeftMenus}>
                    <img 
                        className={styles.logIcon}
                        src={logoImg}
                    ></img>
                    <span className={styles.logoTitle}>全球胜任力研究所</span>
                </div>
                <div className={styles.topRightMenus}>
                    <div className={styles.userName}>{sessionStorage.getItem("user_type_name")+"\u00A0\u00A0"+sessionStorage.getItem("name")}</div>
                    <div className={styles.signOutButton}
                        onClick={signOutMethod}
                    >
                        <FontAwesomeIcon icon={faPowerOff}/>
                    </div>
                </div>
                
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.leftContent}>
                    <div
                        className={classNames(styles.leftMenu, menuIndex == 0 ? styles.leftSelectedMenu : null)}
                        onClick={leftMenuMethod.bind(null, 0)}
                    >学校开课审批
                    </div>
                </div>
                <div className={styles.rightContent}>
                    {menuIndex == 0 ? <CourseApproval/> : null}

                </div>
            </div>
        </div>
    );
};
