import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
    nameKey:"",
    schoolIndex: 0,
    schoolList: [{
        school_name: "未选择",
        school_code: ""
    }],
    infoCheckedAll: false,//信息是否被选中的状态列表
    infoList: [],
    QRContentStr: "",
    downloadStatus: true,
    reviewStatus: "0",

    //分页所需参数
    groupCount: 5,
    startPage: 1,
    pageNumber: 10,
    currentPage: 1,
    totalPageNum: 1,
    pages: [],
    provinceList: [{}],
    showRefundAlert: false,
    showRefundRecord:false,
    paymentInfo:{},
    refundRecordList:[]
};

export const CWZGPaymentQuerySlice = createSlice({
    name: 'CWZGPaymentQuery',
    initialState,
    reducers: {
        setNameKey: (state, action) => {
            state.nameKey = action.payload;
        },
        setSchoolIndex: (state, action) => {
            state.schoolIndex = action.payload;
        },
        setSchoolList: (state, action) => {
            state.schoolList = action.payload;
        },
        setInfoList: (state, action) => {
            state.infoList = action.payload;
        },
        setInfoCheckedAll: (state, action) => {
            state.infoCheckedAll = action.payload;
        },
        setQRContentStr: (state, action) => {
            state.QRContentStr = action.payload;
        },
        setQRDownloadStatus: (state, action) => {
            state.downloadStatus = action.payload;
        },
        setReviewStatus: (state, action) => {
            state.reviewStatus = action.payload;
        },


        setGroupCount: (state, action) => {
            state.groupCount = action.payload;
        },
        setStartPage: (state, action) => {
            state.startPage = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setTotalPageNum: (state, action) => {
            state.totalPageNum = action.payload;
        },
        setProvinceList: (state, action) => {
            state.provinceList = action.payload;
        },
        setShowRefundAlert: (state, action) => {
            state.showRefundAlert = action.payload;
        },
        setShowRefundRecord: (state, action) => {
            state.showRefundRecord = action.payload;
        },
        setPaymentInfo: (state, action) => {
            state.paymentInfo = action.payload;
        },
        setRefundRecordList: (state, action) => {
            state.refundRecordList = action.payload;
        },





    },
});
export const selectNameKey = (state) => state.CWZGPaymentQuery.nameKey;
export const selectSchoolIndex = (state) => state.CWZGPaymentQuery.schoolIndex;
export const selectSchoolList = (state) => state.CWZGPaymentQuery.schoolList;
export const selectInfoList = (state) => state.CWZGPaymentQuery.infoList;
export const selectInfoCheckedAll = (state) => state.CWZGPaymentQuery.infoCheckedAll;
export const selectQRContentStr = (state) => state.CWZGPaymentQuery.QRContentStr;
export const selectDownloadStatus = (state) => state.CWZGPaymentQuery.downloadStatus;
export const selectReviewStatus = (state) => state.CWZGPaymentQuery.reviewStatus;

//分页所需数据
export const selectGroupCount = (state) => state.CWZGPaymentQuery.groupCount;
export const selectStartPage = (state) => state.CWZGPaymentQuery.startPage;
export const selectPageNumber = (state) => state.CWZGPaymentQuery.pageNumber;
export const selectCurrentPage = (state) => state.CWZGPaymentQuery.currentPage;
export const selectTotalPageNum = (state) => state.CWZGPaymentQuery.totalPageNum;
export const selectProvinceList = (state) => state.CWZGPaymentQuery.provinceList;
export const selectShowRefundAlert = (state) => state.CWZGPaymentQuery.showRefundAlert;
export const selectShowRefundRecord = (state) => state.CWZGPaymentQuery.showRefundRecord;
export const selectPaymentInfo = (state) => state.CWZGPaymentQuery.paymentInfo;
export const selectRefundRecordList = (state) => state.CWZGPaymentQuery.refundRecordList;




export const {
    setNameKey,
    setSchoolIndex,
    setSchoolList,
    setInfoList,
    setInfoCheckedAll,
    setQRContentStr,
    setQRDownloadStatus,
    setReviewStatus,
    setGroupCount,
    setStartPage,
    setPageNumber,
    setCurrentPage,
    setTotalPageNum,
    setProvinceList,
    setShowRefundAlert,
    setShowRefundRecord,
    setPaymentInfo,
    setRefundRecordList
} = CWZGPaymentQuerySlice.actions;

export default CWZGPaymentQuerySlice.reducer;