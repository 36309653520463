// 提交开课申请,暂未对接接口
import React, {useRef, useState, useEffect} from "react";
import styles from "./ZXSchool4.module.css";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import Footer from "../../Footer/Footer";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight,faAngleUp,faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {selectAlertInfo, setAlertInfo} from "../../../store/CustomViewSlice";
import {message} from 'antd'
import {setZXMenuIndex} from "../ZXYYMain/ZXYYMainSlice";
import {
    selectDingSchoolList,
    selectLieSchoolList, selectLPDSchoolList,
    selectPingSchoolList,
    setDingSchoolList, setDYFXList,
    setEndStatus
} from "./ZXSchoolSlice";
import {ZXSchoolSetDYFX} from "./ZXSchoolSetDYFX";
import {ZXSchoolSetHX} from "./ZXSchoolSetHX";
import {getSchoolDYFX, getSchoolHX} from "./ZXSchoolApi";

export function ZXschool4() {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [confirmInfo, setConfirmInfo] = useState({})
    const lieSchoolList = useSelector(selectLieSchoolList)
    const pingSchoolList = useSelector(selectPingSchoolList)
    const dingSchoolList = useSelector(selectDingSchoolList)
    const LPDSchoolList = useSelector(selectLPDSchoolList)

    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        let tempList = LPDSchoolList.map((item, index) => {return item});
        tempList.sort((a, b) => {
            return a.order_zonghe - b.order_zonghe;
        })
        var haveOrderZongHe = false;
        tempList.map((item, index) => {
            if(item.order_zonghe != 0){
                haveOrderZongHe = true;
            }
            return item
        });
        if(!haveOrderZongHe){
            tempList = tempList.map((item, index) => {
                let tempDic = JSON.parse(JSON.stringify(item))
                tempDic.order_zonghe = index;
                return tempDic;
            })
        }
        dispatch(setDingSchoolList(tempList))
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次

    const [yiyuanList, setYiyuanList] = useState([{
        yiyuanName:"意愿未知",
    },{
        yiyuanName:"意愿很强",
    },{
        yiyuanName:"意愿较强",
    },{
        yiyuanName:"意愿一般",
    },{
        yiyuanName:"意愿不强",
    }]);
    const [addingStudentNumber, setAddingStudentNumber] = useState("");
    const [HXSchoolInfo, setHXSchoolInfo] = useState({});
    const [HXInfoList, setHXInfoList] = useState([]);
    const [showZXSchoolHX,setShowZXSchoolHX] = useState(false);
    const [DYFXSchoolInfo, setDYFXSchoolInfo] = useState({});
    const [showZXSchoolDYFX,setShowZXSchoolDYFX] = useState(false);

    function refreshMethod() {

    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
        } else if (confirmInfo.eventId == 2) {

        }

    }

    return (
        <div className={styles.background}>
            {contextHolder}
            <CustomConfirmAlert
                info={confirmInfo}
                cancelMethod={cancelMethod}
                confirmMethod={confirmMethod}
            />
            {
                showZXSchoolHX?<ZXSchoolSetHX
                    info={HXSchoolInfo}
                    infoList={HXInfoList}
                    closeMethod = {(e)=>{setShowZXSchoolHX(false)}}
                    tijiaoMethod={(e)=>{
                        alert('暂未实现')
                    }}
                />:null
            }
            {
                showZXSchoolDYFX?<ZXSchoolSetDYFX
                    info = {DYFXSchoolInfo}
                    showUploadButton = {false}
                    closeMethod = {(e)=>{setShowZXSchoolDYFX(false)}}
                    tijiaoMethod={(e)=>{
                        alert('暂未实现')
                    }}
                />:null
            }

            <div className={styles.content}>
                <div className={styles.leftContent}>
                    <span className={styles.leftLieTitle}
                          onClick={(e)=>{
                              dispatch(setZXMenuIndex(1))
                          }}
                    >第1步:【列】</span>
                    <span className={styles.leftPingTitle}
                          onClick={(e)=>{
                              dispatch(setZXMenuIndex(2))
                          }}
                    >第2步:【评】</span>
                    <span className={styles.leftDingTitle}>第3步:【定】</span>
                </div>
                <div className={styles.middleContent}>
                    <div className={styles.middleTopContent}>
                        <div className={styles.middleTitle}>
                            恭喜，你已筛出了诺奖计划备选学校，可再根据实际情况，综合考量选定最终的落地学校。
                        </div>
                        {/*<div className={styles.szqxTitle}>所在区县：临汾市尧都区</div>*/}
                        <div className={styles.BXSchoolTitle}>备选学校：
                            <span className={styles.BXSchoolDes}>
                            {dingSchoolList.length}所
                        </span>
                        </div>
                        <div className={styles.BXSchoolList}>
                            {dingSchoolList.map((item, index) => {
                                return <div className={styles.BXSchoolItem}>
                                    <span className={styles.jcspTitle}>综合水平{index + 1}</span>
                                    <span className={styles.schoolName}>{item.xuexiao_name}</span>
                                    <span className={styles.studentNumber}>{item.xuesheng_num}人</span>
                                    <div className={styles.schoolDYFXButton}
                                         onClick={(e) => {
                                             getSchoolHX(item.xuexiao_code, (data) => {
                                                 if (data.ret == 1) {
                                                     setHXSchoolInfo(item)
                                                     setHXInfoList(data.data.list)
                                                     setShowZXSchoolHX(true)
                                                 }else if(data.ret==99){

                                                    messageApi.open({
                                                        type: 'warning',
                                                        content:data.msg
                                                    });
                                                    setTimeout((e)=>{
                                                        sessionStorage.clear()
                                                        window.location.reload()
                                                    },1000)
                                                } else {
                                                     setHXSchoolInfo(item)
                                                     setHXInfoList([])
                                                     setShowZXSchoolHX(true)
                                                 }
                                             })
                                         }}
                                    >学校画像>
                                    </div>
                                    <div className={styles.schoolDYFXButton}
                                         onClick={(e) => {
                                             setDYFXSchoolInfo(item)
                                             setShowZXSchoolDYFX(true)


                                             getSchoolDYFX(item.xuexiao_code, (data) => {
                                                 if (data.ret == 1) {
                                                     setDYFXSchoolInfo(item)
                                                     let tempList = data.data.list.map((item, index) => {
                                                         let contentList = item.content.split(",")
                                                         let tempDic = {
                                                             name: item.name,
                                                             number: contentList.length === 0 || contentList[0] == "" ? 0 : contentList[0],
                                                             feiyong: contentList.length < 1 || contentList[1] == "" ? 0 : contentList[1],
                                                             PJKeshi: contentList.length < 2 || contentList[2] == "" ? 0 : contentList[2],
                                                             dxqffgm: contentList.length < 3 || contentList[3] == "" ? 0 : contentList[3]
                                                         }
                                                         return tempDic
                                                     })
                                                     dispatch(setDYFXList(tempList))
                                                     setShowZXSchoolDYFX(true)
                                                 } else if(data.ret==99){

                                                    messageApi.open({
                                                        type: 'warning',
                                                        content:data.msg
                                                    });
                                                    setTimeout((e)=>{
                                                        sessionStorage.clear()
                                                        window.location.reload()
                                                    },1000)
                                                }else {
                                                     setDYFXSchoolInfo(item)
                                                     dispatch(setDYFXList([{
                                                         "name": "自费-全部课后服务课程",
                                                         "number": 0,
                                                         "feiyong": 0,
                                                         "PJKeshi": 0,
                                                         "dxqffgm": 0

                                                     },
                                                         {
                                                             "name": "自费-科技类课后服务课程",
                                                             "number": 0,
                                                             "feiyong": 0,
                                                             "PJKeshi": 0,
                                                             "dxqffgm": 0

                                                         },
                                                         {
                                                             "name": "自费-全部校本课程",
                                                             "number": 0,
                                                             "feiyong": 0,
                                                             "PJKeshi": 0,
                                                             "dxqffgm": 0

                                                         },
                                                         {
                                                             "name": "自费-科技类校本课程",
                                                             "number": 0,
                                                             "feiyong": 0,
                                                             "PJKeshi": 0,
                                                             "dxqffgm": 0

                                                         }]))
                                                     setShowZXSchoolDYFX(true)
                                                 }
                                             })
                                         }}
                                    >调研分析>
                                    </div>
                                    <div className={styles.yiyuanText}>
                                        {yiyuanList[item.yiyuan_code].yiyuanName}
                                    </div>
                                    {/*<span className={styles.writeStatus}>已填写</span>*/}
                                    {/*<div className={styles.angleContainer}>*/}
                                    {/*    {index !== 0 && dingSchoolList.length > 1 ?*/}
                                    {/*        <FontAwesomeIcon icon={faAngleUp}*/}
                                    {/*                         className={classNames(styles.angleIcon, styles.angleUp)}*/}
                                    {/*                         onClick={(e) => {*/}
                                    {/*                             var tempArray = dingSchoolList.map((item, index) => {*/}
                                    {/*                                 return item*/}
                                    {/*                             })*/}
                                    {/*                             tempArray.splice(index, 1)*/}
                                    {/*                             tempArray.splice(index - 1, 0, item)*/}
                                    {/*                             dispatch(setDingSchoolList(tempArray))*/}
                                    {/*                         }}*/}
                                    {/*        /> : null}*/}
                                    {/*    {index !== dingSchoolList.length - 1 && dingSchoolList.length > 1 ?*/}
                                    {/*        <FontAwesomeIcon icon={faAngleDown}*/}
                                    {/*                         className={classNames(styles.angleIcon, styles.angleDown)}*/}
                                    {/*                         onClick={(e) => {*/}
                                    {/*                             var tempArray = dingSchoolList.map((item, index) => {*/}
                                    {/*                                 return item*/}
                                    {/*                             })*/}
                                    {/*                             tempArray.splice(index, 1)*/}
                                    {/*                             tempArray.splice(index + 1, 0, item)*/}
                                    {/*                             dispatch(setDingSchoolList(tempArray))*/}
                                    {/*                         }}*/}
                                    {/*        /> : null}*/}
                                    {/*</div>*/}
                                </div>
                            })}
                        </div>

                    </div>
                    <div className={styles.middleBottomContent}>
                    <div className={styles.lastStepButton}
                             onClick={(e) => {
                                 dispatch(setEndStatus(false))
                             }}
                        >上一步
                        </div>
                    </div>
                </div>
                <div className={styles.rightContent}>
                    {/*<div className={styles.xtsContainer}>*/}
                    {/*    <div className={styles.xtsTitle}>小贴士</div>*/}
                    {/*    <div*/}
                    {/*        className={styles.xtsDes}>如何评估学校负责人对项目的意愿强度？*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
            <Footer/>
        </div>
    );
};
