import {
    createSlice
} from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
    ZSSMHXueqiList:[],
    ZSSMHXueqiIndex:0,
    SBPSSchoolIndex:0,
    SBPSSchoolList:[],//已完成学术说明会的学校
    ZSSMHSchoolList:[],
    rangeTime:["",""],

    xxchr:"",
    chjzrs:"", //参会家长人数
    chxsrs:"", //参会学生人数
    yjschr:"",
    hygcjj:"",
    fileList1:[],
    fileList2:[],
    fileList3:[],
    fileList4:[],

    psjlIndex:0,
    psjlList:[{
        name:"暂无",
        code:"1"
    },{
        name:"通过",
        code:"2"
    },{
        name:"不通过",
        code:"3"
    }],
    ZSSMHDetailInfo:{}
};

export const ZXYYZSSMHSlice = createSlice({
    name: 'ZXYYZSSMH',
    initialState,
    reducers: {
        setZSSMHXueqiList:(state, action) => {
            state.ZSSMHXueqiList = action.payload;
        },
        setZSSMHXueqiIndex:(state, action) => {
            state.ZSSMHXueqiIndex = action.payload;
        },
        setSBPSSchoolIndex:(state, action) => {
            state.SBPSSchoolIndex = action.payload;
        },

        setSBPSSchoolList:(state, action) => {
            state.SBPSSchoolList = action.payload;
        },
        setZSSMHSchoolList:(state, action) => {
            state.ZSSMHSchoolList = action.payload;
        },
        setRangeTime:(state, action) => {
            state.rangeTime = action.payload;
        },
        setXxchr:(state, action) => {
            state.xxchr = action.payload;
        },
        setChjzrs:(state, action) => {
            state.chjzrs = action.payload;
        },
        setChxsrs:(state, action) => {
            state.chxsrs = action.payload;
        },
        setYjschr:(state, action) => {
            state.yjschr = action.payload;
        },
        setHygcjj:(state, action) => {
            state.hygcjj = action.payload;
        },
        setFileList1:(state, action) => {
            state.fileList1 = action.payload;
        },
        setFileList2:(state, action) => {
            state.fileList2 = action.payload;
        },
        setFileList3:(state, action) => {
            state.fileList3 = action.payload;
        },
        setFileList4:(state, action) => {
            state.fileList4 = action.payload;
        },
        setpsjlIndex:(state, action) => {
            state.psjlIndex = action.payload;
        },
        setpsjlList:(state, action) => {
            state.psjlList = action.payload;
        },
        setZSSMHDetailInfo:(state, action) => {
            state.ZSSMHDetailInfo = action.payload;
        }



    },
});
export const selectZSSMHXueqiList = (state) => state.ZXYYZSSMH.ZSSMHXueqiList;
export const selectZSSMHXueqiIndex = (state) => state.ZXYYZSSMH.ZSSMHXueqiIndex;
export const selectRangeTime = (state) => state.ZXYYZSSMH.rangeTime;
export const selectXxchr = (state) => state.ZXYYZSSMH.xxchr;
export const selectChjzrs = (state) => state.ZXYYZSSMH.chjzrs;
export const selectChxsrs = (state)=>state.ZXYYZSSMH.chxsrs;
export const selectYjschr = (state) => state.ZXYYZSSMH.yjschr
export const selectHygcjj = (state) => state.ZXYYZSSMH.hygcjj
export const selectFileList1= (state) => state.ZXYYZSSMH.fileList1;
export const selectFileList2= (state) => state.ZXYYZSSMH.fileList2;
export const selectFileList3= (state) => state.ZXYYZSSMH.fileList3;
export const selectFileList4= (state) => state.ZXYYZSSMH.fileList4;
export const selectSBPSSchoolIndex = (state) => state.ZXYYZSSMH.SBPSSchoolIndex;
export const selectSBPSSchoolList = (state) => state.ZXYYZSSMH.SBPSSchoolList;
export const selectZSSMHSchoolList = (state) => state.ZXYYZSSMH.ZSSMHSchoolList;
export const selectpsjlIndex = (state) => state.ZXYYZSSMH.psjlIndex;
export const selectpsjlList = (state) => state.ZXYYZSSMH.psjlList;
export const selectZSSMHDetailInfo = (state) => state.ZXYYZSSMH.ZSSMHDetailInfo;
export const {
    setZSSMHXueqiList,
    setZSSMHXueqiIndex,
    setSBPSSchoolIndex,
    setSBPSSchoolList,
    setZSSMHSchoolList,
    setRangeTime,
    setXxchr,
    setChjzrs,
    setChxsrs,
    setYjschr,
    setHygcjj,
    setFileList1,
    setFileList2,
    setFileList3,
    setFileList4,
    setpsjlIndex,
    setpsjlList,
    setZSSMHDetailInfo,
} = ZXYYZSSMHSlice.actions;

export default ZXYYZSSMHSlice.reducer;