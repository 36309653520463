import {
    createSlice
} from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
    ZSYBHXueqiList:[],
    ZSYBHXueqiIndex:0,
    SBPSSchoolIndex:0,
    SBPSSchoolList:[],//已完成学术说明会的学校
    ZSYBHSchoolList:[],
    rangeTime:["",""],

    xxchr:"",
    yjschr:"",
    hygcjj:"",
    fileList1:[],
    fileList2:[],
    fileList3:[],
    fileList4:[],

    psjlIndex:0,
    psjlList:[{
        name:"暂无",
        code:"1"
    },{
        name:"通过",
        code:"2"
    },{
        name:"不通过",
        code:"3"
    }],
    ZSYBHDetailInfo:{}
};

export const ZXYYZSYBHSlice = createSlice({
    name: 'ZXYYZSYBH',
    initialState,
    reducers: {
        setZSYBHXueqiList:(state, action) => {
            state.ZSYBHXueqiList = action.payload;
        },
        setZSYBHXueqiIndex:(state, action) => {
            state.ZSYBHXueqiIndex = action.payload;
        },
        setSBPSSchoolIndex:(state, action) => {
            state.SBPSSchoolIndex = action.payload;
        },

        setSBPSSchoolList:(state, action) => {
            state.SBPSSchoolList = action.payload;
        },
        setZSYBHSchoolList:(state, action) => {
            state.ZSYBHSchoolList = action.payload;
        },
        setRangeTime:(state, action) => {
            state.rangeTime = action.payload;
        },
        setXxchr:(state, action) => {
            state.xxchr = action.payload;
        },
        setYjschr:(state, action) => {
            state.yjschr = action.payload;
        },
        setHygcjj:(state, action) => {
            state.hygcjj = action.payload;
        },
        setFileList1:(state, action) => {
            state.fileList1 = action.payload;
        },
        setFileList2:(state, action) => {
            state.fileList2 = action.payload;
        },
        setFileList3:(state, action) => {
            state.fileList3 = action.payload;
        },
        setFileList4:(state, action) => {
            state.fileList4 = action.payload;
        },
        setpsjlIndex:(state, action) => {
            state.psjlIndex = action.payload;
        },
        setpsjlList:(state, action) => {
            state.psjlList = action.payload;
        },
        setZSYBHDetailInfo:(state, action) => {
            state.ZSYBHDetailInfo = action.payload;
        }



    },
});
export const selectZSYBHXueqiList = (state) => state.ZXYYZSYBH.ZSYBHXueqiList;
export const selectZSYBHXueqiIndex = (state) => state.ZXYYZSYBH.ZSYBHXueqiIndex;
export const selectRangeTime = (state) => state.ZXYYZSYBH.rangeTime;
export const selectXxchr = (state) => state.ZXYYZSYBH.xxchr;
export const selectYjschr = (state) => state.ZXYYZSYBH.yjschr
export const selectHygcjj = (state) => state.ZXYYZSYBH.hygcjj
export const selectFileList1= (state) => state.ZXYYZSYBH.fileList1;
export const selectFileList2= (state) => state.ZXYYZSYBH.fileList2;
export const selectFileList3= (state) => state.ZXYYZSYBH.fileList3;
export const selectFileList4= (state) => state.ZXYYZSYBH.fileList4;
export const selectSBPSSchoolIndex = (state) => state.ZXYYZSYBH.SBPSSchoolIndex;
export const selectSBPSSchoolList = (state) => state.ZXYYZSYBH.SBPSSchoolList;
export const selectZSYBHSchoolList = (state) => state.ZXYYZSYBH.ZSYBHSchoolList;
export const selectpsjlIndex = (state) => state.ZXYYZSYBH.psjlIndex;
export const selectpsjlList = (state) => state.ZXYYZSYBH.psjlList;
export const selectZSYBHDetailInfo = (state) => state.ZXYYZSYBH.ZSYBHDetailInfo;
export const {
    setZSYBHXueqiList,
    setZSYBHXueqiIndex,
    setSBPSSchoolIndex,
    setSBPSSchoolList,
    setZSYBHSchoolList,
    setRangeTime,
    setXxchr,
    setYjschr,
    setHygcjj,
    setFileList1,
    setFileList2,
    setFileList3,
    setFileList4,
    setpsjlIndex,
    setpsjlList,
    setZSYBHDetailInfo,
} = ZXYYZSYBHSlice.actions;

export default ZXYYZSYBHSlice.reducer;