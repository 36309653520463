export function getMoneyDate(type,dataString) {
    if(dataString == null){
        return "0.00";
    }
    else if(type === 'fen') {
        return parseFloat(dataString / 100).toFixed(2)
    }
    else {
        return parseFloat(dataString).toFixed(2)
    }
}