import React ,{ useRef,useState,useEffect }from "react";
import styles from "./CWZGMain.module.css";
import classNames from "classnames"
import { useSelector, useDispatch } from 'react-redux';
import logoImg from "../../../img/logo.png"
import { selectMenuIndex, setMenuIndex } from "./CWZGMainSlice";
import PaymentQuery from '../PaymentQuery/PaymentQuery'
import { QRApproval } from "../QRApproval/QRApproval";
import {QueryAndExport} from "../QueryAndExport/QueryAndExport";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPowerOff,faChartColumn} from '@fortawesome/free-solid-svg-icons';
import { openLogin,closeCWZGMain } from "../../../store/modalsSlice";
import { loginOutRequest } from "../../Login/loginApi";
export function CWZGMain() {
    const menuIndex = useSelector(selectMenuIndex);
    const dispatch = useDispatch();
    function leftMenuMethod(index){
        dispatch(setMenuIndex(index))
    }
    function signOutMethod() {
        loginOutRequest((data)=>{
        })
        sessionStorage.clear()
        dispatch(openLogin())
        dispatch(closeCWZGMain())
    }
    return (
        <div className={styles.background}>
            <div className={styles.topContainer}>
                <div className={styles.topLeftMenus}>
                    <img 
                        className={styles.logIcon}
                        src={logoImg}
                    ></img>
                    {/* <span className={styles.logoTitle}>全球胜任力研究所</span> */}
                    <span className={styles.logoTitle}>TBI教育集团</span>
                </div>
                <div className={styles.topRightMenus}>
                    <div className={styles.userName}>{sessionStorage.getItem("user_type_name")+"\u00A0\u00A0"+sessionStorage.getItem("name")}</div>
                    <div className={styles.signOutButton}
                        onClick={signOutMethod}
                    >
                        <FontAwesomeIcon icon={faPowerOff}/>
                    </div>
                </div>
                
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.leftContent}>
                    <div
                        className={styles.leftMenuDes}
                    >
                        {/* <FontAwesomeIcon icon={faBarChart}/> */}
                        <FontAwesomeIcon className={styles.leftMenuDesIcon} icon={faChartColumn}/>
                        全国到款看板
                    </div>
                    <div
                        className={classNames(styles.leftMenu, menuIndex == 0 ? styles.leftSelectedMenu : null)}
                        onClick={leftMenuMethod.bind(null, 0)}
                    >学校收费二维码审核
                    </div>
                    <div
                        className={classNames(styles.leftMenu, menuIndex == 1 ? styles.leftSelectedMenu : null)}
                        onClick={leftMenuMethod.bind(null, 1)}
                    >学校开课到款
                    </div>
                    <div
                        className={classNames(styles.leftMenu, menuIndex == 2 ? styles.leftSelectedMenu : null)}
                        onClick={leftMenuMethod.bind(null, 2)}
                    >财务综合查询导出
                    </div>

                </div>
                <div className={styles.rightContent}>
                    {menuIndex == 0 ? <QRApproval/> : null}
                    {menuIndex == 1 ? <PaymentQuery/> : null}
                    {menuIndex == 2 ? <QueryAndExport/> : null}
                </div>
            </div>
        </div>
    );
};
