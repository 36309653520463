import request from "../../../util/request";


export function getRegionListRequest(regionLevel,parentCode,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "quyulist");
    filedata.append('quyu_jibie', regionLevel);
    filedata.append('quyu_fuji_code', parentCode);
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body:filedata,
    }, callback);
}
export function getCompanyListRequest(provinceCode,municipalityCode,countyCode,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_quxian_gongsi_list");
    filedata.append('shengji_quyu_code', provinceCode);
    filedata.append('shiji_quyu_code', municipalityCode);
    filedata.append('quxian_quyu_code', countyCode);
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body:filedata,
    }, callback);
}

export function getInfoListRequest(regionName,contactName,offset,limit,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_zhuxiao_list");
    filedata.append('zhuxiao_quyu_namekey', regionName);
    filedata.append('zhuxiao_xingming_namekey', contactName);
    filedata.append('offset', offset);
    filedata.append('limit', limit);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body:filedata,
    }, callback);
}
export function addZhuxiao(
    provinceName,provinceCode,
    municipalityName,municipalityCode,
    countyName,countyCode,
    countyCompanyName,countyCompanyCode,
    contactName,sexTag,contactPhone,
    account,password,accountStatus,notes,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "add_zhuxiao_info");
    filedata.append('shengji_quyu_name', provinceName);
    filedata.append('shengji_quyu_code', provinceCode);
    filedata.append('shiji_quyu_name', municipalityName);
    filedata.append('shiji_quyu_code', municipalityCode);
    filedata.append('quxian_quyu_name', countyName);
    filedata.append('quxian_quyu_code', countyCode);
    filedata.append('quxian_gongsi_name', countyCompanyName);
    filedata.append('quxian_gongsi_code', countyCompanyCode);
    filedata.append('xingming', contactName);
    filedata.append('xingbie', sexTag);
    filedata.append('lianxi_dianhua', contactPhone);
    filedata.append('user_login', account);
    filedata.append('password', password);
    filedata.append('status', accountStatus);
    filedata.append('beizhu', notes);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function updateZhuxiao(
    provinceName,provinceCode,
    municipalityName,municipalityCode,
    countyName,countyCode,
    countyCompanyName,countyCompanyCode,
    contactName,sexTag,contactPhone,
    account,password,accountStatus,notes,
    code,username,
    callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "update_zhuxiao_info");
    filedata.append('shengji_quyu_name', provinceName);
    filedata.append('shengji_quyu_code', provinceCode);
    filedata.append('shiji_quyu_name', municipalityName);
    filedata.append('shiji_quyu_code', municipalityCode);
    filedata.append('quxian_quyu_name', countyName);
    filedata.append('quxian_quyu_code', countyCode);
    filedata.append('quxian_gongsi_name', countyCompanyName);
    filedata.append('quxian_gongsi_code', countyCompanyCode);
    filedata.append('xingming', contactName);
    filedata.append('xingbie', sexTag);
    filedata.append('lianxi_dianhua', contactPhone);
    filedata.append('user_login', account);
    filedata.append('password', password);
    filedata.append('status', accountStatus);
    filedata.append('beizhu', notes);
    filedata.append('code', code);
    filedata.append('username', username);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function openAccount(code,username,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "kaitong_zhuxiao_zhanghao");
    filedata.append('code', code);
    filedata.append('username', username);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function closeAccount(code,username,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "jinyong_zhuxiao_zhanghao");
    filedata.append('code', code);
    filedata.append('username', username);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function deleteAccount(code,username,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "del_zhuxiao");
    filedata.append('code', code);
    filedata.append('username', username);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}