import React, {useRef} from "react";
import styles from "./QRDownload.module.css";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames"
import {exportComponentAsPDF, exportComponentAsPNG} from 'react-component-export-image';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faL, faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import QRCodeImage from "./QRCodeImage";
import {selectDownloadStatus, selectQRContentStr, setQRContentStr} from "./QRApprovalSlice";
import {selectQRSchoolName} from "../../Zhuxiaoyunying/QueryAndGetQR/QueryAndGetQRSlice";

export default function QRDownload(props) {
    const componentRef = useRef();
    const QRContentStr = useSelector(selectQRContentStr);
    const QRSchoolName = useSelector(selectQRSchoolName)
    const downloadStatus = useSelector(selectDownloadStatus);
    const dispatch = useDispatch();
    return <div className={styles.background}
        // onClick={dispatch(setQRContentStr(""))}
    >
        <div className={styles.imgContainer}>
            <QRCodeImage
                QrCodeValue={QRContentStr}
                QRSchoolName={QRSchoolName}
                ref={componentRef}/>
        </div>

        {/* <QRCodeImage
        QrCodeValue={QRContentStr}
        ref={componentRef}/> */}
        <div className={styles.buttonsContainer}>
            <div className={styles.downloadBtn}
                 onClick={() => {
                     dispatch(setQRContentStr(""))
                 }}
            >关闭当前页
            </div>
            {downloadStatus ?
                <div className={styles.downloadBtn}
                     onClick={() => {
                         var scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
                         var scrollBarHeight = window.innerHeight - document.documentElement.clientHeight;
                         exportComponentAsPNG(componentRef, {
                             "fileName": "收费二维码",
                             html2CanvasOptions: {scrollX: -scrollBarWidth / 2, scrollY: -scrollBarHeight / 2}
                         })
                     }}
                >下载二维码</div> : null
            }


        </div>

    </div>
}

