import request from "../../../util/request";


export function getRegionListRequest(regionLevel,parentCode,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "quyulist");
    filedata.append('quyu_jibie', regionLevel);
    filedata.append('quyu_fuji_code', parentCode);
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body:filedata,
    }, callback);
}

export function getInfoListRequest(regionName,companyName,approvalStatus,offset,limit,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_shengji_quyu_list");
    filedata.append('shengji_quyu_namekey', regionName);
    filedata.append('shengji_gongsi_namekey', companyName);
    filedata.append('shengji_quyu_lianxiren_namekey', approvalStatus);
    filedata.append('offset', offset);
    filedata.append('limit', limit);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body:filedata,
    }, callback);
}
export function addProvinceCompany(provinceName,provinceCode,provinceschoolName,approvalStatus,contactPhone,telephone1,telephone2,telephone3,account,password,accountStatus,notes,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "add_shengji_quyu");
    filedata.append('shengji_quyu_name', provinceName);
    filedata.append('shengji_quyu_code', provinceCode);
    filedata.append('shengji_gongsi_name', provinceschoolName);
    filedata.append('lianxiren', approvalStatus);
    filedata.append('lianxi_dianhua', contactPhone);
    filedata.append('zuoji_quhao', telephone1);
    filedata.append('zuoji_dianhuahao', telephone2);
    filedata.append('zuoji_fenjihao', telephone3);
    filedata.append('user_login', account);
    filedata.append('password', password);
    filedata.append('status', accountStatus);
    filedata.append('beizhu', notes);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function updateProvinceCompany(
    provinceName,provinceCode,provinceschoolName,
    approvalStatus,contactPhone,telephone1,telephone2,
    telephone3,account,password,accountStatus,notes,
    code,username,
    callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "update_shengji_quyu");
    filedata.append('shengji_quyu_name', provinceName);
    filedata.append('shengji_quyu_code', provinceCode);
    filedata.append('shengji_gongsi_name', provinceschoolName);
    filedata.append('lianxiren', approvalStatus);
    filedata.append('lianxi_dianhua', contactPhone);
    filedata.append('zuoji_quhao', telephone1);
    filedata.append('zuoji_dianhuahao', telephone2);
    filedata.append('zuoji_fenjihao', telephone3);
    filedata.append('user_login', account);
    filedata.append('password', password);
    filedata.append('status', accountStatus);
    filedata.append('beizhu', notes);
    filedata.append('code', code);
    filedata.append('username', username);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function openAccount(code,username,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "kaitong_shengji_quyu_zhanghao");
    filedata.append('code', code);
    filedata.append('username', username);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function closeAccount(code,username,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "jinyong_shengji_quyu_zhanghao");
    filedata.append('code', code);
    filedata.append('username', username);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function deleteAccount(code,username,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "del_shengji_quyu");
    filedata.append('code', code);
    filedata.append('username', username);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}