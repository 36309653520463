
import React,{ useRef,useState,useEffect }from "react";
import styles from "./InfoItem.module.css";
import classNames from "classnames"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheckCircle, faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import { selectCompanyName, selectContactName, selectInfoList, selectPageNumber, selectTeacherName, setCurrentPage, setEditInfo, setInfoList, setShowEditInfoPage, setTotalPageNum } from "./TeacherManageSlice";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../../util/constants";
import { closeAccount, deleteAccount, getInfoListRequest, openAccount } from "./TeacherManageApi";
import { CustomConfirmAlert } from "../../CustomView/CustomConfirmAlert";
import { setAlertInfo } from "../../../store/CustomViewSlice";
// import {  shenheCourseConfig } from "./TeacherManageApi";
// const PaymentInfoList = props =>(
export default function InfoItem(props) {
    const dispatch = useDispatch();
    const pageNumber = useSelector(selectPageNumber);
    const regionName = useSelector(selectTeacherName);
    const companyName = useSelector(selectCompanyName);
    const contactName = useSelector(selectContactName);
    const [confirmInfo,setConfirmInfo]=useState({})
    

    function refreshMethod() {
        dispatch(setCurrentPage(1))
        getInfoList(1,pageNumber)
    }
    function getInfoList(tempCurrentPage,tempPageNumber) {
        getInfoListRequest(regionName,companyName,contactName,(tempCurrentPage-1)*tempPageNumber,tempPageNumber,(data)=>{
            if (data.ret==1) {
                dispatch(setInfoList(data.data.shengji_quyu_list))
                var pageNumber1 = Math.ceil(data.data.total_num/tempPageNumber)
                dispatch(setTotalPageNum(pageNumber1))
            } else {
                dispatch(setInfoList([]))
                dispatch(setTotalPageNum(1))
            }
        })
    }
    function cancelMethod(params) {
        setConfirmInfo({
            showStatus:false,
            title:"",
            title1:"",
            content:""
        })
    }
    function confirmMethod(params) {
        setConfirmInfo({
            showStatus:false,
            title:"",
            title1:"",
            content:""
        })
        if (confirmInfo.eventId==1) {
            openAccount(
                props.item.code,
                props.item.login_username,
                (data)=>{
                    if (data.ret==1) {
                        refreshMethod()
                    } else {
                        dispatch(setAlertInfo({
                            showStatus:true,
                            title:"",
                            title1:"账户开通失败，请检查重试",
                            content:""
                        }))
                    }
                }
            )
        } else if(confirmInfo.eventId==2) {
            closeAccount(
                props.item.code,
                props.item.login_username,
                (data)=>{
                    if (data.ret==1) {
                        refreshMethod()
                    } else {
                        dispatch(setAlertInfo({
                            showStatus:true,
                            title:"",
                            title1:"账户禁用失败，请检查重试",
                            content:""
                        }))
                    }
                }
            )
        }
        else if(confirmInfo.eventId==3){
            deleteAccount(
                props.item.code,
                props.item.login_username,
                (data)=>{
                    if (data.ret==1) {
                        refreshMethod()
                    } else {
                        dispatch(setAlertInfo({
                            showStatus:true,
                            title:"",
                            title1:"账户删除失败，请检查重试",
                            content:""
                        }))
                    }
                }
            )
        }
    }

    return <div className={styles.background}>
        <CustomConfirmAlert 
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        <div className={classNames(styles.listContent,styles.itemWidth1)}>
            <span className={styles.listContentSpan}>{props.item.quyu_name}</span>
        </div>
        <div className={classNames(styles.listContent,styles.itemWidth2)}>
            <span className={styles.listContentSpan}>{props.item.gongsi_name}</span>
        </div>
        <div className={classNames(styles.listContent,styles.itemWidth3)}>
            <span className={styles.listContentSpan}>{props.item.lianxiren}</span>
        </div>
        <div className={classNames(styles.listContent,styles.itemWidth4)}>
            <span className={styles.listContentSpan}>{props.item.lianxi_dianhua}</span>
        </div>
        <div className={classNames(styles.listContent,styles.itemWidth5)}>
            <span className={styles.listContentSpan}>{props.item.ctime}</span>
        </div>
        <div className={classNames(styles.listContent,styles.itemWidth6)}>
            <span className={styles.listContentSpan}>{props.item.status==1?"开启":props.item.status==2?"禁用":props.item.status==3?"删除":""}</span>
        </div>
        <div className={classNames(styles.listContent,styles.itemWidth7)}>
            <span className={styles.listContentSpan}>{props.item.status==1?"开启":props.item.status==2?"禁用":props.item.status==3?"删除":""}</span>
        </div>
        <div className={classNames(styles.listContent,styles.itemWidth8)}>
            
            <span className={classNames(styles.listContentSpan,styles.manageDes)}
                onClick={(e)=>{
                    if (props.item.status==1) {
                        dispatch(setAlertInfo({
                            showStatus:true,
                            title:"",
                            title1:"该账号已处于启用状态",
                            content:""
                        }))
                    }
                    else{
                        setConfirmInfo({
                            showStatus:true,
                            title:"",
                            title1:'是否启用该教师？',
                            content:"启用该教师",
                            eventId:1
                        })
                    }
                    
                }}
            >启用</span>
            <span className={classNames(styles.listContentSpan,styles.manageDes)}
                onClick={(e)=>{
                    if (props.item.status==2) {
                        dispatch(setAlertInfo({
                            showStatus:true,
                            title:"",
                            title1:"该账号已处于停用状态",
                            content:""
                        }))
                    }
                    else{
                        setConfirmInfo({
                            showStatus:true,
                            title:"",
                            title1:'是否停用该教师？',
                            content:"停用该教师，停用后无法进行手动或自动排课。",
                            eventId:2
                        })
                    }
                }}
            >停用</span>
            <span className={classNames(styles.listContentSpan,styles.manageDes)}
                onClick={(e)=>{
                    dispatch(setEditInfo(props.item))
                    dispatch(setShowEditInfoPage(true))
                }}
            >编辑</span>
            <span className={classNames(styles.listContentSpan,styles.manageDes)}
                onClick={(e)=>{
                    setConfirmInfo({
                        showStatus:true,
                        title:"",
                        title1:'是否确定删除该账号？',
                        content:"",
                        eventId:3
                    })
                }}
            >删除</span>
        </div>
    </div>
}
// export default PaymentInfoList;

