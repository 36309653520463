import request from "../../../util/request";

export function getRegionListRequest(regionLevel,parentCode,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "quyulist");
    filedata.append('quyu_jibie', regionLevel);
    filedata.append('quyu_fuji_code', parentCode);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body:filedata,
    }, callback);
}
export function getSchools(regionCode,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_school_list");
    filedata.append('quyu_code', regionCode);
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}

export function getInfoListRequest(selectCode,nameKey,offset,limit,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "kaikedaokuan_list");
    filedata.append('school_code', selectCode);
    filedata.append('shenhe_status', "1");
    filedata.append('namekey', nameKey);
    filedata.append('offset', offset);
    filedata.append('limit', limit);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body:filedata,
    }, callback);
}
export function shenhePayment(kaikedaokuan_json,shenhe_status,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "kaikedaokuan_queren");
    filedata.append('kaikedaokuan_json', kaikedaokuan_json);
    filedata.append('shenhe_status', shenhe_status);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function wxRefundRequest(out_trade_no,total_fee,refund_fee,reason,baoming_id,kecheng_jine,jiaoju_jine,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append("out_trade_no",out_trade_no);
    filedata.append("total_fee",total_fee);
    filedata.append("refund_fee",refund_fee);
    filedata.append("reason",reason);
    filedata.append("baoming_id",baoming_id);
    filedata.append("kecheng_jine",kecheng_jine);
    filedata.append("jiaoju_jine",jiaoju_jine);
    filedata.append('username', sessionStorage.getItem('username'));
    return request("/wxpayxcx/example/refund.php", {
        method: "POST",
        body: filedata
    }, callback);
}
export function zfbRefundRequest(out_trade_no,refund_fee,reason,baoming_id,kecheng_jine,jiaoju_jine,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append("out_trade_no",out_trade_no);
    // filedata.append("total_fee",total_fee);
    filedata.append("refund_fee",refund_fee);
    filedata.append("reason",reason);
    filedata.append("baoming_id",baoming_id);
    filedata.append("kecheng_jine",kecheng_jine);
    filedata.append("jiaoju_jine",jiaoju_jine);
    filedata.append("username",jiaoju_jine);
    filedata.append('username', sessionStorage.getItem('username'));
    console.log(filedata)
    return request("/alipayv2/bss/alirefund.php", {
        method: "POST",
        body: filedata
    }, callback);
}
export function getRefoundRecord(baoming_id,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append("caozuo","tuikuan_list");
    filedata.append("baoming_id",baoming_id);
    console.log(filedata)
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}