import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
  menuIndex:0,
  schoolList:[]
};

export const CPZJLMainSlice = createSlice({
  name: 'CPZJLMain',
  initialState,
  reducers: {
    setMenuIndex: (state,action) => {
        state.menuIndex = action.payload;
    }
  },
});

export const selectMenuIndex = (state) => state.CPZJLMain.menuIndex;


export const { 
  setMenuIndex
} = CPZJLMainSlice.actions;

export default CPZJLMainSlice.reducer;
