import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from 'react-redux';
import styles from "./SKJHMange.module.css";
import classNames from "classnames"
import TopControl from "../../TopControl/TopControl"
import Footer from "../../Footer/Footer";
import ExcelUtil from '../../../util/excelUtil';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
// import {getSchools} from './QueryAndGetQRApi'
import ConditionalQuery from "./ConditionalQuery";
import {
    selectSchoolName,
    selectApprovalStatus,
    selectPageNumber,
    selectRegionName,
    setCurrentPage,
    setInfoList,
    setTotalPageNum,
    selectShowReviewCourseInfo, selectShowSDPKPage
} from "./SKJHMangeSlice";
import InfoList from "./InfoList";
import BottomControl from "./BottomControl";
import {getInfoListRequest, getRegionListRequest} from "./SKJHMangeApi";
import ReviewCourseInfo from "./ReviewCourseInfo";
import SDPKPage from "./SDPKPage";

// import {getPaymentInfoList} from './QueryAndGetQRApi'
export function SKJHMange(props) {

    const pageNumber = useSelector(selectPageNumber);
    const regionName = useSelector(selectRegionName);
    const companyName = useSelector(selectSchoolName);
    const approvalStatus = useSelector(selectApprovalStatus);
    const showReviewCourseInfo = useSelector(selectShowReviewCourseInfo)
    const showSDPKPage = useSelector(selectShowSDPKPage)
    // const currentPage = useSelector(selectCurrentPage)


    const dispatch = useDispatch();

    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        getProvinceList()
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    function getProvinceList() {
        getRegionListRequest("1", "", (data) => {
            var newProvinceList = [{
                quyu_name: "未选择",
                quyu_code: ""
            }];
            if (data.ret == 1) {
                data.data.forEach(element => {
                    newProvinceList.push(element)
                });
            }
        })
    }

    function refreshMethod() {
        dispatch(setCurrentPage(1))
        getInfoList(1, pageNumber)
    }

    function getInfoList(tempCurrentPage, tempPageNumber) {
        getInfoListRequest(regionName, companyName, approvalStatus, (tempCurrentPage - 1) * tempPageNumber, tempPageNumber, (data) => {
            if (data.ret == 1) {
                dispatch(setInfoList(data.data.shengji_quyu_list))
                var pageNumber1 = Math.ceil(data.data.total_num / tempPageNumber)
                dispatch(setTotalPageNum(pageNumber1))
            } else {
                dispatch(setInfoList([]))
                dispatch(setTotalPageNum(1))
            }
        })
    }

    return <div className={styles.background}>
        <TopControl
            leftTopTitle="学校开课审批"
            showRefreshButton={false}
            // refreshMethod={refreshMethod}
        />
        <ConditionalQuery/>
        <InfoList/>
        <BottomControl
            pageNumberList={[5, 10, 15, 20, 25, 30]}
        />
        <Footer/>
        {showReviewCourseInfo ? <ReviewCourseInfo/> : null}
        {showSDPKPage ? <SDPKPage/> : null}

    </div>
}

