import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
    infoCheckedAll: false,
    infoList: [],


    //分页所需参数
    groupCount: 5,
    startPage: 1,
    pageNumber: 5,
    currentPage: 1,
    totalPageNum: 1,


    // 数据筛选所需参数
    schoolName: '',
    regionName: '',
    approvalStatus: -1,

    approvalList: [{
        reviewName: "待审核",
        reviewCode: "0"
    }, {
        reviewName: "已通过",
        reviewCode: "1"
    }, {
        reviewName: "已拒绝",
        reviewCode: "2"
    }, {
        reviewName: "全部",
        reviewCode: ""
    }],
    showReviewCourseInfo: false,
    reviewCourseInfoList: [1, 2, 3, 4, 5, 6, 7],
    showSDPKPage: false //是否显示手动排课
};

export const YYZJSKJHMangeSlice = createSlice({
    name: 'YYZJSKJHMange',
    initialState,
    reducers: {
        setInfoCheckedAll: (state, action) => {
            state.infoCheckedAll = action.payload;
        },
        setInfoList: (state, action) => {
            state.infoList = action.payload;
        },


        setGroupCount: (state, action) => {
            state.groupCount = action.payload;
        },
        setStartPage: (state, action) => {
            state.startPage = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setTotalPageNum: (state, action) => {
            state.totalPageNum = action.payload;
        },
        setRegionName: (state, action) => {
            state.regionName = action.payload;
        },
        setSchoolName: (state, action) => {
            state.schoolName = action.payload;
        },
        setApprovalStatus: (state, action) => {
            state.approvalStatus = action.payload;
        },
        setApprovalList: (state, action) => {
            state.approvalList = action.payload;
        },
        setShowReviewCourseInfo: (state, action) => {
            state.showReviewCourseInfo = action.payload;
        },
        setReviewCourseInfoList: (state, action) => {
            state.reviewCourseInfoList = action.payload;
        },
        setShowSDPKPage: (state, action) => {
            state.showSDPKPage = action.payload;
        },

    },
});
export const selectInfoCheckedAll = (state) => state.YYZJSKJHMange.infoCheckedAll;
export const selectInfoList = (state) => state.YYZJSKJHMange.infoList;
//分页所需数据
export const selectGroupCount = (state) => state.YYZJSKJHMange.groupCount;
export const selectStartPage = (state) => state.YYZJSKJHMange.startPage;
export const selectPageNumber = (state) => state.YYZJSKJHMange.pageNumber;
export const selectCurrentPage = (state) => state.YYZJSKJHMange.currentPage;
export const selectTotalPageNum = (state) => state.YYZJSKJHMange.totalPageNum;
export const selectRegionName = (state) => state.YYZJSKJHMange.regionName;
export const selectSchoolName = (state) => state.YYZJSKJHMange.schoolName;
export const selectApprovalStatus = (state) => state.YYZJSKJHMange.approvalStatus;
export const selectApprovalList = (state) => state.YYZJSKJHMange.approvalList;
export const selectShowReviewCourseInfo = (state) => state.YYZJSKJHMange.showReviewCourseInfo;
export const selectReviewCourseInfoList = (state) => state.YYZJSKJHMange.reviewCourseInfoList;
export const selectShowSDPKPage = (state) => state.YYZJSKJHMange.showSDPKPage;


export const {
    setInfoCheckedAll,
    setInfoList,
    setGroupCount,
    setStartPage,
    setPageNumber,
    setCurrentPage,
    setTotalPageNum,
    setRegionName,
    setSchoolName,
    setApprovalStatus,
    setApprovalList,
    setShowReviewCourseInfo,
    setReviewCourseInfoList,
    setShowSDPKPage

} = YYZJSKJHMangeSlice.actions;

export default YYZJSKJHMangeSlice.reducer;