import React, {useRef, useState, useEffect} from "react";
import styles from "./InfoItem.module.css";
import classNames from "classnames"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import {
    selectSchoolName,
    selectApprovalStatus,
    selectInfoList,
    selectPageNumber,
    selectRegionName,
    setCurrentPage,
    setInfoList,
    setShowEditInfoPage,
    setTotalPageNum,
    setInfoCheckedAll, setShowReviewCourseInfo, setShowSDPKPage
} from "./SKJHMangeSlice";
import {useDispatch, useSelector} from "react-redux";
import {API_URL} from "../../../util/constants";
import {closeAccount, deleteAccount, getInfoListRequest, openAccount} from "./SKJHMangeApi";
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {setAlertInfo} from "../../../store/CustomViewSlice";
import {Checkbox} from "antd";
// import {  shenheCourseConfig } from "./CourseApprovalApi";
// const PaymentInfoList = props =>(
export default function InfoItem(props) {
    const dispatch = useDispatch();
    const pageNumber = useSelector(selectPageNumber);
    const regionName = useSelector(selectRegionName);
    const companyName = useSelector(selectSchoolName);
    const approvalStatus = useSelector(selectApprovalStatus);
    const infoList = useSelector(selectInfoList);
    const [confirmInfo, setConfirmInfo] = useState({})


    function refreshMethod() {
        dispatch(setCurrentPage(1))
        getInfoList(1, pageNumber)
    }

    function getInfoList(tempCurrentPage, tempPageNumber) {
        getInfoListRequest(regionName, companyName, approvalStatus, (tempCurrentPage - 1) * tempPageNumber, tempPageNumber, (data) => {
            if (data.ret == 1) {
                dispatch(setInfoList(data.data.shengji_quyu_list))
                var pageNumber1 = Math.ceil(data.data.total_num / tempPageNumber)
                dispatch(setTotalPageNum(pageNumber1))
            } else {
                dispatch(setInfoList([]))
                dispatch(setTotalPageNum(1))
            }
        })
    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
            openAccount(
                props.item.code,
                props.item.login_username,
                (data) => {
                    if (data.ret == 1) {
                        refreshMethod()
                    } else {
                        dispatch(setAlertInfo({
                            showStatus: true,
                            title: "",
                            title1: "账户开通失败，请检查重试",
                            content: ""
                        }))
                    }
                }
            )
        } else if (confirmInfo.eventId == 2) {
            closeAccount(
                props.item.code,
                props.item.login_username,
                (data) => {
                    if (data.ret == 1) {
                        refreshMethod()
                    } else {
                        dispatch(setAlertInfo({
                            showStatus: true,
                            title: "",
                            title1: "账户禁用失败，请检查重试",
                            content: ""
                        }))
                    }
                }
            )
        } else if (confirmInfo.eventId == 3) {
            deleteAccount(
                props.item.code,
                props.item.login_username,
                (data) => {
                    if (data.ret == 1) {
                        refreshMethod()
                    } else {
                        dispatch(setAlertInfo({
                            showStatus: true,
                            title: "",
                            title1: "账户删除失败，请检查重试",
                            content: ""
                        }))
                    }
                }
            )
        }
    }

    function onChange(e) {
        let tempInfoList = []
        let checkedAll = true;
        infoList.forEach(element => {
            var newElement = {}
            for (let key in element) {
                if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                    newElement[key] = element[key]
                }
            }
            if (newElement.Id == props.item.Id) {
                if (e.target.checked) {
                    newElement.checked = true
                } else {
                    newElement.checked = false
                }
            }
            tempInfoList.push(newElement)
            if (newElement.checked == false) {
                checkedAll = false
            }
        });
        console.log(tempInfoList)
        dispatch(setInfoList(tempInfoList))
        dispatch(setInfoCheckedAll(checkedAll))
    }

    return <div className={styles.background}>
        <CustomConfirmAlert
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        <div className={classNames(styles.listContent, styles.itemWidth1)}>
            <Checkbox defaultChecked={false} checked={props.item.checked} onChange={onChange}/>
        </div>
        <div className={classNames(styles.listContent, styles.itemWidth2)}>
            <span className={styles.listContentSpan}>{props.item.lianxiren}</span>
        </div>
        <div className={classNames(styles.listContent, styles.itemWidth3)}>
            <a className={styles.listContentSpan1}
               onClick={(e) => {
                   dispatch(setShowReviewCourseInfo(true))
               }}
            >{props.item.gongsi_name}</a>
        </div>
        <div className={classNames(styles.listContent, styles.itemWidth4)}>
            <span className={styles.listContentSpan}>{props.item.lianxi_dianhua}</span>
        </div>
        <div className={classNames(styles.listContent, styles.itemWidth5)}>
            <span className={styles.listContentSpan}>{props.item.ctime}</span>
        </div>
        <div className={classNames(styles.listContent, styles.itemWidth6)}>
            <span className={styles.listContentSpan}>{props.item.ctime}</span>
        </div>
        <div className={classNames(styles.listContent, styles.itemWidth7)}>
            <span className={styles.listContentSpan}>{props.item.ctime}</span>
        </div>

        <div className={classNames(styles.listContent, styles.itemWidth8)}>
            <span className={classNames(styles.listContentSpan1, styles.manageDes)}
                  onClick={(e) => {
                      dispatch(setShowSDPKPage(true))
                  }}
            >手动排课</span>
            <span className={classNames(styles.listContentSpan1, styles.manageDes)}
                  onClick={(e) => {

                  }}
            >自动排课</span>
            <span className={classNames(styles.listContentSpan1, styles.manageDes)}
                  onClick={(e) => {

                  }}
            >注入Classin</span>
        </div>
    </div>
}
// export default PaymentInfoList;

