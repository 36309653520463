import {
    createSlice
} from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
    XSSMXueqiList:[],
    XSSMXueqiIndex:0,
    XSSMSchoolIndex:0,
    XSSMSchoolList:[],//已完成学术说明会的学校
    rangeTime:["",""],

    xxchr:"",
    yjschr:"",
    fileList:[],

    hyjlIndex:0,
    hyjlList:[{
        name:"暂无",
        code:"1"
    },{
        name:"预计将推进到申报评审环节",
        code:"2"
    },{
        name:"预计不再推进",
        code:"3"
    }],
    XSSMDetailInfo:{}
};

export const ZXYYXSSMHJSlice = createSlice({
    name: 'ZXYYXSSMHJ',
    initialState,
    reducers: {
        setXSSMXueqiList:(state, action) => {
            state.XSSMXueqiList = action.payload;
        },
        setXSSMXueqiIndex:(state, action) => {
            state.XSSMXueqiIndex = action.payload;
        },
        setXSSMSchoolIndex:(state, action) => {
            state.XSSMSchoolIndex = action.payload;
        },

        setXSSMSchoolList:(state, action) => {
            state.XSSMSchoolList = action.payload;
        },
        setRangeTime:(state, action) => {
            state.rangeTime = action.payload;
        },
        setXxchr:(state, action) => {
            state.xxchr = action.payload;
        },
        setYjschr:(state, action) => {
            state.yjschr = action.payload;
        },
        setFileList:(state, action) => {
            state.fileList = action.payload;
        },
        setHyjlIndex:(state, action) => {
            state.hyjlIndex = action.payload;
        },
        setHyjlList:(state, action) => {
            state.hyjlList = action.payload;
        },
        setXSSMDetailInfo:(state, action) => {
            state.XSSMDetailInfo = action.payload;
        }


    },
});
export const selectXSSMXueqiList = (state) => state.ZXYYXSSMHJ.XSSMXueqiList;
export const selectXSSMXueqiIndex = (state) => state.ZXYYXSSMHJ.XSSMXueqiIndex;
export const selectXSSMSchoolIndex = (state) => state.ZXYYXSSMHJ.XSSMSchoolIndex;
export const selectRangeTime = (state) => state.ZXYYXSSMHJ.rangeTime;
export const selectXxchr = (state) => state.ZXYYXSSMHJ.xxchr;
export const selectYjschr = (state) => state.ZXYYXSSMHJ.yjschr;
export const selectFileList= (state) => state.ZXYYXSSMHJ.fileList;
export const selectXSSMSchoolList = (state) => state.ZXYYXSSMHJ.XSSMSchoolList;
export const selectHyjlIndex = (state) => state.ZXYYXSSMHJ.hyjlIndex;
export const selectHyjlList = (state) => state.ZXYYXSSMHJ.hyjlList;
export const selectXSSMDetailInfo = (state) => state.ZXYYXSSMHJ.XSSMDetailInfo;
export const {
    setXSSMXueqiList,
    setXSSMSchoolIndex,
    setXSSMXueqiIndex,
    setXSSMSchoolList,
    setRangeTime,
    setXxchr,
    setYjschr,
    setFileList,
    setHyjlIndex,
    setHyjlList,
    setXSSMDetailInfo
} = ZXYYXSSMHJSlice.actions;

export default ZXYYXSSMHJSlice.reducer;