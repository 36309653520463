import {
    createAsyncThunk,
    createSlice
  } from '@reduxjs/toolkit';
  // import { fetchCount } from './counterAPI';
  
  const initialState = {
    infoCheckedAll:false,
    infoList:[],

    

    //分页所需参数
    groupCount:5,
    startPage:1,
    pageNumber:5,
    currentPage:1,
    totalPageNum:1,


    // 数据筛选所需参数
    schoolName:'',
    regionName:'',
    approvalStatus:-1,

    approvalList:[{
      reviewName:"待审核",
      reviewCode:"0"
    },{
      reviewName:"已通过",
      reviewCode:"1"
    },{
      reviewName:"已拒绝",
      reviewCode:"2"
    },{
      reviewName:"全部",
      reviewCode:""
    }],
    showReviewCourseInfo:false,
    reviewCourseInfoList:[1,2,3,4,5,6,7]
  };
  
  export const YYZJCourseApprovalSlice = createSlice({
    name: 'YYZJCourseApproval',
    initialState,
    reducers: {
      setInfoCheckedAll: (state, action) => {
        state.infoCheckedAll = action.payload;
      },
      setInfoList: (state, action) => {
        state.infoList = action.payload;
      },


      setGroupCount:(state, action) => {
        state.groupCount = action.payload;
      },
      setStartPage:(state, action) => {
        state.startPage = action.payload;
      },
      setPageNumber:(state, action) => {
        state.pageNumber = action.payload;
      },
      setCurrentPage:(state, action) => {
        state.currentPage = action.payload;
      },
      setTotalPageNum:(state, action) => {
        state.totalPageNum = action.payload;
      },
      setRegionName:(state, action) => {
        state.regionName = action.payload;
      },
      setSchoolName:(state, action) => {
        state.schoolName = action.payload;
      },
      setApprovalStatus:(state, action) => {
        state.approvalStatus = action.payload;
      },
      setApprovalList:(state, action) => {
        state.approvalList = action.payload;
      },
      setShowReviewCourseInfo:(state, action) => {
        state.showReviewCourseInfo = action.payload;
      },
      setReviewCourseInfoList:(state, action) => {
        state.reviewCourseInfoList = action.payload;
      },

    },
  });
  export const selectInfoCheckedAll = (state) => state.YYZJCourseApproval.infoCheckedAll;
  export const selectInfoList = (state) => state.YYZJCourseApproval.infoList;
  //分页所需数据
  export const selectGroupCount = (state) => state.YYZJCourseApproval.groupCount;
  export const selectStartPage = (state) => state.YYZJCourseApproval.startPage;
  export const selectPageNumber = (state) => state.YYZJCourseApproval.pageNumber;
  export const selectCurrentPage = (state) => state.YYZJCourseApproval.currentPage;
  export const selectTotalPageNum = (state) => state.YYZJCourseApproval.totalPageNum;
  export const selectRegionName = (state) => state.YYZJCourseApproval.regionName;
  export const selectSchoolName = (state) => state.YYZJCourseApproval.schoolName;
  export const selectApprovalStatus = (state) => state.YYZJCourseApproval.approvalStatus;
  export const selectApprovalList = (state) => state.YYZJCourseApproval.approvalList;
  export const selectShowReviewCourseInfo = (state) => state.YYZJCourseApproval.showReviewCourseInfo;
  export const selectReviewCourseInfoList = (state) => state.YYZJCourseApproval.reviewCourseInfoList;
  
  
  export const {
    setInfoCheckedAll,
    setInfoList,
    setGroupCount,
    setStartPage,
    setPageNumber,
    setCurrentPage,
    setTotalPageNum,
    setRegionName,
    setSchoolName,
    setApprovalStatus,
    setApprovalList,
    setShowReviewCourseInfo,
    setReviewCourseInfoList

  } = YYZJCourseApprovalSlice.actions;
  
  export default YYZJCourseApprovalSlice.reducer;