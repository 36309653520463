import React, {useRef, useState, useEffect} from "react";
import styles from "./CourseInfoItem.module.css";
import classNames from "classnames"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faMinusCircle} from '@fortawesome/free-solid-svg-icons';

import {useDispatch, useSelector} from "react-redux";
import {API_URL} from "../../../util/constants";
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {setAlertInfo} from "../../../store/CustomViewSlice";
import {message} from 'antd'
import {getMoneyDate} from "../../../util/currencyUnit";
export default function CourseInfoItem(props) {
    const dispatch = useDispatch();
    const [confirmInfo, setConfirmInfo] = useState({})
    const [messageApi, contextHolder] = message.useMessage();

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
        } else if (confirmInfo.eventId == 2) {
        }
    }


    return <tr
        className={styles.tr1}
    >
        <td
            className={styles.xuhaoTd}
        >
            {props.index}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.jiaofei_time}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.tuifei_status == 1 ? '退费' : '缴费'}
        </td>
        {/*<td*/}
        {/*    className={styles.td1}*/}
        {/*>*/}
        {/*    {props.item.pay_type == 1 ? '微信' : props.item.pay_type == 2 ? "支付宝" : '第三方'}*/}
        {/*</td>*/}
        <td
            className={styles.td1}
        >
            {props.item.pay_type == 1 ? '微信' : props.item.pay_type == 2 ? "支付宝" : '第三方'}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.school_shengji_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.school_shiji_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.school_quxian_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.school_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.shoufei_zhouqi_code}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.shoufei_zhouqi_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.grade_name}
        </td>
        {/*<td*/}
        {/*    className={styles.td1}*/}
        {/*>*/}
        {/*    {props.item.class_name}*/}
        {/*</td>*/}
        <td
            className={styles.td1}
        >
            {props.item.jiazhang_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.xingbie == 1 ? '男' : '女'}
        </td>
        {/*<td*/}
        {/*    className={styles.td1}*/}
        {/*>*/}
        {/*    {props.item.shengri}*/}
        {/*</td>*/}
        <td
            className={styles.td1}
        >
            {props.item.kecheng_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.tuifei_status == 1&&props.item.pay_type == 1  ?getMoneyDate("fen",props.item.kecheng_jiage):getMoneyDate("yuan",props.item.kecheng_jiage)}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.jiaoju_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.tuifei_status == 1&&props.item.pay_type == 1  ?getMoneyDate("fen",props.item.jiaoju_jiage):getMoneyDate("yuan",props.item.jiaoju_jiage)}
        </td>
        {props.item.tuifei_status == 1 ?
            <td
                className={classNames(styles.td1, styles.refundColor)}
            >
                -{props.item.pay_type == 1 ? getMoneyDate("fen",props.item.tuifei_amount) : getMoneyDate("yuan",props.item.tuifei_amount)}
            </td> : <td
                className={styles.td1}
            >
                {props.item.pay_type == 1 ? getMoneyDate("yuan",props.item.total_jiage) : getMoneyDate("yuan",props.item.total_jiage)}
            </td>
        }



        {/*<td*/}
        {/*    className={classNames(styles.noSelect, styles.td1)}*/}
        {/*>*/}
        {/*    *******{props.item.jiazhang_phone.substring(7)}*/}
        {/*</td>*/}
        <CustomConfirmAlert
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        {contextHolder}
    </tr>
}
