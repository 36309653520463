import React, {useEffect, useRef, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import styles from "./QueryAndGetQR.module.css";
import classNames from "classnames"
import TopControl from "../../TopControl/TopControl"
import Footer from "../../Footer/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
// import {getSchools} from './QueryAndGetQRApi'
import ConditionalQuery from "./ConditionalQuery";
import { selectInfoList, selectQRContentStr, selectReviewInfo, selectSchoolList, selectShowReviewPage, setSchoolList } from "./QueryAndGetQRSlice";
import InfoList from "./InfoList";
import QRDownload from "./QRDownload";
import { setShowReviewPage } from "../../caiwuzhuguan/QRApproval/QRApprovalSlice";
import ReviewPage from "./ReviewPage";
// import {getPaymentInfoList} from './QueryAndGetQRApi'
export function QueryAndGetQR(props) {
    // const dispatch = useDispatch();
    
    // const [schoolIndex, setSchoolIndex] = useState(0);
    // const [showSchoolList, setShowSchoolList] = useState(false);//是否弹出学校列表

    const reviewPageStatus = useSelector(selectShowReviewPage);
    const reviewInfo = useSelector(selectReviewInfo);
    const QRContentStr = useSelector(selectQRContentStr);
    
    // const pageNumber = useSelector(selectPageNumber);
    // const currentPage = useSelector(selectCurrentPage)


    const dispatch = useDispatch();
    const conditionRef = useRef(null);
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    function searchMethod() {
    }
    function refreshMethod() {
        if (conditionRef){
            conditionRef.current.refresh()
        }

    }
    return <div className={styles.background}>
        <TopControl
            leftTopTitle = "收费二维码查询下载"
            showRefreshButton = {true}
            refreshMethod={refreshMethod}
        />
        <ConditionalQuery
            ref={conditionRef}
        />
        <InfoList/>
        <Footer/>
        {QRContentStr!=""?<QRDownload/>:null}
        {reviewPageStatus?
                <div className={styles.exportView}>
                    <ReviewPage 
                        // ref={componentRef}
                        // tableName = {batchName}
                        reviewInfo = {reviewInfo}
                    />
                    <div className={styles.exportButtons}>
                        <div 
                            className={styles.exportButton} 
                            onClick={(e)=>{dispatch(setShowReviewPage(false))}}
                        >关闭</div>
                        {/* <div 
                            className={styles.exportButton} 
                            onClick={(e)=>{

                            }}
                        >导出</div> */}
                    </div>
                    
                </div>:null}
        {QRContentStr!=""?<QRDownload/>:null}
        
    </div>
}

