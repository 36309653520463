import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import styles from "./CourseExport.module.css";
import classNames from "classnames"
import TopControl from "../../TopControl/TopControl"
import Footer from "../../Footer/Footer";
import ExcelUtil from '../../../util/excelUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
// import {getSchools} from './QueryAndGetQRApi'
import CourseConditionalQuery from "./CourseConditionalQuery";
import CourseInfoList from "./CourseInfoList";
import CourseBottomControl from "./CourseBottomControl";
// import {getPaymentInfoList} from './QueryAndGetQRApi'
// export function CourseExport(props) {
const CourseExport =  forwardRef((props, ref) => {
    // const dispatch = useDispatch();
    const dispatch = useDispatch();
    const conditionRef = useRef(null)
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    useImperativeHandle(ref, () => ({
        refresh() {
            refreshMethod()
        }
    }))
    function searchMethod() {
    }
    function refreshMethod() {
        if (conditionRef){
            conditionRef.current.refresh()
        }
    }
    return <div className={styles.background}>
        <CourseConditionalQuery
            ref={conditionRef}
        />
        <CourseInfoList/>
        <CourseBottomControl
            pageNumberList = {[10,20,30,40,50,60]}
        />


    </div>
})
export default CourseExport;

