import React ,{ useRef,useState,useEffect }from "react";
import { useSelector, useDispatch } from 'react-redux';
import styles from "./ConditionalQuery.module.css";
import classNames from "classnames"
// import closeIcon from "./close1.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faReply,faSearch,faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {getInfoListRequest} from './CourseApprovalApi'
import {
    selectSchoolName,
    selectApprovalStatus,
    selectPageNumber,
    selectRegionName,
    setSchoolName,
    setApprovalStatus,
    setCurrentPage,
    setInfoList,
    setRegionName,
    setTotalPageNum,
    selectApprovalList
} from "./CourseApprovalSlice";
import {message} from 'antd'
export default function ConditionalQuery(props) {
    const dispatch = useDispatch();
    const pageNumber = useSelector(selectPageNumber);
    const regionName = useSelector(selectRegionName);
    const schoolName = useSelector(selectSchoolName);
    const approvalStatus = useSelector(selectApprovalStatus);
    const approvalList = useSelector(selectApprovalList);

    const [showApprovalList,setShowApprovalList] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        dispatch(setInfoList([]))
        getInfoList("","","")
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    function getInfoList(region,company,contanct) {
        getInfoListRequest(region,company,contanct,0,pageNumber,(data)=>{
            if (data.ret==1) {
                dispatch(setInfoList(data.data.shengji_quyu_list))
                var pageNumber1 = Math.ceil(data.data.total_num/pageNumber)
                dispatch(setTotalPageNum(pageNumber1))
                dispatch(setCurrentPage(1))
            } else if(data.ret==99){
                messageApi.open({
                    type: 'warning',
                    content:data.msg
                });
                setTimeout((e)=>{
                    sessionStorage.clear()
                    window.location.reload()
                },1000)
            }else {
                dispatch(setInfoList([]))
                dispatch(setTotalPageNum(1))
                dispatch(setCurrentPage(1))
            }
        })
    }
    function searchMethod() {
        getInfoList(regionName,schoolName,approvalStatus)
    }
    function resetMethod() {
        dispatch(setRegionName(""))
        dispatch(setSchoolName(""))
        dispatch(setApprovalStatus(""))
        getInfoList("","","")
    }
    return <div className={styles.background}>
        {contextHolder}
        <div className={styles.titleContainer}>
            <span className={styles.leftTitle}>数据筛选</span>
            {/* <span className={styles.rightTitle}>高级搜索</span> */}
        </div>
        <div className={styles.conditionalContainer}>
            <span className={styles.conditionalTitle1}>学校名称：</span>
            <input
                placeholder="输入学校名称"
                className={styles.conditionInput}
                value={schoolName}
                onChange={(e) => {
                    dispatch(setSchoolName(e.target.value))
                }}
            />
            <span className={styles.conditionalTitle}>区域名称：</span>
            <input
                placeholder="区域关键字"
                className={styles.conditionInput}
                value={regionName}
                onChange={(e) => {
                    dispatch(setRegionName(e.target.value))
                }}
            />
            <span className={styles.conditionalTitle1}>审核状态：</span>
            <div className={styles.approvalStatusContainer}>
                <input
                    placeholder="审核状态"
                    className={styles.conditionInput1}
                    value={approvalStatus == "" ? "全部" : approvalStatus == "0" ? "待审核" : approvalStatus == "1" ? "已通过" : "已拒绝"}
                    onClick={(e)=>{

                        setShowApprovalList(!showApprovalList)
                    }}
                    readOnly
                />
                <FontAwesomeIcon icon={faAngleDown} className={styles.selectButtonIcon1}/>
                {showApprovalList?<div className={classNames(styles.tankangContainer,styles.schoolListPosition)}>
                    {approvalList.map((item,index,arr)=>{
                        return <div className={styles.tankuangItem}
                                    key={index}
                                    onClick={(e)=>{
                                        dispatch(setApprovalStatus(item.reviewCode))
                                        setShowApprovalList(false)
                                    }}
                        >{item.reviewName}</div>
                    })}
                </div>:null}
            </div>
            <div className={styles.selectButton}
                 onClick={searchMethod}
            >
                <FontAwesomeIcon icon={faSearch} className={styles.selectButtonIcon}/>
                查询
            </div>
            <div className={styles.resetButton}
                 onClick={resetMethod}
            >
                <FontAwesomeIcon icon={faReply} className={styles.selectButtonIcon}/>
                重置
            </div>
        </div>

    </div>
}