const APP_ID = "tbibss";
const APP_KEY_LOCAL = "666tbibss20240919";

//  const API_URL="https://www.gamepku.com";
//  const ali_sb3_path = 'aimake/zuopin/';


// const API_URL="https://tbibss.gamification.org.cn";
const ALI_OSS_PATH="test/";
const API_URL="https://sys.nobelplan.cn";
// const ali_sb3_path = 'testaimake/zuopin/';
// const ali_accessKeyId = 'LTAIiQyq04cwHaKH';
// const ali_accessKeySecret = 'qjMo0cWEHHCvjEoRPJMohFMeBY7TI2';
// const ali_bucket = 'spark-app';
// const ali_region = 'oss-cn-qingdao';
export {
    API_URL,
    APP_ID,
    APP_KEY_LOCAL,
    ALI_OSS_PATH
    // ali_accessKeyId,
    // ali_accessKeySecret,
    // ali_bucket,
    // ali_region,
    // ali_sb3_path
}
