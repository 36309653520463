import React from "react";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import styles from "./InfoList.module.css";
// import closeIcon from "./close1.png"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUpload, faDownload, faAdd} from '@fortawesome/free-solid-svg-icons';
import InfoItem from "./InfoItem";
import ExcelUtil from "../../../util/excelUtilNew";
import {selectInfoList, setShowAddInfoPage, setShowEditInfoPage} from "./ZXInfoManageSlice";
import {setAlertInfo} from "../../../store/CustomViewSlice";

export default function InfoList(props) {
    const dispatch = useDispatch();
    const infoList = useSelector(selectInfoList);

    function exportExcelMethod() {
        if (infoList.length == 0) {
            dispatch(setAlertInfo({
                showStatus: true,
                title: "",
                title1: "暂无内容导出",
                content: ""
            }))
            return;
        }
        const initColumn = [{
            title: '驻校姓名',
            dataIndex: 'item1',
            key: 'item1',
        }, {
            title: '所属区县公司',
            dataIndex: 'item2',
            key: 'item2',
        }, {
            title: '所属省级区域',
            dataIndex: 'item3',
            key: 'item3',
        }, {
            title: '所属市',
            dataIndex: 'item4',
            key: 'item4',
        }, {
            title: '所属区县',
            dataIndex: 'item5',
            key: 'item5',
        }, {
            title: '联系方式',
            dataIndex: 'item6',
            key: 'item6',
        }, {
            title: '状态',
            dataIndex: 'item7',
            key: 'item7',
        }]
        let attendanceInfoList = [];
        infoList.map((item, index) => {
            attendanceInfoList.push({
                '驻校姓名': item.xingming,
                '所属区县公司': item.quxian_gongsi_name,
                '所属省级区域': item.shengji_name,
                '所属市': item.shiji_name,
                '所属区县': item.quxian_name,
                '联系方式': item.lianxi_dianhua,
                '状态': item.status == 1 ? "开启" : item.status == 2 ? "禁用" : item.status == 3 ? "删除" : ""
            })
        })
        ExcelUtil.exportExcel( attendanceInfoList, `驻校信息列表.xlsx`,
            [
                {wpx: 150},
                {wpx: 120},
                {wpx: 140},
                {wpx: 110},
                {wpx: 110},
                {wpx: 120},
                {wpx: 80}
            ]
        )
    }

    function addInfoMethod() {
        dispatch(setShowAddInfoPage(true))
    }

    return <div className={styles.background}>
        <div className={styles.titleContainer}>
            <span className={styles.leftTitle}>数据列表</span>
            <div className={styles.rightButtonContainer}>
                <div className={styles.rightButton1} onClick={exportExcelMethod}>
                    <FontAwesomeIcon icon={faUpload} className={styles.rightButtonIcon}/>
                    导出
                </div>
                {/* <div className={styles.rightButton1}>
                    <FontAwesomeIcon icon={faDownload} className={styles.rightButtonIcon}/>
                    导入
                </div> */}
                <div className={styles.rightButton2} onClick={addInfoMethod}>
                    <FontAwesomeIcon icon={faAdd} className={styles.rightButtonIcon}/>
                    添加
                </div>
            </div>
        </div>
        <div className={styles.listContainer}>
            <table className={styles.infoTable}>
                <tr
                    className={styles.tr1}
                >
                    <td
                        className={styles.td1}
                    >驻校姓名
                    </td>
                    <td
                        className={styles.td1}
                    >所属区县公司
                    </td>
                    <td
                        className={styles.td1}
                    >所属省级区域
                    </td>
                    <td
                        className={styles.td1}
                    >所属市
                    </td>
                    <td
                        className={styles.td1}
                    >所属区县
                    </td>
                    <td
                        className={styles.td1}
                    >联系方式
                    </td>
                    <td
                        className={styles.td1}
                    >状态
                    </td>
                    <td
                        className={styles.td1}
                    >操作
                    </td>
                </tr>
                {infoList.map((item, index) => (
                    <InfoItem
                        item={item}
                        key={index}
                    />
                ))}
            </table>
            {/*<div className={styles.listTitleContainer}>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth1)}>*/}
            {/*        <span className={styles.listContentSpan}></span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth2)}>*/}
            {/*        <span className={styles.listContentSpan}></span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth3)}>*/}
            {/*        <span className={styles.listContentSpan}></span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth4)}>*/}
            {/*        <span className={styles.listContentSpan}></span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth5)}>*/}
            {/*        <span className={styles.listContentSpan}></span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth6)}>*/}
            {/*        <span className={styles.listContentSpan}></span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth7)}>*/}
            {/*        <span className={styles.listContentSpan}></span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth8)}>*/}
            {/*        <span className={styles.listContentSpan}></span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*{infoList.map((item, index) => (*/}
            {/*    <InfoItem*/}
            {/*        item={item}*/}
            {/*        key={index}*/}
            {/*    />*/}
            {/*))}*/}
        </div>


    </div>
}

