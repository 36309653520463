// 提交开课申请,暂未对接接口
import React, {useRef, useState, useEffect} from "react";
import styles from "./ZXSchool1.module.css";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import Footer from "../../Footer/Footer";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp, faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {selectAlertInfo, setAlertInfo} from "../../../store/CustomViewSlice";
import {message} from 'antd'
import {setZXMenuIndex} from "../ZXYYMain/ZXYYMainSlice";
import {
    selectDingSchoolList,
    selectLieSchoolList, selectLPDSchoolList,
    selectPingSchoolList, selectXuezhiIndex, selectXuezhiList,
    setLieSchoolList, setLPDSchoolList, setPingSchoolList, setXuezhiIndex,
} from "./ZXSchoolSlice";
import {getSixMathRandom} from "../../../util/handMath";
import {deleteLieSchoolRequest, saveLieSchoolRequest, savePingSchoolRequest} from "./ZXSchoolApi";

export function ZXSchool1() {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [confirmInfo, setConfirmInfo] = useState({})

    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        let tempList = LPDSchoolList.map((item, index) => {return item});
        tempList.sort((a, b) => {
            return a.order_jichu - b.order_jichu;
        })
        dispatch(setLieSchoolList(tempList))

        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    const xuezhiIndex = useSelector(selectXuezhiIndex)
    const xuezhiList = useSelector(selectXuezhiList)
    const LPDSchoolList = useSelector(selectLPDSchoolList)
    const lieSchoolList = useSelector(selectLieSchoolList)
    const pingSchoolList = useSelector(selectPingSchoolList)
    const dingSchoolList = useSelector(selectDingSchoolList)
    const [addingSchoolName, setAddingSchoolName] = useState("");
    const [addingStudentNumber, setAddingStudentNumber] = useState("");
    const [removeIndex, setRemoveIndex] = useState(-1);
    const [removeItem, setRemoveItem] = useState({});


    function refreshMethod() {

    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
            deleteLieSchoolRequest(xuezhiList[xuezhiIndex].xuezhi_code, lieSchoolList[removeIndex].xuexiao_code, (data) => {
                if(data.ret == 1){
                var tempArray = lieSchoolList.map((item, index) => {
                    return item
                })
                tempArray.splice(removeIndex, 1)
                var tempLieArray = tempArray.map((item, index) => {
                    // return item
                    let tempDic = JSON.parse(JSON.stringify(item))
                    if (removeIndex < item.order_jichu) {
                        tempDic.order_jichu = tempDic.order_jichu - 1;
                    }
                    if (removeIndex < item.order_zonghe) {
                        tempDic.order_zonghe = tempDic.order_zonghe - 1;
                    }
                    return tempDic;
                })
                console.log(tempLieArray)
                dispatch(setLieSchoolList(tempLieArray))


                var tempArray1 = LPDSchoolList.map((item, index) => {
                    return item
                })
                tempArray1.splice(removeIndex, 1)

                let tempLPDList = tempArray1.map((item, index) => {
                    let tempDic = JSON.parse(JSON.stringify(item))
                    if (removeIndex < item.order_jichu) {
                        tempDic.order_jichu = tempDic.order_jichu - 1;
                    }
                    if (removeIndex < item.order_zonghe) {
                        tempDic.order_zonghe = tempDic.order_zonghe - 1;
                    }
                    return tempDic;
                })
                console.log(tempLPDList)
                dispatch(setLPDSchoolList(tempLPDList))
                }
                else if(data.ret==99){

                    messageApi.open({
                        type: 'warning',
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }
                else{
                    messageApi.open({
                        type:"warning",
                        content:data.msg
                    })
                }
            })

        } else if (confirmInfo.eventId == 2) {

        }

    }

    return (
        <div className={styles.background}>
            {contextHolder}
            <CustomConfirmAlert
                info={confirmInfo}
                cancelMethod={cancelMethod}
                confirmMethod={confirmMethod}
            />

            <div className={styles.content}>
                <div className={styles.leftContent}>
                    <span className={styles.leftLieTitle}>第1步:【列】</span>
                    <span className={styles.leftPingTitle}
                          onClick={(e) => {
                              if (pingSchoolList.length > 0&&LPDSchoolList.length>0) {
                                  dispatch(setZXMenuIndex(2))
                              }
                          }}
                    >第2步:【评】</span>
                    <span className={styles.leftDingTitle}
                          onClick={(e) => {
                              if (dingSchoolList.length > 0&&LPDSchoolList.length>0) {
                                  dispatch(setZXMenuIndex(3))
                              }
                          }}
                    >第3步:【定】</span>
                </div>
                <div className={styles.middleContent}>
                    <div className={styles.middleTopContent}>
                        <div className={styles.middleTitle}>第1步：【列】罗列所在区/县主城区的主要学校，并按基础教学水平排序
                        </div>
                        {/*<div className={styles.szqxTitle}>所在区县：临汾市尧都区</div>*/}
                        <div className={styles.BXSchoolTitle}>备选学校：
                            <span className={styles.BXSchoolDes}>
                            {lieSchoolList.length}所
                        </span>
                        </div>
                        <div className={styles.BXSchoolList}>
                            {lieSchoolList.map((item, index) => {
                                return <div className={styles.BXSchoolItem}>
                                    <span className={styles.jcspTitle}>基础水平{index + 1}</span>
                                    <span className={styles.schoolName}>{item.xuexiao_name}</span>
                                    <span className={styles.studentNumber}>{item.xuesheng_num}人</span>
                                    <span className={styles.xuezhiSpan}>{item.xuezhi_name}</span>
                                    <div className={styles.angleContainer}>
                                        {index !== 0 && lieSchoolList.length > 1 ?
                                            <FontAwesomeIcon icon={faAngleUp}
                                                             className={classNames(styles.angleIcon, styles.angleUp)}
                                                             onClick={(e) => {
                                                                 var tempArray = lieSchoolList.map((item1, index1) => {

                                                                     let tempDic = JSON.parse(JSON.stringify(item1))
                                                                     if (item1.order_jichu == item.order_jichu) {
                                                                         tempDic.order_jichu = item1.order_jichu - 1;
                                                                     }
                                                                     if (item1.order_jichu == item.order_jichu - 1) {
                                                                         tempDic.order_jichu = item1.order_jichu + 1;
                                                                     }
                                                                     return tempDic;
                                                                 })
                                                                 tempArray.sort((a, b) => {
                                                                     return a.order_jichu - b.order_jichu;
                                                                 })
                                                                 dispatch(setLieSchoolList(tempArray))


                                                                 var tempArray1 = LPDSchoolList.map((item1, index1) => {

                                                                     let tempDic = JSON.parse(JSON.stringify(item1))
                                                                     if (item1.order_jichu == item.order_jichu) {
                                                                         tempDic.order_jichu = item1.order_jichu - 1;
                                                                     }
                                                                     if (item1.order_jichu == item.order_jichu - 1) {
                                                                         tempDic.order_jichu = item1.order_jichu + 1;
                                                                     }
                                                                     return tempDic;
                                                                 })
                                                                 tempArray1.sort((a, b) => {
                                                                     return a.order_jichu - b.order_jichu;
                                                                 })
                                                                 dispatch(setLPDSchoolList(tempArray1))


                                                             }}
                                            /> : null}
                                        {index !== lieSchoolList.length - 1 && lieSchoolList.length > 1 ?
                                            <FontAwesomeIcon icon={faAngleDown}
                                                             className={classNames(styles.angleIcon, styles.angleDown)}
                                                             onClick={(e) => {
                                                                 var tempArray = lieSchoolList.map((item1, index1) => {

                                                                     let tempDic = JSON.parse(JSON.stringify(item1))
                                                                     if (item1.order_jichu == item.order_jichu) {
                                                                         tempDic.order_jichu = item1.order_jichu + 1;
                                                                     }
                                                                     if (item1.order_jichu == item.order_jichu + 1) {
                                                                         tempDic.order_jichu = item1.order_jichu - 1;
                                                                     }
                                                                     return tempDic;
                                                                 })
                                                                 tempArray.sort((a, b) => {
                                                                     return a.order_jichu - b.order_jichu;
                                                                 })
                                                                 dispatch(setLieSchoolList(tempArray))


                                                                 var tempArray1 = LPDSchoolList.map((item1, index1) => {
                                                                     let tempDic = JSON.parse(JSON.stringify(item1))
                                                                     if (item1.order_jichu == item.order_jichu) {
                                                                         tempDic.order_jichu = item1.order_jichu + 1;
                                                                     }
                                                                     if (item1.order_jichu == item.order_jichu + 1) {
                                                                         tempDic.order_jichu = item1.order_jichu - 1;
                                                                     }
                                                                     return tempDic;
                                                                 })
                                                                 tempArray1.sort((a, b) => {
                                                                     return a.order_jichu - b.order_jichu;
                                                                 })
                                                                 dispatch(setLPDSchoolList(tempArray1))
                                                             }}
                                            /> : null}
                                    </div>
                                    <div className={styles.removeButton}
                                         onClick={(e) => {
                                             setRemoveIndex(index)
                                             setRemoveItem(item)
                                             setConfirmInfo({
                                                 showStatus: true,
                                                 title: "提示",
                                                 title1: "移除后，会删除该校所有信息",
                                                 content: "",
                                                 eventId: 1
                                             })

                                         }}
                                    >移除
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className={styles.addBXSchoolContainer}>
                            <span className={styles.jcspTitle}>基础水平{lieSchoolList.length + 1}</span>
                            <input
                                placeholder={'输入学校全名'}
                                className={classNames(styles.addBXSchoolInput, styles.addBXSchoolNamePosition)}
                                value={addingSchoolName}
                                onChange={(e) => {
                                    setAddingSchoolName(e.target.value);
                                }}
                            />
                            <input
                                placeholder={'输入在校生数量'}
                                className={classNames(styles.addBXSchoolInput, styles.addBXSchoolNamePosition)}
                                value={addingStudentNumber}
                                type={"number"}
                                onChange={(e) => {
                                    setAddingStudentNumber(e.target.value);
                                }}
                            />
                            <select className={classNames(styles.dropDown, styles.xuezhiPosition)}
                                    onChange={(e) => {
                                        dispatch(setXuezhiIndex(e.target.value))
                                    }}
                                    value={xuezhiIndex}
                            >
                                {xuezhiList.map((item, index) => {
                                    return <option value={index}>{item.xuezhi_name}</option>
                                })}
                            </select>
                            <div className={styles.confirmAddSchool}
                                 onClick={(e) => {
                                     if (addingSchoolName.length == 0) {
                                         messageApi.open({
                                             type: 'warning',
                                             content: "学校名称未填写"
                                         });
                                     } else if (addingStudentNumber.length == 0) {
                                         messageApi.open({
                                             type: 'warning',
                                             content: "学生数量未填写"
                                         });
                                     } else {
                                         const timestamp = new Date().getTime();
                                         var xuexiao_code = sessionStorage.getItem("username") + timestamp + getSixMathRandom();
                                         var tempDir = {
                                             "xuexiao_name": addingSchoolName,
                                             "xuesheng_num": addingStudentNumber,
                                             "yiyuan_code": 0,
                                             "yiyuan_name": "意愿未知",
                                             "xuexiao_code": xuexiao_code,
                                             "order_jichu": lieSchoolList.length,
                                             "order_zonghe": lieSchoolList.length,
                                             "xuezhi_code":xuezhiList[xuezhiIndex].xuezhi_code,
                                             "xuezhi_name":xuezhiList[xuezhiIndex].xuezhi_name
                                         }
                                         var tempList = lieSchoolList.map((item, index) => {
                                             return item
                                         })
                                         tempList.push(tempDir)
                                         dispatch(setLieSchoolList(tempList));

                                         var tempList1 = LPDSchoolList.map((item, index) => {
                                             return item
                                         })
                                         tempList1.push(tempDir)
                                         dispatch(setLPDSchoolList(tempList1))
                                         setAddingSchoolName('')
                                         setAddingStudentNumber('')
                                     }
                                 }}
                            >确定</div>
                        </div>
                        <div className={styles.addSchoolButton}
                             onClick={(e) => {
                                 if (addingSchoolName.length == 0) {
                                     messageApi.open({
                                         type: 'warning',
                                         content: "学校名称未填写"
                                     });
                                 } else if (addingStudentNumber.length == 0) {
                                     messageApi.open({
                                         type: 'warning',
                                         content: "学生数量未填写"
                                     });
                                 } else {
                                     const timestamp = new Date().getTime();
                                     var xuexiao_code = sessionStorage.getItem("username") + timestamp + getSixMathRandom();
                                     var tempDir = {
                                         "xuexiao_name": addingSchoolName,
                                         "xuesheng_num": addingStudentNumber,
                                         "yiyuan_code": 0,
                                         "yiyuan_name": "意愿未知",
                                         "xuexiao_code": xuexiao_code,
                                         "order_jichu": lieSchoolList.length,
                                         "order_zonghe": lieSchoolList.length,
                                         "xuezhi_code":xuezhiList[xuezhiIndex].xuezhi_code,
                                         "xuezhi_name":xuezhiList[xuezhiIndex].xuezhi_name
                                     }
                                     var tempList = lieSchoolList.map((item, index) => {
                                         return item
                                     })
                                     tempList.push(tempDir)
                                     dispatch(setLieSchoolList(tempList));

                                     var tempList1 = LPDSchoolList.map((item, index) => {
                                         return item
                                     })
                                     tempList1.push(tempDir)
                                     dispatch(setLPDSchoolList(tempList1))
                                     setAddingSchoolName('')
                                     setAddingStudentNumber('')
                                 }
                             }}
                        >+ 添加学校
                        </div>
                    </div>
                    <div className={styles.middleBottomContent}>
                        <div className={styles.nextButton}
                             onClick={(e) => {
                                 if (lieSchoolList.length > 0) {
                                     saveLieSchoolRequest(xuezhiList[xuezhiIndex].xuezhi_code, JSON.stringify(lieSchoolList), (data) => {
                                         if (data.ret == 1) {
                                             dispatch(setZXMenuIndex(2))
                                         }else if(data.ret==99){

                                            messageApi.open({
                                                type: 'warning',
                                                content:data.msg
                                            });
                                            setTimeout((e)=>{
                                                sessionStorage.clear()
                                                window.location.reload()
                                            },1000)
                                        } else {
                                             messageApi.open({
                                                 type: 'warning',
                                                 content: data.msg
                                             })
                                         }
                                     })
                                 } else {
                                     messageApi.open({
                                         type: "warning",
                                         content: "请添加学校信息"
                                     })
                                 }
                             }}
                        >保存，下一步
                        </div>
                    </div>
                </div>
                <div className={styles.rightContent}>
                    <div className={styles.xtsContainer}>
                        <div className={styles.xtsTitle}>小贴士</div>
                        <div
                            className={styles.xtsDes}>
                            1. 根据常识判断，明显不符合标准的学校不用列出来。例如：乡村/偏远地区付费能力弱的学校、学生人数明显偏少的学校、办学水平明显偏低的学校。
                            <br/>
                            2. 根据对各备选学校现有学生人数、办学水平、升学率等基础信息的评估，给备选学校进行“基础水平”排序。
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};
