import React from "react";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import styles from "./InfoList.module.css";
// import closeIcon from "./close1.png"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUpload, faDownload, faAdd} from '@fortawesome/free-solid-svg-icons';
import InfoItem from "./InfoItem";
import ExcelUtil from "../../../util/excelUtilNew";
import {selectInfoList, setShowAddInfoPage} from "./ProvinceInfoManageSlice";
import {setAlertInfo} from "../../../store/CustomViewSlice";

export default function InfoList(props) {
    const dispatch = useDispatch();
    const infoList = useSelector(selectInfoList);

    function exportExcelMethod() {
        if (infoList.length == 0) {
            dispatch(setAlertInfo({
                showStatus: true,
                title: "",
                title1: "暂无内容导出",
                content: ""
            }))
            return;
        }
        const initColumn = [{
            title: '省级区域',
            dataIndex: 'provinceName',
            key: 'provinceName',
        }, {
            title: '省级公司',
            dataIndex: 'companyName',
            key: 'companyName',
        }, {
            title: '联系人',
            dataIndex: 'contactName',
            key: 'contactName',
        }, {
            title: '联系电话',
            dataIndex: 'contactPhone',
            key: 'contactPhone',
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        }, {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
        }]
        let attendanceInfoList = [];
        infoList.map((item, index) => {
            attendanceInfoList.push({
                '省级区域': item.quyu_name,
                '省级公司': item.gongsi_name,
                '联系人': item.lianxiren,
                '联系电话': item.lianxi_dianhua,
                '创建时间': item.ctime,
                '状态': item.status == 1 ? "开启" : item.status == 2 ? "禁用" : item.status == 3 ? "删除" : ""
            })
        })
        ExcelUtil.exportExcel( attendanceInfoList, `省级区域管理列表.xlsx`,
            [
                {wpx: 150},
                {wpx: 90},
                {wpx: 80},
                {wpx: 116},
                {wpx: 116},
                {wpx: 110},
                {wpx: 70},
                {wpx: 60},
                {wpx: 100},
                {wpx: 66},
                {wpx: 200},
                {wpx: 90},
                {wpx: 200},
                {wpx: 90},
                {wpx: 88},
                {wpx: 110}
            ]
        )
    }

    function addInfoMethod() {
        dispatch(setShowAddInfoPage(true))
    }

    return <div className={styles.background}>
        <div className={styles.titleContainer}>
            <span className={styles.leftTitle}>数据列表</span>
            <div className={styles.rightButtonContainer}>
                <div className={styles.rightButton1} onClick={exportExcelMethod}>
                    <FontAwesomeIcon icon={faUpload} className={styles.rightButtonIcon}/>
                    导出
                </div>
                {/* <div className={styles.rightButton1}>
                    <FontAwesomeIcon icon={faDownload} className={styles.rightButtonIcon}/>
                    导入
                </div> */}
                <div className={styles.rightButton2} onClick={addInfoMethod}>
                    <FontAwesomeIcon icon={faAdd} className={styles.rightButtonIcon}/>
                    添加
                </div>
            </div>
        </div>
        <div className={styles.listContainer}>
            <table className={styles.infoTable}>
                <tr
                    className={styles.tr1}
                >
                    <td
                        className={styles.td1}
                    >省级区域
                    </td>
                    <td
                        className={styles.td1}
                    >省级公司
                    </td>
                    <td
                        className={styles.td1}
                    >联系人
                    </td>
                    <td
                        className={styles.td1}
                    >联系电话
                    </td>
                    <td
                        className={styles.td1}
                    >创建时间
                    </td>
                    <td
                        className={styles.td1}
                    >状态
                    </td>
                    <td
                        className={styles.td1}
                    >操作
                    </td>
                </tr>
                {infoList.map((item, index) => (
                    <InfoItem
                        item={item}
                        key={index}
                    />
                ))}
            </table>
            {/*<div className={styles.listTitleContainer}>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth1)}>*/}
            {/*        <span className={styles.listContentSpan}>省级区域</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth2)}>*/}
            {/*        <span className={styles.listContentSpan}>省级公司</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth3)}>*/}
            {/*        <span className={styles.listContentSpan}>联系人</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth4)}>*/}
            {/*        <span className={styles.listContentSpan}>联系电话</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth5)}>*/}
            {/*        <span className={styles.listContentSpan}>创建时间</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth6)}>*/}
            {/*        <span className={styles.listContentSpan}>状态</span>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(styles.listTitle, styles.itemWidth7)}>*/}
            {/*        <span className={styles.listContentSpan}>操作</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*{infoList.map((item, index) => (*/}
            {/*    <InfoItem*/}
            {/*        item={item}*/}
            {/*        key={index}*/}
            {/*    />*/}
            {/*))}*/}
        </div>


    </div>
}

