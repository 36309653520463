import React, {useRef, useState, useEffect, useImperativeHandle, forwardRef} from "react";
import {useSelector, useDispatch} from 'react-redux';
import styles from "./OrderConditionalQuery.module.css";
import classNames from "classnames"
import {Checkbox, DatePicker} from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn'
import OrderInfoItem from "./OrderInfoItem";
import {faReply, faSearch, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setAlertInfo} from "../../../store/CustomViewSlice";
import ExcelUtil from "../../../util/excelUtilNew";
import {
    selectCountyIndex,
    selectCountyList,
    selectMunicipalityIndex,
    selectMunicipalityList,
    selectPageNumber,
    selectParentName,
    selectPayStatus,
    selectPayTypeIndex,
    selectPayTypeList,
    selectProvinceIndex,
    selectProvinceList,
    selectRangeTime,
    selectRefundStatus,
    selectSchoolIndex,
    selectSchoolList,
    selectSexIndex,
    selectSexList,
    selectStudentName,
    selectXueNianIndex,
    selectXueNianList,
    selectXueQiIndex,
    selectXueQiList,
    selectGradeList,
    selectGradeIndex,
    selectMerchantOrderNo,
    selectPlatformOrderNo,
    setCountyIndex,
    setCountyList,
    setCourseIndex,
    setCourseList,
    setJiaojuIndex,
    setJiaojuList,
    setMunicipalityIndex,
    setMunicipalityList,
    setParentName,
    setPayStatus,
    setProvinceIndex,
    setProvinceList,
    setRangeTime,
    setRefundStatus,
    setSchoolIndex,
    setSchoolList,
    setStudentName,
    setXueQiIndex,
    setCurrentPage,
    setInfoList,
    setTotalPageNum,
    setXueNianIndex,
    setSexIndex,
    setPayTypeIndex,
    setInfoNumber,
    setInfoAccount,
    setGradeIndex,
    setMerchantOrderNo,
    setPlatformOrderNo,
    selectCurrentPage,
    selectSchoolOrCourseNameKey,
    setSchoolOrCourseNameKey
} from "./QAEOrderExportSlice";
import {getCourseAndCycle, getOrderInfoListRequest, getRegionListRequest, getSchools} from "./QueryAndExportApi";
import {message} from 'antd'
import {getMoneyDate} from "../../../util/currencyUnit";

dayjs.locale('zh-cn')


const {RangePicker} = DatePicker;
// export default function ConditionalQuery(props) {
const OrderConditionalQuery = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const rangeTime = useSelector(selectRangeTime)
    const payStatus = useSelector(selectPayStatus)
    const refundStatus = useSelector(selectRefundStatus)
    const payTypeIndex = useSelector(selectPayTypeIndex);
    const payTypeList = useSelector(selectPayTypeList);
    const provinceIndex = useSelector(selectProvinceIndex);
    const provinceList = useSelector(selectProvinceList);
    const municipalityIndex = useSelector(selectMunicipalityIndex);
    const municipalityList = useSelector(selectMunicipalityList);
    const countyIndex = useSelector(selectCountyIndex);
    const countyList = useSelector(selectCountyList);
    const schoolIndex = useSelector(selectSchoolIndex)
    const schoolList = useSelector(selectSchoolList);
    const xuenianIndex = useSelector(selectXueNianIndex)
    const xueNianList = useSelector(selectXueNianList);
    const xueQiIndex = useSelector(selectXueQiIndex);
    const xueQiList = useSelector(selectXueQiList);
    const gradeIndex = useSelector(selectGradeIndex);
    const gradeList = useSelector(selectGradeList);
    const studentName = useSelector(selectStudentName);
    const sexIndex = useSelector(selectSexIndex);
    const sexList = useSelector(selectSexList);
    const parentName = useSelector(selectParentName)
    const merchantOrderNo = useSelector(selectMerchantOrderNo);
    const platformOrderNo = useSelector(selectPlatformOrderNo);
    const currentPage = useSelector(selectCurrentPage)
    const pageNumber = useSelector(selectPageNumber)
    const nameKey = useSelector(selectSchoolOrCourseNameKey)
    const [messageApi, contextHolder] = message.useMessage();
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // dispatch(setInfoList([]))
        getRegionList("1", "")
        getCourseAndCycleList()
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    useImperativeHandle(ref, () => ({
        refresh() {
            searchMethod()
        }
    }))

    function getRegionList(regionCode, parentCode) {
        getRegionListRequest(regionCode, parentCode, (data) => {
            var newRegionList = [{
                quyu_name: "全部",
                quyu_code: ""
            }];
            if (data.ret == 1) {
                data.data.forEach(element => {
                    newRegionList.push(element)
                });
            } else if (data.ret == 99) {
                messageApi.open({
                    type: 'warning',
                    content: data.msg
                });
                setTimeout((e) => {
                    sessionStorage.clear()
                    window.location.reload()
                }, 1000)
            }
            if (regionCode == 1) {
                dispatch(setProvinceList(newRegionList))
            } else if (regionCode == 2) {
                dispatch(setMunicipalityList(newRegionList))
            } else if (regionCode == 3) {
                dispatch(setCountyList(newRegionList))
            }

        })
    }

    function getSchoolsList(provinceCode, municipalityCode, countyCode) {
        getSchools(provinceCode, municipalityCode, countyCode, (data) => {
            var newSchoolList = [{
                school_name: "全部",
                school_code: ""
            }];
            if (data.ret == 1) {
                data.data.forEach(element => {
                    newSchoolList.push(element)
                });
            } else if (data.ret == 99) {
                messageApi.open({
                    type: 'warning',
                    content: data.msg
                });
                setTimeout((e) => {
                    sessionStorage.clear()
                    window.location.reload()
                }, 1000)
            }
            dispatch(setSchoolIndex(0))
            dispatch(setSchoolList(newSchoolList))
        })
    }

    function getCourseAndCycleList() {
        getCourseAndCycle((data) => {
            var newCourseList = [{
                kecheng_name: "全部",
                kecheng_code: ""
            }];
            var newJiaojuList = [{
                jiaoju_name: "全部",
                jiaoju_code: ""
            }];
            if (data.ret == 1) {
                data.data.kecheng_list.forEach(element => {
                    newCourseList.push(element)
                });
                data.data.jiaoju_list.forEach(element => {
                    newJiaojuList.push(element)
                });
            } else if (data.ret == 99) {
                messageApi.open({
                    type: 'warning',
                    content: data.msg
                });
                setTimeout((e) => {
                    sessionStorage.clear()
                    window.location.reload()
                }, 1000)
            }
            dispatch(setCourseIndex(0))
            dispatch(setCourseList(newCourseList))
            dispatch(setJiaojuIndex(0))
            dispatch(setJiaojuList(newJiaojuList))
        })
    }

    function exportAllInfo() {
        getOrderInfoListRequest(rangeTime[0], rangeTime[1],
            payStatus ? "1" : "",
            refundStatus ? "1" : "",
            payTypeList[payTypeIndex].code,
            provinceList[provinceIndex].quyu_code,
            municipalityList[municipalityIndex].quyu_code,
            countyList[countyIndex].quyu_code,
            schoolList[schoolIndex].school_code,
            xueNianList[xuenianIndex].code,
            xueQiList[xueQiIndex].code,
            studentName,
            sexList[sexIndex].code,
            parentName,
            merchantOrderNo,
            platformOrderNo,
            gradeList[gradeIndex].code,
            nameKey,
            0, 10000000000000000, (data) => {
                if (data.ret == 1) {
                    exportExcelMethod(data.data.pay_list)
                } else if (data.ret == 99) {
                    messageApi.open({
                        type: 'warning',
                        content: data.msg
                    });
                    setTimeout((e) => {
                        sessionStorage.clear()
                        window.location.reload()
                    }, 1000)
                } else {
                    dispatch(setAlertInfo({
                        showStatus: true,
                        title: "",
                        title1: "暂无内容导出",
                        content: ""
                    }))
                }
            })
    }

    function checkNull(dataString){
        if(dataString == null){
            return "";
        }
        else {
            return dataString;
        }
    }
    function checkNullAndReturnNothing(dataString){
        if(dataString == null){
            return "无";
        }
        else {
            return dataString;
        }
    }
    function exportExcelMethod(infoList) {

        const initColumn = [
            {
                title: '交易时间',
                dataIndex: 'column1',
                key: 'column1',
            },
            {
                title: '交易类型',
                dataIndex: 'column2',
                key: 'column2',
            },
            {
                title: '支付渠道',
                dataIndex: 'column3',
                key: 'column3',
            },
            {
                title: '平台订单号',
                dataIndex: 'column4',
                key: 'column4',
            },
            // {
            //     title: '商户订单号',
            //     dataIndex: 'column5',
            //     key: 'column5',
            // },
            {
                title: '退款订单号',
                dataIndex: 'column6',
                key: 'column6',
            },
            {
                title: '省级区域',
                dataIndex: 'column7',
                key: 'column7',
            },
            {
                title: '市级区域',
                dataIndex: 'column8',
                key: 'column8',
                // className: 'text-monospace',
            },
            {
                title: '区县区域',
                dataIndex: 'column9',
                key: 'column9',
                // className: 'text-monospace',
            },
            {
                title: '学校名称',
                dataIndex: 'column10',
                key: 'column10',
            },
            // {
            //     title: '学年',
            //     dataIndex: 'column11',
            //     key: 'column11',
            // }, {
            //     title: '学期',
            //     dataIndex: 'column12',
            //     key: 'column12',
            // },
            {
                title: '年级',
                dataIndex: 'column13',
                key: 'column13',
            }, {
                title: '学生姓名',
                dataIndex: 'column14',
                key: 'column14',
            }, {
                title: '性别',
                dataIndex: 'column15',
                key: 'column15',
            }, {
                title: '出生年月',
                dataIndex: 'column16',
                key: 'column16',
            },  {
                title: '家长姓名',
                dataIndex: 'column17',
                key: 'column17',
            }, {
                title: '交易金额',
                dataIndex: 'column18',
                key: 'column18',
            }]
        let attendanceInfoList = [];
        infoList.map((item, index) => {
            // attendanceInfoList.push({
            //     column1:checkNull(item.jiaofei_time),
            //     column2:item.tuifei_status == 1 ? '退费' : '缴费',
            //     column3: item.pay_type == 1 ? "微信" : item.pay_type == 2 ? "支付宝" : "第三方",
            //     column4:checkNullAndReturnNothing(item.transaction_id),
            //     // column5:checkNullAndReturnNothing(item.dingdan_code),
            //     column6:checkNullAndReturnNothing(item.tuikuan_dingdan_code),
            //     column7:checkNull(item.school_shengji_name),
            //     column8:checkNull(item.school_shiji_name),
            //     column9:checkNull(item.school_quxian_name),
            //     column10:checkNull(item.school_name),
            //     // column11: item.shoufei_zhouqi_code,//订单查询没有学年
            //     // column12: item.shoufei_zhouqi_name,//订单查询没有学期
            //     column13:checkNull(item.grade_name),
            //     column14:checkNull(item.name),
            //     column15: item.xingbie == 1 ? '男' : '女',
            //     column16:checkNull(item.shengri),
            //     column17:checkNull(item.jiazhang_name),
            //     column18:item.pay_type == 1?(item.tuifei_status==1?"-"+getMoneyDate("fen",item.refund_num):getMoneyDate("fen",item.dingdan_amount)):(item.tuifei_status==1?"-"+getMoneyDate("yuan",item.refund_num):getMoneyDate("yuan",item.dingdan_amount))
            // })
            attendanceInfoList.push({
                "交易时间":checkNull(item.jiaofei_time),
                "交易类型":item.tuifei_status == 1 ? '退费' : '缴费',
                "支付渠道": item.pay_type == 1 ? "微信" : item.pay_type == 2 ? "支付宝" : "第三方",
                "平台订单号":checkNullAndReturnNothing(item.transaction_id),
                "退款订单号":checkNullAndReturnNothing(item.tuikuan_dingdan_code),
                "省级区域":checkNull(item.school_shengji_name),
                "市级区域":checkNull(item.school_shiji_name),
                "区县区域":checkNull(item.school_quxian_name),
                "学校名称":checkNull(item.school_name),
                "年级":checkNull(item.grade_name),
                "学生姓名":checkNull(item.name),
                "性别": item.xingbie == 1 ? '男' : '女',
                "出生年月":checkNull(item.shengri),
                "家长姓名":checkNull(item.jiazhang_name),
                "交易金额":item.pay_type == 1?(item.tuifei_status==1?"-"+getMoneyDate("fen",item.refund_num):getMoneyDate("fen",item.dingdan_amount)):(item.tuifei_status==1?"-"+getMoneyDate("yuan",item.refund_num):getMoneyDate("yuan",item.dingdan_amount))
            })
        })
        ExcelUtil.exportExcel( attendanceInfoList, `财务综合查询导出.xlsx`,
            [
                {wpx: 150},
                {wpx: 90},
                {wpx: 80},
                {wpx: 180},
                {wpx: 180},
                {wpx: 180},
                {wpx: 70},
                {wpx: 90},
                {wpx: 100},
                {wpx: 66},
                {wpx: 100},
                {wpx: 90},
                {wpx: 100},
                {wpx: 90},
                {wpx: 88},
                {wpx: 110},
                {wpx: 110},
                {wpx: 110}
            ]
        )
    }

    function searchMethod() {
        getOrderInfoListRequest(rangeTime[0], rangeTime[1],
            payStatus ? "1" : "",
            refundStatus ? "1" : "",
            payTypeList[payTypeIndex].code,
            provinceList[provinceIndex].quyu_code,
            municipalityList[municipalityIndex].quyu_code,
            countyList[countyIndex].quyu_code,
            schoolList[schoolIndex].school_code,
            xueNianList[xuenianIndex].code,
            xueQiList[xueQiIndex].code,
            studentName,
            sexList[sexIndex].code,
            parentName,
            merchantOrderNo,
            platformOrderNo,
            gradeList[gradeIndex].code,
            nameKey,
            0, pageNumber, (data) => {
                if (data.ret == 1) {
                    dispatch(setInfoList(data.data.pay_list))
                    var pageNumber1 = Math.ceil(data.data.total_num / pageNumber)
                    dispatch(setInfoNumber(data.data.total_num))
                    dispatch(setInfoAccount(data.data.total_pay))
                    dispatch(setTotalPageNum(pageNumber1))
                    dispatch(setCurrentPage(1))
                } else if (data.ret == 99) {
                    messageApi.open({
                        type: 'warning',
                        content: data.msg
                    });
                    setTimeout((e) => {
                        sessionStorage.clear()
                        window.location.reload()
                    }, 1000)
                } else {
                    dispatch(setInfoNumber(0))
                    dispatch(setInfoAccount(0))
                    dispatch(setInfoList([]))
                    dispatch(setTotalPageNum(1))
                    dispatch(setCurrentPage(1))
                }
            })
    }

    const dateFormat = 'YYYY-MM-DD';
    var currentDate = new Date();
    var maxDateString = currentDate.toJSON().slice(0, 10);
    return <div className={styles.background}>
        {contextHolder}
        {false ? <div className={styles.hideListCover}
                      onClick={(e) => {
                      }}
        ></div> : null}
        <div className={styles.container}>
            <span>查询时间范围：</span>
            <RangePicker
                locale={locale}
                // needConfirm = {true}
                minDate={dayjs('2024-01-01', dateFormat)}
                maxDate={dayjs(maxDateString, dateFormat)}
                value={rangeTime[0].length > 0 ? [dayjs(rangeTime[0], dateFormat), dayjs(rangeTime[1], dateFormat)] : null}
                // pickerValue={[moment('2023-01-01', 'YYYY-MM-DD'), moment('2023-01-07', 'YYYY-MM-DD')]}
                // onFocus={(_, info) => {
                //     console.log(info)
                //     console.log('Focus:', info.range);
                // }}
                // onBlur={(_, info) => {
                //     console.log(info)
                //     console.log('Blur:', info.range);
                // }}
                onChange={(date, dateString) => {
                    dispatch(setRangeTime(dateString))
                }}
            />
            <Checkbox className={styles.selectPayStatus}
                      defaultChecked={false}
                      checked={payStatus}
                      onClick={(e) => {
                          dispatch(setPayStatus(!payStatus))
                      }}/>
            缴费
            <Checkbox className={styles.refundStatus}
                      defaultChecked={false}
                      checked={refundStatus}
                      onClick={(e) => {
                          dispatch(setRefundStatus(!refundStatus))
                      }}/>
            退费
            <span className={styles.payTypeTitle}>支付渠道：</span>
            <select className={styles.dropDown}
                    onChange={(e) => {
                        dispatch(setPayTypeIndex(e.target.value))
                    }}
                    value={payTypeIndex}
            >
                {payTypeList.map((item, index) => {
                    return <option value={index}>{item.name}</option>
                })}
            </select>
        </div>
        <div className={styles.container1}>

            <table className={styles.conditionTable}>
                <tr
                    className={styles.tr1}
                >
                    <td
                        className={styles.td1}
                    >
                        <span className={styles.conditionItemTitle}>省域：</span>
                        <select className={styles.conditionDropDown}
                                onChange={(e) => {
                                    dispatch(setProvinceIndex(e.target.value))
                                    dispatch(setMunicipalityIndex(0))
                                    dispatch(setCountyIndex(0))
                                    dispatch(setSchoolIndex(0))
                                    dispatch(setMunicipalityList([{
                                        quyu_name: "全部",
                                        quyu_code: ""
                                    }]))
                                    dispatch(setCountyList([{
                                        quyu_name: "全部",
                                        quyu_code: ""
                                    }]))
                                    dispatch(setSchoolList([{
                                        school_name: "全部",
                                        school_code: ""
                                    }]))
                                    getRegionList("2", provinceList[e.target.value].quyu_code)
                                }}
                                value={provinceIndex}
                        >
                            {provinceList.map((item, index) => {
                                return <option value={index}>{item.quyu_name}</option>
                            })}
                        </select>
                    </td>
                    <td
                        className={styles.td1}
                    ><span className={styles.conditionItemTitle}>地市：</span>
                        <select className={styles.conditionDropDown}
                                onChange={(e) => {
                                    dispatch(setMunicipalityIndex(e.target.value))
                                    dispatch(setCountyIndex(0))
                                    dispatch(setSchoolIndex(0))
                                    dispatch(setCountyList([{
                                        quyu_name: "全部",
                                        quyu_code: ""
                                    }]))
                                    dispatch(setSchoolList([{
                                        school_name: "全部",
                                        school_code: ""
                                    }]))
                                    getRegionList("3", municipalityList[e.target.value].quyu_code)
                                }}
                                value={municipalityIndex}
                        >
                            {municipalityList.map((item, index) => {
                                return <option value={index}>{item.quyu_name}</option>
                            })}
                        </select>
                    </td>
                    <td
                        className={styles.td1}
                    ><span className={styles.conditionItemTitle}>区县：</span>
                        <select className={styles.conditionDropDown}
                                onChange={(e) => {
                                    dispatch(setCountyIndex(e.target.value))
                                    dispatch(setSchoolIndex(0))
                                    dispatch(setSchoolList([{
                                        school_name: "全部",
                                        school_code: ""
                                    }]))
                                    getSchoolsList(provinceList[provinceIndex].quyu_code, municipalityList[municipalityIndex].quyu_code, countyList[e.target.value].quyu_code)
                                }}
                                value={countyIndex}
                        >
                            {countyList.map((item, index) => {
                                return <option value={index}>{item.quyu_name}</option>
                            })}
                        </select>
                    </td>
                    <td
                        className={styles.td1}
                    ><span className={styles.conditionItemTitle}>学校名称：</span>
                        <select className={styles.conditionDropDown}
                                onChange={(e) => {
                                    dispatch(setSchoolIndex(e.target.value))
                                }}
                                value={schoolIndex}
                        >
                            {schoolList.map((item, index) => {
                                return <option value={index}>{item.school_name}</option>
                            })}
                        </select>
                    </td>

                </tr>
                <tr
                    className={styles.tr1}
                >
                    {/*<td*/}
                    {/*    className={styles.td2}*/}
                    {/*>*/}
                    {/*    <span className={styles.conditionItemTitle}>学年：</span>*/}
                    {/*    <select className={styles.conditionDropDown}*/}
                    {/*            onChange={(e) => {*/}
                    {/*                dispatch(setXueNianIndex(e.target.value))*/}
                    {/*            }}*/}
                    {/*            value={xuenianIndex}*/}
                    {/*    >*/}
                    {/*        {xueNianList.map((item, index) => {*/}
                    {/*            return <option value={index}>{item.name}</option>*/}
                    {/*        })}*/}
                    {/*    </select>*/}
                    {/*</td>*/}
                    {/*<td*/}
                    {/*    className={styles.td2}*/}
                    {/*><span className={styles.conditionItemTitle}>学期：</span>*/}
                    {/*    <select className={styles.conditionDropDown}*/}
                    {/*            onChange={(e) => {*/}
                    {/*                dispatch(setXueQiIndex(e.target.value))*/}
                    {/*            }}*/}
                    {/*            value={xueQiIndex}*/}
                    {/*    >*/}
                    {/*        {xueQiList.map((item, index) => {*/}
                    {/*            return <option value={index}>{item.name}</option>*/}
                    {/*        })}*/}
                    {/*    </select>*/}
                    {/*</td>*/}

                    <td
                        className={styles.td2}
                    ><span className={styles.conditionItemTitle}>学生：</span>
                        <input
                            placeholder={'全部'}
                            className={styles.conditionParentInput}
                            onChange={(e) => {
                                dispatch(setStudentName(e.target.value))
                            }}
                            value={studentName}
                        />
                    </td>
                    <td
                        className={styles.td2}
                    >
                        <span className={styles.conditionItemTitle}>性别：</span>
                        <select className={styles.conditionDropDown}
                                onChange={(e) => {
                                    dispatch(setSexIndex(e.target.value))
                                }}
                                value={sexIndex}
                        >
                            {sexList.map((item, index) => {
                                return <option value={index}>{item.name}</option>
                            })}
                        </select>
                    </td>
                    <td
                        className={styles.td2}
                    ><span className={styles.conditionItemTitle}>年级：</span>
                        <select className={styles.conditionDropDown}
                                onChange={(e) => {
                                    dispatch(setGradeIndex(e.target.value))
                                }}
                                value={gradeIndex}
                        >
                            {gradeList.map((item, index) => {
                                return <option value={index}>{item.name}</option>
                            })}
                        </select>
                    </td>
                    <td
                        className={styles.td2}
                    ><span className={styles.conditionItemTitle}>家长姓名：</span>
                        <input
                            placeholder={'全部'}
                            className={styles.conditionParentInput}
                            onChange={(e) => {
                                dispatch(setParentName(e.target.value))
                            }}
                            value={parentName}
                        />
                    </td>

                </tr>
                <tr
                    className={styles.tr1}
                >

                </tr>
            </table>
            <div className={styles.orderNameContainer}>
                {/*<div className={styles.orderNameItem1}>*/}
                {/*    <span className={styles.conditionItemTitle}>商户订单号查询：</span>*/}
                {/*    <input*/}
                {/*        className={styles.conditionOrderNameInput}*/}
                {/*        onChange={(e) => {*/}
                {/*            dispatch(setMerchantOrderNo(e.target.value))*/}
                {/*        }}*/}
                {/*        value={merchantOrderNo}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className={styles.orderNameItem2}>*/}
                {/*    <span className={classNames(styles.conditionItemTitle, styles.orderName)}>平台订单号查询：</span>*/}
                {/*    <input*/}
                {/*        className={styles.conditionOrderNameInput1}*/}
                {/*        onChange={(e) => {*/}
                {/*            dispatch(setPlatformOrderNo(e.target.value))*/}
                {/*        }}*/}
                {/*        value={platformOrderNo}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className={styles.orderNameItem1}>
                    <span className={styles.conditionItemTitle}>平台订单号查询：</span>
                    <input
                        className={styles.conditionOrderNameInput}
                        placeholder={'输入由支付平台定义的平台订单号进行特定订单查询'}
                        onChange={(e) => {
                            dispatch(setPlatformOrderNo(e.target.value))
                        }}
                        value={platformOrderNo}
                    />
                </div>
                <div className={styles.orderNameItem2}>
                    <span className={classNames(styles.conditionItemTitle, styles.orderName)}>关键字：</span>
                    <input
                        className={styles.conditionOrderNameInput1}
                        placeholder={'输入学校名称关键字进行查询'}
                        onChange={(e) => {
                            dispatch(setSchoolOrCourseNameKey(e.target.value))
                        }}
                        value={nameKey}
                    />
                </div>
            </div>
        </div>
        {/*</div>*/}
        <div className={styles.buttonsContainer}>
            <div className={classNames(styles.buttonContainer, styles.button1)}
                 onClick={searchMethod}
            >
                <FontAwesomeIcon icon={faSearch} className={styles.buttonIcon}/>
                查询
            </div>
            <div className={classNames(styles.buttonContainer, styles.button2)}
                 onClick={(e) => {
                     dispatch(setRangeTime(["", ""]))
                     dispatch(setPayStatus(0))
                     dispatch(setRefundStatus(0))
                     dispatch(setPayTypeIndex(0))
                     dispatch(setProvinceIndex(0))
                     dispatch(setMunicipalityIndex(0))
                     dispatch(setCountyIndex(0))
                     dispatch(setSchoolIndex(0))
                     dispatch(setXueNianIndex(0))
                     dispatch(setXueQiIndex(0))
                     dispatch(setStudentName(""))
                     dispatch(setSexIndex(0))
                     dispatch(setParentName(""))
                     dispatch(setCourseIndex(0))
                     dispatch(setJiaojuIndex(0))
                     dispatch(setMerchantOrderNo(""))
                     dispatch(setPlatformOrderNo(""))
                     dispatch(setInfoNumber(0))
                     dispatch(setInfoAccount(0))
                     dispatch(setInfoList([]))
                     dispatch(setGradeIndex(0))
                     dispatch(setSchoolOrCourseNameKey(""))
                     // set
                 }}
            >
                <FontAwesomeIcon icon={faReply} className={styles.buttonIcon}/>
                重置
            </div>
            <div className={styles.buttonContainer}
                 onClick={exportAllInfo}
            >
                <FontAwesomeIcon icon={faUpload} className={styles.buttonIcon}/>
                导出
            </div>
        </div>
    </div>
})
export default OrderConditionalQuery;