import {
    createSlice
} from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
    SBPSXueqiList:[],
    SBPSXueqiIndex:0,
    XSSMSchoolIndex:0,
    XSSMSchoolList:[],//已完成学术说明会的学校
    SBPSSchoolList:[],
    rangeTime:["",""],

    xxchr:"",
    yjschr:"",
    fileList1:[],
    fileList2:[],
    fileList3:[],
    fileList4:[],

    psjlIndex:0,
    psjlList:[{
        name:"暂无",
        code:"1"
    },{
        name:"通过",
        code:"2"
    },{
        name:"不通过",
        code:"3"
    }],
    SBPSDetailInfo:{}
};

export const ZXYYSBPSHJSlice = createSlice({
    name: 'ZXYYSBPSHJ',
    initialState,
    reducers: {
        setSBPSXueqiList:(state, action) => {
            state.SBPSXueqiList = action.payload;
        },
        setSBPSXueqiIndex:(state, action) => {
            state.SBPSXueqiIndex = action.payload;
        },
        setXSSMSchoolIndex:(state, action) => {
            state.XSSMSchoolIndex = action.payload;
        },

        setXSSMSchoolList:(state, action) => {
            state.XSSMSchoolList = action.payload;
        },
        setSBPSSchoolList:(state, action) => {
            state.SBPSSchoolList = action.payload;
        },
        setRangeTime:(state, action) => {
            state.rangeTime = action.payload;
        },
        setXxchr:(state, action) => {
            state.xxchr = action.payload;
        },
        setYjschr:(state, action) => {
            state.yjschr = action.payload;
        },
        setFileList1:(state, action) => {
            state.fileList1 = action.payload;
        },
        setFileList2:(state, action) => {
            state.fileList2 = action.payload;
        },
        setFileList3:(state, action) => {
            state.fileList3 = action.payload;
        },
        setFileList4:(state, action) => {
            state.fileList4 = action.payload;
        },
        setpsjlIndex:(state, action) => {
            state.psjlIndex = action.payload;
        },
        setpsjlList:(state, action) => {
            state.psjlList = action.payload;
        },
        setSBPSDetailInfo:(state, action) => {
            state.SBPSDetailInfo = action.payload;
        }



    },
});
export const selectSBPSXueqiList = (state) => state.ZXYYSBPSHJ.SBPSXueqiList;
export const selectSBPSXueqiIndex = (state) => state.ZXYYSBPSHJ.SBPSXueqiIndex;
export const selectXSSMSchoolIndex = (state) => state.ZXYYSBPSHJ.XSSMSchoolIndex;
export const selectRangeTime = (state) => state.ZXYYSBPSHJ.rangeTime;
export const selectXxchr = (state) => state.ZXYYSBPSHJ.xxchr;
export const selectYjschr = (state) => state.ZXYYSBPSHJ.yjschr;
export const selectFileList1= (state) => state.ZXYYSBPSHJ.fileList1;
export const selectFileList2= (state) => state.ZXYYSBPSHJ.fileList2;
export const selectFileList3= (state) => state.ZXYYSBPSHJ.fileList3;
export const selectFileList4= (state) => state.ZXYYSBPSHJ.fileList4;
export const selectXSSMSchoolList = (state) => state.ZXYYSBPSHJ.XSSMSchoolList;
export const selectSBPSSchoolList = (state) => state.ZXYYSBPSHJ.SBPSSchoolList;
export const selectpsjlIndex = (state) => state.ZXYYSBPSHJ.psjlIndex;
export const selectpsjlList = (state) => state.ZXYYSBPSHJ.psjlList;
export const selectSBPSDetailInfo = (state) => state.ZXYYSBPSHJ.SBPSDetailInfo;
export const {
    setSBPSXueqiList,
    setXSSMSchoolIndex,
    setSBPSXueqiIndex,
    setXSSMSchoolList,
    setSBPSSchoolList,
    setRangeTime,
    setXxchr,
    setYjschr,
    setFileList1,
    setFileList2,
    setFileList3,
    setFileList4,
    setpsjlIndex,
    setpsjlList,
    setSBPSDetailInfo,
} = ZXYYSBPSHJSlice.actions;

export default ZXYYSBPSHJSlice.reducer;