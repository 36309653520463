
import React, {useRef, useState} from "react";
import styles from "./EditInfo.module.css";
import { useDispatch,useSelector } from "react-redux";
import classNames from "classnames"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClose,faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
import { selectCompanyName, selectContactName, selectEditInfo, selectPageNumber, selectRegionName, setCompanyName, setShowEditInfoPage } from "./CountyInfoManageSlice";
import { updateCountyCompany } from "./CountyInfoManageApi";
import { setAlertInfo } from "../../../store/CustomViewSlice";
import {message} from 'antd'
export default function EditInfo(props) {
    const [countyName, setCountyName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [contactsName, setContactsName] = useState("");
    const [contactsPhone, setContactsPhone] = useState("");
    const [telePhone1, setTelePhone1] = useState("");
    const [telePhone2, setTelePhone2] = useState("");
    const [telePhone3, setTelePhone3] = useState("");
    const [notes,setNotes] = useState("")
    const [account, setAccount] = useState("");
    const [password, setPassword] = useState("");
    const [accountStatus,setAccountStatus] = useState(0)
    const editInfo = useSelector(selectEditInfo);
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    function isValidPhoneNumber(phoneNumber) {
        return /^1[3-9]\d{9}$/.test(phoneNumber);
    }
    return <div className={styles.background}>
        {contextHolder}
        <div className={styles.contentContainer}>
            <div className={styles.topBar}>
                <div className={styles.topBarLeft}>编辑区县区域</div>
                <FontAwesomeIcon 
                    className={styles.topBarRight}
                    icon={faClose}
                    onClick={(e)=>{
                        dispatch(setShowEditInfoPage(false))
                    }}
                    />
            </div>
            <div className={styles.middleContent}>
                <div className={styles.middleLeftContainer}>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            区县名称：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            区县公司名称：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            联系人：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            联系电话：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div>
                            联系电话(座机)：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            登录账号：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            登录密码：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight3)}>
                        <div>
                            账户状态：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight1)}>
                        {/* <div className={styles.redStar}>*</div> */}
                        <div>
                            登录备注：
                        </div>
                    </div>
                    
                </div>
                <div className={styles.middleRightContainer}>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            // placeholder="请选择所属区县区域"
                            placeholder={editInfo.quxian_name+"(只读)"}
                            readOnly
                            value={countyName}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            // placeholder="请填写区县公司全名"
                            placeholder={editInfo.gongsi_name}
                            value={companyName}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setCompanyName(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            // placeholder="请填写区县公司联系人全名"
                            placeholder={editInfo.lianxiren}
                            value={contactsName}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setContactsName(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            // placeholder="请填写区县公司联系人电话"
                            placeholder={editInfo.lianxi_dianhua}
                            value={contactsPhone}
                            maxLength={11}
                            onChange={((e)=>{
                                setContactsPhone(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={classNames(styles.middleRightInput,styles.schoolTelephoneWidth)}
                            // placeholder="电话区号"
                            placeholder={editInfo.zuoji_quhao}
                            value={telePhone1}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setTelePhone1(e.target.value)
                            })}
                        />
                        <input className={classNames(styles.middleRightInput,styles.schoolTelephoneWidth1)}
                            // placeholder="电话号码"
                            placeholder={editInfo.zuoji_dianhuahao}
                            value={telePhone2}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setTelePhone2(e.target.value)
                            })}
                        />
                        <input className={classNames(styles.middleRightInput,styles.schoolTelephoneWidth2)}
                            // placeholder="分机号码"
                            placeholder={editInfo.zuoji_fenjihao}
                            value={telePhone3}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setTelePhone3(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            // placeholder="区县公司登录系统所使用的账号"
                            placeholder={editInfo.user_login}
                            value={account}
                            readOnly
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setAccount(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            // placeholder="设定登录密码"
                            placeholder={editInfo.password.replace(/[^\s]/g, '*')}
                            value={password}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setPassword(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight4)}>
                        <input
                            className={styles.manRadio}
                            type="radio"
                            checked={accountStatus==0?editInfo.status==1:accountStatus==1}
                            onChange={(e)=>{
                                setAccountStatus(1)
                            }}
                        />
                        <div className={styles.sexName}>开通</div>
                        <input
                            className={styles.womanRadio}
                            type="radio"
                            // checked={accountStatus==0}
                            checked={accountStatus==0?editInfo.status==2:accountStatus==2}
                            onChange={(e)=>{
                                setAccountStatus(2)
                            }}
                        />
                        <div className={styles.sexName}>禁用</div>
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight1)}>
                        <textarea className={styles.middleRightTextarea}
                            // placeholder="请输入备注信息"
                            placeholder={editInfo.beizhu}
                            value={notes}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setNotes(e.target.value)
                            })}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.bottomContainer}>
                <div className={classNames(styles.cancelButton,styles.button)}
                    onClick={(e)=>{
                        dispatch(setShowEditInfoPage(false))
                    }}
                >取消</div>
                <div className={classNames(styles.confirmButton,styles.button)}
                    onClick={(e)=>{
                        updateCountyCompany(
                            editInfo.shengji_name,
                            editInfo.shengji_code,
                            editInfo.shiji_name,
                            editInfo.shiji_code,
                            editInfo.quxian_name,
                            editInfo.quxian_code,
                            companyName==""?editInfo.gongsi_name:companyName,
                            contactsName==""?editInfo.lianxiren:contactsName,
                            contactsPhone==""?editInfo.lianxi_dianhua:contactsPhone,
                            telePhone1==""?editInfo.zuoji_quhao:telePhone1,
                            telePhone2==""?editInfo.zuoji_dianhuahao:telePhone2,
                            telePhone3==""?editInfo.zuoji_fenjihao:telePhone3,
                            editInfo.user_login,
                            password==""?editInfo.password:password,
                            accountStatus==""?editInfo.status:accountStatus,
                            notes==""?editInfo.beizhu:notes,
                            editInfo.code,
                            editInfo.login_username,
                            (data)=>{
                                if (data.ret==1) {
                                    dispatch(setShowEditInfoPage(false))
                                    props.refresh()
                                }else if(data.ret==99){

                                    messageApi.open({
                                        type: 'warning',
                                        content:data.msg
                                    });
                                    setTimeout((e)=>{
                                        sessionStorage.clear()
                                        window.location.reload()
                                    },1000)
                                } else {
                                    dispatch(setAlertInfo({
                                        showStatus:true,
                                        title:"",
                                        title1:'更新失败，请检查内容是否有改动',
                                        content:""
                                    }))
                                }
                            }
                        )
                    }}
                >确定</div>  
            </div>
        </div>
    </div>
}

