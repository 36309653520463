import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
  menuIndex:0,
  schoolList:[]
};

export const QGZMainSlice = createSlice({
  name: 'QGZMain',
  initialState,
  reducers: {
    setMenuIndex: (state,action) => {
        state.menuIndex = action.payload;
    },
    setSchoolList: (state,action) => {
      state.menuIndex = action.payload;
    }
  },
});

export const selectMenuIndex = (state) => state.QGZMain.menuIndex;


export const { 
  setMenuIndex,
  setSchoolList
} = QGZMainSlice.actions;

export default QGZMainSlice.reducer;
