import {
    createAsyncThunk,
    createSlice
  } from '@reduxjs/toolkit';
  // import { fetchCount } from './counterAPI';
  
  const initialState = {
    schoolIndex:0,
    schoolList: [{
      school_name:"未选择",
      school_code:""
    }],
    infoList:[],
    QRContentStr:"",
    downloadStatus:true,

    //分页所需参数
    groupCount:5,
    startPage:1,
    pageNumber:15,
    currentPage:1,
    totalPageNum:1,


    //添加驻校信息界面
    showAddInfoPage:false,
    showEditInfoPage:false,
    editInfo:{},

    // 数据筛选所需参数
    regionName:'',
    companyName:'',
    contactName:'',

    provinceList:[{
  }]
  };
  
  export const ZBZGCountyInfoManageSlice = createSlice({
    name: 'ZBZGCountyInfoManage',
    initialState,
    reducers: {
      setSchoolIndex:(state, action) => {
        state.schoolIndex = action.payload;
      },
      setSchoolList: (state, action) => {
        state.schoolList = action.payload;
      },
      setInfoList: (state, action) => {
        state.infoList = action.payload;
      },
      setQRContentStr:(state, action) => {
        state.QRContentStr = action.payload;
      },
      setQRDownloadStatus:(state, action) => {
        state.downloadStatus = action.payload;
      },


      setGroupCount:(state, action) => {
        state.groupCount = action.payload;
      },
      setStartPage:(state, action) => {
        state.startPage = action.payload;
      },
      setPageNumber:(state, action) => {
        state.pageNumber = action.payload;
      },
      setCurrentPage:(state, action) => {
        state.currentPage = action.payload;
      },
      setTotalPageNum:(state, action) => {
        state.totalPageNum = action.payload;
      },

      setShowAddInfoPage:(state, action) => {
        state.showAddInfoPage = action.payload;
      },
      setShowEditInfoPage:(state, action) => {
        state.showEditInfoPage = action.payload;
      },
      setEditInfo:(state, action) => {
        console.log(action.payload)
        state.editInfo = action.payload;
      },
      setRegionName:(state, action) => {
        state.regionName = action.payload;
      },
      setCompanyName:(state, action) => {
        state.companyName = action.payload;
      },
      setContactName:(state, action) => {
        state.contactName = action.payload;
      },
      setProvinceList:(state, action) => {
        state.provinceList = action.payload;
      },
      
    },
  });
  export const selectSchoolIndex = (state) => state.ZBZGCountyInfoManage.schoolIndex;
  export const selectSchoolList = (state) => state.ZBZGCountyInfoManage.schoolList;
  export const selectInfoList = (state) => state.ZBZGCountyInfoManage.infoList;
  export const selectQRContentStr = (state) => state.ZBZGCountyInfoManage.QRContentStr;
  export const selectDownloadStatus = (state) => state.ZBZGCountyInfoManage.downloadStatus;
  //分页所需数据
  export const selectGroupCount = (state) => state.ZBZGCountyInfoManage.groupCount;
  export const selectStartPage = (state) => state.ZBZGCountyInfoManage.startPage;
  export const selectPageNumber = (state) => state.ZBZGCountyInfoManage.pageNumber;
  export const selectCurrentPage = (state) => state.ZBZGCountyInfoManage.currentPage;
  export const selectTotalPageNum = (state) => state.ZBZGCountyInfoManage.totalPageNum;
  export const selectShowAddInfoPage = (state) => state.ZBZGCountyInfoManage.showAddInfoPage;
  export const selectShowEditInfoPage = (state) => state.ZBZGCountyInfoManage.showEditInfoPage;
  export const selectEditInfo = (state) => state.ZBZGCountyInfoManage.editInfo;
  export const selectRegionName = (state) => state.ZBZGCountyInfoManage.regionName;
  export const selectCompanyName = (state) => state.ZBZGCountyInfoManage.companyName;
  export const selectContactName = (state) => state.ZBZGCountyInfoManage.contactName;
  export const selectProvinceList = (state) => state.ZBZGCountyInfoManage.provinceList;
  
  
  export const {
    setSchoolIndex,
    setSchoolList,
    setInfoList,
    setQRContentStr,
    setQRDownloadStatus,
    setGroupCount,
    setStartPage,
    setPageNumber,
    setCurrentPage,
    setTotalPageNum,
    setShowAddInfoPage,
    setShowEditInfoPage,
    setEditInfo,
    setRegionName,
    setCompanyName,
    setContactName,
    setProvinceList
  } = ZBZGCountyInfoManageSlice.actions;
  
  export default ZBZGCountyInfoManageSlice.reducer;