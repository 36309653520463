
import React, {useRef, useState} from "react";
import styles from "./EditInfo.module.css";
import { useDispatch,useSelector } from "react-redux";
import classNames from "classnames"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClose,faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
import { selectEditInfo, selectProvinceList, setProvinceList, setShowEditInfoPage } from "./ZXInfoManageSlice";
import { getCompanyListRequest, getRegionListRequest, updateZhuxiao } from "./ZXInfoManageApi";
import { setAlertInfo } from "../../../store/CustomViewSlice";
import {message} from 'antd'
export default function EditInfo(props) {
    const provinceList = useSelector(selectProvinceList);
    const [provinceIndex, setProvinceIndex] = useState(0);
    const [showProvinceList, setShowProvinceList] = useState(false);

    const [municipalityList, setMunicipalityList] = useState([{
        quyu_name:"未选择",
        quyu_code:""
    }]);
    const [municipalityIndex, setMunicipalityIndex] = useState(0);
    const [showMunicipalityList, setShowMunicipalityList] = useState(false);
    
    const [showCountyList, setShowCountyList] = useState(false);
    const [countyIndex, setCountyIndex] = useState(0);
    const [countyList, setCountyList] = useState([{
        quyu_name:"未选择",
        quyu_code:""
    }]);

    const [companyIndex, setCompanyIndex] = useState(0);
    const [showCompanyList, setShowCompanyList] = useState("");
    const [companyList, setCompanyList] = useState([{
        gongsi_name:"未选择",
        code:""
    }]);

    const [teacherName, setTeacherName] = useState("");
    const [teacherSex, setTeacherSex] = useState(-1);
    const [teacherPhone, setTeacherPhone] = useState("");
    const [loginAccount, setLoginAccount] = useState("");
    const [password,setPassword] = useState("")
    const [notes,setNotes] = useState("")
    const [accountStatus,setAccountStatus] = useState(0)
    const editInfo = useSelector(selectEditInfo);
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    function getProvinceList(){
        getRegionListRequest("1","",(data)=>{
            var newProvinceList = [{
                quyu_name:"未选择",
                quyu_code:""
            }];
            if (data.ret==1) {
                data.data.forEach(element => {
                    newProvinceList.push(element)
                });
                dispatch(setProvinceList(newProvinceList))
            }else if(data.ret==99){

                messageApi.open({
                    type: 'warning',
                    content:data.msg
                });
                setTimeout((e)=>{
                    sessionStorage.clear()
                    window.location.reload()
                },1000)
            }
        })
    }
    function getMunicipalityList(index){
        setCountyIndex(0)
        setCountyList([{
            quyu_name:"未选择",
            quyu_code:""
        }])
        setCompanyIndex(0)
        setCompanyList([{
            gongsi_name:"未选择",
            code:""
        }])
        if (index==0) {
            setMunicipalityIndex(0)
            setMunicipalityList([{
                quyu_name:"未选择",
                quyu_code:""
            }])
        }
        else{
            getRegionListRequest("2",provinceList[index].quyu_code,(data)=>{
                var tempList = [{
                    quyu_name:"未选择",
                    quyu_code:""
                }];
                if (data.ret==1) {
                    data.data.forEach(element => {
                        tempList.push(element)
                    });
                    setMunicipalityIndex(0)
                    setMunicipalityList(tempList)
                }else if(data.ret==99){

                    messageApi.open({
                        type: 'warning',
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }
                else{
                    setMunicipalityIndex(0)
                    setMunicipalityList([{
                        quyu_name:"未选择",
                        quyu_code:""
                    }])
                }
            })
        }
    }
    function getCountyList(index){
        setCompanyIndex(0)
        setCompanyList([{
            gongsi_name:"未选择",
            code:""
        }])
        if (index==0) {
            setCountyIndex(0)
            setCountyList([{
                quyu_name:"未选择",
                quyu_code:""
            }])
        }
        else{
            getRegionListRequest("3",municipalityList[index].quyu_code,(data)=>{
                var tempList = [{
                    quyu_name:"未选择",
                    quyu_code:""
                }];
                if (data.ret==1) {
                    data.data.forEach(element => {
                        tempList.push(element)
                    });
                    setCountyIndex(0)
                    setCountyList(tempList)
                }else if(data.ret==99){

                    messageApi.open({
                        type: 'warning',
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }
                else{
                    setCountyIndex(0)
                    setCountyList([{
                        quyu_name:"未选择",
                        quyu_code:""
                    }])
                }
            })
        }
        
    }
    function getCompanyList(index){
        if (index==0) {
            setCompanyIndex(0)
            setCompanyList([{
                gongsi_name:"未选择",
                code:""
            }])
        }
        else{
            getCompanyListRequest(provinceList[provinceIndex].quyu_code,municipalityList[municipalityIndex].quyu_code,countyList[index].quyu_code,(data)=>{
                var tempList = [{
                    gongsi_name:"未选择",
                    code:""
                }];
                if (data.ret==1) {
                    data.data.forEach(element => {
                        tempList.push(element)
                    });
                    setCompanyIndex(0)
                    setCompanyList(tempList)
                }else if(data.ret==99){

                    messageApi.open({
                        type: 'warning',
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }
                else{
                    setCompanyIndex(0)
                    setCompanyList([{
                        gongsi_name:"未选择",
                        code:""
                    }])
                }
            })
        }
        
    }
    function isValidPhoneNumber(phoneNumber) {
        return /^1[3-9]\d{9}$/.test(phoneNumber);
    }
    return <div className={styles.background}>
        {contextHolder}
        {showProvinceList||showMunicipalityList||showCountyList||showCompanyList?<div className={styles.hideListCover}
            onClick={(e)=>{
                setShowProvinceList(false)
                setShowMunicipalityList(false)
                setShowCountyList(false)
                setShowCompanyList(false)
            }}
        ></div>:null}
        <div className={styles.contentContainer}>
            <div className={styles.topBar}>
                <div className={styles.topBarLeft}>编辑驻校信息</div>
                <FontAwesomeIcon 
                    className={styles.topBarRight}
                    icon={faClose}
                    onClick={(e)=>{
                        dispatch(setShowEditInfoPage(false))
                    }}
                    />
            </div>
            <div className={styles.middleContent}>
                <div className={styles.middleLeftContainer}>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            所属省级区域：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            所属地市：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            所属区县区域：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            所属区县公司：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            姓名：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            性别：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            联系电话：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            登录账号：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight)}>
                        <div className={styles.redStar}>*</div>
                        <div>
                            登录密码：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight1)}>
                        {/* <div className={styles.redStar}>*</div> */}
                        <div>
                            登录备注：
                        </div>
                    </div>
                    <div className={classNames(styles.middleLeftItem,styles.middleLeftItemHeight3)}>
                        <div>
                            账户状态：
                        </div>
                    </div>
                </div>
                <div className={styles.middleRightContainer}>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            // placeholder="请选择所属省级区域"
                            placeholder={editInfo.shengji_name}
                            value={provinceIndex==0?"":provinceList[provinceIndex].quyu_name}
                            readOnly
                            onClick={(e)=>{
                                setShowProvinceList(!showProvinceList)
                                setShowMunicipalityList(false)
                                setShowCountyList(false)
                                setShowCompanyList(false)
                            }}
                        />
                        <FontAwesomeIcon 
                            className={styles.inputIcon}
                            icon={faAngleDown}
                        />
                        {showProvinceList?<div className={classNames(styles.tankangContainer,styles.municipalityListPosition)}>
                            {provinceList.map((item,index,arr)=>{
                                return <div className={styles.tankuangItem}
                                    key={index}
                                    onClick={(e)=>{
                                        setProvinceIndex(index)
                                        setShowProvinceList(false)
                                        getMunicipalityList(index)
                                    }}
                                >{item.quyu_name}</div>
                            })}
                        </div>:null}
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            // placeholder="请选择所属地市"
                            placeholder={editInfo.shiji_name}
                            readOnly
                            value={provinceIndex==0&&municipalityIndex==0?"":municipalityList[municipalityIndex].quyu_name}
                            onClick={(e)=>{
                                setShowProvinceList(false)
                                setShowMunicipalityList(!showMunicipalityList)
                                setShowCountyList(false)
                                setShowCompanyList(false)
                            }}
                        />
                        <FontAwesomeIcon 
                            className={styles.inputIcon}
                            icon={faAngleDown}
                        />
                        {showMunicipalityList?<div className={classNames(styles.tankangContainer,styles.municipalityListPosition)}>
                            {municipalityList.map((item,index,arr)=>{
                                return <div className={styles.tankuangItem}
                                    key={index}
                                    onClick={(e)=>{
                                        setMunicipalityIndex(index)
                                        setShowMunicipalityList(false)
                                        getCountyList(index)
                                    }}
                                >{item.quyu_name}</div>
                            })}
                        </div>:null}
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            // placeholder="请选择所属区县区域"
                            placeholder={editInfo.quxian_name}
                            readOnly
                            value={provinceIndex==0&&municipalityIndex==0&&countyIndex==0?"":countyList[countyIndex].quyu_name}
                            onClick={(e)=>{
                                setShowProvinceList(false)
                                setShowMunicipalityList(false)
                                setShowCountyList(!showCountyList)
                                setShowCompanyList(false)
                            }}
                        />
                        <FontAwesomeIcon 
                            className={styles.inputIcon}
                            icon={faAngleDown}
                        />
                        {showCountyList?<div className={classNames(styles.tankangContainer,styles.municipalityListPosition)}>
                            {countyList.map((item,index,arr)=>{
                                return <div className={styles.tankuangItem}
                                    key={index}
                                    onClick={(e)=>{
                                        setCountyIndex(index)
                                        setShowCountyList(false)
                                        getCompanyList(index)
                                    }}
                                >{item.quyu_name}</div>
                            })}
                        </div>:null}
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            // placeholder="请选择所对应的区县公司"
                            placeholder={editInfo.quxian_gongsi_name}
                            readOnly
                            value={provinceIndex==0&&municipalityIndex==0&&countyIndex==0&&companyIndex==0?"":companyList[companyIndex].gongsi_name}
                            onClick={(e)=>{
                                setShowProvinceList(false)
                                setShowMunicipalityList(false)
                                setShowCountyList(false)
                                setShowCompanyList(!showCompanyList)
                            }}
                        />
                        <FontAwesomeIcon 
                            className={styles.inputIcon}
                            icon={faAngleDown}
                        />
                        {showCompanyList?<div className={classNames(styles.tankangContainer,styles.municipalityListPosition)}>
                            {companyList.map((item,index,arr)=>{
                                return <div className={styles.tankuangItem}
                                    key={index}
                                    onClick={(e)=>{
                                        setCompanyIndex(index)
                                        setShowCompanyList(false)
                                    }}
                                >{item.gongsi_name}</div>
                            })}
                        </div>:null}
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            // placeholder="填写驻校人员姓名"
                            placeholder={editInfo.xingming}
                            value={teacherName}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setTeacherName(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input
                            className={styles.manRadio}
                            type="radio"
                            // checked={teacherSex==1}
                            checked={teacherSex==-1?editInfo.xingbie==1:teacherSex==1}
                            onChange={(e)=>{
                                setTeacherSex(1)
                            }}
                        />
                        <div className={styles.sexName}>男</div>
                        <input
                            className={styles.womanRadio}
                            type="radio"
                            // checked={teacherSex==0}
                            checked={teacherSex==-1?editInfo.xingbie==0:teacherSex==0}
                            onChange={(e)=>{
                                setTeacherSex(0)
                            }}
                        />
                        <div className={styles.sexName}>女</div>
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            // placeholder="驻校人员联系电话"
                            placeholder={editInfo.lianxi_dianhua}
                            value={teacherPhone}
                            maxLength={11}
                            onChange={((e)=>{
                                setTeacherPhone(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            // placeholder="驻校人员登录系统所使用的账号"
                            placeholder={editInfo.user_login}
                            value={loginAccount}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setLoginAccount(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight)}>
                        <input className={styles.middleRightInput}
                            // placeholder="设定登录密码"
                            placeholder={editInfo.password.replace(/[^\s]/g, '*')}
                            value={password}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setPassword(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight1)}>
                        <textarea className={styles.middleRightTextarea}
                            // placeholder="请输入备注信息"
                            placeholder={editInfo.beizhu}
                            value={notes}
                            onChange={((e)=>{
                                console.log(e.target.value)
                                setNotes(e.target.value)
                            })}
                        />
                    </div>
                    <div className={classNames(styles.middleRightItem,styles.middleLeftItemHeight4)}>
                        <input
                            className={styles.manRadio}
                            type="radio"
                            checked={accountStatus==0?editInfo.status==1:accountStatus==1}
                            onChange={(e)=>{
                                setAccountStatus(1)
                            }}
                        />
                        <div className={styles.sexName}>开通</div>
                        <input
                            className={styles.womanRadio}
                            type="radio"
                            checked={accountStatus==0?editInfo.status==2:accountStatus==2}
                            onChange={(e)=>{
                                setAccountStatus(2)
                            }}
                        />
                        <div className={styles.sexName}>禁用</div>
                    </div>
                </div>
            </div>
            <div className={styles.bottomContainer}>
                <div className={classNames(styles.cancelButton,styles.button)}
                    onClick={(e)=>{
                        dispatch(setShowEditInfoPage(false))
                    }}
                >取消</div>
                <div className={classNames(styles.confirmButton,styles.button)}
                    onClick={(e)=>{
                        
                        if(password!=""&&password.length<6){
                            dispatch(setAlertInfo({
                                showStatus:true,
                                title:"",
                                title1:"登录密码不能小于6位",
                                content:""
                            }))
                        }
                        else{
                            updateZhuxiao(
                                provinceIndex==0?editInfo.shengji_name:provinceList[provinceIndex].quyu_name,
                                provinceIndex==0?editInfo.shengji_code:provinceList[provinceIndex].quyu_code,
                                provinceIndex==0&&municipalityIndex==0?editInfo.shiji_name:municipalityList[municipalityIndex].quyu_name,
                                provinceIndex==0&&municipalityIndex==0?editInfo.shiji_code:municipalityList[municipalityIndex].quyu_code,
                                provinceIndex==0&&municipalityIndex==0&&countyIndex==0?editInfo.quxian_name:countyList[countyIndex].quyu_name,
                                provinceIndex==0&&municipalityIndex==0&&countyIndex==0?editInfo.quxian_code:countyList[countyIndex].quyu_code,
                                provinceIndex==0&&municipalityIndex==0&&countyIndex==0&&companyIndex==0?editInfo.quxian_gongsi_name:companyList[companyIndex].gongsi_name,
                                provinceIndex==0&&municipalityIndex==0&&countyIndex==0&&companyIndex==0?editInfo.quxian_gongsi_code:companyList[companyIndex].code,
                                teacherName==""?editInfo.xingming:teacherName,
                                teacherSex==-1?editInfo.xingbie:teacherSex,
                                teacherPhone==""?editInfo.lianxi_dianhua:teacherPhone,
                                loginAccount==""?editInfo.user_login:loginAccount,
                                password==""?editInfo.password:password,
                                accountStatus==0?editInfo.status:accountStatus,
                                notes==""?editInfo.beizhu:notes,
                                editInfo.code,
                                editInfo.login_username,
                                (data)=>{
                                    if (data.ret==1) {
                                        dispatch(setShowEditInfoPage(false))
                                        props.refresh()
                                    }else if(data.ret==99){

                                        messageApi.open({
                                            type: 'warning',
                                            content:data.msg
                                        });
                                        setTimeout((e)=>{
                                            sessionStorage.clear()
                                            window.location.reload()
                                        },1000)
                                    } else {
                                        dispatch(setAlertInfo({
                                            showStatus:true,
                                            title:"",
                                            title1:"更新失败，请检查内容是否有改动",
                                            content:""
                                        }))
                                    }
                                }
                            )
                        }
                    }}
                >确定</div>  
            </div>
        </div>
    </div>
}

