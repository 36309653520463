import {API_URL} from "./constants";

function getApiUrl(path){
    let url;
    if (path.indexOf("http")==-1) {
        url=API_URL+path;
    }
    else{
        url=path;
    }
     return url;
}


function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    // const errortext = codeMessage[response.status] || response.statusText;
    // notification.error({
    //     message: `请求错误 ${response.status}: ${response.url}`,
    //     description: errortext,
    // });
    const error = new Error();
    error.name = response.status.toString();
    error.response = response;
    throw error;
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options, callback) {

    // 提交请求不用带cookie
    const defaultOptions = {
        headers: {},
        // credentials: "include",
    };
    const newOptions = { ...defaultOptions, ...options };


    if (
        newOptions.method === "POST" ||
        newOptions.method === "PUT" ||
        newOptions.method === "DELETE"
    ) {
        if (!(newOptions.body instanceof FormData)) {
            newOptions.headers = {
                Accept:"application/json",
    //"Content-Type": "application/json",
                ...newOptions.headers,
            };

           newOptions.body = JSON.stringify(newOptions.body);


        } else {
            // newOptions.body is FormData
            newOptions.headers = {
                Accept: "application/json",
                ...newOptions.headers,
            };
        }
    }
    return fetch(getApiUrl(url), newOptions)
        .then(checkStatus)
        .then(response => response.json())
        .then(data => {
            callback && callback(data);
        })
        .catch(e => {
            console.error(e);
            callback && callback(e);
            var event = new CustomEvent('showCustomAlert', {
                "detail": {  //可携带额外的数据
                   content:"未知错误("+url.substr(url.length-5)+")，请检查网络或刷新重试！"
                }
            });
            if(window.dispatchEvent) {  
                window.parent.window.dispatchEvent(event);
            } else {
                window.parent.window.fireEvent(event);
            }
            // alert("未知错误("+url.substr(url.length-5)+")，请检查网络或刷新重试！");
        });
}
