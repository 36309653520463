import {
    createAsyncThunk,
    createSlice
  } from '@reduxjs/toolkit';
  // import { fetchCount } from './counterAPI';
  
  const initialState = {
    schoolIndex:0,
    schoolList: [{
      school_name:"未选择",
      school_code:""
    }],
    infoList:[],
    QRContentStr:"",
    downloadStatus:true,

    //分页所需参数
    groupCount:5,
    startPage:1,
    pageNumber:15,
    currentPage:1,
    totalPageNum:1,

    //添加驻校信息界面
    showAddInfoPage:false,
    showEditInfoPage:false,

    editInfo:{},
    // 数据筛选所需参数
    regionName:'',
    schoolName:'',
    contactName:'',

    provinceList:[{
    }]
  };
  
  export const ZBZGZXInfoManageSlice = createSlice({
    name: 'ZBZGZXInfoManage',
    initialState,
    reducers: {
      setSchoolIndex:(state, action) => {
        state.schoolIndex = action.payload;
      },
      setSchoolList: (state, action) => {
        state.schoolList = action.payload;
      },
      setInfoList: (state, action) => {
        state.infoList = action.payload;
      },
      setQRContentStr:(state, action) => {
        state.QRContentStr = action.payload;
      },
      setQRDownloadStatus:(state, action) => {
        state.downloadStatus = action.payload;
      },


      setGroupCount:(state, action) => {
        state.groupCount = action.payload;
      },
      setStartPage:(state, action) => {
        state.startPage = action.payload;
      },
      setPageNumber:(state, action) => {
        state.pageNumber = action.payload;
      },
      setCurrentPage:(state, action) => {
        state.currentPage = action.payload;
      },
      setTotalPageNum:(state, action) => {
        state.totalPageNum = action.payload;
      },

      setShowAddInfoPage:(state, action) => {
        state.showAddInfoPage = action.payload;
      },
      setShowEditInfoPage:(state, action) => {
        state.showEditInfoPage = action.payload;
      },

      setEditInfo:(state, action) => {
        state.editInfo = action.payload;
      },
      setRegionName:(state, action) => {
        state.regionName = action.payload;
      },
      setSchoolName:(state, action) => {
        state.schoolName = action.payload;
      },
      setContactName:(state, action) => {
        state.contactName = action.payload;
      },
      setProvinceList:(state, action) => {
        state.provinceList = action.payload;
      },
      
    },
  });
  export const selectSchoolIndex = (state) => state.ZBZGZXInfoManage.schoolIndex;
  export const selectSchoolList = (state) => state.ZBZGZXInfoManage.schoolList;
  export const selectInfoList = (state) => state.ZBZGZXInfoManage.infoList;
  export const selectQRContentStr = (state) => state.ZBZGZXInfoManage.QRContentStr;
  export const selectDownloadStatus = (state) => state.ZBZGZXInfoManage.downloadStatus;
  //分页所需数据
  export const selectGroupCount = (state) => state.ZBZGZXInfoManage.groupCount;
  export const selectStartPage = (state) => state.ZBZGZXInfoManage.startPage;
  export const selectPageNumber = (state) => state.ZBZGZXInfoManage.pageNumber;
  export const selectCurrentPage = (state) => state.ZBZGZXInfoManage.currentPage;
  export const selectTotalPageNum = (state) => state.ZBZGZXInfoManage.totalPageNum;
  export const selectShowAddInfoPage = (state) => state.ZBZGZXInfoManage.showAddInfoPage;
  export const selectShowEditInfoPage = (state) => state.ZBZGZXInfoManage.showEditInfoPage;
  export const selectEditInfo = (state) => state.ZBZGZXInfoManage.editInfo;
  export const selectRegionName = (state) => state.ZBZGZXInfoManage.regionName;
  export const selectSchoolName = (state) => state.ZBZGZXInfoManage.schoolName;
  export const selectContactName = (state) => state.ZBZGZXInfoManage.contactName;
  export const selectProvinceList = (state) => state.ZBZGZXInfoManage.provinceList;
  
  export const {
    setSchoolIndex,
    setSchoolList,
    setInfoList,
    setQRContentStr,
    setQRDownloadStatus,
    setGroupCount,
    setStartPage,
    setPageNumber,
    setCurrentPage,
    setTotalPageNum,
    setShowAddInfoPage,
    setShowEditInfoPage,
    setEditInfo,
    setRegionName,
    setSchoolName,
    setContactName,
    setProvinceList
  } = ZBZGZXInfoManageSlice.actions;
  
  export default ZBZGZXInfoManageSlice.reducer;