import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
    leftTopTitle:"",
    showRefreshButton:true
};

export const TopControlSlice = createSlice({
  name: 'topControl',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },
});

export const selectLeftTopTitle = (state) => state.modals.leftTopTitle;
export const selectShowRefreshButton = (state) => state.modals.showRefreshButton;


export const { 
} = TopControlSlice.actions;

export default TopControlSlice.reducer;
