import {
    createAsyncThunk,
    createSlice
  } from '@reduxjs/toolkit';
  // import { fetchCount } from './counterAPI';
  
  const initialState = {
    infoList:[],
    QRContentStr:"",
    downloadStatus:true,

    

    //分页所需参数
    groupCount:5,
    startPage:1,
    pageNumber:5,
    currentPage:1,
    totalPageNum:1,


    showAddInfoPage:false,
    showAddInfoCoursePage:false,
    allCourseList:[],
    allCourseChecked:false,
    showAddInfoTimePage:false,
    showEditInfoPage:false,
    
    editInfo:{},

    // 数据筛选所需参数
    teacherName:'',
    teacherCategoryIndex:0,
    teacherCategoryList: [{
      name:"全部类别",
      code:""
    }],
    courseTypeIndex:0,
    courseTypeList: [{
      name:"全部课程",
      code:""
    }],


    companyName:'',
    contactName:'',

    provinceList:[{
    }]
  };
  
  export const YYZJTeacherManageSlice = createSlice({
    name: 'YYZJTeacherManage',
    initialState,
    reducers: {
      setInfoList: (state, action) => {
        state.infoList = action.payload;
      },


      setGroupCount:(state, action) => {
        state.groupCount = action.payload;
      },
      setStartPage:(state, action) => {
        state.startPage = action.payload;
      },
      setPageNumber:(state, action) => {
        state.pageNumber = action.payload;
      },
      setCurrentPage:(state, action) => {
        state.currentPage = action.payload;
      },
      setTotalPageNum:(state, action) => {
        state.totalPageNum = action.payload;
      },

      setShowAddInfoPage:(state, action) => {
        state.showAddInfoPage = action.payload;
      },
      setShowAddInfoCoursePage:(state, action) => {
        state.showAddInfoCoursePage = action.payload;
      },
      setAllCourseList:(state, action) => {
        state.allCourseList = action.payload;
      },
      setAllCourseChecked:(state, action) => {
        state.allCourseChecked = action.payload;
      },
      setShowAddInfoTimePage:(state, action) => {
        state.showAddInfoTimePage = action.payload;
      },
      
      
      setShowEditInfoPage:(state, action) => {
        state.showEditInfoPage = action.payload;
      },
      setEditInfo:(state, action) => {
        state.editInfo = action.payload;
      },
      setTeacherName:(state, action) => {
        state.teacherName = action.payload;
      },
      setTeacherCategoryIndex:(state, action) => {
        state.teacherCategoryIndex = action.payload;
      },
      setTeacherCategoryList:(state, action) => {
        state.teacherCategoryList = action.payload;
      },
      setCourseTypeIndex:(state, action) => {
        state.courseTypeIndex = action.payload;
      },
      setCourseTypeList:(state, action) => {
        state.courseTypeList = action.payload;
      },
      
      setCompanyName:(state, action) => {
        state.companyName = action.payload;
      },
      setContactName:(state, action) => {
        state.contactName = action.payload;
      },
      setProvinceList:(state, action) => {
        state.provinceList = action.payload;
      },
    },
  });
  export const selectInfoList = (state) => state.YYZJTeacherManage.infoList;
  export const selectQRContentStr = (state) => state.YYZJTeacherManage.QRContentStr;
  export const selectDownloadStatus = (state) => state.YYZJTeacherManage.downloadStatus;
  //分页所需数据
  export const selectGroupCount = (state) => state.YYZJTeacherManage.groupCount;
  export const selectStartPage = (state) => state.YYZJTeacherManage.startPage;
  export const selectPageNumber = (state) => state.YYZJTeacherManage.pageNumber;
  export const selectCurrentPage = (state) => state.YYZJTeacherManage.currentPage;
  export const selectTotalPageNum = (state) => state.YYZJTeacherManage.totalPageNum;
  export const selectShowAddInfoPage = (state) => state.YYZJTeacherManage.showAddInfoPage;
  export const selectShowAddInfoCoursePage = (state) => state.YYZJTeacherManage.showAddInfoCoursePage;
  export const selectAllCourseList = (state) => state.YYZJTeacherManage.allCourseList;
  export const selectShowAddInfoTimePage = (state) => state.YYZJTeacherManage.showAddInfoTimePage;
  export const selectAllCourseChecked = (state) => state.YYZJTeacherManage.allCourseChecked;
  export const selectShowEditInfoPage = (state) => state.YYZJTeacherManage.showEditInfoPage;
  export const selectEditInfo = (state) => state.YYZJTeacherManage.editInfo;
  export const selectTeacherName = (state) => state.YYZJTeacherManage.teacherName;
  export const selectTeacherCategoryIndex = (state) => state.YYZJTeacherManage.teacherCategoryIndex;
  export const selectTeacherCategoryList = (state) => state.YYZJTeacherManage.teacherCategoryList;
  export const selectCourseTypeIndex = (state) => state.YYZJTeacherManage.courseTypeIndex;
  export const selectCourseTypeList = (state) => state.YYZJTeacherManage.courseTypeList;
  
  
  export const selectCompanyName = (state) => state.YYZJTeacherManage.companyName;
  export const selectContactName = (state) => state.YYZJTeacherManage.contactName;
  export const selectProvinceList = (state) => state.YYZJTeacherManage.provinceList;
  
  
  export const {
    setInfoList,
    setGroupCount,
    setStartPage,
    setPageNumber,
    setCurrentPage,
    setTotalPageNum,
    setShowAddInfoPage,
    setShowAddInfoCoursePage,
    setAllCourseList,
    setAllCourseChecked,
    setShowAddInfoTimePage,
    setShowEditInfoPage,
    setEditInfo,
    setTeacherName,
    setTeacherCategoryIndex,  
    setTeacherCategoryList,
    setCourseTypeIndex,
    setCourseTypeList,
    setCompanyName,
    setContactName,
    setProvinceList
  } = YYZJTeacherManageSlice.actions;
  
  export default YYZJTeacherManageSlice.reducer;