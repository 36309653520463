// 提交开课申请,暂未对接接口
import React, {useRef, useState, useEffect} from "react";
import styles from "./ZXSchool2.module.css";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import Footer from "../../Footer/Footer";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp, faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {selectAlertInfo, setAlertInfo} from "../../../store/CustomViewSlice";
import {message} from 'antd'
import {setZXMenuIndex} from "../ZXYYMain/ZXYYMainSlice";
import {
    selectDingSchoolList,
    selectLieSchoolList, selectLPDSchoolList,
    selectPingSchoolList, selectXuezhiIndex, selectXuezhiList, setEndStatus, setLieSchoolList, setLPDSchoolList,
    setPingSchoolList
} from "./ZXSchoolSlice";
import {ZXSchoolSetHX} from "./ZXSchoolSetHX";
import {getNewCopyDic} from "../../../util/handleObject";
import {addSchoolHX, getSchoolHX, saveLieSchoolRequest, savePingSchoolRequest} from "./ZXSchoolApi";

export function ZXSchool2() {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [confirmInfo, setConfirmInfo] = useState({})

    useEffect(() => {
        let tempList = LPDSchoolList.map((item, index) => {return item});
        tempList.sort((a, b) => {
            return a.order_jichu - b.order_jichu;
        })
        dispatch(setPingSchoolList(tempList))
        console.log('dingSchoolListdin222gSchoolList')
        console.log(dingSchoolList)
        // 在这里执行你的组件挂载后的逻辑

        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次

    const xuezhiIndex = useSelector(selectXuezhiIndex)
    const xuezhiList = useSelector(selectXuezhiList)
    const LPDSchoolList = useSelector(selectLPDSchoolList)
    const lieSchoolList = useSelector(selectLieSchoolList)
    const pingSchoolList = useSelector(selectPingSchoolList)
    const dingSchoolList = useSelector(selectDingSchoolList)
    const [yiyuanList, setYiyuanList] = useState([{
        yiyuanName: "意愿未知",
    }, {
        yiyuanName: "意愿很强",
    }, {
        yiyuanName: "意愿较强",
    }, {
        yiyuanName: "意愿一般",
    }, {
        yiyuanName: "意愿不强",
    }]);
    const [addingStudentNumber, setAddingStudentNumber] = useState("");
    const [HXSchoolInfo, setHXSchoolInfo] = useState({});
    const [HXInfoList, setHXInfoList] = useState([]);
    const [showZXSchoolHX, setShowZXSchoolHX] = useState(false);
    const [removeIndex, setRemoveIndex] = useState(-1);
    const [removeItem, setRemoveItem] = useState({});

    function refreshMethod() {

    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {


            // if(data.ret == 1){
            var tempArray = pingSchoolList.map((item, index) => {
                return item
            })
            tempArray.splice(removeIndex, 1)
            var tempLieArray = tempArray.map((item, index) => {
                // return item
                let tempDic = JSON.parse(JSON.stringify(item))
                if (removeIndex < item.order_jichu) {
                    tempDic.order_jichu = tempDic.order_jichu - 1;
                }
                if (removeIndex < item.order_zonghe) {
                    tempDic.order_zonghe = tempDic.order_zonghe - 1;
                }
                return tempDic;
            })
            console.log(tempLieArray)
            dispatch(setPingSchoolList(tempLieArray))


            var tempArray1 = LPDSchoolList.map((item, index) => {
                return item
            })
            tempArray1.splice(removeIndex, 1)

            let tempLPDList = tempArray1.map((item, index) => {
                let tempDic = JSON.parse(JSON.stringify(item))
                if (removeIndex < item.order_jichu) {
                    tempDic.order_jichu = tempDic.order_jichu - 1;
                }
                if (removeIndex < item.order_zonghe) {
                    tempDic.order_zonghe = tempDic.order_zonghe - 1;
                }
                return tempDic;
            })
            dispatch(setLPDSchoolList(tempLPDList))

        } else if (confirmInfo.eventId == 2) {

        }

    }

    return (
        <div className={styles.background}>
            {contextHolder}
            <CustomConfirmAlert
                info={confirmInfo}
                cancelMethod={cancelMethod}
                confirmMethod={confirmMethod}
            />
            {
                showZXSchoolHX ? <ZXSchoolSetHX
                    info={HXSchoolInfo}
                    infoList = {HXInfoList}
                    showUploadButton = {true}
                    closeMethod={(e) => {
                        setShowZXSchoolHX(false)
                    }}
                    tijiaoMethod={(HXInfoList1) => {
                        addSchoolHX(HXSchoolInfo.xuexiao_code,JSON.stringify(HXInfoList1),(data)=>{
                            if(data.ret == 1){

                                let tempArray = pingSchoolList.map((item, index) => {
                                    if(HXSchoolInfo.xuexiao_code === item.xuexiao_code){
                                        return Object.assign(JSON.parse(JSON.stringify(item)),{"huaxiang_status":"1"})
                                    }
                                    else{
                                        return item;
                                    }
                                })
                                dispatch(setPingSchoolList(tempArray))


                                let tempArray1 = LPDSchoolList.map((item, index) => {
                                    if(HXSchoolInfo.xuexiao_code === item.xuexiao_code){
                                        return Object.assign(JSON.parse(JSON.stringify(item)),{"huaxiang_status":"1"})
                                    }
                                    else{
                                        return item;
                                    }
                                })
                                dispatch(setLPDSchoolList(tempArray1))
                                setShowZXSchoolHX(false)
                            }else if(data.ret==99){

                                messageApi.open({
                                    type: 'warning',
                                    content:data.msg
                                });
                                setTimeout((e)=>{
                                    sessionStorage.clear()
                                    window.location.reload()
                                },1000)
                            } else {
                                 messageApi.open({
                                     type: 'warning',
                                     content: data.msg
                                 })
                             }
                        })
                    }}
                /> : null
            }

            <div className={styles.content}>
                <div className={styles.leftContent}>
                    <span className={styles.leftLieTitle}
                          onClick={(e) => {
                              dispatch(setZXMenuIndex(1))
                          }}
                    >第1步:【列】</span>
                    <span className={styles.leftPingTitle}>第2步:【评】</span>
                    <span className={styles.leftDingTitle}
                          onClick={(e) => {
                              if (dingSchoolList.length > 0&&LPDSchoolList.length>0) {
                                  dispatch(setZXMenuIndex(3))
                              }
                          }}
                    >第3步:【定】</span>
                </div>
                <div className={styles.middleContent}>
                    <div className={styles.middleTopContent}>
                        <div className={styles.middleTitle}>第2步：【评】调研明晰学校画像，并评估各校实控人对诺奖计划的意愿强度
                        </div>
                        {/*<div className={styles.szqxTitle}>所在区县：临汾市尧都区</div>*/}
                        <div className={styles.BXSchoolTitle}>备选学校：
                            <span className={styles.BXSchoolDes}>
                            {pingSchoolList.length}所
                        </span>
                        </div>
                        <div className={styles.BXSchoolList}>
                            {pingSchoolList.map((item, index) => {
                                return <div className={styles.BXSchoolItem}>
                                    <span className={styles.jcspTitle}>基础水平{index + 1}</span>
                                    <span className={styles.schoolName}>{item.xuexiao_name}</span>
                                    <span className={styles.studentNumber}>{item.xuesheng_num}人</span>
                                    <div className={styles.schoolHXButton}
                                         onClick={(e) => {
                                             getSchoolHX(item.xuexiao_code,(data)=>{
                                                 if(data.ret == 1){
                                                     setHXSchoolInfo(item)
                                                     setHXInfoList(data.data.list)
                                                     setShowZXSchoolHX(true)
                                                 }else if(data.ret==99){

                                                    messageApi.open({
                                                        type: 'warning',
                                                        content:data.msg
                                                    });
                                                    setTimeout((e)=>{
                                                        sessionStorage.clear()
                                                        window.location.reload()
                                                    },1000)
                                                }
                                                 else {
                                                     setHXSchoolInfo(item)
                                                     setHXInfoList([])
                                                     setShowZXSchoolHX(true)
                                                 }
                                             })
                                         }}
                                    >学校画像
                                    </div>
                                    <span className={styles.writeStatus}>{!("huaxiang_status" in item)||item.huaxiang_status==0?"未填写":"已填写"}</span>
                                    <select className={classNames(styles.dropDown, styles.yiyuanPosition)}
                                            onChange={(e) => {
                                                let tempArray = pingSchoolList.map((item, index) => {
                                                    return item;
                                                })
                                                tempArray = tempArray.map((item1, index1) => {
                                                    if (index === index1) {
                                                        let tempDic = getNewCopyDic(item1)
                                                        Object.assign(tempDic, {
                                                            yiyuan_code: e.target.value,
                                                            yiyuan_name:yiyuanList[e.target.value].yiyuanName
                                                        })
                                                        return tempDic
                                                    } else {
                                                        return item1;
                                                    }
                                                })
                                                dispatch(setPingSchoolList(tempArray))



                                                let tempArray1 = LPDSchoolList.map((item, index) => {
                                                    return item;
                                                })
                                                tempArray1 = tempArray1.map((item1, index1) => {
                                                    if (index === index1) {
                                                        let tempDic = getNewCopyDic(item1)
                                                        Object.assign(tempDic, {
                                                            yiyuan_code: e.target.value,
                                                            yiyuan_name:yiyuanList[e.target.value].yiyuanName
                                                        })
                                                        return tempDic
                                                    } else {
                                                        return item1;
                                                    }
                                                })
                                                dispatch(setLPDSchoolList(tempArray1))
                                            }}
                                            value={parseInt(item.yiyuan_code)}
                                    >
                                        {yiyuanList.map((item, index) => {
                                            return <option value={index}>{item.yiyuanName}</option>
                                        })}
                                    </select>
                                    {item.yiyuan_code == 4 ?
                                        <div className={styles.removeButton}
                                             onClick={(e) => {
                                                 if (pingSchoolList.length == 1) {
                                                     messageApi.open({
                                                         type: 'warning',
                                                         content: "该步骤无法移除所有学校"
                                                     });
                                                     return;
                                                 }
                                                 setRemoveIndex(index)
                                                 setRemoveItem(item)
                                                 setConfirmInfo({
                                                     showStatus: true,
                                                     title: "提示",
                                                     title1: "移除后，会删除该校所有信息",
                                                     content: "",
                                                     eventId: 1
                                                 })

                                             }}
                                        >移除</div> : null}
                                </div>
                            })}
                        </div>

                    </div>
                    <div className={styles.middleBottomContent}>
                        <div className={styles.lastStepButton}
                             onClick={(e) => {
                                 dispatch(setZXMenuIndex(1))
                             }}
                        >上一步
                        </div>
                        <div className={styles.nextButton}
                             onClick={(e) => {
                                 let haveHXInfo = false;
                                 pingSchoolList.map((item,index)=>{
                                     for (const itemKey in item) {
                                         console.log(itemKey)
                                         if(itemKey == "huaxiang_status"&&item[itemKey]==1) {
                                             haveHXInfo = true;
                                         }
                                     }
                                 })

                                 if(pingSchoolList.length==0) {
                                     messageApi.open({
                                         type: "warning",
                                         content: "请添加学校信息"
                                     })
                                 }
                                 else if (!haveHXInfo) {
                                     messageApi.open({
                                         type: "warning",
                                         content: " 请填写学校画像信息"
                                     })
                                 } else {
                                     savePingSchoolRequest(xuezhiList[xuezhiIndex].xuezhi_code,JSON.stringify(pingSchoolList),(data)=>{
                                        
                                         if(data.ret == 1){
                                             dispatch(setZXMenuIndex(3))
                                         }else if(data.ret==99){

                                            messageApi.open({
                                                type: 'warning',
                                                content:data.msg
                                            });
                                            setTimeout((e)=>{
                                                sessionStorage.clear()
                                                window.location.reload()
                                            },1000)
                                        }
                                         else {
                                             messageApi.open({
                                                 type: 'warning',
                                                 content:data.msg
                                             })
                                         }
                                     })

                                     dispatch(setZXMenuIndex(3))
                                     dispatch(setEndStatus(false))
                                 }
                             }}
                        >保存，下一步
                        </div>
                    </div>
                </div>
                <div className={styles.rightContent}>
                    <div className={styles.xtsContainer}>
                        <div className={styles.xtsTitle}>小贴士</div>
                        <div
                            className={styles.xtsDes}>
                            1. 调研备选学校，获取详细的学校画像信息，并作记录。
                            <br/>
                            2. 特别关注学校实控人对诺奖计划的态度和意愿，并标记。
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};
