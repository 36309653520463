import React, {useEffect, useRef, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import styles from "./PaymentQuery.module.css";
import classNames from "classnames"
import TopControl from "../../TopControl/TopControl"
import Footer from "../../Footer/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
// import {getSchools} from './QueryAndGetQRApi'
import ConditionalQuery from "./ConditionalQuery";
import {
    selectInfoList,
    selectQRContentStr,
    selectSchoolList,
    selectShowRefundAlert, selectShowRefundRecord,
    setSchoolList
} from "./PaymentQuerySlice";
import InfoList from "./InfoList";
import BottomControl from "./BottomControl";
import RefundAlert from "./RefundAlert";
import RefundRecord from "./RefundRecord";
// import {getPaymentInfoList} from './QueryAndGetQRApi'
export default function PaymentQuery(props) {
    

    
    const schoolList = useSelector(selectSchoolList);
    const infoList = useSelector(selectInfoList);
    const showRefundAlert = useSelector((selectShowRefundAlert))
    const QRContentStr = useSelector(selectQRContentStr);
    const showRefundRecord = useSelector((selectShowRefundRecord))

    
    // const pageNumber = useSelector(selectPageNumber);
    // const currentPage = useSelector(selectCurrentPage)


    const dispatch = useDispatch();
    const conditionRef = useRef(null);
    
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    function searchMethod() {
    }
    function refreshMethod() {
        if (conditionRef){
            conditionRef.current.refresh()
        }

    }
    return <div className={styles.background}>
        <TopControl
            leftTopTitle = "学校开课到款"
            showRefreshButton = {true}
            refreshMethod={refreshMethod}
        />
        <ConditionalQuery
            ref={conditionRef}
        />
        <InfoList/>
        <BottomControl
            pageNumberList = {[10,20,30,40,50,60]}
        />
        <Footer/>
        {showRefundAlert?<RefundAlert/>:null}
        {showRefundRecord?<RefundRecord/>:null}
    </div>
}

