import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
  menuIndex:0,
};

export const CWZGMainSlice = createSlice({
  name: 'CWZGMain',
  initialState,
  reducers: {
    setMenuIndex: (state,action) => {
        state.menuIndex = action.payload;
    },
  },
});

export const selectMenuIndex = (state) => state.CWZGMain.menuIndex;


export const { 
  setMenuIndex,
} = CWZGMainSlice.actions;

export default CWZGMainSlice.reducer;
