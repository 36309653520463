// 手动排课界面
import React, {useRef, useState} from "react";
import styles from "./SDPKPage.module.css";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose, faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
import {selectReviewCourseInfoList, setApprovalStatus, setShowSDPKPage} from "./SKJHMangeSlice";
import {addCountyCompany, getRegionListRequest} from "./SKJHMangeApi";
import {setAlertInfo} from "../../../store/CustomViewSlice";

export default function SDPKPage(props) {
    const dispatch = useDispatch();
    const infoList = useSelector(selectReviewCourseInfoList)
    // 首次备课弹框所需变量
    const [showSCBKTeacherList, setShowSCBKTeacherList] = useState(false);
    const [SCBKTeacherList, setSCBKTeacherList] = useState([1, 2, 3, 4]);
    const [SCBKTeacherIndex, setSCBKTeacherIndex] = useState(0);
    // 首次授课弹框所需变量
    const [showSCSKTeacherList, setShowSCSKTeacherList] = useState(false);
    const [SCSKTeacherList, setSCSKTeacherList] = useState([1, 2, 3, 4]);
    const [SCSKTeacherIndex, setSCSKTeacherIndex] = useState(0);
    // 计划备课弹框所需变量
    const [showJHBKTeacherList, setShowJHBKTeacherList] = useState(false);
    const [JHBKTeacherList, setJHBKTeacherList] = useState([1, 2, 3, 4]);
    const [JHBKTeacherIndex, setJHBKTeacherIndex] = useState(0);
    // 首次授课弹框所需变量
    const [showJHSKTeacherList, setShowJHSKTeacherList] = useState(false);
    const [JHSKTeacherList, setJHSKTeacherList] = useState([1, 2, 3, 4]);
    const [JHSKTeacherIndex, setJHSKTeacherIndex] = useState(0);

    function isValidPhoneNumber(phoneNumber) {
        return /^1[3-9]\d{9}$/.test(phoneNumber);
    }

    return <div className={styles.background}>
        <div className={styles.contentContainer}>
            <div className={styles.topBar}>
                <div className={styles.topBarLeft}>手动排课</div>
                <FontAwesomeIcon
                    className={styles.topBarRight}
                    icon={faClose}
                    onClick={(e) => {
                        dispatch(setShowSDPKPage(false))
                    }}
                />
            </div>
            <div className={styles.middleContent}>
                <div className={styles.schoolName}>学校名称：新疆乌鲁木齐第二实验小学</div>
                <div className={styles.courseInfo}>
                    <div className={styles.courseInfoItem}>课程名称：人工智能L1级课程</div>
                    <div className={styles.courseInfoItem}>课程人数：35人</div>
                </div>
                <div className={styles.courseInfo}>
                    <div className={styles.courseInfoItem}>首次备课时间：2024--------</div>
                    <div className={styles.courseInfoItem}>备课教师
                        <div className={styles.topBarSchoolsContainer}
                             onClick={() => setShowSCBKTeacherList(!showSCBKTeacherList)}>
                            <div className={styles.topBarSchoolsName}>{SCBKTeacherList[SCBKTeacherIndex]}</div>
                            <FontAwesomeIcon icon={faAngleDown} className={styles.topBarSchoolsArrow}/>
                            {showSCBKTeacherList ?
                                <div className={classNames(styles.tankangContainer, styles.schoolListPosition)}>
                                    {SCBKTeacherList.map((item, index, arr) => {
                                        return <div className={styles.tankuangItem}
                                                    key={index}
                                                    onClick={(e) => {
                                                        setSCBKTeacherIndex(index)
                                                    }}
                                        >{item}</div>
                                    })}
                                </div> : null}

                        </div>
                    </div>
                </div>
                <div className={styles.courseInfo}>
                    <div className={styles.courseInfoItem}>首次授课时间：2024--------</div>
                    <div className={styles.courseInfoItem}>授课教师
                        <div className={styles.topBarSchoolsContainer}
                             onClick={() => setShowSCBKTeacherList(!showSCBKTeacherList)}>
                            <div className={styles.topBarSchoolsName}>{SCBKTeacherList[SCBKTeacherIndex]}</div>
                            <FontAwesomeIcon icon={faAngleDown} className={styles.topBarSchoolsArrow}/>
                            {showSCBKTeacherList ?
                                <div className={classNames(styles.tankangContainer, styles.schoolListPosition)}>
                                    {SCBKTeacherList.map((item, index, arr) => {
                                        return <div className={styles.tankuangItem}
                                                    key={index}
                                                    onClick={(e) => {
                                                        setSCBKTeacherIndex(index)
                                                    }}
                                        >{item}</div>
                                    })}
                                </div> : null}

                        </div>
                    </div>
                </div>
                <div className={styles.courseInfo}>
                    <div className={styles.courseInfoItem}>计划备课时间：</div>
                </div>
                <div className={styles.courseInfo}>
                    <div className={styles.courseInfoItem}>备课老师：
                        <div className={styles.topBarSchoolsContainer}
                             onClick={() => setShowJHBKTeacherList(!showJHBKTeacherList)}>
                            <div className={styles.topBarSchoolsName}>{JHBKTeacherList[JHBKTeacherIndex]}</div>
                            <FontAwesomeIcon icon={faAngleDown} className={styles.topBarSchoolsArrow}/>
                            {showJHBKTeacherList ?
                                <div className={classNames(styles.tankangContainer, styles.schoolListPosition)}>
                                    {JHBKTeacherList.map((item, index, arr) => {
                                        return <div className={styles.tankuangItem}
                                                    key={index}
                                                    onClick={(e) => {
                                                        setJHBKTeacherIndex(index)
                                                    }}
                                        >{item}</div>
                                    })}
                                </div> : null}

                        </div>
                    </div>
                </div>
                <div className={styles.courseInfo}>
                    <div className={styles.courseInfoItem}>计划授课时间：</div>
                </div>
                <div className={styles.courseInfo}>
                    <div className={styles.courseInfoItem}>授课老师：
                        <div className={styles.topBarSchoolsContainer}
                             onClick={() => setShowJHSKTeacherList(!showJHSKTeacherList)}>
                            <div className={styles.topBarSchoolsName}>{JHSKTeacherList[JHSKTeacherIndex]}</div>
                            <FontAwesomeIcon icon={faAngleDown} className={styles.topBarSchoolsArrow}/>
                            {showJHSKTeacherList ?
                                <div className={classNames(styles.tankangContainer, styles.schoolListPosition)}>
                                    {JHSKTeacherList.map((item, index, arr) => {
                                        return <div className={styles.tankuangItem}
                                                    key={index}
                                                    onClick={(e) => {
                                                        setJHSKTeacherIndex(index)
                                                    }}
                                        >{item}</div>
                                    })}
                                </div> : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.cancelButton}
                     onClick={(e) => {
                         // props.cancelMethod()
                         dispatch(setShowSDPKPage(false))
                     }}
                >取消
                </div>
                <div className={styles.confirmButton}
                     onClick={(e) => {
                         // dispatch(setShowAddInfoPage(false))
                         // props.confirmMethod()
                     }}
                >确定
                </div>
            </div>

        </div>
    </div>
}

