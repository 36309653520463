
import React, { useState,useEffect } from 'react';
import styles from '../Footer/Footer.module.css';
export default function Footer() {
    return (
        <div className={styles.background}>
            <div className={styles.footerDes}>Copyright 2024© TBI国际教育集团, All Rights Reserved.</div>
            <div className={styles.footerDes}>预见方能遇见</div>
        </div>
    )
}
