// 提交开课申请,暂未对接接口
import React, {useRef, useState, useEffect} from "react";
import styles from "./XSSMHJ.module.css";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import Footer from "../../Footer/Footer";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faReply} from '@fortawesome/free-solid-svg-icons';
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {selectAlertInfo, setAlertInfo} from "../../../store/CustomViewSlice";
import {message, DatePicker, Upload, Modal} from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import dayjs from "dayjs";
import 'dayjs/locale/zh-cn'
import {
    setRangeTime,
    selectRangeTime,
    selectHyjlIndex,
    setHyjlIndex,
    selectHyjlList,
    selectXSSMXueqiList,
    selectXSSMXueqiIndex,
    setXSSMXueqiIndex,
    setXSSMSchoolIndex,
    selectXSSMSchoolIndex,
    selectXSSMSchoolList,
    setXSSMXueqiList,
    setXxchr,
    selectXxchr,
    setYjschr,
    setXSSMSchoolList,
    selectYjschr, selectFileList, setFileList, setXSSMDetailInfo,
} from "./XSSMHJSlice";
import {ALI_OSS_PATH, API_URL} from "../../../util/constants";
import {
    selectDingSchoolList,
    setDingSchoolList,
    setLieSchoolList,
    setLPDSchoolList,
    setPingSchoolList
} from "../ZXSchool/ZXSchoolSlice";
import OSS from 'ali-oss'
import {
    addXSSMSchoolInfoRequest,
    getAliOssTokenRequest,
    getXSSMSchoolInfoRequest,
    getXSSMSchoolListRequest,
    getXueqiListRequest
} from "./XSSMHJApi";
import {getSixMathRandom} from "../../../util/handMath";
import {setXMLDMenuIndex, setXSSMMenuIndex} from "../ZXYYMain/ZXYYMainSlice";
import {getSchoolListRequest} from "../ZXSchool/ZXSchoolApi";
import uploadImg from "../../../img/upload_picture.svg";

dayjs.locale('zh-cn')
const {RangePicker} = DatePicker;


let client;
let clientExpTime;


export function XSSMHJ() {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [confirmInfo, setConfirmInfo] = useState({})
    const [tjStatus, setTjStatus] = useState(0)
    const [ZXSchoolList, setZXSchoolList] = useState([])

    const XSSMXueqiList = useSelector(selectXSSMXueqiList)
    const XSSMXueqiIndex = useSelector(selectXSSMXueqiIndex)
    const XSSMSchoolIndex = useSelector(selectXSSMSchoolIndex)
    // const ZXSchoolList = useSelector(selectDingSchoolList)
    const XSSMSchoolList = useSelector(selectXSSMSchoolList)
    // const [fileList, setFileList] = useState([]);
    const fileList = useSelector(selectFileList)
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const rangeTime = useSelector(selectRangeTime)
    const xxchr = useSelector(selectXxchr)
    const yjschr = useSelector(selectYjschr)

    const hyjlIndex = useSelector(selectHyjlIndex)
    const hyjlList = useSelector(selectHyjlList)
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        getAliOssToken()
        getXueqiList()
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    function getXueqiList(index) {
        getXueqiListRequest((data) => {
            if (data.ret == 1) {
                if(data.data){
                    dispatch(setXSSMXueqiList(data.data));
                    data.data.map((item, index) => {
                        if (item.dangqian_xueqi == 1) {
                            dispatch(setXSSMXueqiIndex(index))
                            getXSSMSchoolList(item.xueqi_code, 0)
                            getZXSchoolList(item.xueqi_code)
                        }
                    })
                }

            }else if(data.ret==99){

                messageApi.open({
                    type: 'warning',
                    content:data.msg
                });
                setTimeout((e)=>{
                    sessionStorage.clear()
                    window.location.reload()
                },1000)
            } else {
                messageApi.open({
                    type: 'error',
                    content: "学期请求失败，请重新进入该界面"
                })
            }
        })
    }
    function getZXSchoolList(xueqiCode) {
        getSchoolListRequest((data)=>{
            if(data.ret == 1){
                let dateList = data.data.list.map((item)=>{
                    return item
                });
                let tempList = []
                dateList.map((item, index) => {
                    if(item.status == 1){
                        tempList.push(item)
                    }
                    return item;
                })
                setZXSchoolList(tempList)
                if(dateList.length>0){
                    getXSSMSchoolInfo(xueqiCode, dateList[0].xuexiao_code)
                }

            }else if(data.ret==99){

                messageApi.open({
                    type: 'warning',
                    content:data.msg
                });
                setTimeout((e)=>{
                    sessionStorage.clear()
                    window.location.reload()
                },1000)
            }
            else {
                setZXSchoolList([])
            }
        })
    }
    function getXSSMSchoolList(xueqiCode, index) {
        getXSSMSchoolListRequest(xueqiCode, (data) => {
            if (data.ret == 1) {
                dispatch(setXSSMSchoolList(data.data));
                dispatch(setXSSMSchoolIndex(index))
            }else if (data.ret == 99) {
                messageApi.open({
                    type: 'warning',
                    content: data.msg
                });
                setTimeout((e) => {
                    sessionStorage.clear()
                    window.location.reload()
                }, 1000)
            } else {
                dispatch(setXSSMSchoolList([]));
                dispatch(setXSSMSchoolIndex(index))
            }
        })
    }

    function getXSSMSchoolInfo(xueqi_code, school_code) {
        getXSSMSchoolInfoRequest(xueqi_code, school_code, (data) => {
            if(data.ret == 1){
                // set
                let result = data.data;
                dispatch(setRangeTime([result.start_time,result.end_time]))
                dispatch(setXxchr(result.school_canhui))
                dispatch(setYjschr(result.yanjiusuo_canhui))
                let tempList = result.huiyi_image.split(',')
                let tempNameList = result.huiyi_image_name.split(',')
                tempList = tempList.map((item, index) => {
                    let tempDic = {
                        "url":item,
                        "uid":index,
                        "name": tempNameList[index]
                    }
                    return tempDic
                })
                dispatch(setFileList(tempList))
                dispatch(setHyjlIndex(result.jielun_code - 1))
                setTjStatus(1)
            }else if (data.ret == 99) {
                messageApi.open({
                    type: 'warning',
                    content: data.msg
                });
                setTimeout((e) => {
                    sessionStorage.clear()
                    window.location.reload()
                }, 1000)
            }
            else {
                dispatch(setRangeTime(["",""]))
                dispatch(setXxchr(""))
                dispatch(setYjschr(""))
                dispatch(setFileList([]))
                dispatch(setHyjlIndex(0))
                setTjStatus(0)
            }
        })
    }

    function getSchoolList(index) {
        // getSchoolListRequest(xuezhiList[index].xuezhi_code,(data)=>{
        //
        // })
    }

    function getAliOssToken() {
        let nowDate = new Date().getTime()
        if (!client||nowDate - clientExpTime>1200000) {
            clientExpTime = new Date().getTime();
            getAliOssTokenRequest((data) => {
                if ("Credentials" in data) {
                    client = new OSS({
                        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
                        region: 'oss-cn-qingdao',
                        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                        accessKeyId: data.Credentials.AccessKeyId,
                        accessKeySecret: data.Credentials.AccessKeySecret,
                        // 从STS服务获取的安全令牌（SecurityToken）。
                        stsToken: data.Credentials.SecurityToken,
                        refreshSTSToken: async () => {
                            // 向您搭建的STS服务获取临时访问凭证。
                            getAliOssTokenRequest((data) => {
                                return {
                                    accessKeyId: data.Credentials.AccessKeyId,
                                    accessKeySecret: data.Credentials.AccessKeySecret,
                                    stsToken: data.Credentials.SecurityToken,
                                }
                            })

                        },
                        // 刷新临时访问凭证的时间间隔，单位为毫秒。
                        refreshSTSTokenInterval: 3000000,
                        // 填写Bucket名称。
                        bucket: 'tgnp'
                    })
                }
            })
        }
    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
        } else if (confirmInfo.eventId == 2) {

        }

    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    function handleCancel() {
        setPreviewVisible(false);
    }

    async function handlePreview(file) {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true);
    }

    function handleChange(info){
        getAliOssToken()
        let tempList = info.fileList.map((item, index) => {
            let tempDic = {
                "url": item.url,
                "uid": item.uid,
                "status": item.status,
                "name": item.name
            }
            return tempDic
        })
        dispatch(setFileList(tempList));
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    const customRequest = async options => {
        const {onSuccess, onError, file} = options;

        try {
            // 使用阿里云OSS SDK上传文件
            let timestamp = new Date().getTime()
            let random = getSixMathRandom()
            const result = await client.put(ALI_OSS_PATH+"xueshu/" + timestamp + random + file.name, file);
            file.url = result.url;
            onSuccess(file);
        } catch (e) {
            onError(e);
        }
    };

    const dateFormat = 'YYYY-MM-DD HH:mm';
    var currentDate = new Date();
    var maxDateString = (parseInt(currentDate.toJSON().slice(0, 4))+5)+"-12-31 00:00";
    const uploadButton = (
        <div>
            {/*<Icon type="plus" />*/}
            <div className="ant-upload-text">上传</div>
        </div>
    );
    return (
        <div className={styles.background}>
            {contextHolder}
            <CustomConfirmAlert
                info={confirmInfo}
                cancelMethod={cancelMethod}
                confirmMethod={confirmMethod}
            />
            <div className={styles.topBar}>
                <div className={styles.topBarLine1}>
                    <span className={styles.xueqiLabel}>学期：</span>
                    <select className={styles.xueqiData}
                            onChange={(e) => {
                                dispatch(setXSSMXueqiIndex(e.target.value))
                                getXSSMSchoolList(XSSMXueqiList[e.target.value].xueqi_code, 0)
                                getXSSMSchoolInfo(XSSMXueqiList[e.target.value].xueqi_code, ZXSchoolList[0].xuexiao_code)
                            }}
                            value={XSSMXueqiIndex}
                    >
                        {XSSMXueqiList.map((item, index) => {
                            return <option value={index}>{item.xueqi_nmae}</option>
                        })}
                    </select>
                </div>
                <div className={styles.topBarLine2}>
                    <span className={styles.xueqiLabel}>已完成“学术说明会”的学校：</span>
                    <div className={styles.YWCSchoolContainer}>
                        {XSSMSchoolList.length > 0 ? XSSMSchoolList.map((item, index) => {
                            return <span>
                                <span className={styles.schooolName}>{item.school_name}</span>
                                <span className={styles.lookDetail}
                                      onClick={(e)=>{
                                          getXSSMSchoolInfoRequest(
                                              XSSMXueqiList[XSSMXueqiIndex].xueqi_code,
                                              item.school_code,
                                              (data)=>{
                                                  if(data.ret == 1){
                                                      dispatch(setXSSMDetailInfo(data.data))
                                                      dispatch(setXSSMMenuIndex(1))
                                                  }else if(data.ret==99){

                                                    messageApi.open({
                                                        type: 'warning',
                                                        content:data.msg
                                                    });
                                                    setTimeout((e)=>{
                                                        sessionStorage.clear()
                                                        window.location.reload()
                                                    },1000)
                                                }
                                              }
                                          )
                                          // dispatch(setXSSMMenuIndex(1))
                                      }}
                                >查看></span>
                            </span>
                        }) : <span className={styles.schooolName}>暂无</span>}
                    </div>
                </div>
            </div>

            <div className={styles.content}>
                <div className={styles.contentLine1}>
                    <span
                        className={styles.contentTitle}>跟甄选出的学校进一步沟通，推动召开“学术说明会”，并记录：</span>
                </div>
                <div className={styles.contentBottom}>
                    <div className={styles.bottomLine1}>
                        <span className={styles.bottomLeftTitle}>
                            学校：
                        </span>
                        {ZXSchoolList.length > 0 ? <select className={styles.schoolList}
                                                           onChange={(e) => {
                                                               dispatch(setXSSMSchoolIndex(e.target.value))

                                                               getXSSMSchoolInfo(XSSMXueqiList[XSSMXueqiIndex].xueqi_code, ZXSchoolList[e.target.value].xuexiao_code)
                                                           }}
                                                           value={XSSMSchoolIndex}
                        >
                            {ZXSchoolList.map((item, index) => {
                                return <option value={index}>{item.xuexiao_name}</option>
                            })}
                        </select> : <div>
                            <span className={styles.zanwu}>暂无</span>
                            <span className={styles.goZXCD}
                                  onClick={(e)=>{
                                      dispatch(setXMLDMenuIndex(0))
                                  }}
                            >需先完成“甄选触达环节”></span>
                        </div>}

                    </div>
                    <div className={styles.bottomLine1}>
                        <span className={styles.bottomLeftTitle}>
                            会议日期：
                        </span>
                        {tjStatus==0?<RangePicker
                            locale={locale}
                            // needConfirm = {true}
                            minDate={dayjs('2024-01-01 00:00', dateFormat)}
                            maxDate={dayjs(maxDateString, dateFormat)}
                            showTime={{format: 'HH:mm'}}
                            value={rangeTime[0].length > 0 ? [dayjs(rangeTime[0], dateFormat), dayjs(rangeTime[1], dateFormat)] : null}
                            onChange={(date, dateString) => {
                                dispatch(setRangeTime(dateString))
                            }}
                        />
                            :<span className={styles.rightContent1}>{rangeTime[0]}&nbsp;~&nbsp;{rangeTime[1]}</span>
                        }
                    </div>
                    <div className={styles.bottomLine2}>
                         <span className={styles.bottomLeftTitle}>
                            学校参会人：
                        </span>
                        {tjStatus == 0?<textarea
                            className={styles.textarea}
                            // value={beizhu}
                            onChange={(e) => {
                                dispatch(setXxchr(e.target.value))
                            }}
                            placeholder='请输入~'
                            value={xxchr}
                        ></textarea>:
                            <div className={styles.textarea}>{xxchr}</div>}
                    </div>
                    <div className={styles.bottomLine2}>
                         <span className={styles.bottomLeftTitle}>
                            研究所参会人：
                        </span>
                        {tjStatus == 0?<textarea
                            className={styles.textarea}
                            // value={beizhu}
                            onChange={(e) => {
                                dispatch(setYjschr(e.target.value))
                            }}
                            placeholder='请输入~'
                            value={yjschr}
                        ></textarea>:
                        <div className={styles.textarea}>{yjschr}</div>}
                    </div>
                    <div className={styles.bottomLine2}>
                         <span className={styles.bottomLeftTitle}>
                            上传会议截图：
                        </span>
                        <div className={styles.uploadWidth}>
                            <Upload
                                listType="picture"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                customRequest={customRequest}
                                accept=".jpg,.png,.jpeg,.svg,.gif"
                                disabled={tjStatus == 1}
                            >
                                {fileList.length >= 4 || tjStatus == 1 ? null : <div className={styles.uploadButton}>
                                    <img className={styles.uploadImgIcon} src={uploadImg}/>上传图片
                                </div>}
                            </Upload>
                        </div>
                            {tjStatus == 0 ?
                                <div className={styles.uploadImgDes}>在线学术说明会议的截图或照片均可</div> : null}
                            <Modal visible={previewVisible} footer={null}
                                   onCancel={handleCancel}
                                   width={1200}
                            >
                                <img alt="example" style={{width: '100%'}} src={previewImage}/>
                            </Modal>
                        </div>
                        <div className={styles.bottomLine1}>
                        <span className={styles.bottomLeftTitle}>
                            会议结论：
                        </span>
                            {tjStatus == 0 ? <select className={styles.schoolList}
                                                     onChange={(e) => {
                                                         dispatch(setHyjlIndex(e.target.value))
                                                     }}
                                                     value={hyjlIndex}
                                >
                                    {hyjlList.map((item, index) => {
                                        return <option value={index}>{item.name}</option>
                                    })}
                                </select> :
                                <span className={styles.rightContent1}>{hyjlList[hyjlIndex].name}</span>
                            }
                        </div>
                        <div className={styles.bottomLine3}>
                            <div
                                className={classNames(styles.tijiaoButton, tjStatus == 1 ? styles.tijiaoButton1 : null)}
                                onClick={(e) => {
                                    if (tjStatus == 1) {
                                        setTjStatus(0);
                                        return;
                                    }
                                    let haveErrorImg = false;
                                    let fileName = '';
                                    let fileUrl = '';
                                    fileList.map((item, index) => {
                                        if (item.status == "error") {
                                            haveErrorImg = true;
                                        }
                                        if (fileName == "") {
                                            fileName = item.name
                                        } else {
                                            fileName = fileName + "," + item.name
                                        }
                                        if (fileUrl == "") {
                                            fileUrl = item.url
                                        } else {
                                            fileUrl = fileUrl + "," + item.url
                                        }
                                    })
                                    if (ZXSchoolList.length == 0) {
                                        message.open({
                                            type: "warning",
                                            content: "需先完成甄选触达环节"
                                        })
                                        return;
                                    } else if (rangeTime[1] == "") {
                                        message.open({
                                            type: "warning",
                                            content: "请选择会议时间"
                                        })
                                        return;
                                    } else if (xxchr == "") {
                                        message.open({
                                            type: "warning",
                                            content: "请填写学校参会人"
                                        })
                                        return;
                                    } else if (yjschr == "") {
                                        message.open({
                                            type: "warning",
                                            content: "请填写研究所参会人"
                                        })
                                        return;
                                    } else if (haveErrorImg) {
                                        message.open({
                                            type: "warning",
                                            content: "部分图片上传失败，请将红色边框图片删除后重新上传"
                                        })
                                        return;
                                    } else if (fileList.length == 0) {
                                        message.open({
                                            type: "warning",
                                            content: "请先上传图片"
                                        })
                                        return;
                                    } else if (fileList.length === 0) {
                                        message.open({
                                            type: "warning",
                                            content: "请先上传图片"
                                        })
                                        return;
                                    } else if (hyjlIndex == 0) {
                                        message.open({
                                            type: "warning",
                                            content: "会议结论未选择"
                                        })
                                        return;
                                    }
                                    addXSSMSchoolInfoRequest(
                                        XSSMXueqiList[XSSMXueqiIndex].xueqi_code,
                                        XSSMXueqiList[XSSMXueqiIndex].xueqi_nmae,
                                        ZXSchoolList[XSSMSchoolIndex].xuexiao_code,
                                        ZXSchoolList[XSSMSchoolIndex].xuexiao_name,
                                        rangeTime[0],
                                        rangeTime[1],
                                        xxchr,
                                        yjschr,
                                        fileName,
                                        fileUrl,
                                        hyjlList[hyjlIndex].code,
                                        hyjlList[hyjlIndex].name,
                                        (data) => {
                                            if (data.ret == 1) {
                                                setTjStatus(1)
                                                messageApi.open({
                                                    type: 'success',
                                                    content: "恭喜您，提交完成"
                                                })
                                                dispatch(setXMLDMenuIndex(2))
                                            }else if (data.ret == 99) {
                                                messageApi.open({
                                                    type: 'warning',
                                                    content: data.msg
                                                });
                                                setTimeout((e) => {
                                                    sessionStorage.clear()
                                                    window.location.reload()
                                                }, 1000)
                                            }
                                            else{
                                                messageApi.open({
                                                    type: 'warning',
                                                    content: data.msg
                                                });
                                            }
                                        })
                                }}
                            >
                                {tjStatus == 1 ? "修改" : "提交"}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
        </div>
    );
};
