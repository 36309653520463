import request from "../../../util/request";
export function getAliOssTokenRequest( callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('utoken', sessionStorage.getItem("utoken"));
    return request("/aliyun/osssts.php", {
        method: "POST",
        body: filedata
    }, callback);
}

export function getXueqiListRequest( callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_xueqi_list");
    return request("/Yunyingd_a/i", {
        method: "POST",
        body: filedata
    }, callback);
}
export function getXSSMSchoolListRequest( xueqi_code,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_yiwancheng_shuoming_schools");
    filedata.append('xueqi_code',xueqi_code)
    filedata.append('username_login',sessionStorage.getItem("username_login"))
    return request("/Yunyingd_a/i", {
        method: "POST",
        body: filedata
    }, callback);
}
export function getSBPSSchoolListRequest( xueqi_code,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_yiwancheng_shenbao_schools");
    filedata.append('xueqi_code',xueqi_code)
    filedata.append('username_login',sessionStorage.getItem("username_login"))
    return request("/Yunyingd_a/i", {
        method: "POST",
        body: filedata
    }, callback);
}
// 获取单个学校已提交的学术说明内容
export function getSBPSSchoolInfoRequest( xueqi_code,school_code,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_school_shenbao_info");
    filedata.append('xueqi_code',xueqi_code)
    filedata.append('school_code',school_code)
    filedata.append('username_login',sessionStorage.getItem("username_login"))
    return request("/Yunyingd_a/i", {
        method: "POST",
        body: filedata
    }, callback);
}



export function addSBPSSchoolInfoRequest( xueqi_code,xueqi_name,school_code,
                                          school_name,start_time,end_time,
                                          school_canhui,yanjiusuo_canhui,
                                          huiyi_image_name,huiyi_image,
                                          jielun_code,jielun_name,
                                          shenqingbiao_image_name,shenqingbiao_image,
                                          cailiao_url_name,cailiao_url,
                                          xieyi_image_name,xieyi_image,
                                          callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "add_school_shenbao");
    filedata.append('xueqi_code',xueqi_code)
    filedata.append('xueqi_name',xueqi_name)
    filedata.append('school_code',school_code)
    filedata.append('school_name',school_name)
    filedata.append('start_time',start_time)
    filedata.append('end_time',end_time)
    filedata.append('school_canhui',school_canhui)
    filedata.append('yanjiusuo_canhui',yanjiusuo_canhui)
    filedata.append('huiyi_image_name',huiyi_image_name)
    filedata.append('huiyi_image',huiyi_image)
    filedata.append('jielun_code',jielun_code)
    filedata.append('jielun_name',jielun_name)
    filedata.append('shenqingbiao_image_name',shenqingbiao_image_name)
    filedata.append('shenqingbiao_image',shenqingbiao_image)
    filedata.append('cailiao_url_name',cailiao_url_name)
    filedata.append('cailiao_url',cailiao_url)
    filedata.append('xieyi_image_name',xieyi_image_name)
    filedata.append('xieyi_image',xieyi_image)
    filedata.append('username_login',sessionStorage.getItem("username_login"))
    return request("/Yunyingd_a/i", {
        method: "POST",
        body: filedata
    }, callback);
}


