import React, {useRef, useState, useEffect} from "react";
import styles from "./YYZJMain.module.css";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import logoImg from "../../../img/logo.png"
// import { CourseConfig } from "../CourseConfig/CourseConfig";
import {TeacherManage} from "../TeacherManage/TeacherManage";

import {selectMenuIndex, setMenuIndex} from "./YYZJMainSlice";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPowerOff} from '@fortawesome/free-solid-svg-icons';
import {closeYYZJMain, openLogin} from "../../../store/modalsSlice";
import {CourseApproval} from "../CourseApproval/CourseApproval";
import {SKJHMange} from "../SKJHMange/SKJHMange";
import { loginOutRequest } from "../../Login/loginApi";

export function YYZJMain() {
    const menuIndex = useSelector(selectMenuIndex);
    const dispatch = useDispatch();

    function leftMenuMethod(index) {
        dispatch(setMenuIndex(index))
    }

    function signOutMethod() {
        loginOutRequest((data)=>{
        })
        sessionStorage.clear()
        dispatch(openLogin())
        dispatch(closeYYZJMain())
    }

    return (
        <div className={styles.background}>
            <div className={styles.topContainer}>
                <div className={styles.topLeftMenus}>
                    <img
                        className={styles.logIcon}
                        src={logoImg}
                    ></img>
                    <span className={styles.logoTitle}>全球胜任力研究所</span>
                </div>
                <div className={styles.topRightMenus}>
                    <div
                        className={styles.userName}>{sessionStorage.getItem("user_type_name") + "\u00A0\u00A0" + sessionStorage.getItem("name")}</div>
                    <div className={styles.signOutButton}
                         onClick={signOutMethod}
                    >
                        <FontAwesomeIcon icon={faPowerOff}/>
                    </div>
                </div>

            </div>
            <div className={styles.contentContainer}>
                <div className={styles.leftContent}>
                    <div
                        className={classNames(styles.leftMenu, menuIndex == 0 ? styles.leftSelectedMenu : null)}
                        onClick={leftMenuMethod.bind(null, 0)}
                    >学校开课审批
                    </div>
                    <div
                        className={classNames(styles.leftMenu, menuIndex == 1 ? styles.leftSelectedMenu : null)}
                        onClick={leftMenuMethod.bind(null, 1)}
                    >教师资源管理
                    </div>
                    <div
                        className={classNames(styles.leftMenu, menuIndex == 2 ? styles.leftSelectedMenu : null)}
                        onClick={leftMenuMethod.bind(null, 2)}
                    >授课计划管理
                    </div>
                    {/* <div 
                        className={classNames(styles.leftMenu,menuIndex==1?styles.leftSelectedMenu:null)}
                        onClick={leftMenuMethod.bind(null,1)}
                    >区县区域管理</div>
                    <div 
                        className={classNames(styles.leftMenu,menuIndex==2?styles.leftSelectedMenu:null)}
                        onClick={leftMenuMethod.bind(null,2)}
                    >驻校信息管理</div>
                    <div 
                        className={classNames(styles.leftMenu,menuIndex==3?styles.leftSelectedMenu:null)}
                        onClick={leftMenuMethod.bind(null,3)}
                    >学校信息管理</div> */}

                </div>
                <div className={styles.rightContent}>
                    {menuIndex == 0 ? <CourseApproval/> : null}
                    {menuIndex == 1 ? <TeacherManage/> : null}
                    {menuIndex == 2 ? <SKJHMange/> : null}

                </div>
            </div>
        </div>
    );
};
