import request from "../../../util/request";


export function getSchools(callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_schools_byzhuxiao");
    filedata.append('username', sessionStorage.getItem('username'));
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}

export function getQRInfoList(schoolCode,reviewCode,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_school_pici_list");
    filedata.append('school_code', schoolCode);
    filedata.append('shenhe_status', reviewCode);
    filedata.append('username', sessionStorage.getItem("username"));
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function deleteCourseConfig(schoolCode,pici_code,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "chexiao_school_pici_bycode");
    filedata.append('school_code', schoolCode);
    filedata.append('pici_code', pici_code);
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}
export function getSchoolShoufeixiangBypici(schoolCode,pici_code,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_school_shoufeixiang_bypici");
    filedata.append('school_code', schoolCode);
    filedata.append('pici_code', pici_code);
    
    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}

export function shenheCourseConfig(schoolCode,pici_code,shenhe_status,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "shenhe_school_pici_status");
    filedata.append('school_code', schoolCode);
    filedata.append('pici_code', pici_code);
    filedata.append('shenhe_status', shenhe_status);

    return request("/Caiwuadmin/admin", {
        method: "POST",
        body: filedata
    }, callback);
}