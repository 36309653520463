
import React, {useRef, useState} from "react";
import styles from "./ReviewCourseInfo.module.css";
import { useDispatch,useSelector } from "react-redux";
import classNames from "classnames"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClose,faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
import {selectReviewCourseInfoList, setApprovalStatus, setShowReviewCourseInfo} from "./SKJHMangeSlice";
import { addCountyCompany, getRegionListRequest } from "./SKJHMangeApi";
import { setAlertInfo } from "../../../store/CustomViewSlice";
export default function ReviewCourseInfo(props) {
    const dispatch = useDispatch();
    const infoList = useSelector(selectReviewCourseInfoList)
    const [showYYJLList,setShowYYJLList] = useState(false)
    const [YYJLList,setYYJLList] = useState([{
        name:'未指派',
        code:"1"
    },{
        name:'未指派',
        code:"2"
    },{
        name:'未指派',
        code:"3"
    }])
    function isValidPhoneNumber(phoneNumber) {
        return /^1[3-9]\d{9}$/.test(phoneNumber);
    }
    return <div className={styles.background}>
        {showYYJLList?<div className={styles.hideListCover}
                 onClick={(e)=>{
                     setShowYYJLList(false)
                 }}
        ></div>:null}
        <div className={styles.contentContainer}>
            <div className={styles.topBar}>
                <div className={styles.topBarLeft}>开课申请详情</div>
                <FontAwesomeIcon
                    className={styles.topBarRight}
                    icon={faClose}
                    onClick={(e)=>{
                        dispatch(setShowReviewCourseInfo(false))
                    }}
                />
            </div>
            <div className={styles.middleContent}>
                <table
                    align="top"
                    border="0"
                    bordercolor="#f00"
                    cellSpacing="0"
                    cellspadding="0"
                    width="790"
                >
                    <tr valign="middle"
                        align="center"
                        className={styles.tr1}
                    >
                        <td
                            width="64" height="40px"
                            align={"right"}
                            className={styles.td1}
                        >
                            学校名称:
                        </td>
                        <td
                            width="140" height="40px"
                            align={"left"}
                            className={styles.td2}
                        >
                            测试学校A
                        </td>
                        <td
                            width="64" height="40px"
                            align={"left"}
                            className={styles.td1}
                        >
                            区县区域:
                        </td>
                        <td
                            width="120" height="40px"
                            align={"left"}
                            className={styles.td2}
                        >
                            测试区县
                        </td>
                        <td
                            width="64" height="40px"
                            align={"left"}
                            className={styles.td1}
                        >
                            省级区域:
                        </td>
                        <td
                            width="120" height="40px"
                            align={"left"}
                            className={styles.td2}
                        >
                            测试省
                        </td>
                        <td
                            width="64" height="40px"
                            align={"left"}
                            className={styles.td1}
                        >
                            申请时间:
                        </td>
                        <td
                            width="160" height="40px"
                            align={"left"}
                            className={styles.td2}
                        >
                            2024-08-22 13:55:21
                        </td>
                    </tr>
                    <tr valign="middle"
                        align="center"
                        className={styles.tr1}
                    >
                        <td
                            width="64" height="40px"
                            align={"right"}
                            className={styles.td1}
                        >
                            课程名称:
                        </td>
                        <td
                            width="140" height="40px"
                            align={"left"}
                            className={styles.td2}
                        >
                            人工智能L1级课程
                        </td>
                        <td
                            width="64" height="40px"
                            align={"left"}
                            className={styles.td1}
                        >
                            开课学期:
                        </td>
                        <td
                            width="120" height="40px"
                            align={"left"}
                            className={styles.td2}
                        >
                            秋季
                        </td>
                        <td
                            width="64" height="40px"
                            align={"left"}
                            className={styles.td1}
                        >
                            教具名称:
                        </td>
                        <td
                            width="120" height="40px"
                            align={"left"}
                            className={styles.td2}
                        >
                            智能手柄
                        </td>
                        <td
                            width="64" height="40px"
                            align={"left"}
                            className={styles.td1}
                        >
                            教具数量:
                        </td>
                        <td
                            width="160" height="40px"
                            align={"left"}
                            className={styles.td2}
                        >
                            30
                        </td>
                    </tr>
                    <tr valign="middle"
                        align="center"
                        className={styles.tr1}
                    >
                        <td
                            width="80" height="40px"
                            align={"right"}
                            className={styles.td1}
                        >
                            线下老师:
                        </td>
                        <td
                            width="120" height="40px"
                            align={"left"}
                            className={styles.td2}
                        >
                            王华
                        </td>
                        <td
                            width="64" height="40px"
                            align={"left"}
                            className={styles.td1}
                        >
                            老师电话:
                        </td>
                        <td
                            width="120" height="40px"
                            align={"left"}
                            className={styles.td2}
                        >
                            13622125474
                        </td>
                        <td
                            width="64" height="40px"
                            align={"left"}
                            className={styles.td1}
                        >
                            驻校姓名:
                        </td>
                        <td
                            width="120" height="40px"
                            align={"left"}
                            className={styles.td2}
                        >
                            李想
                        </td>
                        <td
                            width="64" height="40px"
                            align={"left"}
                            className={styles.td1}
                        >驻校电话:
                        </td>
                        <td
                            width="160" height="40px"
                            align={"left"}
                            className={styles.td2}
                        >
                            13622129858
                        </td>
                    </tr>
                    <tr valign="middle"
                        align="center"
                        className={styles.tr1}
                    >
                        <td
                            width="64" height="40px"
                            align={"right"}
                            className={styles.td1}
                        >
                            邮寄联系人:
                        </td>
                        <td
                            width="140" height="40px"
                            align={"left"}
                            className={styles.td2}
                        >
                            王华
                        </td>
                        <td
                            width="64" height="40px"
                            align={"right"}
                            className={styles.td1}
                        >
                            邮寄电话:
                        </td>
                        <td
                            width="120" height="40px"
                            align={"left"}
                            className={styles.td2}
                        >
                            13622125474
                        </td>
                        <td
                            width="64" height="40px"
                            align={"left"}
                            className={styles.td1}
                        >
                            邮寄地址:
                        </td>
                        <td
                            width="120" height="40px"
                            align={"left"}
                            colSpan={"3"}
                            className={styles.td2}
                        >
                            测试邮寄地址
                        </td>


                    </tr>

                </table>
                <div className={styles.scbskContainer}>
                    <div className={styles.scbsklist}>
                        <div className={styles.td1}>首次备课时间：</div>
                        <div className={styles.td2}>2024-08-22 13:55-14:55</div>
                    </div>
                    <div className={styles.scbsklist}>
                        <div className={styles.td1}>首次授课时间：</div>
                        <div className={styles.td2}>2024-08-22 13:55-14:55</div>
                    </div>

                </div>
                <div className={styles.scbskContainer}>
                    <div className={styles.scbsklist}>
                        <div className={styles.td1}>备授课计划：</div>
                        <div className={styles.td2}>每周一备课，每周三(13:55-14:55)、每周五(13:55-14:55) 授课</div>
                    </div>
                </div>
                <div className={styles.classInfoContainer}>
                    <div className={styles.classInfoList}>
                        <div className={styles.td1}>班级名称：</div>
                        <div className={styles.td2}>测试班级   共XX人  总金额：XX万</div>
                    </div>
                    <div className={styles.classInfoList}>
                        <div className={styles.td1}>分班说明：</div>
                        <div className={styles.td2}>测试分班说明</div>
                    </div>
                </div>
                <div className={styles.table2}>
                    <table
                        align="top"
                        border="0"
                        bordercolor="#f00"
                        cellSpacing="0"
                        cellspadding="0"
                        width="760"
                    >
                        <tr valign="middle"
                            align="center"
                            className={styles.tr2}
                        >
                            <td
                                width="50" height="60px"
                                align={"center"}
                                className={styles.td3}
                            >
                                序号
                            </td>
                            <td
                                width="80" height="60px"
                                align={"center"}
                                className={styles.td3}
                            >
                                姓名
                            </td>
                            <td
                                width="70" height="60px"
                                align={"center"}
                                className={styles.td3}
                            >
                                性别
                            </td>
                            <td
                                width="100" height="60px"
                                align={"center"}
                                className={styles.td3}
                            >
                                年级
                            </td>
                            <td
                                width="80" height="60px"
                                align={"center"}
                                className={styles.td3}
                            >
                                班级
                            </td>
                            <td
                                width="90" height="60px"
                                align={"center"}
                                className={styles.td3}
                            >
                                课程费用
                            </td>
                            <td
                                width="110" height="60px"
                                align={"center"}
                                className={styles.td3}
                            >
                                教具名称
                            </td>
                            <td
                                width="80" height="60px"
                                align={"center"}
                                className={styles.td3}
                            >
                                教具费用
                            </td>
                            <td
                                width="80" height="60px"
                                align={"center"}
                                className={styles.td3}
                            >
                                总费用
                            </td>

                        </tr>
                        {infoList.map((item, index) => {

                            return <tr valign="middle"
                                       align="center"
                                       className={styles.tr3}
                            >
                                <td
                                    width="50" height="34px"
                                    align={"center"}
                                    className={styles.td4}
                                >
                                    序号
                                </td>
                                <td
                                    width="80" height="34px"
                                    align={"center"}
                                    className={styles.td4}
                                >
                                    姓名
                                </td>
                                <td
                                    width="70" height="34px"
                                    align={"center"}
                                    className={styles.td4}
                                >
                                    性别
                                </td>
                                <td
                                    width="100" height="34px"
                                    align={"center"}
                                    className={styles.td4}
                                >
                                    年级
                                </td>
                                <td
                                    width="80" height="34px"
                                    align={"center"}
                                    className={styles.td4}
                                >
                                    班级
                                </td>
                                <td
                                    width="90" height="34px"
                                    align={"center"}
                                    className={styles.td4}
                                >
                                    课程费用
                                </td>
                                <td
                                    width="110" height="34px"
                                    align={"center"}
                                    className={styles.td4}
                                >
                                    教具名称
                                </td>
                                <td
                                    width="80" height="34px"
                                    align={"center"}
                                    className={styles.td4}
                                >
                                    教具费用
                                </td>
                                <td
                                    width="80" height="34px"
                                    align={"center"}
                                    className={styles.td4}
                                >
                                    总费用
                                </td>

                            </tr>
                        })}

                    </table>
                </div>

            </div>

            <div className={styles.bottomContainer}>
                <div className={styles.bottomList}>
                    <div className={styles.yyjlTitle}>指派运营经理：</div>
                    <div className={styles.yyjlName}
                         onClick={(e)=>{
                             setShowYYJLList(!showYYJLList)
                         }}
                    >未指派
                        <FontAwesomeIcon icon={faAngleDown} className={styles.faAngleDownIcon}/>
                        {showYYJLList?<div className={classNames(styles.tankangContainer,styles.YYJLNameListPosition)}>
                            {YYJLList.map((item,index,arr)=>{
                                return <div className={styles.tankuangItem}
                                            key={index}
                                            onClick={(e)=>{
                                                dispatch(setApprovalStatus(item.reviewCode))
                                                setShowYYJLList(false)
                                            }}
                                >{item.name}</div>
                            })}
                        </div>:null}
                    </div>
                </div>
                <div className={styles.bottomList}>
                    <div className={classNames(styles.confirmButton, styles.button)}

                    >同意
                    </div>
                    <div className={classNames(styles.cancelButton, styles.button)}
                         onClick={(e) => {
                             dispatch(setShowReviewCourseInfo(false))
                         }}
                    >拒绝
                    </div>
                </div>

            </div>
        </div>
    </div>
}

