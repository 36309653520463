import React, { useState,useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import styles from './CustomConfirmAlert.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClose,faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
export function CustomConfirmAlert(props) {
//   const count = useSelector(selectCount);
//   const dispatch = useDispatch();
  const [userName, setUserName] = useState('');

  // const incrementValue = Number(incrementAmount) || 0;

  useEffect(()=>{
  })


  return (
    props.info.showStatus?
        <div className={styles.background}>
          <div className={styles.container}>
            <div className={styles.topBar}>
                <div className={classNames(styles.topTitle,styles.scrollable)}>{props.info.title==""?"确认提示":props.info.title}</div>
                <div className={styles.topCancelButton}>
                    <FontAwesomeIcon 
                        icon={faClose}
                        onClick={(e)=>{
                            // dispatch(setShowAddInfoPage(false))
                            props.cancelMethod()
                        }}
                    />
                </div>
                
            </div>
            <div className={styles.middleContainer}>
                <FontAwesomeIcon 
                    className={styles.questionIcon}
                    icon={faQuestionCircle}
                    onClick={(e)=>{
                        // dispatch(setShowAddInfoPage(false))
                    }}
                />
                <div className={styles.middleRightContainer}>
                    <div className={classNames(styles.middleTitle,styles.scrollable)}>
                        {props.info.title1}
                    </div>
                    <div className={classNames(styles.middleContent,styles.scrollable)}>
                        {props.info.content}
                    </div>
                </div>
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.cancelButton}
                    onClick={(e)=>{
                        props.cancelMethod()
                    }}
                >取消</div>
                <div className={styles.confirmButton}
                    onClick={(e)=>{
                        // dispatch(setShowAddInfoPage(false))
                        props.confirmMethod()
                    }}
                >确定</div>
            </div>
          </div>
        </div>:null
        )
}
