// 提交开课申请,暂未对接接口
import React, {useRef, useState, useEffect} from "react";
import styles from "./ZXSchool.module.css";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import Footer from "../../Footer/Footer";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faReply} from '@fortawesome/free-solid-svg-icons';
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {selectAlertInfo, setAlertInfo} from "../../../store/CustomViewSlice";
import {message} from 'antd'
import {setZXMenuIndex} from "../ZXYYMain/ZXYYMainSlice";
import {
    selectDingSchoolList,
    selectLieSchoolList, selectLPDSchoolList,
    selectPingSchoolList,
    selectXuezhiIndex,
    selectXuezhiList,
    setDingSchoolList,
    setEndStatus,
    setLieSchoolList,
    setLPDSchoolList,
    setPingSchoolList,
    setXuezhiIndex
} from "./ZXSchoolSlice";
import {getSchoolListRequest} from "./ZXSchoolApi";

export function ZXSchool() {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [confirmInfo, setConfirmInfo] = useState({})
    const [countyList, setCountyList] = useState([{
        quyu_name: "测试区域1",
        quyu_code: ""
    }, {
        quyu_name: "测试区域2",
        quyu_code: ""
    }]);
    const [countyIndex, setCountyIndex] = useState(0);
    const xuezhiIndex = useSelector(selectXuezhiIndex)
    const xuezhiList = useSelector(selectXuezhiList)
    const LPDSchoolList = useSelector(selectLPDSchoolList)
    const lieSchoolList = useSelector(selectLieSchoolList)
    const pingSchoolList = useSelector(selectPingSchoolList)
    const dingSchoolList = useSelector(selectDingSchoolList)

    const [dingStatus, setDingStatus] = useState(0)
    const [endSchoolNumber,setEndSchoolNumber] = useState(0)

    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        getSchoolList(xuezhiIndex)
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次

    function getSchoolList(index) {
        getSchoolListRequest((data)=>{
            if(data.ret == 1){
                let dateList = data.data.list.map((item)=>{
                    return item
                });
                dispatch(setLPDSchoolList(data.data.list));
                if(dateList.length > 0){
                    let tempList = dateList.map((item, index) => {return item});
                    tempList.sort((a, b) => {
                        return a.order_jichu - b.order_jichu;
                    })
                    dispatch(setLieSchoolList(tempList))
                }
                let haveHuaxiang = false;
                let haveDiaoyan = false;
                let tempEndNumber = 0;
                dateList.map((item, index) => {
                    if(item.huaxiang_status == 1){
                        haveHuaxiang = true;
                    }
                    if(item.diaoyan_status==1){
                        haveDiaoyan = true
                    }
                    if(item.status == 1){
                        tempEndNumber = tempEndNumber + 1;
                        setDingStatus(1)
                    }
                    return item;
                })
                setEndSchoolNumber(tempEndNumber);
                if(haveHuaxiang){
                    let tempList = dateList.map((item, index) => {return item});
                    tempList.sort((a, b) => {
                        return a.order_jichu - b.order_jichu;
                    })
                    dispatch(setPingSchoolList(tempList))
                }
                if(haveDiaoyan){
                    let tempList = dateList.map((item, index) => {return item});
                    tempList.sort((a, b) => {
                        return a.order_zonghe- b.order_zonghe;
                    })
                    dispatch(setDingSchoolList(tempList))
                }

            }else if(data.ret==99){

                messageApi.open({
                    type: 'warning',
                    content:data.msg
                });
                setTimeout((e)=>{
                    sessionStorage.clear()
                    window.location.reload()
                },1000)
            }
            else {
                setDingStatus(0)
                dispatch(setLieSchoolList([]))
                dispatch(setPingSchoolList([]))
                dispatch(setDingSchoolList([]))
                dispatch(setLPDSchoolList([]))
                setEndSchoolNumber(0)

            }
        })
    }

    function refreshMethod() {

    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
        } else if (confirmInfo.eventId == 2) {

        }

    }

    return (
        <div className={styles.background}>
            {contextHolder}
            <CustomConfirmAlert
                info={confirmInfo}
                cancelMethod={cancelMethod}
                confirmMethod={confirmMethod}
            />
            <div className={styles.topBar}>
                <div>
                    {endSchoolNumber > 0 ? <div className={styles.targetSchoolTitle}>
                        目标学校：
                        <span className={styles.mbxxTitle}>{lieSchoolList[0].xuexiao_name + "等" + endSchoolNumber + "所学校"}</span>
                        <span className={styles.xiangqing}
                              onClick={(e)=>{
                                  dispatch(setZXMenuIndex(1))
                              }}
                        >详情></span>
                    </div> : <div className={styles.targetSchoolTitle}>
                        目标学校：
                        <span className={styles.mbxxTitle}>暂无</span>

                    </div>}
                    {/*<span className={styles.countySpanTitle}>*/}
                    {/*学制：*/}
                    {/*</span>*/}
                    {/*<select className={classNames(styles.dropDown, styles.countyPosition)}*/}
                    {/*        onChange={(e) => {*/}
                    {/*            dispatch(setXuezhiIndex(e.target.value))*/}
                    {/*            getSchoolList(e.target.value)*/}
                    {/*        }}*/}
                    {/*        value={xuezhiIndex}*/}
                    {/*>*/}
                    {/*    {xuezhiList.map((item, index) => {*/}
                    {/*        return <option value={index}>{item.xuezhi_name}</option>*/}
                    {/*    })}*/}
                    {/*</select>*/}
                    {/*<span className={styles.countySpanTitle}>*/}
                    {/*区县选择：*/}
                    {/*</span>*/}
                    {/*<select className={classNames(styles.dropDown, styles.countyPosition)}*/}
                    {/*        onChange={(e) => {*/}
                    {/*            setCountyIndex(e.target.value)*/}
                    {/*        }}*/}
                    {/*        value={countyIndex}*/}
                    {/*>*/}
                    {/*    {countyList.map((item, index) => {*/}
                    {/*        return <option value={index}>{item.quyu_name}</option>*/}
                    {/*    })}*/}
                    {/*</select>*/}
                    {/*<span className={styles.countySpanTitle}>*/}
                    {/*学制：*/}
                    {/*</span>*/}
                    {/*<select className={classNames(styles.dropDown, styles.countyPosition)}*/}
                    {/*        onChange={(e) => {*/}
                    {/*            dispatch(setXuezhiIndex(e.target.value))*/}
                    {/*        }}*/}
                    {/*        value={xuezhiIndex}*/}
                    {/*>*/}
                    {/*    {xuezhiList.map((item, index) => {*/}
                    {/*        return <option value={index}>{item.xuezhi_name}</option>*/}
                    {/*    })}*/}
                    {/*</select>*/}
                </div>
                <div className={styles.topBarRight}>
                    {/*<div className={styles.resetButton}*/}
                    {/*     onClick={() => {*/}
                    {/*         alert('重置')*/}
                    {/*     }}*/}
                    {/*>*/}
                    {/*    <FontAwesomeIcon icon={faReply} className={styles.selectButtonIcon}/>*/}
                    {/*    重置*/}
                    {/*</div>*/}

                    {/*<div className={styles.selectButton}*/}
                    {/*     onClick={() => {*/}
                    {/*         alert('查询')*/}
                    {/*     }}*/}
                    {/*>*/}
                    {/*    <FontAwesomeIcon icon={faSearch} className={styles.selectButtonIcon}/>*/}
                    {/*    查询*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.contentTitleContainer}>
                    按以下3步法，科学甄选评估你的目标客户学校
                </div>
                <div className={styles.contentBottom}>
                    <div className={styles.leftContent}>
                        {/*{LPDSchoolList.length > 0 ? <div className={styles.targetSchoolTitle}>*/}
                        {/*    目标学校：*/}
                        {/*    <span className={styles.mbxxTitle}>{lieSchoolList[0].xuexiao_name + "等" + lieSchoolList.length + "所学校"}</span>*/}
                        {/*    <span className={styles.xiangqing}*/}
                        {/*        onClick={(e)=>{*/}
                        {/*            dispatch(setZXMenuIndex(1))*/}
                        {/*        }}*/}
                        {/*    >详情></span>*/}
                        {/*</div> : <div className={styles.targetSchoolTitle}>*/}
                        {/*    目标学校：*/}
                        {/*    <span className={styles.mbxxTitle}>暂无</span>*/}

                        {/*</div>}*/}

                            <div className={classNames(styles.leftItem, styles.leftItemPosition)}>
                        <div className={styles.itemLeft}>
                        <span className={styles.leftItemTitle}>第1步：列</span>
                                <span
                                    className={styles.leftItemDes}>罗列所在区/县主城区的主要学校，并按基础教学水平排序</span>
                            </div>
                            <div className={styles.itemRight}>
                                <div className={styles.itemRightButton}
                                     onClick={() => {
                                         dispatch(setZXMenuIndex(1))
                                     }}
                                >{lieSchoolList.length === 0 ? "开始" : "进入"}
                                </div>
                            </div>
                        </div>
                        <div className={classNames(styles.leftItem, styles.leftItemPosition)}>
                            <div className={styles.itemLeft}>
                                <span className={styles.leftItemTitle}>第2步：评</span>
                                <span
                                    className={styles.leftItemDes}>调研明晰学校画像，并评估各校实控人对诺奖计划的意愿强度</span>
                            </div>
                            <div className={styles.itemRight}>
                                <div
                                    className={classNames(styles.itemRightButton, pingSchoolList.length === 0 ? styles.noDateItem : null)}
                                    onClick={() => {
                                        if(pingSchoolList.length != 0){
                                            dispatch(setZXMenuIndex(2))
                                        }
                                    }}
                                >进入
                                </div>
                            </div>
                        </div>
                        <div className={classNames(styles.leftItem, styles.leftItemPosition)}>
                            <div className={styles.itemLeft}>
                                <span className={styles.leftItemTitle}>第3步：定</span>
                                <span
                                    className={styles.leftItemDes}>进一步调研分析各校学生自费课的参与情况，估测现有自费课的收益规模，并依此形成学校综合水平排名</span>
                            </div>
                            <div className={styles.itemRight}>
                                <div
                                    className={classNames(styles.itemRightButton, dingSchoolList.length === 0 ? styles.noDateItem : null)}
                                    onClick={() => {
                                        if(dingSchoolList.length != 0&&dingStatus == 0){
                                            dispatch(setZXMenuIndex(3))
                                            dispatch(setEndStatus(false))
                                        }
                                        else if(dingStatus == 1) {
                                            dispatch(setZXMenuIndex(3))
                                            dispatch(setEndStatus(true))
                                        }
                                    }}
                                >进入
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={styles.rightContent}>
                        <span className={styles.zbyiTitle}>总部意见：无</span>
                        <div className={styles.xtsContainer}>
                            <div className={styles.xtsTitle}>小贴士</div>
                            <div
                                className={styles.xtsDes}>学校的综合质量，将直接决定诺奖计划的运营成效。列、评、定“3步法”是一个辅助你甄选学校的过程性工具。请从多个纬度详尽调研评估各备选学校，从中选定最适合落地诺奖计划的学校。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};
