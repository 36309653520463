// import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import React, { useRef } from 'react';
import styles from "./QRCodeImage.module.css";
import logoImg from "../../../img/logoIco.png"
import { QRCodeCanvas } from 'qrcode.react';

const QRCodeImage = React.forwardRef((props, ref) => (
  // <div ref={ref}>Hello World</div>
  <div ref={ref} className={styles.QrCodeImgContainer}>
    {/*<img src={QrCodeBgImg} className={styles.QrCodeBgImg}></img>*/}
      <div className={styles.QrCodeBgImg}>
          <span className={styles.qrTitle}>诺奖计划报名</span>
          <span className={styles.schoolName}>{props.QRSchoolName}</span>
          <div className={styles.QrCodeImg}>
              {props.QrCodeValue !== "" ?
                  <QRCodeCanvas
                      value={props.QrCodeValue}
                      size={130}
                      imageSettings={{ // 配置二维码中间出现的logo信息
                          src: logoImg, // logo的地址 可以是在线图片也可以是本地图片 没有默认值 类型为string
                          width: 18, // logo的宽度 默认值是整个二维码的10% 类型为number
                          height: 18, // logo的高度 默认值是整个二维码的10% 类型为number
                          excavate: true, // 是否是镂空状态 默认值false 类型为boolean
                      }}
                  /> : null
              }

          </div>
          <span className={styles.payTypeDes}>请使用微信或支付宝扫描</span>
      </div>

  </div>
));

export default QRCodeImage;