import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
  menuIndex:0,
  schoolList:[]
};

export const YYZJMainSlice = createSlice({
  name: 'YYZJMain',
  initialState,
  reducers: {
    setMenuIndex: (state,action) => {
        state.menuIndex = action.payload;
    },
    setSchoolList: (state,action) => {
      state.menuIndex = action.payload;
    }
  },
});

export const selectMenuIndex = (state) => state.YYZJMain.menuIndex;


export const { 
  setMenuIndex,
  setSchoolList
} = YYZJMainSlice.actions;

export default YYZJMainSlice.reducer;
