import React, {useEffect, useRef, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import styles from "./QueryAndExport.module.css";
import classNames from "classnames"
import TopControl from "../../TopControl/TopControl"
import Footer from "../../Footer/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleDown, faL} from '@fortawesome/free-solid-svg-icons';
// import {getSchools} from './QueryAndGetQRApi'
import CourseConditionalQuery from "./CourseConditionalQuery";
// import {  } from "./QueryAndExportSlice";
import CourseInfoList from "./CourseInfoList";
import CourseExport from "./CourseExport";
import OrderExport from "./OrderExport";
// import {getPaymentInfoList} from './QueryAndGetQRApi'
export function QueryAndExport(props) {
    // const dispatch = useDispatch();
    // const reviewPageStatus = useSelector(selectShowReviewPage);
    const [menuIndex,setMenuIndex] = useState(0)


    const dispatch = useDispatch();
    const conditionRef = useRef(null)
    const conditionRef1 = useRef(null)
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    function searchMethod() {
    }
    function refreshMethod() {
        if(menuIndex==0){
            if (conditionRef){
                conditionRef.current.refresh()
            }
        }
        else if (menuIndex==1){
            if (conditionRef1){
                conditionRef1.current.refresh()
            }
        }

    }
    return <div className={styles.background}>
        <TopControl
            leftTopTitle = "诺奖计划数据查询导出"
            showRefreshButton = {true}
            refreshMethod={refreshMethod}
        />
        <div className={styles.typeMenu}>
            <div className={classNames(menuIndex==0?styles.selectMenu:null,styles.menu,styles.menu1)}
                 onClick={(e)=>{
                     setMenuIndex(0)
                 }}
            >
                按交易订单
            </div>
            <div className={classNames(menuIndex==1?styles.selectMenu:null,styles.menu)}
                 onClick={(e)=>{
                     setMenuIndex(1)
                 }}
            >
                按学期课程
            </div>
        </div>
        {menuIndex==0?<OrderExport
            ref={conditionRef}
        />:null}
        {menuIndex==1?<CourseExport
            ref={conditionRef1}
        />:null}

        <Footer/>
    </div>
}

